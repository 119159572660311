<!-- <app-home></app-home> -->
<app-pagenotfound *ngIf="error == 'ACCESS_DENIED'; else GPNav"></app-pagenotfound>

<ng-template #GPNav>
    <mat-sidenav-container class="add-portfolio-container" hasBackdrop="true">
        <mat-sidenav mode="over" position="end" [opened]="this.bankTransactionsService.openTransactionDrawerFlag.value"
            [autoFocus]="false" (closed)="this.bankTransactionsService.closeDrawer()">
            <app-bank-transaction-detail></app-bank-transaction-detail>
        </mat-sidenav>
        <mat-sidenav-content class="add-portfolio-sidenav">
            <mat-sidenav-container class="invoice-sidenav-container" hasBackdrop="true">
                <mat-sidenav mode="over" position="end" class="invoice-details-side-nav"
                    [opened]="this.invoiceDetailService.openInvoiceDetail.value" [autoFocus]="false"
                    (closed)="this.invoiceDetailService.closeInvoiceDetailDrawer()" [@.disabled]="true">
                    <app-invoice-detail *ngIf="this.invoiceDetailService.openInvoiceDetail.value"></app-invoice-detail>
                </mat-sidenav>
                <mat-sidenav-content class="counterpart-side-draw-sidenav">
                    <mat-sidenav-container class="add-portfolio-container" hasBackdrop="true">
                        <mat-sidenav mode="over" position="end"
                            [opened]="this.portfolioService.openPortfolioDrawerFlag.value" [autoFocus]="false"
                            (closed)="this.portfolioService.closePortfolioDrawer()">
                            <app-add-company></app-add-company>
                        </mat-sidenav>
                        <mat-sidenav-content class="add-portfolio-sidenav">
                            <mat-sidenav-container class="task-details-container" hasBackdrop="true">
                                <mat-sidenav mode="over" position="end"
                                    [opened]="this.managementService.openDrawerFlag.value" [autoFocus]="false"
                                    (closed)="this.managementService.closeDrawer()">
                                    <app-task-details></app-task-details>
                                </mat-sidenav>
                                <mat-sidenav-content class="conunterpart-content">
                                    <mat-sidenav-container class="counterpart-side-draw" hasBackdrop="true">
                                        <mat-sidenav mode="over" position="end"
                                            [opened]="this.counterpartDetailService.openDrawerFlag.value"
                                            [autoFocus]="false" (closed)="this.counterpartDetailService.closeDrawer()">
                                            <app-counterpart-side-draw
                                                *ngIf="this.counterpartDetailService.openDrawerFlag.value"></app-counterpart-side-draw>
                                        </mat-sidenav>
                                        <mat-sidenav-content class="task-details-sidenav">
                                            <app-home *ngIf="isAuthenticated"></app-home>
                                        </mat-sidenav-content>
                                    </mat-sidenav-container>
                                </mat-sidenav-content>
                            </mat-sidenav-container>
                        </mat-sidenav-content>
                    </mat-sidenav-container>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>