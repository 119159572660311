<h1 mat-dialog-title>Edit Bank Account</h1>
<mat-dialog-content>
  <div class="edit-account-form">
    <mat-form-field>
      <mat-label>Account Holder Name</mat-label>
      <input matInput [formControl]="accHolderName" required>
      <mat-error *ngIf="accHolderName.hasError('required')">Please enter an account holder name</mat-error>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>IBan</mat-label>
      <input matInput [(ngModel)]="iBan" disabled>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>BIC</mat-label>
      <input matInput [(ngModel)]="bic" disabled>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>Currency</mat-label>
      <input matInput [(ngModel)]="currency" disabled>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>Country</mat-label>
      <input matInput [(ngModel)]="country" disabled>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="action-section">

    <button mat-button (click)="editAccount()" class="add-account"
      [disabled]="!(accHolderName.valid && initialaccHolderName !== accHolderName.value)">Update</button>
  </div>
</mat-dialog-actions>