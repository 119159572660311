import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/Home/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InvoicesComponent } from './pages/transactions/invoices/invoices.component';
import { ManagementComponent, TaskCardComponent, TaskDetailsComponent } from './pages/management/management.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { InvestorsComponent } from './pages/investors/investors.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import {
  AddBankaccountDialog,
  EditBankaccountDialog,
  BankAccountsComponent,
} from './pages/settings/bank-accounts/bank-accounts.component';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { ConfigService } from './initializer/config.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { I18nModule } from './modules/i18n/i18n.module';
import { MaterialModule } from './modules/material/material.module';
import { TermsOfUseComponent } from './shared/components/dialog/terms-of-use/terms-of-use.component';
import {
  MAT_DATE_FORMATS,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { CommingSoonComponent } from './shared/components/comming-soon/comming-soon.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { InvalidPageComponent } from './shared/components/invalid-page/invalid-page.component';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './shared/components/table/table.component';
import { ServiceNotAvailableComponent } from './shared/components/service-not-found/service-not-available.component';
import { SurveyModule } from 'survey-angular-ui';
import {
  PortfolioCompaniesComponent,
  AddPortfolioCompanyDialog,
  AddQuarterDialog,
  DeletePortfolioCompanyDialog,
  SaveQuarterDialog,
} from './pages/portfolio-companies/portfolio-companies.component';
import {
  SucessSnackBarComponent,
  ErrorSnackBarComponent,
  InfoSnackBarComponent,
  SucessBigSnackBarComponent,
} from './shared/components/snack-bar/snack-bar.component';
import { MatSelectPositionDirective } from './shared/directives/select-pos.directive';
import { PCMenuPositionDirective } from './shared/directives/portfolio-company-position.directive';
import { InvestorDetailComponent } from './pages/investors/investor-detail/investor-detail.component';
import { PersonNamePositionDirective } from './shared/directives/person-name-position.directive';
import { ReportPreviewComponent } from './pages/reports/report-preview/report-preview.component';
import { ReportDetailsComponent } from './pages/reports/report-details/report-details.component';
import { DndDirective } from './shared/directives/dnd.directive';
import { FinoComponent } from './pages/settings/fino/fino.component';
import { UserManagementComponent } from './pages/settings/user-management/user-management.component';
import { PaymentApprovalComponent } from './pages/settings/payment-approval/payment-approval.component';
import { DnsEntriesComponent } from './pages/settings/dns-entries/dns-entries.component';
import { BankTransactionDetailComponent, BankTransactionsComponent } from './pages/transactions/bank-transactions/bank-transactions.component';
import { AddCompanyComponent, DeletePortfolioDialog, PortfolioComponent } from './pages/portfolio/portfolio.component';
import { PMenuPositionDirective } from './shared/directives/portfolio-position.directive';

import { LetterHeadPreviewDialog, LetterheadsComponent, RenameLetterheadDialog } from './pages/settings/letterheads/letterheads.component';
import { CUSTOM_DATE_FORMATS } from './utils/mat-date-format.util';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';



const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};
export function storageFactory(): OAuthStorage {
  return localStorage;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InvoicesComponent,
    ManagementComponent,
    ReportsComponent,
    DocumentsComponent,
    InvestorsComponent,
    OnboardingComponent,
    BankAccountsComponent,
    DialogComponent,
    TermsOfUseComponent,
    CommingSoonComponent,
    ServiceNotAvailableComponent,
    PagenotfoundComponent,
    InvalidPageComponent,
    PdfViewerComponent,
    TableComponent,
    AddBankaccountDialog,
    EditBankaccountDialog,
    PortfolioCompaniesComponent,
    AddPortfolioCompanyDialog,
    AddQuarterDialog,
    SaveQuarterDialog,
    DeletePortfolioCompanyDialog,
    SucessSnackBarComponent,
    SucessBigSnackBarComponent,
    ErrorSnackBarComponent,
    InfoSnackBarComponent,
    MatSelectPositionDirective,
    PCMenuPositionDirective,
    PersonNamePositionDirective,
    TaskDetailsComponent,
    TaskCardComponent,
    InvestorDetailComponent,
    ReportPreviewComponent,
    ReportDetailsComponent,
    DndDirective,
    FinoComponent,
    BankTransactionsComponent,
    UserManagementComponent,
    PaymentApprovalComponent,
    DnsEntriesComponent,
    PortfolioComponent,
    PMenuPositionDirective,
    AddCompanyComponent,
    DeletePortfolioDialog,
    RenameLetterheadDialog,
    LetterheadsComponent,
    LetterHeadPreviewDialog,
    BankTransactionDetailComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.datahubApiUrl],
        sendAccessToken: true,
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    I18nModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    SurveyModule,
  ],

  providers: [
    Title,
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.init(),
      deps: [ConfigService],
      multi: true,
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
