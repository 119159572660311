<div class="add-portfolio-company">
    <div class="content">
        <div class="heading">{{data.type === 'add' ? 'Add portfolio company': 'Rename'}}
            <button (click)="dialogRef.close()" [disabled]="loading">
                <img src="../../../assets/images/cross_icon_grey.svg" alt="close">
            </button>
        </div>
        <div class="form-field">
            <div class="lable">Company name</div>
            <mat-form-field class="company-name">
                <input matInput placeholder="Name" [(ngModel)]="companyName" autocomplete="off">
            </mat-form-field>
        </div>
    </div>
    <div class="action">
        <button mat-flat-button class="cancel" (click)="dialogRef.close()">Cancel</button>
        <button *ngIf="data.type === 'add'" mat-flat-button class="save" [disabled]="!companyName || loading"
            (click)="createPortfolioCompany()">Save</button>
        <button *ngIf="data.type !== 'add'" mat-flat-button class="save" [disabled]="!companyName || loading"
            (click)="renamePortfolioCompany()">Save</button>
    </div>
</div>