import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { UserService } from 'src/app/core/services/user.service';
import { ConfigService } from 'src/app/initializer/config.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent {
  constructor(
    private dialogRef: MatDialogRef<TermsOfUseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService,
    private config: ConfigService,
    public userService: UserService
  ) {}
  checkboxChecked = false;

  mailTo() {
    window.location.href = 'mailto:support@ace-alternatives.com';
  }

  toggleCheckbox(checkbox: any) {
    this.checkboxChecked = checkbox.checked;
  }

  submitTermsOfUse() {
    if (this.checkboxChecked && !this.data.confirmedTermsOfUse) {
      this.apiHttpService
        .put(this.apiEndpointsService.postSessionUser(), {
          gpConfirmed: true,
        })
        .subscribe(
          _response => {
            this.dialogRef.close();
            this.config.fetchuserData();
          },
          error => {
            console.log('Error while agreeing Terms of Use: ', error);
          }
        );
    } else {
      this.dialogRef.close();
    }
  }
}
