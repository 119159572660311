import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-service-not-available',
  templateUrl: './service-not-available.component.html',
  styleUrls: ['./service-not-available.component.scss'],
})
export class ServiceNotAvailableComponent {
  @Input()
  description: string;
  constructor(private userService: UserService) {}

  ngOnInit() {}
}
