<div class="counterpart-side-tray"
    *ngIf="(counterpartDetailService.counterpartDetailsObservable$ | async) && (counterpartDetailService.openDrawerObservable$ | async)">
    <div class="header">
        <div class="heading" [ngClass]="{'single': titles.length === 1}" *ngFor="let title of titles; index as i">
            <img *ngIf="i !== 0" src="../../../../assets/images/arrow_right_icon.svg" height="16px" width="16px">
            <span class="breadcrum-nav" *ngIf="i === 0 && titles.length > 1" (click)="hideSubForms()">{{title}}</span>
            <span *ngIf="i === 0 && titles.length === 1">{{title}}</span>
            <span *ngIf="i !== 0">{{title}}</span>
        </div>
        <img src="../../../assets/images/cross_icon_grey.svg" alt="close" height="24px" width="24px"
            (click)="counterpartDetailService.closeDrawer();">
    </div>
    <div #counterpartDetailsSection style="height: calc(100vh - 81px); overflow-y: scroll;">
        <!-- conuterpart details -->
        <ng-container
            *ngIf="!isCreate && !showContactForm && !showBankAccountForm && !showVatIdForm && !showAddressForm && !showBasicForm">
            <div class="counterpart-details content">
                <div class="basic-details">
                    <div class="section-header">
                        <div class="section-heading">
                            <img src="../../../../assets/images/company_big_blackbg.svg" height="36px" width="36px">
                            Basic details
                        </div>
                        <div class="cta">
                            <button (click)="editCounterpartBasicDetail()"> <img
                                    src="../../../../assets/images/edit_icon_black.svg" height="20px"
                                    width="20px">Edit</button>
                        </div>
                    </div>
                    <div class="details">
                        <div class="item" *ngFor="let item of convertObjectToArray(basicDetails)">
                            <div class="key">{{item.key}}</div>
                            <div class="value">{{item.value}}</div>
                        </div>
                    </div>
                </div>
                <div class="address">
                    <div class="section-header">
                        <div class="section-heading">
                            <img src="../../../../assets/images/address_bigblackbg.svg" height="36px" width="36px">
                            Address
                        </div>
                        <div class="cta" *ngIf="!fetchingAddress; else ctaLoader">
                            <button (click)="editCounterpartAddress()"> <img
                                    src="../../../../assets/images/edit_icon_black.svg" height="20px"
                                    width="20px">Edit</button>
                        </div>
                    </div>
                    <div class="details" *ngIf="!fetchingAddress; else addressDetailsLoader ">
                        <div class="item" *ngFor="let item of convertObjectToArray(address)">
                            <div class="key">{{item.key}}</div>
                            <div class="value">{{item.value}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedCounterpart.type === 'organization'" class="contact-person">
                    <div class="section-header">
                        <div class="section-heading">
                            <img src="../../../../assets/images/persons_big_blackbg.svg" height="36px" width="36px">
                            Contact Person
                        </div>
                        <div class="cta" *ngIf="!fetchingContacts; else ctaLoader">
                            <button (click)="addContactHandler()">
                                <img src="../../../../assets/images/plus_icon_black.svg" height="20px" width="20px">Add
                            </button>
                        </div>
                    </div>
                    <div class="details" *ngIf="!fetchingContacts; else detailsLoader">
                        <div class="item" *ngFor="let item of contacts">
                            <div class="info contact">
                                <div> {{item.first_name}} {{item.last_name}}
                                    <div class="badge" *ngIf="item.is_default">Primary</div>
                                </div>
                                <div>
                                    <div>
                                        <img src="../../../../assets/images/mail_icon.svg" height="16px" width="16px">
                                        {{item?.email}}
                                    </div>
                                    <div *ngIf="item.phone">
                                        <img src="../../../../assets/images/phone_icon.svg" height="16px" width="16px">
                                        {{item?.phone}}
                                    </div>
                                </div>
                            </div>
                            <img [matMenuTriggerFor]="contactMenu"
                                src="../../../../assets/images/three_dot_menu_icon.svg" height="24px" width="24px">
                            <mat-menu class="counterpart-mat-menu" #contactMenu="matMenu" xPosition="before"
                                counterpart-detail-action-position>
                                <div *ngIf="!item.is_default" mat-menu-item
                                    (click)="makePrimaryContact(item); $event.preventDefault()">
                                    <img src="../../../../assets/images/user_icon.svg" height="20px" width="20px" />
                                    Make primary
                                </div>
                                <div mat-menu-item (click)="editContact(item); $event.preventDefault()">
                                    <img src="../../../../assets/images/edit_icon.svg" height="20px" width="20px" />
                                    Edit
                                </div>
                                <div *ngIf="!item.is_default" mat-menu-item
                                    (click)="deleteContact(item.id); $event.preventDefault()">
                                    <img src="../../../../assets/images/delete_icon.svg" height="20px" width="20px" />
                                    Delete
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="vat-ids">
                    <div class="section-header">
                        <div class="section-heading">
                            <img src="../../../../assets/images/percentage_big_blackbg.svg" height="36px" width="36px">
                            VAT IDs
                        </div>
                        <div class="cta" *ngIf="!fetchingVatIds; else ctaLoader">
                            <button (click)="addVatIdsHandler()">
                                <img src="../../../../assets/images/plus_icon_black.svg" height="20px" width="20px">Add
                            </button>
                        </div>
                    </div>
                    <div class="details" *ngIf="!fetchingVatIds; else detailsLoader">
                        <div class="item" *ngFor="let item of vatIds">
                            <div class="info">
                                <div>{{item.value}}</div>
                                <div>{{item.country}}</div>
                            </div>
                            <img [matMenuTriggerFor]="vatIdMenu" src="../../../../assets/images/three_dot_menu_icon.svg"
                                height="24px" width="24px">
                            <mat-menu class="counterpart-mat-menu" #vatIdMenu="matMenu" xPosition="before"
                                counterpart-detail-action-position>
                                <div mat-menu-item (click)="editVatId(item); $event.preventDefault()">
                                    <img src="../../../../assets/images/edit_icon.svg" alt="delete_icon" height="20px"
                                        width="20px" />
                                    Edit
                                </div>
                                <div mat-menu-item (click)="deleteVatId(item.id); $event.preventDefault()">
                                    <img src="../../../../assets/images/delete_icon.svg" alt="delete_icon" height="20px"
                                        width="20px" />
                                    Delete
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <div class="bank-account">
                    <div class="section-header">
                        <div class="section-heading">
                            <img src="../../../../assets/images/bank_big_blackbg.svg" height="36px" width="36px">
                            Bank Accounts
                        </div>
                        <div class="cta" *ngIf="!fetchingBankAccounts; else ctaLoader">
                            <button (click)="addBankAccountHandler()">
                                <img src="../../../../assets/images/plus_icon_black.svg" height="20px" width="20px">Add
                            </button>
                        </div>
                    </div>
                    <div class="details" *ngIf="!fetchingBankAccounts; else detailsLoader">
                        <div class="item" *ngFor="let item of bankAccounts">
                            <div class="info">
                                <div>{{item.name}}
                                    <div class="badge" *ngIf="item.is_default_for_currency">Default</div>
                                </div>
                                <div>{{item.iban}}</div>
                            </div>
                            <img [matMenuTriggerFor]="accountMenu"
                                src="../../../../assets/images/three_dot_menu_icon.svg" height="24px" width="24px">
                            <mat-menu class="counterpart-mat-menu" #accountMenu="matMenu" xPosition="before"
                                counterpart-detail-action-position>
                                <div *ngIf="!item.is_default_for_currency" mat-menu-item
                                    (click)="makePrimaryAccount(item); $event.preventDefault()">
                                    <img src="../../../../assets/images/flag_team_icon.svg" height="20px"
                                        width="20px" />
                                    Set as default
                                </div>
                                <div mat-menu-item (click)="editBankAccount(item); $event.preventDefault()">
                                    <img src="../../../../assets/images/edit_icon.svg" height="20px" width="20px" />
                                    Edit
                                </div>
                                <div mat-menu-item (click)="deleteBankAccount(item.id); $event.preventDefault()">
                                    <img src="../../../../assets/images/delete_icon.svg" height="20px" width="20px" />
                                    Delete
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- create counterPart -->
        <ng-container *ngIf="isCreate">
            <form #createCounterpartForm="ngForm" (change)="createCounterpartForm.control.markAsTouched()">
                <div class="create-counterpart-form content">
                    <div class="counterpart-type">
                        <div class="lable">Set this counterparty as:</div>
                        <input [(ngModel)]="createCounterpart.type" name="type" type="text" [hidden]="true">
                        <div class="type-wrapper">
                            <div class="type-container" (click)="createCounterpart.type = 'organization'">
                                <img *ngIf="createCounterpart.type !== 'organization'"
                                    src="../../../../assets/images/counterpart_unselect.svg" height="24px" width="24px">
                                <img *ngIf="createCounterpart.type === 'organization'"
                                    src="../../../../assets/images/counterpart_select.svg" height="24px" width="24px">
                                Legal entity
                            </div>
                            <div class="type-container" (click)="createCounterpart.type = 'individual'">
                                <img *ngIf="createCounterpart.type === 'individual'"
                                    src="../../../../assets/images/counterpart_select.svg" height="24px" width="24px">
                                <img *ngIf="createCounterpart.type !== 'individual'"
                                    src="../../../../assets/images/counterpart_unselect.svg" height="24px" width="24px">
                                Private individual
                            </div>
                        </div>
                    </div>
                    <div class="name-fields" *ngIf="createCounterpart.type === 'individual'">
                        <div class="input-text">
                            <div class="lable">
                                First name <span class="required">*</span>
                            </div>
                            <input [(ngModel)]="createCounterpart.first_name"
                                (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                                [ngClass]="{'error': firstName.invalid && firstName.touched}" name="firstName"
                                #firstName="ngModel" required type="text" placeholder="Enter first name">
                            <div *ngIf="firstName.invalid && firstName.touched">
                                <span class="post-icon error">
                                    <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                                </span>
                                <div class="error-msg">Please enter first name</div>
                            </div>
                        </div>
                        <div class="input-text">
                            <div class="lable">
                                Last name <span class="required">*</span>
                            </div>
                            <input [(ngModel)]="createCounterpart.last_name"
                                (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                                [ngClass]="{'error': lastName.invalid && lastName.touched}" name="lastName"
                                #lastName="ngModel" required type="text" placeholder="Enter last name">
                            <div *ngIf="lastName.invalid && lastName.touched">
                                <span class="post-icon error">
                                    <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                                </span>
                                <div class="error-msg">Please enter last name</div>
                            </div>
                        </div>
                    </div>
                    <div class="input-text" *ngIf="createCounterpart.type === 'organization'">
                        <div class="lable">
                            Company name <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="createCounterpart.legal_name"
                            (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                            [ngClass]="{'error': name.invalid && name.touched}" name="name" type="text"
                            placeholder="Enter a name" #name="ngModel" required>
                        <div *ngIf="name.invalid && name.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter name</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Phone
                        </div>
                        <input [(ngModel)]="createCounterpart.phone"
                            (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                            [ngClass]="{'error': phone.invalid && phone.touched}" pattern="^[0-9]+$" #phone="ngModel"
                            name="phone" type="text" placeholder="Enter phone number">
                        <div *ngIf="phone.invalid && phone.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg" *ngIf="phone.errors?.['pattern']">Please enter a valid phone
                                number
                            </div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Email <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="createCounterpart.email"
                            (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                            [ngClass]="{'error': email.invalid && email.touched}" #email="ngModel" required email
                            name="email" type="email" placeholder="Enter email address">
                        <div *ngIf="email.invalid && email.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg" *ngIf="email.errors?.['required']">Please enter email</div>
                            <div class="error-msg" *ngIf="email.errors?.['email']">Please enter a valid email</div>
                        </div>
                    </div>
                    <div class="email-remainder">
                        <div class="description">
                            <div class="lable">Enable email reminders</div>
                            You can change this setting if you wish to forbid sending any payment reminders to this
                            counterpart.
                        </div>
                        <label class="switch" for="checkbox">
                            <input [(ngModel)]="createCounterpart.reminders_enabled" #remainder="ngModel"
                                name="remainder" type="checkbox" id="checkbox"
                                (change)="remainder.control.markAsTouched()" />
                            <div class="slider round"></div>
                        </label>
                    </div>
                    <div class="address">
                        <div class="lable">
                            Business address
                        </div>
                        <div class="wrapper">
                            <div class="input-text">
                                <div class="lable">
                                    Address line 1 <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createCounterpart.address.line1"
                                    (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                                    [ngClass]="{'error': address1.invalid && address1.touched}" required name="address1"
                                    #address1="ngModel" type="text" placeholder="Enter address line 1" required>
                                <div *ngIf="address1.invalid && address1.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter address line 1</div>
                                </div>
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    Address line 2
                                </div>
                                <input [(ngModel)]="createCounterpart.address.line2"
                                    (ngModelChange)="createCounterpartForm.form.markAsTouched()" name="address2"
                                    type="text" placeholder="Enter address line 2">
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    City <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createCounterpart.address.city"
                                    (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                                    [ngClass]="{'error': city.invalid && city.touched}" name="city" #city="ngModel"
                                    type="text" placeholder="eg. Berlin" required>
                                <div *ngIf="city.invalid && city.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter city</div>
                                </div>
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    ZIP code <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createCounterpart.address.postal_code"
                                    (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                                    [ngClass]="{'error': zipCode.invalid && zipCode.touched}" name="zipCode"
                                    #zipCode="ngModel" type="text" placeholder="eg. 10176" required>
                                <div *ngIf="zipCode.invalid && zipCode.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter ZIP code</div>
                                </div>
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    State / Area / Province <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createCounterpart.address.state"
                                    (ngModelChange)="createCounterpartForm.form.markAsTouched()"
                                    [ngClass]="{'error': state.invalid && state.touched}" name="state" #state="ngModel"
                                    type="text" placeholder="eg. Brandenburg" required>
                                <div *ngIf="state.invalid && state.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter State / Area / Province</div>
                                </div>
                            </div>
                            <div class="dropdown" [ngClass]="{'error': country.invalid && country.touched}">
                                <div class="lable">Country <span class="required">*</span></div>
                                <mat-form-field appearance="fill">
                                    <mat-select [(ngModel)]="createCounterpart.address.country" name="country"
                                        #country="ngModel" placeholder="Select a country" #locationSelect required>
                                        <mat-option class="create-counterpart-options small"
                                            *ngFor="let item of countries"
                                            [value]="item.value">{{item.name}}</mat-option>
                                    </mat-select>
                                    <img class="arrow-down" [ngClass]="{ 'open': locationSelect.panelOpen}"
                                        src="../../../../assets/images/arrow-down.svg" height="20px" width="20px"
                                        matSuffix />
                                </mat-form-field>
                                <div *ngIf="country.invalid && country.touched">
                                    <div class="error-msg">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            TAX ID
                        </div>
                        <input [(ngModel)]="createCounterpart.tax_id" name="taxId" type="text"
                            placeholder="Enter a valid Tax ID">
                    </div>
                </div>
                <div class="footer">
                    <button (click)="counterpartDetailService.closeDrawer();">Cancel</button>
                    <button class="create"
                        [disabled]="createCounterpartForm.invalid || creatingCounterpart || !createCounterpartForm.touched"
                        (click)="createCounterpartHandler()">
                        <ng-container *ngIf="!creatingCounterpart; else spinner">
                            Create
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
        <!-- add contact person -->
        <ng-container *ngIf="showContactForm && !isCreate">
            <form #createContactForm="ngForm">
                <div class="add-contact-form content">
                    <div class="name-fields">
                        <div class="input-text">
                            <div class="lable">
                                First name <span class="required">*</span>
                            </div>
                            <input [(ngModel)]="createContact.first_name"
                                (ngModelChange)="createContactForm.form.markAsTouched()"
                                [ngClass]="{'error': contactFirstName.invalid && contactFirstName.touched}"
                                name="contactFirstName" #contactFirstName="ngModel" required type="text"
                                placeholder="Enter first name">
                            <div *ngIf="contactFirstName.invalid && contactFirstName.touched">
                                <span class="post-icon error">
                                    <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                                </span>
                                <div class="error-msg">Please enter first name</div>
                            </div>
                        </div>
                        <div class="input-text">
                            <div class="lable">
                                Last name <span class="required">*</span>
                            </div>
                            <input [(ngModel)]="createContact.last_name"
                                (ngModelChange)="createContactForm.form.markAsTouched()"
                                [ngClass]="{'error': contactLastName.invalid && contactLastName.touched}"
                                name="contactLastName" #contactLastName="ngModel" required type="text"
                                placeholder="Enter last name">
                            <div *ngIf="contactLastName.invalid && contactLastName.touched">
                                <span class="post-icon error">
                                    <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                                </span>
                                <div class="error-msg">Please enter last name</div>
                            </div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Email <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="createContact.email"
                            (ngModelChange)="createContactForm.form.markAsTouched()"
                            [ngClass]="{'error': contactEmail.invalid && contactEmail.touched}" name="contactEmail"
                            #contactEmail="ngModel" required email type="email" placeholder="Enter email address">
                        <div *ngIf="contactEmail.invalid && contactEmail.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg" *ngIf="contactEmail.errors?.['required']">Please enter email</div>
                            <div class="error-msg" *ngIf="contactEmail.errors?.['email']">Please enter a valid email
                            </div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Phone
                        </div>
                        <input [(ngModel)]="createContact.phone"
                            (ngModelChange)="createContactForm.form.markAsTouched()"
                            [ngClass]="{'error': contactPhone.invalid && contactPhone.touched}" pattern="^[0-9]+$"
                            name="contactPhone" #contactPhone="ngModel" type="text" placeholder="Enter phone number">
                        <div *ngIf="contactPhone.invalid && contactPhone.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg" *ngIf="contactPhone.errors?.['pattern']">Please enter a valid phone
                                number
                            </div>
                        </div>
                    </div>
                    <div class="address">
                        <div class="lable">
                            Address
                        </div>
                        <div class="wrapper">
                            <div class="input-text">
                                <div class="lable">
                                    Address line 1 <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createContact.address.line1"
                                    (ngModelChange)="createContactForm.form.markAsTouched()"
                                    [ngClass]="{'error': contactAddress1.invalid && contactAddress1.touched}"
                                    name="contactAddress1" #contactAddress1="ngModel" required type="text"
                                    placeholder="Enter address line 1">
                                <div *ngIf="contactAddress1.invalid && contactAddress1.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter address line 1</div>
                                </div>
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    Address line 2
                                </div>
                                <input [(ngModel)]="createContact.address.line2"
                                    (ngModelChange)="createContactForm.form.markAsTouched()" name="contactAddress2"
                                    type="text" placeholder="Enter address line 2">
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    City <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createContact.address.city"
                                    (ngModelChange)="createContactForm.form.markAsTouched()"
                                    [ngClass]="{'error': contactCity.invalid && contactCity.touched}" name="contactCity"
                                    #contactCity="ngModel" required type="text" placeholder="eg. Berlin">
                                <div *ngIf="contactCity.invalid && contactCity.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter city</div>
                                </div>
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    ZIP code <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createContact.address.postal_code"
                                    (ngModelChange)="createContactForm.form.markAsTouched()"
                                    [ngClass]="{'error': contactZipCode.invalid && contactZipCode.touched}"
                                    name="contactZipCode" #contactZipCode="ngModel" required type="text"
                                    placeholder="eg. 10176">
                                <div *ngIf="contactZipCode.invalid && contactZipCode.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter ZIP code</div>
                                </div>
                            </div>
                            <div class="input-text">
                                <div class="lable">
                                    State / Area / Province <span class="required">*</span>
                                </div>
                                <input [(ngModel)]="createContact.address.state"
                                    (ngModelChange)="createContactForm.form.markAsTouched()"
                                    [ngClass]="{'error': contactState.invalid && contactState.touched}"
                                    name="contactState" #contactState="ngModel" required type="text"
                                    placeholder="eg. Brandenburg">
                                <div *ngIf="contactState.invalid && contactState.touched">
                                    <span class="post-icon error">
                                        <img src="../../../../assets/images/warning_icon.svg" height="20px"
                                            width="20px">
                                    </span>
                                    <div class="error-msg">Please enter state / area / province</div>
                                </div>
                            </div>
                            <div class="dropdown"
                                [ngClass]="{'error': contactCountry.invalid && contactCountry.touched}">
                                <div class="lable">Country <span class="required">*</span></div>
                                <mat-form-field appearance="fill">
                                    <mat-select [(ngModel)]="createContact.address.country" name="contactCountry"
                                        #contactCountry="ngModel" required placeholder="Select a country"
                                        #locationSelect>
                                        <mat-option class="create-counterpart-options small"
                                            *ngFor="let item of countries"
                                            [value]="item.value">{{item.name}}</mat-option>
                                    </mat-select>
                                    <img class="arrow-down" [ngClass]="{ 'open': locationSelect.panelOpen}"
                                        src="../../../../assets/images/arrow-down.svg" height="20px" width="20px"
                                        matSuffix />
                                </mat-form-field>
                                <div *ngIf="contactCountry.invalid && contactCountry.touched">
                                    <div class="error-msg">Please select country</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="checkbox">
                            <mat-checkbox [(ngModel)]="createContact.isPrimary" name="contactIsPrimary">Set as
                                primary</mat-checkbox>
                        </div> -->
                </div>
                <div class="footer">
                    <button (click)="hideSubForms()">Cancel</button>
                    <button *ngIf="!editForm" class="create"
                        [disabled]="createContactForm.invalid || creatingContact || !createContactForm.touched"
                        (click)="createContactHandler()">
                        <ng-container *ngIf="!creatingContact; else spinner">
                            Add contact
                        </ng-container>
                    </button>
                    <button *ngIf="editForm" class="create"
                        [disabled]="createContactForm.invalid || creatingContact || !createContactForm.touched"
                        (click)="updateContactHandler()">
                        <ng-container *ngIf="!creatingContact; else spinner">
                            Update
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
        <!-- add vat id -->
        <ng-container *ngIf="showVatIdForm && !isCreate">
            <form #createVatIdForm="ngForm">
                <div class="add-vatid-form content">
                    <div class="dropdown" [ngClass]="{'error': vatCountry.invalid && vatCountry.touched}">
                        <div class="lable">Country <span class="required">*</span></div>
                        <mat-form-field appearance="fill">
                            <mat-select [(ngModel)]="createVatId.country" name="vatCountry" #vatCountry="ngModel"
                                required placeholder="Select a country" #locationSelect>
                                <mat-option class="create-counterpart-options" *ngFor="let item of countries"
                                    [value]="item.value">{{item.name}}</mat-option>
                            </mat-select>
                            <img class="arrow-down" [ngClass]="{ 'open': locationSelect.panelOpen}"
                                src="../../../../assets/images/arrow-down.svg" height="20px" width="20px" matSuffix />
                        </mat-form-field>
                        <div *ngIf="vatCountry.invalid && vatCountry.touched">
                            <div class="error-msg">Please select country</div>
                        </div>
                    </div>
                    <div class="dropdown" [ngClass]="{'error': vatType.invalid && vatType.touched}">
                        <div class="lable">VAT Type <span class="required">*</span></div>
                        <mat-form-field appearance="fill">
                            <mat-select [(ngModel)]="createVatId.type" name="vatType" #vatType="ngModel" required
                                placeholder="Select a type" #locationSelect>
                                <mat-option class="create-counterpart-options" *ngFor="let item of vatTypes"
                                    [value]="item.value">{{item.name}}</mat-option>
                            </mat-select>
                            <img class="arrow-down" [ngClass]="{ 'open': locationSelect.panelOpen}"
                                src="../../../../assets/images/arrow-down.svg" height="20px" width="20px" matSuffix />
                        </mat-form-field>
                        <div *ngIf="vatType.invalid && vatType.touched">
                            <div class="error-msg">Please select type</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable"> VAT ID <span class="required">*</span></div>
                        <input [(ngModel)]="createVatId.value" (ngModelChange)="createVatIdForm.form.markAsTouched()"
                            [ngClass]="{'error': vatId.invalid && vatId.touched}" name="vatId" #vatId="ngModel" required
                            type="text" placeholder="Enter your VAT Id">
                        <div *ngIf="vatId.invalid && vatId.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter VAT Id</div>
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <button (click)="hideSubForms()">Cancel</button>
                    <button *ngIf="!editForm" class="create"
                        [disabled]="createVatIdForm.invalid || creatingVatId || !createVatIdForm.touched"
                        (click)="createvatIdHandler()">
                        <ng-container *ngIf="!creatingVatId; else spinner">
                            Add VAT ID
                        </ng-container>
                    </button>
                    <button *ngIf="editForm" class="create"
                        [disabled]="createVatIdForm.invalid || creatingVatId || !createVatIdForm.touched"
                        (click)="updateVatIdHandler()">
                        <ng-container *ngIf="!creatingVatId; else spinner">
                            Update
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
        <!-- add bank account -->
        <ng-container *ngIf="showBankAccountForm && !isCreate">
            <form #createBankAccountForm="ngForm">
                <div class="add-bank-account-form content">
                    <div class="input-text">
                        <div class="lable">
                            Account name <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="createBankAccount.name"
                            (ngModelChange)="createBankAccountForm.form.markAsTouched()"
                            [ngClass]="{'error': accountName.invalid && accountName.touched}" name="accountName"
                            #accountName="ngModel" required type="text" placeholder="Enter account name">
                        <div *ngIf="accountName.invalid && accountName.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter account name</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Account number
                        </div>
                        <input [(ngModel)]="createBankAccount.account_number"
                            (ngModelChange)="createBankAccountForm.form.markAsTouched()" name="accountNumber"
                            type="text" placeholder="Enter account number">
                    </div>
                    <div class="dropdown" [ngClass]="{'error': location.invalid && location.touched}">
                        <div class="lable">Bank location <span class="required">*</span></div>
                        <mat-form-field appearance="fill">
                            <img src="../../../../assets/images/location_icon.svg" height="20px" width="20px"
                                matPrefix />
                            <mat-select [(ngModel)]="createBankAccount.country" name="location" #location="ngModel"
                                required placeholder="Select a location" #locationSelect>
                                <mat-option class="create-counterpart-options" *ngFor="let item of countries"
                                    [value]="item.value">{{item.name}}</mat-option>
                            </mat-select>
                            <img class="arrow-down" [ngClass]="{ 'open': locationSelect.panelOpen}"
                                src="../../../../assets/images/arrow-down.svg" height="20px" width="20px" matSuffix />
                        </mat-form-field>
                        <div *ngIf="location.invalid && location.touched">
                            <div class="error-msg">Please select location</div>
                        </div>
                    </div>
                    <div class="dropdown" [ngClass]="{'error': currency.invalid && currency.touched}">
                        <div class="lable">Currency <span class="required">*</span></div>
                        <mat-form-field appearance="fill">
                            <img src="../../../../assets/images/cash_icon.svg" height="20px" width="20px" matPrefix />
                            <mat-select [(ngModel)]="createBankAccount.currency" name="currency" #currency="ngModel"
                                required placeholder="Select a currency" #currencySelect>
                                <mat-option class="create-counterpart-options" *ngFor="let item of currencies"
                                    [value]="item.value">{{item.name}}</mat-option>
                            </mat-select>
                            <img class="arrow-down" [ngClass]="{ 'open': currencySelect.panelOpen}"
                                src="../../../../assets/images/arrow-down.svg" height="20px" width="20px" matSuffix />
                        </mat-form-field>
                        <div *ngIf="currency.invalid && currency.touched">
                            <div class="error-msg">Please select currency</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            IBAN <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="createBankAccount.iban"
                            (ngModelChange)="createBankAccountForm.form.markAsTouched()"
                            [ngClass]="{'error': iban.invalid && iban.touched}" name="iban" #iban="ngModel" required
                            type="text" placeholder="Enter IBAN">
                        <div *ngIf="iban.invalid && iban.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter IBAN</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            SWIFT/BIC
                        </div>
                        <input [(ngModel)]="createBankAccount.bic"
                            (ngModelChange)="createBankAccountForm.form.markAsTouched()" name="bic" type="text"
                            placeholder="Enter SWIFT/BIC">
                        <span class="post-icon">
                            <img src="../../../../assets/images/help_circle_grey.svg" height="20px" width="20px">
                        </span>
                    </div>
                    <div class="checkbox" *ngIf="!editForm">
                        <mat-checkbox [(ngModel)]="createBankAccount.is_default_for_currency" name="isDefault">Set as
                            default</mat-checkbox>
                    </div>
                </div>
                <div class="footer">
                    <button (click)="hideSubForms()">Cancel</button>
                    <button *ngIf="!editForm" class="create" (click)="createBankAccountHandler()"
                        [disabled]="createBankAccountForm.invalid || creatingBankAccount || !createBankAccountForm.touched">
                        <ng-container *ngIf="!creatingBankAccount; else spinner">
                            Add bank
                        </ng-container>
                    </button>
                    <button *ngIf="editForm" class="create" (click)="updateBankaccountHandler()"
                        [disabled]="createBankAccountForm.invalid || creatingBankAccount || !createBankAccountForm.touched">
                        <ng-container *ngIf="!creatingBankAccount; else spinner">
                            Update
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
        <!-- edit address form -->
        <ng-container *ngIf="showAddressForm && !isCreate">
            <form #editAddressForm="ngForm">
                <div class="edit-address-form content">
                    <div class="input-text">
                        <div class="lable">
                            Address line 1 <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="editAddress.line1" (ngModelChange)="editAddressForm.form.markAsTouched()"
                            [ngClass]="{'error': editAddress1.invalid && editAddress1.touched}" name="contactAddress1"
                            #editAddress1="ngModel" required type="text" placeholder="Enter address line 1">
                        <div *ngIf="editAddress1.invalid && editAddress1.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter address line 1</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Address line 2
                        </div>
                        <input [(ngModel)]="editAddress.line2" (ngModelChange)="editAddressForm.form.markAsTouched()"
                            name="editAddress2" type="text" placeholder="Enter address line 2">
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            City <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="editAddress.city" (ngModelChange)="editAddressForm.form.markAsTouched()"
                            [ngClass]="{'error': editAddressCity.invalid && editAddressCity.touched}" name="contactCity"
                            #editAddressCity="ngModel" required type="text" placeholder="eg. Berlin">
                        <div *ngIf="editAddressCity.invalid && editAddressCity.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter city</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            ZIP code <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="editAddress.postal_code"
                            (ngModelChange)="editAddressForm.form.markAsTouched()"
                            [ngClass]="{'error': editAddressZipCode.invalid && editAddressZipCode.touched}"
                            name="contactZipCode" #editAddressZipCode="ngModel" required type="text"
                            placeholder="eg. 10176">
                        <div *ngIf="editAddressZipCode.invalid && editAddressZipCode.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter ZIP code</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            State / Area / Province <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="editAddress.state" (ngModelChange)="editAddressForm.form.markAsTouched()"
                            [ngClass]="{'error': editAddressState.invalid && editAddressState.touched}"
                            name="editAddressState" #editAddressState="ngModel" required type="text"
                            placeholder="eg. Brandenburg">
                        <div *ngIf="editAddressState.invalid && editAddressState.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter state / area / province</div>
                        </div>
                    </div>
                    <div class="dropdown"
                        [ngClass]="{'error': editAddressCountry.invalid && editAddressCountry.touched}">
                        <div class="lable">Country <span class="required">*</span></div>
                        <mat-form-field appearance="fill">
                            <mat-select [(ngModel)]="editAddress.country" name="contactCountry"
                                #editAddressCountry="ngModel" required placeholder="Select a country" #locationSelect>
                                <mat-option class="create-counterpart-options" *ngFor="let item of countries"
                                    [value]="item.value">{{item.name}}</mat-option>
                            </mat-select>
                            <img class="arrow-down" [ngClass]="{ 'open': locationSelect.panelOpen}"
                                src="../../../../assets/images/arrow-down.svg" height="20px" width="20px" matSuffix />
                        </mat-form-field>
                        <div *ngIf="editAddressCountry.invalid && editAddressCountry.touched">
                            <div class="error-msg">Please select country</div>
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <button (click)="hideSubForms()">Cancel</button>
                    <button class="create"
                        [disabled]="editAddressForm.invalid || editingAddress || !editAddressForm.touched"
                        (click)="editAddressHandler()">
                        <ng-container *ngIf="!editingAddress; else spinner">
                            Update
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
        <!-- edit basic info form -->
        <ng-container *ngIf="showBasicForm && !isCreate">
            <form #editBasicForm="ngForm">
                <div class="edit-basic-form content">
                    <div class="name-fields" *ngIf="selectedCounterpart.type === 'individual'">
                        <div class="input-text">
                            <div class="lable">
                                First name <span class="required">*</span>
                            </div>
                            <input [(ngModel)]="editBasicDetail.first_name"
                                (ngModelChange)="editBasicForm.form.markAsTouched()"
                                [ngClass]="{'error': editBasicfirstName.invalid && editBasicfirstName.touched}"
                                name="editBasicfirstName" #editBasicfirstName="ngModel" required type="text"
                                placeholder="Enter first name">
                            <div *ngIf="editBasicfirstName.invalid && editBasicfirstName.touched">
                                <span class="post-icon error">
                                    <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                                </span>
                                <div class="error-msg">Please enter first name</div>
                            </div>
                        </div>
                        <div class="input-text">
                            <div class="lable">
                                Last name <span class="required">*</span>
                            </div>
                            <input [(ngModel)]="editBasicDetail.last_name"
                                (ngModelChange)="editBasicForm.form.markAsTouched()"
                                [ngClass]="{'error': editBasiclastName.invalid && editBasiclastName.touched}"
                                name="editBasiclastName" #editBasiclastName="ngModel" required type="text"
                                placeholder="Enter last name">
                            <div *ngIf="editBasiclastName.invalid && editBasiclastName.touched">
                                <span class="post-icon error">
                                    <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                                </span>
                                <div class="error-msg">Please enter last name</div>
                            </div>
                        </div>
                    </div>
                    <div class="input-text" *ngIf="selectedCounterpart.type === 'organization'">
                        <div class="lable">
                            Company name <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="editBasicDetail.legal_name"
                            (ngModelChange)="editBasicForm.form.markAsTouched()"
                            [ngClass]="{'error': editBasicLegalName.invalid && editBasicLegalName.touched}"
                            name="editBasicLegalName" type="text" placeholder="Enter a name"
                            #editBasicLegalName="ngModel" required>
                        <div *ngIf="editBasicLegalName.invalid && editBasicLegalName.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg">Please enter name</div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Phone
                        </div>
                        <input [(ngModel)]="editBasicDetail.phone" (ngModelChange)="editBasicForm.form.markAsTouched()"
                            [ngClass]="{'error': editBasicphone.invalid && editBasicphone.touched}" pattern="^[0-9]+$"
                            #editBasicphone="ngModel" name="phone" type="text" placeholder="Enter phone number">
                        <div *ngIf="editBasicphone.invalid && editBasicphone.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg" *ngIf="editBasicphone.errors?.['pattern']">Please enter a valid phone
                                number
                            </div>
                        </div>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            Email <span class="required">*</span>
                        </div>
                        <input [(ngModel)]="editBasicDetail.email" (ngModelChange)="editBasicForm.form.markAsTouched()"
                            [ngClass]="{'error': editBasicEmail.invalid && editBasicEmail.touched}"
                            #editBasicEmail="ngModel" required email name="editBasicEmail" type="email"
                            placeholder="Enter email address">
                        <div *ngIf="editBasicEmail.invalid && editBasicEmail.touched">
                            <span class="post-icon error">
                                <img src="../../../../assets/images/warning_icon.svg" height="20px" width="20px">
                            </span>
                            <div class="error-msg" *ngIf="editBasicEmail.errors?.['required']">Please enter email</div>
                            <div class="error-msg" *ngIf="editBasicEmail.errors?.['email']">Please enter a valid email
                            </div>
                        </div>
                    </div>
                    <div class="email-remainder">
                        <div class="description">
                            <div class="lable">Enable email reminders</div>
                            You can change this setting if you wish to forbid sending any payment reminders to this
                            counterpart.
                        </div>
                        <label class="switch" for="checkbox">
                            <input [(ngModel)]="editBasicDetail.reminders_enabled"
                                (ngModelChange)="editBasicForm.form.markAsTouched()" #editBasicRemainder="ngModel"
                                name="editBasicRemainder" type="checkbox" id="checkbox"
                                (change)="editBasicRemainder.control.markAsTouched()" />
                            <div class="slider round"></div>
                        </label>
                    </div>
                    <div class="input-text">
                        <div class="lable">
                            TAX ID
                        </div>
                        <input [(ngModel)]="editBasicDetail.tax_id" (ngModelChange)="editBasicForm.form.markAsTouched()"
                            name="editBasicTaxId" type="text" placeholder="Enter a valid Tax ID">
                    </div>
                </div>

                <div class="footer">
                    <button (click)="hideSubForms()">Cancel</button>
                    <button class="create" [disabled]="editBasicForm.invalid || editingBasic || !editBasicForm.touched"
                        (click)="editBasicHandler()">
                        <ng-container *ngIf="!editingBasic; else spinner">
                            Update
                        </ng-container>
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
</div>
<ng-template #spinner>
    <div style="display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>
<ng-template #addressDetailsLoader>
    <div style="display: flex; flex-direction: column; gap: 18px">
        <ngx-skeleton-loader *ngFor="let number of [0, 1, 2, 3, 4, 5]" count="1" appearance="line" [theme]="{
                          width: '100%',
                          height: '30px',
                          marginBottom: 0
                        }"></ngx-skeleton-loader>
    </div>
</ng-template>
<ng-template #detailsLoader>
    <div style="display: flex; flex-direction: column; gap: 18px">
        <div *ngFor="let number of [0, 1]" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; flex-direction: column; gap: 8px;">
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                              width: '185px',
                              height: '20px',
                              marginBottom: 0
                            }"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '105px',
                                height: '20px',
                                marginBottom: 0
                              }"></ngx-skeleton-loader>
            </div>

            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '24px',
                                height: '24px',
                                marginBottom: 0
                              }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
<ng-template #ctaLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                            width: '86px',
                            height: '30px',
                            marginBottom: 0
                          }"></ngx-skeleton-loader>
</ng-template>