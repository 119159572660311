<div class="modal-overlay">
    <div class="modal-content">
        <button class="close" (click)="closeQrCodeHandler()" (keydown.enter)="closeQrCodeHandler()" (keydown.space)="closeQrCodeHandler()" aria-label="Close QR Code">&times;</button>
        <h3 class="title">{{metaData.name}}</h3>
        <p class="reference">#{{metaData.reference}}</p>
        <p class="amount"><span>&#8364;</span>{{metaData.amount/100}}</p>
        <div class="qrcode-container">
            <img class="qr-code" *ngIf="qrCodeBase64" [src]="qrCodeBase64" alt="EPC QR Code">
        </div>

        <p *ngIf="status !== 'paid'" class="code-description">Scan this QR code on your banking app to pay</p>
        <p *ngIf="status === 'paid'" class="code-description">You confirmed the transfer on {{selectedInvoice.paid_at|date:'medium'}}</p>

        <div *ngIf="status === 'waiting_to_be_paid'" class="footer-container">
            <button class="btn btn-primary" (click)="markAsPaidHandler()">I have made the transfer</button>
            <button class="btn btn-secondary" (click)="closeQrCodeHandler()">I will do it later</button>
        </div>
    </div>
</div>