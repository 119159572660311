import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopstateService {
  private popstateSubject = new Subject<void>();
  popstate$ = this.popstateSubject.asObservable();

  constructor() {
    window.addEventListener('popstate', () => {
      this.popstateSubject.next();
    });
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.popstateSubject.next();
      }
    });
  }
}
