import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {
  SucessSnackBarComponent,
  ErrorSnackBarComponent,
  InfoSnackBarComponent,
  SucessBigSnackBarComponent,
} from '../components/snack-bar/snack-bar.component';
import { DialogComponent } from '../components/dialog/dialog.component';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private secondaryPath = new BehaviorSubject<string[]>([]);
  secondaryPathObservable$ = this.secondaryPath.asObservable();

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  public async setSecondaryPath(data: string[]) {
    this.secondaryPath.next(data);
  }

  successNotification(message: string) {
    this._snackBar.openFromComponent(SucessSnackBarComponent, {
      duration: 3 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      data: {
        message,
        preClose: () => {
          this._snackBar.dismiss();
        },
      },
    });
  }

  successBigNotification(message: string, messageBody?: string) {
    this._snackBar.openFromComponent(SucessBigSnackBarComponent, {
      duration: 3 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      data: {
        message,
        messageBody,
        preClose: () => {
          this._snackBar.dismiss();
        },
      },
    });
  }

  errorNotification(message: string) {
    this._snackBar.openFromComponent(ErrorSnackBarComponent, {
      duration: 3 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      data: {
        message,
        preClose: () => {
          this._snackBar.dismiss();
        },
      },
    });
  }

  infoNotification(message: string) {
    this._snackBar.openFromComponent(InfoSnackBarComponent, {
      duration: 3 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      data: {
        message,
        preClose: () => {
          this._snackBar.dismiss();
        },
      },
    });
  }

  OpenAccessDenied(gpId: string, vehicleId: string, page: string) {
    let dialogRef = this.dialog.open(DialogComponent, {
      data: <ModalDialogData>{
        titleKey: 'Access Denied',
        contentKey: 'access denied',
        positiveMessageKey: 'OK',
        negativeMessageKey: '',
        isValid: '1',
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === '1') {
        this.router.navigate([
          `gp/${gpId}/vehicle/${vehicleId}/${page}`,
        ]);
      }
    });
  }

  formateDate(dateString: string) {
    let date = new Date(dateString);

    let monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    let paddedDay = day.toString().padStart(2, '0');

    return `${paddedDay}. ${month} ${year}`;
  }

  formateDateTime(dateString: string) {
    const date = new Date(dateString);

    const day = date.getUTCDate();
    const paddedDay = day.toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    return `${paddedDay}. ${month} ${year}, ${hours}:${minutes}`;
  }

  formateUnixDate(dateValue: string | number) {
    const date = new Date(+dateValue * 1000); // Convert to milliseconds
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day}. ${month} ${year}`;
  }

  convert(value: any, type: any, selectedVehicle: any) {
    if (value != null || value != undefined) {
      let format;
      if (selectedVehicle) {
        if (
          selectedVehicle.separators &&
          selectedVehicle.separators.thousands === '.' &&
          selectedVehicle.separators.decimal === ','
        ) {
          format = 'de-DE';
        } else {
          format = 'en-US';
        }
      }
      const numFor = new Intl.NumberFormat(format);
      let val: any = Math.round(value);
      let convertedAmount = numFor.format(val);
      if (selectedVehicle.negativeNumbers === 'parentheses') {
        const currentval = numFor.format(parseInt(val));
        convertedAmount =
          currentval.toString().charAt(0) === '-'
            ? '(' +
            currentval.toString().substring(1, currentval.toString().length) +
            ')'
            : currentval;
        return convertedAmount;
      } else {
        convertedAmount = numFor.format(parseInt(val));
        return convertedAmount;
      }
    }
    return 0;
  }
  convertWithDecimal = (
    value: any,
    selectedVehicle: any,
    paramKey: any = false,
    roundOfRequired: boolean = false,
    title: string = ''
  ) => {
    const MAXIMUM_AMOUNT = 1000000000; // 1 Billion
    if (paramKey === true && value === null) {
      return '-';
    }
    let format;

    if (value != null || value != undefined) {
      if (selectedVehicle) {
        if (
          selectedVehicle.separators &&
          selectedVehicle.separators.thousands === '.' &&
          selectedVehicle.separators.decimal === ','
        ) {
          format = 'de-DE';
        } else {
          format = 'en-US';
        }
      }

      let convertedAmount;

      if (title !== 'IRR') {
        convertedAmount = value.toLocaleString(format, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        convertedAmount = value.toLocaleString(format, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        });
      }

      if (roundOfRequired) {
        if (value >= MAXIMUM_AMOUNT) {
          convertedAmount = value.toLocaleString(format, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });
        }
      }

      if (selectedVehicle.negativeNumbers === 'parentheses') {
        let newConvertedAmount =
          convertedAmount.toString().charAt(0) === '-'
            ? '(' +
            convertedAmount
              .toString()
              .substring(1, convertedAmount.toString().length) +
            ')'
            : convertedAmount;
        return newConvertedAmount;
      } else {
        return convertedAmount;
      }
    }
    return 0.0;
  }
  unitConvert(value: any, selectedVehicle: any) {
    if (value != null) {
      let format;
      if (selectedVehicle) {
        if (
          selectedVehicle.separators &&
          selectedVehicle.separators.thousands === '.' &&
          selectedVehicle.separators.decimal === ','
        ) {
          format = 'de-DE';
        } else {
          format = 'en-US';
        }
      }
      const numFor = new Intl.NumberFormat(format);
      var val: any = Math.round(value);
      let convertedAmount = { value: numFor.format(val), unit: '' };
      if (selectedVehicle.negativeNumbers === 'parentheses') {
        if (val >= 100000000000 || val <= -100000000000) {
          val = val / 1000000000;
          const currentval = numFor.format(parseInt(val));
          convertedAmount = {
            value:
              currentval.toString().charAt(0) === '-'
                ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
                : currentval,
            unit: selectedVehicle.abbreviations
              ? selectedVehicle.abbreviations.billion
              : 'bn'
          };
        } else if (val >= 100000000 || val <= -100000000) {
          val = val / 1000000;
          const currentval = numFor.format(parseInt(val));
          convertedAmount = {
            value:
              currentval.toString().charAt(0) === '-'
                ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
                : currentval,
            unit: selectedVehicle.abbreviations
              ? selectedVehicle.abbreviations.million
              : 'mn'
          };
        } else if (val >= 100000 || val <= -100000) {
          val = val / 1000;

          const currentval = numFor.format(parseInt(val));
          convertedAmount = {
            value:
              currentval.toString().charAt(0) === '-'
                ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
                : currentval,
            unit: selectedVehicle.abbreviations
              ? selectedVehicle.abbreviations.thousand
              : 'k'
          };
        } else {
          const currentval = numFor.format(parseInt(val));
          convertedAmount = {
            value:
              currentval.toString().charAt(0) === '-'
                ? '(' +
                currentval
                  .toString()
                  .substring(1, currentval.toString().length) +
                ')'
                : currentval,
            unit: ''
          };
        }
        return convertedAmount;
      } else {
        if (val >= 100000000000 || val <= -100000000000) {
          val = val / 1000000000;
          convertedAmount = {
            value: numFor.format(parseInt(val)),
            unit: selectedVehicle.abbreviations
              ? selectedVehicle.abbreviations.billion
              : 'bn'
          };
        } else if (val >= 100000000 || val <= -100000000) {
          val = val / 1000000;
          convertedAmount = {
            value: numFor.format(parseInt(val)),
            unit: selectedVehicle.abbreviations
              ? selectedVehicle.abbreviations.million
              : 'mn'
          };
        } else if (val >= 100000 || val <= -100000) {
          val = val / 1000;

          convertedAmount = {
            value: numFor.format(parseInt(val)),
            unit: selectedVehicle.abbreviations
              ? selectedVehicle.abbreviations.thousand
              : 'k'
          };
        }
        return convertedAmount;
      }
    }
    return { value: '', unit: '' };
  }

}
