import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ManagementService } from './management.service';
import { ActivatedRoute } from '@angular/router';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { FormBuilder, Validators } from '@angular/forms';

export interface ICardData {
  topic: string;
  title: string;
  taskId: number;
  timeStamp: string | null;
  archived: boolean;
  priority: string;
  id: string
}

interface ITaskData {
  status: string;
  tasks: ICardData[];
}

interface ITaskApi {
  id: string;
  name: string;
  status: string;
  startDate: string;
  topicId: string;
  archived: boolean;
  priority: string;
}

export interface ITopicListItem {
  id: string,
  name: string,
  uncompletedTaskCount: number
}
@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
})
export class ManagementComponent {
  search: string;
  topics: ITopicListItem[] = [];
  topicList: ITopicListItem[]
  taskDatas: ITaskData[]
  vehicleId: string;
  isLoading: boolean = false;
  isTaskDetailsFetched: boolean = true;
  showGetArchived: boolean = true;
  persistedTaskDatas: ITaskData[] = [];
  tempPersistTaskData: ITaskData[];
  showMigration: boolean = false;
  showNoTopics: boolean = false;
  isGettingArchived: boolean = false;
  suggestTaskAvailable: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private managementService: ManagementService,
    private commonService: CommonService,
    public breakPointService: BreakPointService
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.getAllTasks()
  }

  sortedTasks(tasks: ITaskApi[]) {
    const priorityOrder: any = {
      "critical": 1,
      "high": 2,
      "medium": 3,
      "low": 4
    };

    tasks.sort((a, b) => {
      const priorityComparison = priorityOrder[a.priority] - priorityOrder[b.priority];

      if (priorityComparison !== 0) {
        return priorityComparison;
      }

      // Handle tasks with missing startDate (sort by id if both are missing)
      if (!a.startDate && !b.startDate) {
        return b.id.localeCompare(a.id);
      } else if (!a.startDate) {
        return -1;
      } else if (!b.startDate) {
        return 1;
      }

      // If both tasks have startDate, sort by startDate (descending order)
      const dateComparison = new Date(b.startDate).getTime() - new Date(a.startDate).getTime();

      if (dateComparison !== 0) {
        return dateComparison;
      }

      // If both priorities and startDates are equal, sort by id (descending)
      return b.id.localeCompare(a.id);
    });


    return tasks
  }

  getAllTasks() {
    this.isLoading = true;
    this.managementService
      .getAllTasks(this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          if (parsedResponse.data.tasks.length == 0) {
            this.showNoTopics = true
          }
          this.topicList = parsedResponse.data.topics
          this.taskDatas = this.transformTasks(this.sortedTasks(parsedResponse.data.tasks));
          this.persistedTaskDatas = JSON.parse(JSON.stringify(this.taskDatas));
          this.suggestTaskAvailable = parsedResponse.data.suggestTaskAvailable;
        },
        error: error => {
          if (error.error.message.includes('Vehicle does not exist')) {
            this.showNoTopics = true
            // this.showMigration = true
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  getTaskDetails(cardData: ICardData, status: string) {
    this.managementService.setTaskDetails({ ...cardData, status, vehicleId: this.vehicleId, isSuggest: false })
    this.managementService.openDrawer()
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  }

  transformTasks(inputArray: any): ITaskData[] {
    const predefinedOrder = ['Proposed', 'In progress', 'Completed'];
    const output: any = {
      'Proposed': { status: 'Proposed', tasks: [] },
      'In progress': { status: 'In progress', tasks: [] },
      'Completed': { status: 'Completed', tasks: [] }
    };
    const topicMap = this.topicList.reduce((map: any, topic) => {
      map[topic.id] = topic.name;
      return map;
    }, {});

    inputArray.forEach((task: any) => {
      if (!output[task.status]) {
        output[task.status] = { status: task.status, tasks: [] };
      }
      const newTask = {
        topic: topicMap[task.topicId],
        title: task.name,
        taskId: task.id,
        timeStamp: task.startDate ? `Started on ${this.formatDate(task.startDate)}` : null,
        archived: task.archived,
        priority: task.priority,
        id: task.id
      };
      output[task.status].tasks.push(newTask);
    });

    const resultArray: ITaskData[] = Object.values(output);
    resultArray.sort((a: ITaskData, b: ITaskData) => {
      const indexA = predefinedOrder.indexOf(a.status);
      const indexB = predefinedOrder.indexOf(b.status);
      if (a.status === 'Completed') return 1;
      if (b.status === 'Completed') return -1;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    return resultArray;
  }

  mergeTasks(newTasksData: ITaskData[]) {
    this.taskDatas = JSON.parse(JSON.stringify(this.persistedTaskDatas))
    newTasksData.forEach(taskData => {
      const existingItem = this.taskDatas.find(item => item.status === taskData.status);
      if (existingItem) {
        existingItem.tasks.push(...taskData.tasks);
      } else {
        this.taskDatas.push(taskData);
      }
    });
  }

  getArchivedTasks() {
    this.isGettingArchived = true;
    (this.topics.length == 0 ? this.managementService.getArchivedTasks(this.vehicleId) : this.managementService.getArchivedTasks(this.vehicleId, this.topics.map(task => task.id))).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        const formatedData = this.transformTasks(parsedResponse.data)
        this.showGetArchived = false
        if (parsedResponse.data.length === 0) {
          this.commonService.infoNotification('No archived tasks are available')
          this.tempPersistTaskData = JSON.parse(JSON.stringify(this.taskDatas));
        } else {
          this.mergeTasks(formatedData)
          this.tempPersistTaskData = JSON.parse(JSON.stringify(this.taskDatas))
          this.searchChangeHandler()
        }
      },
      error: _error => {
        this.commonService.errorNotification('Failed to retrieve archived tasks')
        this.isGettingArchived = false
      },
      complete: () => {
        this.isGettingArchived = false
      }
    })
  }

  searchChangeHandler() {
    if (!this.showGetArchived) {
      this.taskDatas = this.tempPersistTaskData.map(taskData => ({
        status: taskData.status,
        tasks: taskData.tasks.filter(task => ((!this.search ? true : task.title.toLowerCase().includes(this.search.toLowerCase())) && (this.topics.length == 0 ? true : this.topics.map(x => x.name).includes(task.topic))))
      }));
    } else {
      this.taskDatas = this.persistedTaskDatas.map(taskData => ({
        status: taskData.status,
        tasks: taskData.tasks.filter(task => ((!this.search ? true : task.title.toLowerCase().includes(this.search.toLowerCase())) && (this.topics.length == 0 ? true : this.topics.map(x => x.name).includes(task.topic))))
      }));
    }
  }

  topicChangeHandler() {
    if (this.topics.length == 0) {
      this.taskDatas = this.persistedTaskDatas.map(taskData => ({
        status: taskData.status,
        tasks: taskData.tasks.filter(task => (!this.search ? true : task.title.toLowerCase().includes(this.search.toLowerCase())))
      }));
    } else {
      this.taskDatas = this.persistedTaskDatas.map(taskData => ({
        status: taskData.status,
        tasks: taskData.tasks.filter(task => (this.topics.map(x => x.name).includes(task.topic) && (!this.search ? true : task.title.toLowerCase().includes(this.search.toLowerCase()))))
      }));
    }
    this.showGetArchived = true
  }

  openSuggestTask() {
    this.managementService.setTaskDetails({ vehicleId: this.vehicleId, isSuggest: true, topicList: this.topicList })
    this.managementService.openDrawer()
  }
}

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./management.component.scss'],
})
export class TaskCardComponent {

  @Input() cardData: ICardData
  @Output() cardClickEvent = new EventEmitter<ICardData>();


  clickHandler(value: ICardData) {
    if (value.archived) return
    this.cardClickEvent.emit(value);
  }
}

interface ISubTask {
  id: string,
  name: string,
  status: string,
  subSubTask?: any,
  showSpinner?: boolean
}
interface File {
  lastModified: number,
  lastModifiedDate: Date,
  name: string,
  size: number,
  type: string,
  webkitRelativePath: string,
}
@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./management.component.scss'],
})
export class TaskDetailsComponent {
  isLoading: boolean = false
  subTaskStatus: any = {
    'to do': 'todo_icon',
    'completed': 'done_circle',
    'blocked': 'blocked_icon',
    'in progress': 'clock_icon',
    'in approval': 'clock_icon',
    'in review': 'clock_icon'
  }
  taskStatus: any = {
    'completed': 'done_circle',
    'in progress': 'clock_icon',
    'cancelled': 'warning_icon',
    'blocked': 'blocked_icon',
    'on hold': 'blocked_icon',
    'to do': 'todo_icon',
    'proposed': 'todo_icon',
    'in planning': 'todo_icon',
    'to be staffed': 'todo_icon'
  }
  vehicleId: string;
  taskId: number;
  subTasks: ISubTask[] = [];
  isSuggest: boolean = false;
  files: any[] = [];
  topic: any;
  suggestTaskForm: any;
  isFileSizeExceed: boolean = false;
  submitted: boolean = false;
  topics: ITopicListItem[];
  panelOpenState = false;

  constructor(
    public managementService: ManagementService,
    private commonService: CommonService,
    private fb: FormBuilder
  ) { }

  getTaskStatus(task: any | null): string {
    if (!task) {
      return 'In Progress'
    } else {
      return this.taskStatus[task.status.toLowercase()]
    }
  }

  ngOnInit() {
    this.managementService.taskDetailsObservable$.subscribe((res: any) => {
      if (res && !res.isSuggest) {
        this.isSuggest = false;
        this.taskId = res.taskId;
        this.vehicleId = res.vehicleId;
        this.getTaskDetails();
      } else {
        this.files = [];
        this.isSuggest = true;
        this.vehicleId = res?.vehicleId;
        this.topics = res?.topicList;
        this.loadSubTaskForm();
      }
    });

  }

  getTaskDetails() {
    this.isLoading = true
    this.subTasks = []
    this.managementService.getTaskDetail(this.vehicleId, this.taskId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.subTasks = parsedResponse.data.subtasks.map((x: any) => ({ ...x, subSubTask: null, showSpinner: false }))
        if (this.subTasks.length == 0) {
          this.commonService.infoNotification('No subtasks are available')
        }
      },
      error: _error => {
        this.isLoading = false
        this.commonService.errorNotification('Failed to retrieve task details')
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }

  onFileDropped(event: any) {
    this.checkFileValidation(event);
  }

  fileBrowseHandler(event: any) {
    this.checkFileValidation(event.target.files);
  }

  checkFileValidation(uploadFiles: any) {
    this.isFileSizeExceed = false;
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (uploadFiles && uploadFiles.length > 0 && uploadFiles[0].size >= maxSizeInBytes) {
      this.isFileSizeExceed = true;
      return
    }
    this.files = uploadFiles;
  }

  loadSubTaskForm() {
    this.suggestTaskForm = this.fb.group({
      taskTitle: ['', [Validators.required]],
      taskTopic: ['', [Validators.required]],
      subTaskDesc: ['', [Validators.required]],
    });
  }
  get taskTitle() {
    return this.suggestTaskForm.get('taskTitle');
  }
  get taskTopic() {
    return this.suggestTaskForm.get('taskTopic');
  }
  get subTaskDesc() {
    return this.suggestTaskForm.get('subTaskDesc');
  }

  submitTask() {
    if (this.suggestTaskForm.valid) {
      this.submitted = true;
      const formData = new FormData();
      if (this.files.length > 0) {
        formData.append('fileData', this.files[0]);
      }
      formData.append('title', this.suggestTaskForm.value?.taskTitle);
      formData.append('topic', this.suggestTaskForm.value?.taskTopic);
      formData.append('description', this.suggestTaskForm.value?.subTaskDesc);
      this.managementService.submitSuggestTask(this.vehicleId, formData)
        .subscribe({
          next: response => {
            if (response) {
              this.commonService.successBigNotification('The task has been successfully submitted.', 'If we have any questions, we will get in touch with you.');
            }
          },
          error: error => {
            this.submitted = false;
            this.commonService.errorNotification('Something went wrong. Please inform your ACE support contact. Thank you!');
          },
          complete: () => {
            this.submitted = false;
            this.managementService.closeDrawer();
            this.files = [];
          }
        });
    }
  }

  showTaskStatusTooltip(data: any | null): string {
    if (data && data === 'In Progress') {
      return 'In progress'
    }
    return data;
  }

  cancelTask() {
    this.files = [];
    this.submitted = false;
  }

  openSubTask(subTask: ISubTask, row: any) {
    if (row.expanded) {
      row.close()
    } else {
      if (subTask.subSubTask !== null && subTask.subSubTask.length === 0) {
        return
      }
      const index = this.subTasks.findIndex(x => x.id === subTask.id);
      if (this.subTasks[index].subSubTask === null) {
        this.subTasks[index].showSpinner = true;
        this.managementService.getSubTaskDetail(this.vehicleId, subTask.id).subscribe({
          next: response => {
            let parsedResponse = JSON.parse(JSON.stringify(response));
            const subSubTasks = [...parsedResponse.data];
            this.subTasks[index].subSubTask = subSubTasks;
            if (subSubTasks.length == 0) {
              this.commonService.infoNotification('No further tasks were found.');
            } else {
              row.open();
            }
          },
          error: _error => {
            this.subTasks[index].showSpinner = false
            this.commonService.errorNotification('Failed to retrieve task details.')
          },
          complete: () => {
            this.subTasks[index].showSpinner = false
          }
        })
      } else {
        row.open()
      }
    }

  }

  convertTextToLinks(text: string): string {
    // Updated regex to include different enclosing characters around URLs
    const urlRegex = /([\(\[\{“"'])?(https?:\/\/[^\s\)\]\}“"']+)([\)\]\}”"'])?/g;

    return text.replace(urlRegex, (match, openChar, url, closeChar) => {
        // Wrap the matched URL along with any enclosing characters in the link tag.
        const displayText = `${openChar || ''}${url}${closeChar || ''}`;
        return `<a href="${url}" target="_blank" class="sub-task-link">${displayText}</a>`;
    });
  }
}
