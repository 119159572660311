<!-- <app-home></app-home> -->
<app-pagenotfound *ngIf="error == 'ACCESS_DENIED'; else GPNav"></app-pagenotfound>

<ng-template #GPNav>
    <mat-sidenav-container class="add-portfolio-container" hasBackdrop="true">
        <mat-sidenav mode="over" position="end" [opened]="this.portfolioService.openPortfolioDrawerFlag.value"
            [autoFocus]="false" (closed)="this.portfolioService.closePortfolioDrawer()">
            <app-add-company></app-add-company>
        </mat-sidenav>
        <mat-sidenav-content class="add-portfolio-sidenav">
            <mat-sidenav-container class="task-details-container" hasBackdrop="true">
                <mat-sidenav mode="over" position="end" [opened]="this.managementService.openDrawerFlag.value"
                    [autoFocus]="false" (closed)="this.managementService.closeDrawer()">
                    <app-task-details></app-task-details>
                </mat-sidenav>
                <mat-sidenav-content class="task-details-sidenav">
                    <app-home *ngIf="isAuthenticated" [baseData]="baseData"></app-home>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-template>