<h1 mat-dialog-title>Add Bank Account</h1>
<mat-dialog-content>
  <div class="add-account-form">
    <mat-form-field>
      <mat-label>Account Holder Name</mat-label>
      <input matInput [formControl]="accHolderName" required>
      <mat-error *ngIf="accHolderName.hasError('required')">Please enter an account holder name</mat-error>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>IBAN</mat-label>
      <input matInput [formControl]="iBan" required>
      <mat-error *ngIf="iBan.hasError('required')">please enter an IBAN</mat-error>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>BIC</mat-label>
      <input matInput [(ngModel)]="bic">
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>Currency</mat-label>
      <input matInput [(ngModel)]="currency" disabled>
    </mat-form-field>
    <mat-form-field class="half-size">
      <mat-label>Country</mat-label>
      <input matInput [(ngModel)]="country" disabled>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-button mat-dialog-close>Okay</button> -->
  <div class="action-section">

    <button mat-button (click)="addAccount()" class="add-account"
      [disabled]="!(accHolderName.valid && iBan.valid)">Add</button>
  </div>
</mat-dialog-actions>