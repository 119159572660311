import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class BankTransactionsService {
  public openTransactionDrawerFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private transactionDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  transactionDetailsObservable$ = this.transactionDetails.asObservable();
  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService,
  ) { }

  public openDrawer(): void {
    this.openTransactionDrawerFlag.next(true);
  }

  public closeDrawer(): void {
    this.openTransactionDrawerFlag.value && this.openTransactionDrawerFlag.next(false);
  }

  public setTransactionDetails(data: any): void {
    !this.openTransactionDrawerFlag.value && this.transactionDetails.next(data);
  }

  getTransactions(vehicleId: string, sortBy: string, orderBy: SortDirection, pgIndex: number, pgSize: number, params?: { [key: string]: any }, payload?: any) {
    const queryParams = {
      page: Number(pgIndex) + 1,
      pageSize: pgSize,
      sortBy: sortBy,
      order: orderBy,
      ...params
    };

    // Extract multi-select filters to body
    const body = {
      iban: payload.iban || [],
      transactionType: payload.transactionType || [],
      counterparty: payload.counterparty || []
    };
    const url = this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/bank-accounts-v2/transactions`,
      false
    );
    return this.apiHttpService.post(url, body, { params: queryParams });
  }

  getExcelFile(vehicleId: string, sortBy: string, orderBy: SortDirection, pgIndex: number, pgSize: number, params?: { [key: string]: any }, payload?: any) {
    const queryParams = {
      page: Number(pgIndex) + 1,
      pageSize: pgSize,
      sortBy: sortBy,
      order: orderBy,
      ...params
    };
    // Extract multi-select filters to body
    const body = {
      iban: payload?.iban || [],
      transactionType: payload?.transactionType || [],
      counterparty: payload?.counterparty || []
    };
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-accounts-v2/transactions`,
        false
      ), body, { responseType: 'arraybuffer', params: queryParams }
    );
  }

  syncTransaction(vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/bank-accounts-v2/sync-transactions`,
        false
      ), { vehicleId }
    );
  }
}
