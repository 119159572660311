import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivateFn } from '@angular/router';
import { switchMap, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { SaveQuarterDataDialog } from '../../pages/quarterly-data/quarter-data-details/quarter-data-details.component';
import { QuarterlyDataComponent } from '../../pages/quarterly-data/quarterly-data.component';

export const unsavedQuarterlyDataChangesGuard: CanDeactivateFn<
QuarterlyDataComponent
> = (component: QuarterlyDataComponent) => {
  const dialog = inject(MatDialog);
  const userService = inject(UserService);
  if (component && component.getChildComponent() && component.getChildComponent().getFormStatus()) {
    const dialogRef = dialog.open(SaveQuarterDataDialog, {
      data: {
        quarterlyDataId: component.getChildComponent().quarterlyDataId,
        selectedportfolioCompany: component.getChildComponent().selectedportfolioCompany,
        portfolioType: component.getChildComponent().portfolioType,
        formData: component.getChildComponent().getFormData(),
        uploadFilepath: component.getChildComponent().uploadkpiFilePath,
      },
    });

    return dialogRef.afterClosed().pipe(
      switchMap(result => {
        if (
          result &&
          (result.status === 'success' || result.status === 'discard')
        ) {
          component.getChildComponent().resetFormStatus();
          if (result.status === 'discard' && component.getChildComponent().uploadkpiFilePath) {
            component.getChildComponent().deleteKPIFileWithoutSave(component.getChildComponent().uploadkpiFilePath);
          }
          return of(true);
        } else {
          userService.setResetValues('reset')
          return of(false);
        }
      })
    );
  }
  return true;
};

// import { CanDeactivateFn } from '@angular/router';
// import { inject } from '@angular/core';
// import { QuarterlyDataComponent } from 'src/app/pages/quarterly-data/quarterly-data.component';
// import { UnsavedQuarterlyDataChangesService } from '../../pages/quarterly-data/unsaved-quarterly-data-changes.service';

// export const unsavedQuarterlyDataChangesGuard: CanDeactivateFn<QuarterlyDataComponent> = (
//   component: QuarterlyDataComponent
// ) => {
//   const unsavedChangesService = inject(UnsavedQuarterlyDataChangesService);
//   return unsavedChangesService.checkUnsavedChanges(component);
// };
