<div class="delete-portfolio-company">
  <div class="content">
    <div class="heading">
      Delete “{{data.quarterData.year + ' Q'+ data.quarterData.quarter}}” ?
      <button (click)="dialogRef.close()" [disabled]="loading">
        <img src="../../../assets/images/cross_icon_grey.svg" alt="close" />
      </button>
    </div>
    <div class="form-field">
      <div class="description">Are you sure you want to delete?</div>
    </div>
  </div>
  <div class="action">
    <button mat-flat-button class="cancel" (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="delete"
      (click)="deletequarterData()"
      [disabled]="loading">
      <ng-container *ngIf="!loading; else whiteSpinner"> Delete </ng-container>
    </button>
  </div>
</div>
<ng-template #whiteSpinner>
  <app-spiner-loader [color]="'white'"></app-spiner-loader>
</ng-template>
