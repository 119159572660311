import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Column } from './column';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { ConfigService } from 'src/app/initializer/config.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit {
  @ViewChild(MatSort)
  sort!: MatSort;
  @Input()
  tableColumns: Array<Column> = [];
  @Input()
  lastDayOfQuarter: any;
  @Input()
  lastDayOfLastQuarter: any;
  @Input()
  enableSorting = true;
  @Input()
  languageCode: any;
  @Input()
  menuTitle: string = '' as string;
  currentVehicle: any;

  @Input()
  isClickable = false;

  @Input()
  tableData: Array<T> = [];

  @Output()
  rowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  doSort: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: Array<string> = [];
  dataSource: MatTableDataSource<T> = new MatTableDataSource();
  selection = new SelectionModel<any>(false, []);
  lpsid: any;
  vehicleId: any;

  handleSelection(row: any) {
    if (
      row.documentId !== undefined &&
      row.documentId !== '' &&
      row.documentId !== null
    ) {
      this.rowClicked.emit(row);
      this.selection.toggle(row);
    }
  }
  handleSort() {
    this.doSort.emit();
    this.dataSource.sort = this.sort;
  }

  isEnclosedWithParantheses(value: any): boolean {
    const re = /\((.*)\)/i;
    const matches = value.match(re);
    if (matches) {
      return true;
    }
    return false;
  }
  isValueExist(value: any): boolean {
    const re = /\((.*)\)/i;
    const matches = value.match(re);
    if (matches) {
      return true;
    }
    return false;
  }
  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  formatValue(value: any): any {
    if (value[0] === '(' && value[value.length - 1] === ')') {
      return `${value.slice(1, value.length - 1)}`;
    } else if (parseInt(value) < 0) {
      return Math.abs(value);
    }
    return value;
  }
  formatMinusValue(value: any): any {
    if (value[0] === '(' && value[value.length - 1] === ')') {
      const mVal = '-' + `${value.slice(1, value.length - 1)}`;
      return mVal;
    } else if (parseInt(value) < 0) {
      return Math.abs(value);
    }
    return value;
  }

  translateWithDate(
    text: any,
    lastDayOfQuarter: any,
    lastDayOfLastQuarter: any
  ) {
    if (
      text.includes('{formatDate(lastDayOfQuarter)}') &&
      text.includes('{formatDate(lastDayOfLastQuarter)}')
    ) {
      const t1 = text.replace(
        '{formatDate(lastDayOfQuarter)}',
        lastDayOfQuarter
      );
      return t1.replace(
        '{formatDate(lastDayOfLastQuarter)}',
        lastDayOfLastQuarter
      );
    } else if (text.includes('{formatDate(lastDayOfQuarter)}')) {
      return text.replace('{formatDate(lastDayOfQuarter)}', lastDayOfQuarter);
    } else if (text.includes('{formatDate(lastDayOfLastQuarter)}')) {
      return text.replace(
        '{formatDate(lastDayOfLastQuarter)}',
        lastDayOfLastQuarter
      );
    } else {
      return text;
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private config: ConfigService,
    public datepipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.lpsid = this.activatedRoute.snapshot.params['lpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    const baseconfigData = this.config.baseData.data;
    this.currentVehicle = baseconfigData.vehicles.find(
      (item: any) => item.id === this.vehicleId
    );

    // this.currentVehicle = history.state.vehicle;
    this.displayedColumns = this.tableColumns.map(c => c.columnDef);
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'sendDate': {
          const newDate = new Date(item.sendDate);
          return newDate;
        }
        case 'reportTitle': {
          const newTitle = item.title;
          return newTitle;
        }
        case 'type': {
          const newType = item.type;
          return newType;
        }
        case 'dueDate': {
          const newDate = new Date(item.dueDate);
          return newDate;
        }
        case 'amount': {
          const number = this.formatMinusValue(item.amount);
          const newAmount = number;
          return newAmount;
        }
        default: {
          return item[property];
        }
      }
    };
  }
}
