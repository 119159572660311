<div class="quarter-portfolio">
  <input type="hidden" id="valueChanged" value="false" #changed />
  <div class="company-content">
    <div class="left-panel">
      <div class="left-panel-header">
        <button type="button" class="btn-back" (click)="backtoQuarterlyData()">
          <span class="btn-image"><img src="assets/images/back_icon.svg" alt="back_img" /></span>
          Back
        </button>
        <button type="button" class="btn-back pre-fill-btn" [disabled]="
            (selectedportfolioCompany &&
              selectedportfolioCompany.status === 'final') ||
            (quarterlyData && quarterlyData.length <= 1)
          " [matTooltip]="
            (selectedportfolioCompany && selectedportfolioCompany['status']) ===
            'final'
              ? prefilltoolTip.global_pre_fill
              : prefilltoolTip.no_availableQuarters
          " [matTooltipDisabled]="
            !(
              (quarterlyData && quarterlyData.length <= 1) ||
              (selectedportfolioCompany &&
                selectedportfolioCompany.status === 'final')
            )
          " (click)="openPreFillDialog('questionaire')">
          Pre-fill...
        </button>
      </div>
      <ng-container *ngIf="!questionaireTypeLoading; else companyListLoader">
        <div class="portfolio-summary">
          <ng-container *ngIf="quarterlyDataTypes['questionnaire']">
            <div class="data-container">
              <div class="data-heading">Base data</div>
              <ng-container *ngFor="
                  let queationaire of quarterlyDataTypes['questionnaire']
                ">
                <div class="data-list" [ngClass]="{
                    active: selectedportfolioCompany.id === queationaire.id
                  }" (click)="changePortfolioType(queationaire, 'questionnaire')">
                  <div class="data-title">
                    <img *ngIf="selectedportfolioCompany.id === queationaire.id"
                      src="../.../../../../../assets/images/portfolio_document_active_icon.svg" alt="documents_icon"
                      height="24px" width="24px" />
                    <img *ngIf="selectedportfolioCompany.id !== queationaire.id"
                      src="../.../../../../../assets/images/portfolio_document_icon.svg" alt="documents_icon"
                      height="24px" width="24px" />
                    <div class="name">{{ queationaire.name }}</div>
                  </div>
                  <img [matTooltip]="capitalizeStatusValue(queationaire.status)" class="status-img" src="{{
                      questionaireImageStatusImage[queationaire.status].path
                    }}" alt="status_icon" height="24px" width="24px" />
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="quarterlyDataTypes['portfolioCompany']">
            <div class="data-container">
              <div class="data-heading">Portfolio companies</div>
              <ng-container *ngFor="
                  let queationaire of quarterlyDataTypes['portfolioCompany']
                ">
                <div class="data-list" [ngClass]="{
                    active: selectedportfolioCompany.id === queationaire.id
                  }" (click)="
                    changePortfolioType(queationaire, 'portfolio-company')
                  ">
                  <div class="data-title">
                    <img *ngIf="selectedportfolioCompany.id === queationaire.id"
                      src="../.../../../../../assets/images/pc_icon_active.svg" alt="documents_icon" height="24px"
                      width="24px" />
                    <img *ngIf="selectedportfolioCompany.id !== queationaire.id" src="assets/images/pc_icon.svg"
                      alt="documents_icon" height="24px" width="24px" />
                    <div class="name">{{ queationaire.name }}</div>
                  </div>
                  <img class="status-img" [matTooltip]="capitalizeStatusValue(queationaire.status)" src="{{
                      questionaireImageStatusImage[queationaire.status].path
                    }}" alt="status_icon" height="24px" width="24px" />
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="container-middle-border"></div>

    <div class="right-panel">
      <div class="tool-bar">
        <ng-container *ngIf="
            !questionaireTypeLoading && !isQuarterFormLoading;
            else toolBarLoader
          ">
          <div class="quarter-period">
            {{ selectedportfolioCompany['name'] }} {{ quarter }}
          </div>
          <div class="cta-section">
            <button type="button" class="btn-action btn-prefield" [disabled]="
                availableQuarters.length === 0 ||
                (selectedportfolioCompany &&
                  selectedportfolioCompany['status']) === 'final'
              " [matTooltip]="
                (selectedportfolioCompany &&
                  selectedportfolioCompany['status']) === 'final'
                  ? prefilltoolTip.questionaire_pre_fill
                  : prefilltoolTip.no_availableQuarters
              " [matTooltipDisabled]="
                !(
                  availableQuarters.length === 0 ||
                  (selectedportfolioCompany &&
                    selectedportfolioCompany.status === 'final')
                )
              " (click)="openPreFillDialog('questionaireForm')">
              Pre-fill...
            </button>

            <button type="button" [disabled]="isEditInProgress" *ngIf="
                formQuarterStatus === 'draft' || formQuarterStatus === 'open'
              " class="btn-action" (click)="addReportData('draft')">
              <ng-container *ngIf="!draftQuarterLoading; else blackSpinner">
                Save draft
              </ng-container>
            </button>
            <button type="button" [disabled]="isEditInProgress" *ngIf="formQuarterStatus === 'final'" class="btn-action"
              (click)="addReportData('draft', false)">
              <ng-container *ngIf="!draftQuarterLoading; else blackSpinner">
                Edit report
              </ng-container>
            </button>

            <button type="button" *ngIf="
                formQuarterStatus === 'draft' || formQuarterStatus === 'open'
              " [disabled]="
                !(formQuarterStatus == 'draft' || formQuarterStatus == 'open')
              " class="btn-action submit" (click)="addReportData('final')">
              <ng-container *ngIf="!submitQuarterLoading; else whiteSpinner">
                Submit
              </ng-container>
            </button>
            <button type="button" *ngIf="formQuarterStatus === 'final'" class="btn-action submitted">
              <img src="../../../../assets/images/check_icon.svg" alt="icon" height="16px" width="16px" />Submitted
            </button>
          </div>
        </ng-container>
      </div>
      <div class="form-section">
        <ng-container>
          <ng-container *ngIf="
              !questionaireTypeLoading && !isQuarterFormLoading;
              else reportLoader
            ">
            <div class="form-container">
              <survey [model]="model" style="font-family: Inter"></survey>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #backButtonLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '85px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #prefilLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '85px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #submitLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '120px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #summaryLpader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '150px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #companyListLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8]">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '312px',
        height: '34px',
        margin: '10px 0'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #reportLoader>
  <div style="padding-top: 8px; border-bottom: 1px solid var(--border-color)">
    <ngx-skeleton-loader *ngFor="let number of [0, 1, 2, 3]" count="1" appearance="line" [theme]="{
        width: '110px',
        height: '34px',
        'margin-right': '16px',
        'margin-top': '10px'
      }"></ngx-skeleton-loader>
  </div>
  <div style="padding: 0 24px">
    <div *ngFor="let number of [0, 1, 2, 3, 4]">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '100%',
          height: '76px',
          margin: 0,
          'margin-top': '20px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
<ng-template #toolBarLoader>
  <div style="display: flex; width: 100%; justify-content: space-between">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '250px',
        height: '34px',
        'margin-top': '10px',
        'margin-left': '0px'
      }"></ngx-skeleton-loader>
    <div>
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '120px',
          height: '34px',
          'margin-top': '10px',
          'margin-right': '16px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '120px',
          height: '34px',
          'margin-top': '10px',
          'margin-right': '10px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '120px',
          height: '34px',
          'margin-top': '10px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
<ng-template #whiteSpinner>
  <app-spiner-loader [color]="'white'"></app-spiner-loader>
</ng-template>

<ng-template #blackSpinner>
  <app-spiner-loader [color]="'black'"></app-spiner-loader>
</ng-template>