import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-approval',
  templateUrl: './payment-approval.component.html',
  styleUrls: ['./payment-approval.component.scss']
})
export class PaymentApprovalComponent {
  serviceNotAvailableDesc = 'This vehicle is currently not configured to use this service. Please contact support at';
}
