import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { ReportsService } from '../reports.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface IDocument {
  id: number,
  name: string,
  mimeType: string,
}

export interface IMailPreview {
  sentAt: string,
  subject: string,
  htmlContent: string,
  recipients: string[],
  cc: string[]
}
@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss'],
})
export class ReportPreviewComponent {
  reportHeading: string;
  selectedFile: any;
  selectedType = 'email';
  renderedDocuments: IDocument[] = [];
  staticDocuments: IDocument[] = [];
  mailPreview: IMailPreview[] = []
  filePath: any;
  isLoading: boolean = false;
  isLoadingList: boolean = false;
  mobPdfActive: boolean = true;
  safeHtmlContent: SafeHtml;
  @ViewChild('iframeElement', { static: true }) iframeElement: ElementRef<HTMLIFrameElement>;

  constructor(
    public breakPointService: BreakPointService,
    private reportsService: ReportsService,
    public commonService: CommonService,
    private dialogRef: MatDialogRef<ReportPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.reportHeading = this.data.limitedPartner.name;
    this.getLPReportDetail();
  }

  getLPReportDetail() {
    this.isLoadingList = true;
    this.reportsService
      .getLPReportDetails(this.data.vehicleId, this.data.reportId, this.data.limitedPartner.limitedPartnerId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.renderedDocuments = parsedResponse.data.renderedDocuments;
          this.staticDocuments = parsedResponse.data.staticDocuments;
          if (parsedResponse.data.mailPreview.sentAt) {
            this.mailPreview.push(parsedResponse.data.mailPreview);
          }
          if (this.mailPreview.length) {
            this.selectedFile = [...this.mailPreview]
            this.selectedType = 'email';
          } else if (this.renderedDocuments.length) {
            this.selectedFile = [this.renderedDocuments[0]]
            this.selectedType = 'renderedPdf';
          } else {
            this.selectedFile = [this.staticDocuments[0]]
            this.selectedType = 'staticPdf';
          }
          if (this.selectedType !== 'email') {
            this.getFileData(this.selectedFile[0].id)
          }
        },
        error: error => {
          this.commonService.errorNotification('Failed to retrieve report details')
          this.handleClose();
        },
        complete: () => {
          this.isLoadingList = false;
        },
      });
  }

  getFileData(fileId: number) {
    this.isLoading = true;
    this.reportsService
      .getFilebyId(this.data.vehicleId, this.data.reportId, fileId)
      .subscribe({
        next: response => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8',
          });
          this.filePath = blob;
        },
        error: error => {
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve the file.')
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  handleClose(): void {
    this.dialogRef.close();
  }

  onSelectionChange(type: string) {
    this.selectedType = type;
    if (type != 'email') {
      this.getFileData(this.selectedFile[0].id)
    }
  }

  toggleMenu() {
    this.mobPdfActive = !this.mobPdfActive;
  }

  joinRecipients(recipients: string[]) {
    return recipients.join(', ')
  }

  isPDF(filename: any) {
    const extension = filename.split('.').pop().toLowerCase();
    return extension === 'pdf';
  }

  downloadFile() {
    const blobURL = URL.createObjectURL(this.filePath);

    const a = document.createElement('a');
    a.href = blobURL;
    a.download = this.selectedFile[0].name;
    a.click();

    URL.revokeObjectURL(blobURL);
  }

  getHtmlContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      content
    );
  }
}
