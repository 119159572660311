import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioCompaniesService {
  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) {}

  getFormDefinitions(designation: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `form-definitions/${designation}`,
        false
      )
    );
  }
  getPortfolioCompanies(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/portfolio-companies`,
        false
      )
    );
  }
  createPortfolioCompanie(vehicleId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/portfolio-companies`,
        false
      ),
      data
    );
  }
  deletePortfolioCompanie(portfolioCompanyId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `portfolio-companies/${portfolioCompanyId}`,
        false
      )
    );
  }
  updatePortfolioCompany(portfolioCompanyId: string, data: any) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `portfolio-companies/${portfolioCompanyId}`,
        false
      ),
      data
    );
  }
  getQuarterReportData(
    portfolioCompanyId: string,
    year: string,
    quarter: string
  ) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `portfolio-companies/${portfolioCompanyId}/quarterly-data/${year}/${quarter}`,
        false
      )
    );
  }
  updateQuarterlyReportData(
    portfolioCompanyId: string,
    year: string,
    quarter: string,
    data: any
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `portfolio-companies/${portfolioCompanyId}/quarterly-data/${year}/${quarter}`,
        false
      ),
      data
    );
  }
  createReportingPeriod(portfolioCompanyId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `portfolio-companies/${portfolioCompanyId}/quarterly-data`,
        false
      ),
      data
    );
  }
}
