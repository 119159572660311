import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface UserData {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  events: Events
}
interface Events {
  confirmedTermsOfUse: string | null;
  lastLogin: string | null;
  firstLogin: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userDetails: any;
  private userData = new BehaviorSubject<UserData>({
    id: '',
    title: '',
    firstName: '',
    lastName: '',
    events: {
      confirmedTermsOfUse: '',
      lastLogin: '',
      firstLogin: '',
    },
  });
  private fundAdminCockpitUrl = new BehaviorSubject('');
  private selectedVehicle = new BehaviorSubject('');
  private resetValues = new BehaviorSubject('');

  userDataObservable$ = this.userData.asObservable();
  fundAdminCockpitUrlObservable$ = this.fundAdminCockpitUrl.asObservable();
  selectedVehicleObservable$ = this.selectedVehicle.asObservable();
  resetValuesObservable$ = this.resetValues.asObservable();


  public async setResetValues(data: string) {
    this.resetValues.next(data);
  }

  public async setFundAdminCockpitUrl(data: string) {
    this.fundAdminCockpitUrl.next(data);
  }

  public async setSelectedVehicle(data: any) {
    this.selectedVehicle.next(data);
  }

  public async setUserData(data: any) {
    if (data) {
      this.userData.next(data);
    }
  }

  setUserConfig(name: any, interfaceLanguage: any, userId: any) {
    if (name && userId && interfaceLanguage) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: name,
          languageCode: interfaceLanguage,
          id: userId,
        })
      );
    }
    if (!localStorage.getItem('user')) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          name: 'Wendelin Luther',
          languageCode: 'en',
          id: 1,
        })
      );
    }

    this.userDetails = localStorage.getItem('user');
  }

  getUserConfig() {
    return this.userDetails;
  }
}
