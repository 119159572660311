<div class="task-card" (click)="clickHandler(cardData)" [ngClass]="{archived: cardData.archived}">
    <div class="topic">
        {{cardData.topic}}
    </div>
    <div class="title">
        {{cardData.title}}
        <!-- <strong style="color: red;">{{cardData.priority}}</strong>
        {{' '}}
        <strong style="color: blue;">{{cardData.id}}</strong> -->
    </div>
    <div class="time-stamp" *ngIf="cardData.timeStamp">
        <img *ngIf="!cardData.archived" src="../../../assets/images/clock_icon_grey.svg" alt="icon" height="18px"
            width="18px">
        <img *ngIf="cardData.archived" src="../../../assets/images/archived_icon.svg" alt="icon" height="18px"
            width="18px">
        {{cardData.archived ? 'Archived' : cardData.timeStamp}}
    </div>
</div>