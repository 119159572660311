import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class CounterpartsService {
  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService
  ) {}

  getCounterparts(
    vehicleId: string,
    filters: any,
    pageIndex: number = 0,
    pageSize: number,
    sort: string,
    order: string,
    pageToken?: string
  ) {
    const updatedFilters = {
      ...filters,
      limit: pageSize,
      sort: sort,
      order: order,
    };
    if (pageToken) {
      updatedFilters.pagination = pageToken;
    }

    const filteredParams = Object.fromEntries(
      Object.entries(updatedFilters).filter(
        ([_, value]) => value !== undefined && value !== null && value !== ''
      )
    );

    const url = this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/counterparts`,
      false
    );

    return this.apiHttpService.get(url, { params: filteredParams });
  }
}
