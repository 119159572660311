<div class="terms-of-use-container">
  <div mat-dialog-content class="popup-container">
    <div class="terms-of-use-card">
      <div class="card-header">
        <span class="heading">General Client Portal Terms of Use</span>
      </div>
      <div class="card-body">
        <div class="section">
          <div class="title">1. General Provisions</div>
          <br />
          <div class="content">
            <span class="sub-heading">1.1 Scope of these Terms</span>
            <br />
            These terms and conditions (as amended by ACE Alternatives GmbH from time to time, these “Terms”) govern the
            access to, and use of, this Client portal website, including the information, materials, and functions
            contained or embodied therein (collectively, the “Site”).
            <br />
            These Terms are between (i) ACE Alternatives, together with its affiliates (collectively, “ACE Alternatives”
            or “we” or “our”), (ii) you, (iii) the person(s) on whose behalf you are accessing the Site (such as a
            Client or prospective Client), and anyone authorized to access the Site on your behalf (collectively “you”).
            <br />
            <br />
            <span class="sub-heading">1.2 Intended Use</span>
            <br />
            ACE Alternatives GmbH operates this Site solely on behalf of its Clients (each a “Client” and collectively
            “Clients”). This Site is solely intended for access and for use by parties who have entered into a Sertvice
            agreement and been accepted as Clients (a “Client” and collectively “Clients”) in a certain business
            venture(s) or other structure(s) supported by ACE Alternatives and its affiliates.
            <br />
            <br />
            <span class="sub-heading">1.3 Password Protected Access</span>
            <br />
            The Site is password-protected and accessible only to users who have been issued passwords by an authorized
            representative of ACE Alternatives. Passwords are issued only to current Clients, and certain other parties
            approved by ACE Alternatives who are deemed authorized for access to the Site pursuant to these Terms.
            <br />
            <br />
            <span class="sub-heading">1.4 Authorized User;Responsibility for Users</span>
            <br />
            The Client has confirmed to us that you are an appointed officer, an employee, an agent, or an independent
            contractor of the Client authorized by the Client to access the Site, in the name and on behalf of the
            Client, to review certain information related to the Client and the business venture (“Authorized User”).
            <br />
            Clients will ensure at all times that:
            <br />
            <ul>
              <li>- only Authorized Users can access and use the Site;</li>
              <li>- all Authorized Users comply with these Terms;</li>
              <li>
                - each Authorized User is assigned a unique login/password by utilizing the appropriate authentication
                and password assignment tools which are available on the Site;
              </li>
              <li>
                - Authorized User login/password details are kept confidential; and
              </li>
              <li>
                - The Site is not used or accessed in relation to any business venture other than those for which the
                Client’s agreement has been accepted.
              </li>
            </ul>
            For the purposes of these Terms, all activities of (i) Authorized Users and (ii) any persons accessing the
            Site via the login/password of an Authorized User (whether or not legitimately) shall be deemed the
            activities of Clients.
            <br />
            As an Authorized User, by accessing this Site, you (including on behalf of any entity you represent) agree
            to be bound by these Terms.
            <br />
            <br />
            <span class="sub-heading">1.5 Compromise or Loss of Credentials</span>
            <br />
            As an Authorized User, you are responsible for ensuring the security and confidentiality of your login
            credentials, including not sharing those with any other person. You shall immediately notify ACE
            Alternatives at <span class="mailTo" (click)="mailTo()">support@ace-alternatives.com</span> of any
            information or event that may compromise the security of the Site, including loss or compromise of
            your login credentials. You accept full responsibility for any use of your login credentials. ACE
            Alternatives may suspend or revoke your access to and use of the Site at any time in its sole discretion.
            <br />
            <br />
            <span class="sub-heading">1.6 No Separate Contractual Relationship with You; No Business Advice; Regional
              Regulatory Limitations</span>
            <br />
            Your access to this Site is being provided as an accommodation to and at the request of a Client.
            <br />
            By accessing this Site, you are acknowledging and agreeing that your grant of access and use of the Site and
            its functionality does not establish a contractual, business, client, agency, or third-party beneficiary
            relationship between you and ACE Alternatives or its affiliates and ACE Alternatives and its affiliates
            expressly disclaim any liability for damages or losses (whether asserted on the basis of contract, tort or
            otherwise) related to your access and use of this Site. Client’s use of group email addresses with respect
            to access to this Site is solely at the risk of the Client and ACE Alternatives accepts no responsibility
            regarding such use by the Client.
            <br />
            FURTHERMORE, THE CONTENTS HEREOF ARE NOT INTENDED FOR ACCESS BY, DISTRIBUTION TO, OR USE BY, ANY PERSON OR
            ENTITY IN ANY JURISDICTION OR COUNTRY WHERE SUCH ACCESS, DISTRIBUTION OR USE WOULD BE CONTRARY TO LOCAL LAW
            OR REGULATION.
          </div>
        </div>
        <div class="section">
          <div class="title">2. Important Disclosures and Disclaimers</div>
          <br />
          <div class="content">
            <span class="sub-heading">2.1 Review Everything Carefully and Completely</span>
            <br />
            The Site may contain performance information for the business ventures managed by ACE Alternatives. A
            Clientship in any business venture may not be suitable for all Clients, and ACE Alternatives does not
            guarantee their performance. The returns from such a Clientship may rise and fall and are not guaranteed,
            and Clients may lose all or some of their initial capital invested. There can be no assurance that any
            business venture will achieve its business objective or avoid substantial losses, and performance may be
            volatile. You acknowledge that past performance is not indicative of future results.
            <br />
            <br />
            <span class="sub-heading">2.2 No Reliance</span>
            <br />
            While ACE Alternatives uses reasonable efforts to update the information contained in the Site, ACE
            Alternatives makes no representations or warranties as to the accuracy, reliability, or completeness of any
            information on the Site. Any content on the Site is subject to change without notice.
            <br />
            <br />
            <span class="sub-heading">2.3 No Advice</span>
            <br />
            The Site is not intended to supply tax, legal, accounting, or business advice, and the materials (or any
            part of them) are not intended to be construed as a recommendation to enter into any Clientship. If you are
            in any doubt as to the matters or contents contained on the Site you should obtain independent professional
            advice. By accepting these Terms, you acknowledge that the Site and any of its materials are for your
            private, non-commercial informational purposes only and that the use and interpretation of the Site and any
            of its materials require a specific and in-depth knowledge of the business markets.
            <br />
            <br />
            <span class="sub-heading">2.4 Confidentiality</span>
            <br />
            By accessing the Site, you agree that you will maintain as confidential all information, materials, and
            functions contained or embodied therein, including information concerning the business ventures of ACE
            Alternatives (including all reports and notices received for a business venture). You will not disclose such
            information to any person, except for information that is otherwise publicly available or required to be
            disclosed by law, and you will not use any such information in any manner which could reasonably be expected
            to be adverse to the best interests of ACE Alternatives or any business venture. You agree to use your best
            efforts to safeguard such information and to prevent unauthorized, negligent, or inadvertent use or
            disclosure thereof. You may, however, share such information with the relevant Client’s business advisers,
            beneficial owners, accountants, and/or attorneys, provided that such other persons undertake: (a) to hold
            such information confidential to the same extent set forth in these Terms; and (b) not to use any such
            information in any manner which could reasonably be expected to be adverse to the best interests of ACE
            Alternatives or any business venture. For the avoidance of doubt, you may not provide information concerning
            ACE Alternatives or the business ventures to any third party, knowing that such third party may use such
            information in any form of printed, electronic, or “on-line” publication, newsletter, or circular, whether
            publicly or privately distributed. For the avoidance of doubt, these obligations are in addition to any
            confidentiality obligations to which you may otherwise be subject.
          </div>
        </div>
        <div class="section">
          <div class="title">3. LIMITATION OF LIABILITY</div>
          <br />
          <div class="content">
            <span class="sub-heading">3.1 General Limitation of Liability</span>
            <br />
            ACE ALTERNATIVES DOES NOT MAKE ANY REPRESENTATION, WARRANTY, OR PROMISE THAT THE SITE OR ANY MATERIALS OR
            FEATURES AVAILABLE ON OR THROUGH THE SITE WILL BE ACCURATE, SECURE, COMPLETE, ERROR-FREE, TIMELY, OR
            UNINTERRUPTED, OR THAT ANY DEFECTS WILL BE CORRECTED.
            <br />
            THE INFORMATION AND CONTENT ON THIS SITE ARE PROVIDED ON AN "AS-IS", "AS AVAILABLE" BASIS.
            <br />
            TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, ACE ALTERNATIVES DISCLAIMS ALL WARRANTIES, EXPRESS OR
            IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A
            PARTICULAR PURPOSE WITH RESPECT TO THE SITE AND THE MATERIALS AND FEATURES AVAILABLE ON OR THROUGH THE
            SITE, AND FREEDOM FROM VIRUSES OR OTHER CONTAMINATION. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE
            SITE.
            <br />
            TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, THE LIABILITY OF ACE ALTERNATIVES, ITS CLIENT(S), OR BUSINESS
            VENTURES SERVICED BY ACE ALTERNATIVES FOR ITS CLIENTS, WILL BE EXCLUDED FOR ANY CONSEQUENTIAL, INCIDENTAL,
            SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF ANY USE OF OR INABILITY TO USE THE SITE, REGARDLESS
            OF WHETHER ACE ALTERNATIVES, THE CLIENT, OR THE BUSINESS VENTURE SERVICED BY ACE ALTERNATIVES HAS BEEN
            APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN
            CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE.
            <br />
            <br />
            <span class="sub-heading">3.2 Third-Party Content</span>
            <br />
            The Site may include services, general news and information, commentary, research, and other data supplied
            by companies that are not affiliated with ACE Alternatives or its clients (“Third Party Content”). ACE
            Alternatives has not been involved in the preparation or editing of Third Party Content and does not
            explicitly or implicitly endorse or approve such content or services. ACE Alternatives does not guarantee
            the accuracy, timeliness, completeness, or utility of Third Party Content, and ACE Alternatives shall not
            be responsible or liable for any such content or services. Your access to Third Party Content or other
            websites is at your own risk and is subject to their own privacy policies.
            <br />
            <br />
            <span class="sub-heading">3.3 Third-Party Links</span>
            <br />
            ACE Alternatives may provide a link to other sites through this Site. ACE Alternatives does not control
            any third-party site and makes no representations concerning the content of any such site and expressly
            disclaims any liability with respect thereto.
          </div>
        </div>
        <div class="section">
          <div class="title">4. Access</div>
          <br />
          <div class="content">
            ACE Alternatives takes reasonable and appropriate measures to protect information we receive through the
            Site and to ensure that information is only disclosed to you. However, the Internet is an open system and
            there is no absolute guarantee that the information you have sent to us or retrieve will not be
            intercepted by others and you assume that risk in accessing this Site.
            <br />
            ACE Alternatives reserves the right to monitor and audit all transactions and data (including e-mail
            messages) for any purpose sent over this network and your use of this Site unless prohibited by law. You
            agree that a printed version of these terms and conditions shall be admissible in judicial or
            administrative proceedings to the same extent as other business documents and records.
            <br />
            Neither ACE Alternatives nor its relevant Client(s) guarantee that the Site, and any feature thereof, will
            always be available.
            <br />
            ACE Alternatives may suspend or terminate your access to this Site, or may terminate provision of this
            Site or any portion thereof, for any or no reason at any time, subject to the terms of any separate
            written agreement between the Client of ACE, the business venture, or other structure which has requested
            your access to this Site.
          </div>
        </div>
        <div class="section">
          <div class="title">5. Intellectual Property Rights and License</div>
          <br />
          <div class="content">
            <span class="sub-heading">5.1 Intellectual Property Rights</span>
            <br />
            ACE Alternatives retains any and all intellectual property and other proprietary rights (including moral
            rights) in and to the Site, together with any and all upgrades, updates, enhancements, improvements,
            modifications, and derivative works thereof. The Site is protected by applicable copyright, trade dress,
            patent, and trademark laws, international conventions, and other laws protecting intellectual property and
            related proprietary rights. Subject to your agreement and compliance with these Terms, ACE Alternatives
            grants you a limited, revocable, non-exclusive, non-transferable, non-sublicensable license to access and
            use the Site strictly and solely for the purpose as described in section 1 above.
            <br />
            <br />
            <span class="sub-heading">5.2 Restrictions</span>
            <br />
            You shall not, nor shall you cause or permit any other party to: (i) modify, adapt, copy, alter, publish,
            print, disclose, download, translate, or create derivative works of the Site; (ii) disable, disrupt,
            circumvent, or otherwise interfere with any feature, functionality, or control that prevents, restricts,
            or otherwise governs access to, or use of, any aspect of the Site; (iii) build a product or service using
            similar ideas, features, functions, or graphics of, or otherwise contained within, the Site; (iv)
            sublicense, resell, rent, lease, transfer, or assign the Site or the use thereof, or to offer the Site on
            a time-share basis to any third party; (v) reverse engineer, decompile, decode, or disassemble the Site
            (or any part thereof); (vi) attempt to derive the source code for the Site (or any part thereof) or
            attempt to gain access to any underlying code used to implement or deploy the Site, or (vii) otherwise
            exploit or use the Site in any way except as strictly set forth in these Terms.
          </div>
        </div>
        <div class="section">
          <div class="title">6. Miscellaneous Matters</div>
          <br />
          <div class="content">
            <span class="sub-heading">6.1 Privacy</span>
            <br />
            Information about how ACE Alternatives processes your personal information in relation to the use of the
            Site is located in our Privacy Notice [here], which is incorporated into and forms a part of these Terms.
            <br />
            <br />
            <span class="sub-heading">6.2 Completeness</span>
            <br />
            These Terms constitute the entire understanding between ACE Alternatives, the Client, the Client, and you
            as Authorized User, concerning the Site; except that nothing in these Terms is intended to supersede or
            abrogate any provision of a business venture’s constituent or governing documents or relevant Clientship
            documents, or terms applicable to materials available on the Site. These Terms do not govern the terms of
            any Clientship the Client, or you, may enter into with a Client of ACE Alternatives, or any other aspect
            of the relationship with a Client of ACE Alternatives, beyond your use of or access to the Site.
            <br />
            <br />
            <span class="sub-heading">6.3 Amendments</span>
            <br />
            These Terms are subject to change. ACE Alternatives reserves the right to modify these Terms, which it may
            do by posting changes to the Site. If you do not agree with these terms and conditions, please do not
            access the Site.
            <br />
            <br />
            <span class="sub-heading">6.4 Governing Law and Place of Venue</span>
            <br />
            The access to and the use of the Site by Authorized Users in accordance with these Terms, and these Terms
            including the limitation of liability, are governed by the laws of the Federal Republic of Germany,
            notwithstanding any principles of conflicts of law. Any dispute in connection with the Site and these
            Terms will be exclusively settled by the courts of Berlin in the Federal Republic of Germany.
            <br />
            <br />
            <span class="sub-heading">6.5 Questions and Notice</span>
            <br />
            For information, support, or questions or to provide notice pursuant to these Terms, please contact us at:
            <span class="mailTo" (click)="mailTo()">support@ace-alternatives.com</span>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="checkbox" *ngIf="!data.confirmedTermsOfUse">
          <mat-checkbox class="agreement" (change)="toggleCheckbox($event)">
            I confirm that I agree to these terms of use.
          </mat-checkbox>
        </div>
        <div class="acknowledgement" *ngIf="data.confirmedTermsOfUse">
          <span class="message">
            You agreed to these terms of use on
            <span class="date">
              {{ data.confirmedTermsOfUse | date : 'dd MMM, yyyy' }}
            </span>
            at
            <span>
              {{ data.confirmedTermsOfUse | date : 'h:mm a' }}
            </span>
          </span>
        </div>
        <mat-dialog-actions class="actions">
          <div>
            <button class="button-warn" [disabled]="!checkboxChecked && !data.confirmedTermsOfUse" mat-button
              (click)="submitTermsOfUse()">
              OK
            </button>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
  </div>