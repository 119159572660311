// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// export const environment = {
//   production: false,
//   authUrl: 'http://localhost:5004/realms/ace',
//   clientId: 'gp-portal.app',
//   baseUrl: 'https://investor-portal-mock-dev.ace-alternatives.com',
//   tokenEndpoint:
//     'http://localhost:5004/realms/ace/protocol/openid-connect/token',
//   datahubApiUrl: 'https://dev.api.suite7.io/lp-portal/v1',
//   useRealAPI: false,
// };

export const environment = {
  production: false,
  authUrl: 'https://auth.ace-alternatives.com/auth/realms/ace-test',
  clientId: 'gp-portal.app',
  baseUrl: 'https://investor-portal-mock-dev.ace-alternatives.com',
  tokenEndpoint:
    'https://auth.ace-alternatives.com/auth/realms/ace-test/protocol/openid-connect/token',
  datahubApiUrl: 'https://dev.api.suite7.io/gp-portal/v1',
  // datahubApiUrl: 'http://0.0.0.0:3003/gp-portal/v1',
  useRealAPI: false,
  pdfjsExpressViewerLicenseKey: 'H54vX6fcVEDr2EREk2ZS',
  pdfApryseViewerLicenseKey:
    'ACE Alternatives GmbH:OEM:Suite7::B+:AMS(20271219):82DCAE9307AAD4F33B313BC9C26039264E6F1FCF785A037E221BB404345037F151B6F5C7',
  layer: 'dev',
  lpDatahubApi: 'https://dev.api.suite7.io/lp-portal/v1',
  moniteDropinUrl: 'https://api.sandbox.monite.com/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
