import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
const REFRESH_TIME_MS = 3 * 60 * 1000; // 3 in milliseconds
const LOGOUT_TIME_MS = 180 * 60 * 1000; // 3 hour in milliseconds

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  tokenRefreshSubscription: any;

  getIssuer(hostname: string): string {
    if (hostname === 'investors.join.capital') {
      return 'https://auth.investors.join.capital/auth/realms/ace';
    } else if (hostname === 'investors.robincap.com') {
      return 'https://auth.investors.robincap.com/auth/realms/ace';
    } else if (hostname === 'investors.interfacecap.com') {
      return 'https://auth.investors.interfacecap.com/auth/realms/ace';
    } else if (hostname === 'investors.bfc.vc') {
      return 'https://auth.investors.bfc.vc/auth/realms/ace';
    } else if (hostname === 'investors.w3.fund') {
      return 'https://auth.investors.w3.fund/auth/realms/ace';
    } else if (hostname === 'investors.vireo.vc') {
      return 'https://auth.investors.vireo.vc/auth/realms/ace';
    } else if (hostname === 'hic-investors.redstone.vc') {
      return 'https://auth.hic-investors.redstone.vc/auth/realms/ace';
    } else if (hostname === 'investors.vanagon.vc') {
      return 'https://auth.investors.vanagon.vc/auth/realms/ace';
    } else if (hostname === 'investors.4p.capital') {
      return 'https://auth.investors.4p.capital/auth/realms/ace';
    } else {
      // Default issuer
      return environment.authUrl;
    }
  }

  getTokenEndpoint(hostname: string): string {
    if (hostname === 'investors.join.capital') {
      return 'https://auth.investors.join.capital/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.robincap.com') {
      return 'https://auth.investors.robincap.com/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.interfacecap.com') {
      return 'https://auth.investors.interfacecap.com/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.bfc.vc') {
      return 'https://auth.investors.bfc.vc/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.w3.fund') {
      return 'https://auth.investors.w3.fund/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.vireo.vc') {
      return 'https://auth.investors.vireo.vc/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'hic-investors.redstone.vc') {
      return 'https://auth.hic-investors.redstone.vc/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.vanagon.vc') {
      return 'https://auth.investors.vanagon.vc/auth/realms/ace/protocol/openid-connect/token';
    } else if (hostname === 'investors.4p.capital') {
      return 'https://auth.investors.4p.capital/auth/realms/ace/protocol/openid-connect/token';
    } else {
      // Default token endpoint
      return environment.tokenEndpoint;
    }
  }

  constructor(private oauthService: OAuthService) {
    const actualDomainName = window.location.hostname;
    // console.log('actualDomainName from auth config', actualDomainName);
    const authConfig: AuthConfig = {
      issuer: this.getIssuer(actualDomainName),
      redirectUri: window.location.origin,
      clientId: environment.clientId,
      responseType: 'code',
      showDebugInformation: !environment.production,
      sessionChecksEnabled: false,
      useSilentRefresh: false,
      requireHttps: environment.production,
      tokenEndpoint: this.getTokenEndpoint(actualDomainName),
    };
    // console.log('final authConfig from auth service constructor', authConfig);
    this.oauthService.configure(authConfig);

    this.oauthService.events.subscribe(event => {
      if (
        event.type === 'token_expires' ||
        event.type === 'session_terminated' ||
        event.type === 'session_error' ||
        event.type === 'code_error'
      ) {
        // console.log('event:::Testing Event Fire', event);       
        this.oauthService.revokeTokenAndLogout({
          client_id: this.oauthService.clientId,
          returnTo: this.oauthService.redirectUri
        }, true);
      } else if (event.type === 'token_refresh_error') {
        this.authenticate()
      }
    });
  }

  logoutAfterThreeHours() {
    const logoutTimer$ = timer(LOGOUT_TIME_MS);
    logoutTimer$.pipe(take(1)).subscribe(() => {
      this.oauthService.revokeTokenAndLogout({
        client_id: this.oauthService.clientId,
        returnTo: this.oauthService.redirectUri
      }, true);
    });
  }

  startTokenRefreshTimer() {
    this.tokenRefreshSubscription = timer(0, REFRESH_TIME_MS)
      .pipe(switchMap(() => this.oauthService.refreshToken()))
      .subscribe({});
  }

  authenticate(): Promise<boolean> {
    return this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => {
        const pathname = window.location.pathname;
        if (!this.oauthService.hasValidAccessToken() && pathname.length > 1) {
          localStorage.setItem('tpKey', pathname);
        }
        return this.oauthService.hasValidAccessToken();
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  isAuthenticated(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public logout() {
    localStorage.removeItem('tpKey');
    this.oauthService.revokeTokenAndLogout({
      client_id: this.oauthService.clientId,
      returnTo: this.oauthService.redirectUri
    }, true);
  }

  login() {
    this.oauthService.initLoginFlow();
  }

  public getBearerToken(): string {
    const accessToken = this.oauthService.getAccessToken();
    if (!accessToken) {
      throw new Error('Access token not found');
    }
    return `Bearer ${accessToken}`;
  }
}
