<div class="delete-letterhead">
    <div class="content">
        <div class="heading">Delete “{{data.name}}” ?
            <button (click)="dialogRef.close()" [disabled]="loading">
                <img src="../../../assets/images/cross_icon_grey.svg" alt="close">
            </button>
        </div>
        <div class="form-field">
            <div class="description">Are you sure you want to delete? This will remove all the data including the pages.
            </div>
        </div>
    </div>
    <div class="action">
        <button mat-flat-button class="cancel" [disabled]="loading" (click)="dialogRef.close()">Cancel</button>
        <button mat-flat-button class="delete" [disabled]="loading" (click)="deleteLetterhead()">Delete</button>
    </div>
</div>