import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { InvoiceDetailService } from "../invoice-detail/invoice-detail.service";



@Component({
  selector: "app-qrcode",
  templateUrl: "./qrcode.component.html",
  styleUrls: ["./qrcode.component.scss"],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class QrcodeComponent implements OnDestroy, OnInit {
    @Input() qrCodeBase64: string|null = null;
    @Input() metaData: any = null;
    @Input() status: string|null = null;

    @Output() closeQrCodeEvent = new EventEmitter<void>();
    @Output() markAsPaidEvent = new EventEmitter<void>();

    selectedInvoice: any | null = null;


        private destroy$: Subject<void> = new Subject<void>();

    constructor(private invoiceDetailsService: InvoiceDetailService) {}
    ngOnDestroy(): void {
        
        this.destroy$.next();
        this.destroy$.complete()
    }

    ngOnInit() {
        this.invoiceDetailsService.invoiceDetailsAsObservables$.pipe(
            takeUntil(this.destroy$),

        ).subscribe((invoiceDetails) => {
            this.selectedInvoice = invoiceDetails;

            console.log("selectedInvoice", this.selectedInvoice);
        }
        );
    }

    closeQrCodeHandler() {
        this.closeQrCodeEvent.emit();
    }
    markAsPaidHandler() {
        this.markAsPaidEvent.emit();
    }
}