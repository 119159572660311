<div class="snack-bar big">
    <div class="message-wrapper">
        <div class="icon">
            <img src="../../../../assets/images/check_icon.svg" alt="icon" height="17.14px" width="17.14px">
        </div>
        <div class="message">
            <div class="message-title">{{data.message || 'An unexpected error occurred'}}</div>
            <div class="message-body">{{data.messageBody ? data.messageBody : ''}}</div>
        </div>
    </div>
    <button (click)="dismiss()">
        <img src="../../../../assets/images/cross_icon_grey.svg" alt="close" height="20px" width="20px">
    </button>
</div>