import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { BankTransactionsService } from './bank-transactions.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, merge, of as observableOf, Subject } from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { IFilterMenu } from 'src/app/shared/components/FilterMenu/filter-menu.model';
import {
  CounterPartyLabelComponent,
  BankAccountLabelComponent,
} from './custom-label.component';
interface ITransactions {
  id: string;
  accountId: string;
  account: string;
  currency: string;
  amount: number;
  purpose: string;
  name: string;
  date: string;
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
interface SubItem {
  name?: string;
  selected?: boolean;
  count?: number;
  accountNo?: number;
}
@Component({
  selector: 'app-bank-transactions',
  templateUrl: './bank-transactions.component.html',
  styleUrls: ['./bank-transactions.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class BankTransactionsComponent implements AfterViewInit {
  search: string;
  vehicleId: string;
  gpId: string;
  transactionId: string | undefined;
  selecetdTransaction: any;
  displayedColumns: string[] = [
    'bookingDate',
    'purpose',
    'amount',
    'currency',
    'partnerName',
    'bankAccount',
    'details',
    // 'accountId'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  totalRecords = 0;
  transactions: ITransactions[] = [];
  noTransactions = false;
  bankAccount: any | null;
  accountList: any;
  filters: BehaviorSubject<any> = new BehaviorSubject<any>({});
  refresh: BehaviorSubject<any> = new BehaviorSubject<string>('');
  isLoading = true;
  isClearBtn = false;
  isPageLoading = false;
  isDownloading = false;
  isSyncInProgress = false;
  amountType = '';
  rowHeight = 60; // Adjust row height as per your table styles
  private searchSubject = new Subject<boolean>();
  maxDate: Date = new Date();
  monthsBack = 6;
  showExportTooltip = true;
  currentFilterOrder: SortDirection = 'desc';

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableSection', { static: false }) tableSection!: ElementRef;
  @ViewChild('paginator', { static: false }) paginator!: MatPaginator;
  @ViewChild('subFilterSelect') subFilterSelect: MatSelect;

  pageSize = 10;
  updatePageSize = false;
  selectedFilters: any[] = [];
  filterMenuItems: IFilterMenu[] = [];

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    public commonService: CommonService,
    private bankTransactionsService: BankTransactionsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.transactionId = this.activatedRoute.snapshot.params['transactionId'];
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Bank']);
    }, 0);
    this.searchSubject.pipe(debounceTime(1000)).subscribe(flag => {
      flag && this.searchFilter();
    });
    this.filters.subscribe(filters => {
      if (
        filters &&
        filters.order &&
        (filters.order === 'asc' || filters.order === 'desc')
      ) {
        this.currentFilterOrder = filters.order;
      } else {
        this.currentFilterOrder = 'desc';
      }
    });
  }
  
  sortChange() {
    this.filters.next({
      ...this.filters.getValue(),
      sortBy: this.sort.active,
      order: this.sort.direction,
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    // Handle sort changes, paginator changes, and filter updates in merge
    merge(this.sort.sortChange, this.paginator.page, this.filters, this.refresh)
      .pipe(
        startWith({}), // Emit an initial empty object when the stream starts
        tap(() => this.adjustPageSize()),
        switchMap(() => this.loadTransactions()),
        catchError(() => observableOf([]))
      )
      .subscribe(data => {
        this.dataSource.data = data;
      });
  }

  adjustPageSize() {
    const availableHeight =
      this.tableSection.nativeElement.offsetHeight - 70 - 45;
    const rowsPerPage = Math.floor(availableHeight / this.rowHeight);
    const roundedRowsPerPage = Math.ceil(rowsPerPage / 5) * 5;
    this.pageSize = Math.max(roundedRowsPerPage, 10);
    this.paginator.pageSize = this.pageSize;
    this.updatePageSize = true;
  }

  // Method to calculate the total selected filters (main items + sub-items)
  getSelectedFilterCount(): number {
    let count = this.selectedFilters.length;
    this.selectedFilters.forEach((item: any) => {
      count += item['selectedSubItems']?.length;
    });
    return count;
  }
  getQueryKey(parentValue: string): string {
    return parentValue; // default
  }
  getQueryParams() {
    const queryParams: string[] = [];
    this.selectedFilters.forEach((item: any) => {
      const queryKey = this.getQueryKey(item.parentValue);
      item.selectedChildren.forEach((value: any) => {
        queryParams.push(`${queryKey}=${encodeURIComponent(value)}`);
      });
    });
    return queryParams.join('&');
  }
  getRequestPayload() {
    const filters: any = {};

    this.selectedFilters.forEach((item: any) => {
        const queryKey = this.getQueryKey(item.parentValue);
        filters[queryKey] = item.selectedChildren; // Directly map values to the key
    });
    return filters;
  }

  filterApplied(filters: any) {
    this.selectedFilters = filters;
    this.paginator.pageIndex = 0;
    this.refresh.next('refresh');
  }
  isPayloadEmpty(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }
  loadTransactions() {
    const filters = this.filters.getValue();
    const payloadFilters = this.getRequestPayload();
    
    let isFilterApplied = Object.values(filters).some(value => value);
    if(!this.isPayloadEmpty(payloadFilters)) {
      isFilterApplied = true;
    }

    if (this.dataSource.data.length > 0 || isFilterApplied || this.isClearBtn) {
      this.isPageLoading = true; // Show pagination loader
    } else {
      this.isLoading = true; // Show initial loader
      this.isPageLoading = true;
      this.cdr.detectChanges();
    }

    return this.bankTransactionsService
      .getTransactions(
        this.vehicleId,
        this.sort.active,
        this.sort.direction,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        filters,
        this.getRequestPayload(),
      )
      .pipe(
        map(data => {
          const isFilterApplied = Object.keys(filters).some(
            key => key !== 'order' && key !== 'sortBy'
          );
          this.filterMenuItems = [];
          const parsedResponse = JSON.parse(JSON.stringify(data));

          this.isLoading = false;
          this.isPageLoading = false;
          this.isClearBtn = false;
          this.totalRecords = parsedResponse.data.count;

          if (data === null) {
            return [];
          }

          if (isFilterApplied) {
            this.showExportTooltip = false;
          }

          // Process data and handle account list if no filter is applied
          if (!isFilterApplied) {
            this.showExportTooltip = true;
            this.noTransactions = !parsedResponse.data.count;
            this.noTransactions = !parsedResponse.data.lastSynced;
          }

          if (parsedResponse.data.accounts) {
            this.filterMenuItems.push({
              label: 'Bank account',
              value: 'iban',
              children: parsedResponse.data.accounts.map((account: any) => ({
                label: BankAccountLabelComponent,
                value: account?.accountNo,
                count: account?.count,
                labelProps: {
                  name: account?.bank,
                  accountNo: account?.accountNo,
                },
              })),
            });
          }

          if (parsedResponse.data.transactionType) {
            this.filterMenuItems.push({
              label: 'Transaction type',
              value: 'transactionType',
              children: parsedResponse.data.transactionType.map(
                (transactionType: any) => ({
                  label: transactionType?.name,
                  value: transactionType?.name,
                  count: transactionType?.count,
                })
              ),
            });
          }

          if (parsedResponse.data.counterparty) {
            this.filterMenuItems.push({
              label: 'Counterparty',
              value: 'counterparty',
              children: parsedResponse.data.counterparty.map(
                (counterparty: any) => ({
                  label: CounterPartyLabelComponent,
                  value: counterparty?.name,
                  count: counterparty?.count,
                  labelProps: {
                    name: counterparty?.name,
                    count: counterparty?.count,
                  },
                })
              ),
            });
          }

          return parsedResponse.data.transactions;
        })
      );
  }

  searchHandler() {
    this.searchSubject.next(true);
  }

  searchFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();
    if (filterValue) {
      this.filters.next({
        ...this.filters.getValue(),
        search: filterValue,
      });
    }
    this.paginator.pageIndex = 0;
  }
  accountFilter(event: any) {
    if (this.bankAccount) {
      this.filters.next({
        ...this.filters.getValue(),
        iban: this.bankAccount.accountNo,
      });
      this.paginator.pageIndex = 0;
    }
  }

  amountFilter(amountType: string) {
    this.amountType = amountType;
    this.filters.next({
      ...this.filters.getValue(),
      transactionType: amountType,
    });
    this.paginator.pageIndex = 0;
  }

  dateRangeFilter(startDate: any, endDate: any) {
    if (startDate.value && endDate.value) {
      const startDateString = this.commonService.convertDateFormat(
        startDate.value
      );
      const endDateString = this.commonService.convertDateFormat(endDate.value);
      this.filters.next({
        ...this.filters.getValue(),
        startDate: startDateString,
        endDate: endDateString,
      });
      this.paginator.pageIndex = 0;
    }
  }

  clearFilter(filterKey: string) {
    this.isClearBtn = true;
    if (filterKey === 'dateRange') {
      this.range.reset();
    }
    const currentFilters = this.filters.getValue();
    const updatedFilters = { ...currentFilters };

    if (filterKey === 'dateRange') {
      delete updatedFilters.startDate;
      delete updatedFilters.endDate;
    } else {
      delete updatedFilters[filterKey];
    }
    this.filters.next(updatedFilters);
    this.paginator.pageIndex = 0;
  }

  handleBackspaceClear(event: KeyboardEvent) {
    const filterValue = (this.search || '').trim().toLowerCase();
    // Detect backspace or delete, and reset the filter if the search is empty
    if ((event.key === 'Backspace' || event.key === 'Delete') && !filterValue) {
      this.clearFilter('search');
    }
  }

  syncTransactions() {
    this.isSyncInProgress = true;
    this.bankTransactionsService.syncTransaction(this.vehicleId).subscribe({
      next: response => {
        this.isSyncInProgress = false;
        this.refresh.next('refresh');
        this.commonService.successNotification(
          'Transactions synced successfully'
        );
      },
      error: error => {
        this.isSyncInProgress = false;
        this.commonService.errorNotification('Failed to sync transactions');
      },
      complete: () => {
        this.isSyncInProgress = false;
      },
    });
  }

  downloadExcel() {
    this.isDownloading = true;
    const filters = this.filters.getValue();
    const payloadFilters = this.getRequestPayload();
    
    let isFilterApplied = Object.values(filters).some(value => value);

    if(!this.isPayloadEmpty(payloadFilters)) {
      isFilterApplied = true;
    }
    if(!isFilterApplied) {
      const maxDate = new Date();
          const minDate = new Date();
          maxDate.setHours(0, 0, 0, 0);
          minDate.setHours(0, 0, 0, 0);
          // Subtract monthsBack from today's date
          minDate.setMonth(minDate.getMonth() - this.monthsBack);
          // Format them as 'YYYY-MM-DD'
          filters.startDate = minDate.toISOString().split('T')[0];
          filters.endDate = maxDate.toISOString().split('T')[0];
    }
    this.bankTransactionsService
      .getExcelFile(
        this.vehicleId,
        this.sort.active,
        this.sort.direction,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        { ...filters, format: 'excel' },
        { ...payloadFilters },
      )
      .subscribe({
        next: res => {
          const blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const blobURL = URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = blobURL;
          a.download = `${this.formatDate(new Date())} transactions`;
          a.click();

          URL.revokeObjectURL(blobURL);
          this.isDownloading = false;
        },
        error: err => {
          this.isDownloading = false;
          console.log(err);
        },
      });
  }

  getAmountClass(amount: number): string {
    if (amount < 0) {
      return 'negative-amt';
    } else if (amount > 0) {
      return 'positive-amt';
    }
    return '';
  }

  formatDate(date: Date) {
    // 2024-09-17
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  navigateBack() {
    // this.backClick.emit();
  }

  showDetails(transaction: any) {
    const { account, amount, currency, bookingDate, purpose, paymentPartner } =
      transaction;
    const filteredTransaction = {
      account,
      amount,
      currency,
      bookingDate,
      purpose,
      paymentPartner,
    };
    this.selecetdTransaction = filteredTransaction;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/transactions/${transaction.id}`
    );
    this.transactionId = transaction.id;
  }

  hideDetails() {
    this.transactionId = undefined;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/transactions`
    );
    this.commonService.setSecondaryPath([]);
    // !this.transactions && this.getTransactions();
  }

  viewTransactions(data: any) {
    const { account, amount, currency, bookingDate, purpose, paymentPartner } =
      data;
    const filteredTransaction = {
      account,
      amount,
      currency,
      bookingDate,
      purpose,
      paymentPartner,
    };
    this.selecetdTransaction = filteredTransaction;
    this.bankTransactionsService.setTransactionDetails(
      this.selecetdTransaction
    );
    this.bankTransactionsService.openDrawer();
  }

  isFilterApplied(): boolean {
    const { start, end } = this.range?.value || {};
    const filters = this.filters.getValue();

    return this.selectedFilters.length > 0 || !!start || !!end || Object.values(filters).some(Boolean);
  }
  
}

@Component({
  selector: 'app-bank-transaction-detail',
  templateUrl: './bank-transaction-detail.component.html',
  styleUrls: ['./bank-transactions.component.scss'],
  // imports: [MenuSelectComponent],
})
export class BankTransactionDetailComponent {
  isLoading = false;
  transactionDetails: any;
  dateFields = ['bookingDate', 'date', 'valueDate'];
  hideFields = ['id', 'valueDate', 'tags'];

  constructor(
    public commonService: CommonService,
    public bankTransactionsService: BankTransactionsService
  ) {}

  ngOnInit() {
    this.bankTransactionsService.transactionDetailsObservable$.subscribe(
      (res: any) => {
        if (res) {
          this.transactionDetails = this.objectToArrayOfObjects(res);
        }
      }
    );
  }
  objectToArrayOfObjects(obj: any) {
    return Object.entries(obj).map(([key, value]) => ({
      key: key,
      value: value,
    }));
  }

  isObject(item: any) {
    return typeof item === 'object' && item !== null;
  }

}
