<div class="dashboard-pg grid-container" [ngClass]="{'isHandset': breakPointService.isHandset$ | async, 'isTablet': breakPointService.isTablet$ | async}">
  <div class="grid-row-1">
    <mat-card class="dashboard-card">
        <div class="card-container">
          <div class="cardHead">
            <div class="cardHeadLeft">
              {{ 'translations.dashboard.timeline.title' | translate }}
            </div>
          </div>
          <div class="cardBody timeline-body">
            <div *ngIf="dashboardBaseData.timeline; else timelineLoader" class="area-chart-container">
              <div class="progress">
                <div class="parallelogram parallelogram--2" *ngIf="
                          getEventPosition(
                            dashboardBaseData.timeline.startInvestmentDate,
                            dashboardBaseData.timeline.endDate
                          ) < 50
                        "></div>
                <div class="progressDate start">
                  <div>
                    <p class="progressYear">
                      {{
                      timeLineData.startInvestmentDate
                      | date
                      : 'dd. MMM'
                      : ''
                      : (languageCode === 'de' ? 'de' : 'en')
                      }}
                      {{ timeLineData.startInvestmentDate | date : 'yyyy' }}
                    </p>
                  </div>
                </div>
                <div class="progressDate" [ngStyle]="{
                          left:
                            getEventPosition(
                              dashboardBaseData.timeline.startInvestmentDate,
                              dashboardBaseData.timeline.startLiquidationDate
                            ) >= 100
                              ? 'calc(' +
                                getEventPosition(
                                  dashboardBaseData.timeline.startInvestmentDate,
                                  dateNow,
                                  dashboardBaseData.timeline.startLiquidationDate
                                ) /
                                  2 +
                                '% - 28px)'
                              : 'calc(50% - 28px)'
                        }">
                  <div>
                    <p class="progressYear">
                      {{
                      timeLineData.startLiquidationDate
                      | date
                      : 'dd. MMM'
                      : ''
                      : (languageCode === 'de' ? 'de' : 'en')
                      }}
                      {{ timeLineData.startLiquidationDate | date : 'yyyy' }}
                    </p>
                  </div>
                </div>
                <div class="progressDate end">
                  <div>
                    <p class="progressYear">
                      {{
                      timeLineData.endDate
                      | date
                      : 'dd. MMM'
                      : ''
                      : (languageCode === 'de' ? 'de' : 'en')
                      }}
                      {{ timeLineData.endDate | date : 'yyyy' }}
                    </p>
                  </div>
                </div>
                <div class="chartCategory">
                  <div class="investment">
                    <div></div>
                    <span>{{
                      'translations.dashboard.timeline.investment' | translate
                      }}</span>
                  </div>
                  <div class="liquidation">
                    <div></div>
                    <span>{{
                      'translations.dashboard.timeline.liquidation' | translate
                      }}</span>
                  </div>
                </div>
                <div #timelineEvents class="progress-value" [ngStyle]="{
                          width:
                            getEventPosition(
                              dashboardBaseData.timeline.startInvestmentDate,
                              dashboardBaseData.timeline.startLiquidationDate
                            ) >= 100
                              ? getEventPosition(
                                  dashboardBaseData.timeline.startInvestmentDate,
                                  dateNow,
                                  dashboardBaseData.timeline.startLiquidationDate
                                ) /
                                  2 +
                                '%'
                              : '50%'
                        }">
                  <ng-container *ngIf="sortedTimelineEvents.length">
                    <div *ngFor="let event of sortedTimelineEvents; let i = index" id="{{
                              event.date + '---' + event.title + '---' + event.type
                            }}" class="event-dot" [tooltip]="getTimeLineTooltip(event)" [ngStyle]="{
                              left:
                                'calc(' +
                                getEventPosition(
                                  dashboardBaseData.timeline.startInvestmentDate,
                                  dashboardBaseData.timeline.startLiquidationDate,
                                  event.date
                                ) +
                                '% - 10px)',
                              opacity: showTimelineEvents ? '1' : '0'
                            }">
                      <div class="event-dot-border"></div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    <div class="grid-col-1">
      <div class="dashboard-card youtube-banner-card">
        <a *ngIf="
        dashboardBaseData.banner && dashboardBaseData.banner.href;
        else bannerLoader
      " href="{{ dashboardBaseData.banner.href }}" target="_blank">
          <img mat-card-image src="{{ dashboardBaseData.banner.url }}" style="object-fit: cover" onerror="this.style.border_radius='0px';this.style.padding='8px';this.style.color='#111';this.style.width='auto'" />
        </a>
      </div>
      <mat-card class="dashboard-card donut-chart">
        <div class="card-container">
          <div class="cardBody investment-body">
            <div
              *ngIf="dashboardBaseData.investments; else investmentLoader"
              class="investment-card"
            >
              <div class="left">
                <div class="svg-item">
                  <svg
                  viewBox="0 0 40 40"
                  class="donut"
                >
                  <circle
                    class="donut-ring"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke-width="3.6"
                  ></circle>
                  <circle
                    *ngIf="dashboardBaseData.investments.realizedInvestments !== 0"
                    class="donut-segment donut-segment-2"
                    cx="20"
                    cy="20"
                    r="15.91549430918954"
                    fill="transparent"
                    stroke-linecap="round"
                    stroke-width="3.6"
                    [attr.stroke-dasharray]="
                      getPercentage(
                        dashboardBaseData.investments.realizedInvestments,
                        dashboardBaseData.investments.unrealizedInvestments
                      ) +
                      ' ' +
                      (100 -
                        getPercentage(
                          dashboardBaseData.investments.realizedInvestments,
                          dashboardBaseData.investments.unrealizedInvestments
                        ))
                    "
                    stroke-dashoffset="25"
                  ></circle>
                  <text
                    x="50%"
                    y="50%"
                    fill="#101828"
                    font-family="Inter"
                    font-size="8px"
                    font-weight="600"
                    text-anchor="middle"
                    dominant-baseline="middle"
                  >
                    {{
                      getPercentage(
                        dashboardBaseData.investments.realizedInvestments,
                        dashboardBaseData.investments.unrealizedInvestments
                      )
                    }}%
                  </text>
                </svg>
                
               
                
                
                
                
                
                
                
                
                
                </div>
              </div>
              <div class="right">
                <div class="investment-num" *ngIf="dashboardBaseData.investments; else investmentLoadertitle">
                  {{
                    dashboardBaseData.investments.realizedInvestments +
                      dashboardBaseData.investments.unrealizedInvestments
                  }}
                  {{
                    (dashboardBaseData.investments.realizedInvestments + dashboardBaseData.investments.unrealizedInvestments) == 1
                      ? ('translations.dashboard.investments.title' | translate)
                      : ('translations.dashboard.investments.titlePlural' | translate)
                  }}
                </div>
                <div class="realized-unrealized">
                  <div class="realized">
                    <span class="investmentTiles realized"></span>
                    <span>
                      {{ dashboardBaseData.investments.realizedInvestments }}
                      {{
                        'translations.dashboard.investments.realized'
                          | translate
                      }}
                    </span>
                  </div>
                  <div class="unrealized">
                    <span class="investmentTiles unrealized"></span>
                    <span>
                      {{ dashboardBaseData.investments.unrealizedInvestments }}
                      {{
                        'translations.dashboard.investments.unrealized'
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="grid-row-2">
    <mat-card class="dashboard-card">
      <div class="card-container">
        <div class="cardHead">
          <div class="cardHeadLeft">
            {{ 'translations.dashboard.performance.title' | translate }}
            <img src="../../../assets/images/help-circle.svg" alt="help" (click)="showMetrics()">
          </div>
          <div>
            <!-- <a class="cardHeadRight" (click)="handleMenuClick('insights')">
              {{ 'translations.dashboard.seeDetails' | translate }}
            </a> -->
          </div>
        </div>
        <div class="cardBody">
          <div class="performance">
            <div class="item">
              <div class="item-count">
                <p *ngIf="dashboardBaseData.performance; else KPILoader" class="count">
                  {{
                  dashboardBaseData.performance.tvpi !== undefined &&
                  dashboardBaseData.performance.tvpi !== null
                  ? performanceValue(dashboardBaseData.performance.tvpi)
                  : '-'
                  }}
                </p>
                <p class="postCount">TVPI</p>
              </div>
            </div>
            <div class="item-tile visible"></div>
            <div class="item">
              <div class="item-count">
                <p *ngIf="dashboardBaseData.performance; else KPILoader" class="count">
                  {{
                  dashboardBaseData.performance.dpi !== undefined &&
                  dashboardBaseData.performance.dpi !== null
                  ? performanceValue(dashboardBaseData.performance.dpi)
                  : '-'
                  }}
                </p>
                <p class="postCount">DPI</p>
              </div>
            </div>
            <div class="item-tile hidden"></div>
            <div class="item">
              <div class="item-count">
                <p *ngIf="dashboardBaseData.performance; else KPILoader" class="count">
                  {{
                  dashboardBaseData.performance.rvpi !== undefined &&
                  dashboardBaseData.performance.rvpi !== null
                  ? performanceValue(dashboardBaseData.performance.rvpi)
                  : '-'
                  }}
                </p>
                <p class="postCount">RVPI</p>
              </div>
            </div>
            <div class="item-tile visible"></div>
            <div class="item">
              <div class="item-count">
                <p *ngIf="dashboardBaseData.performance; else KPILoader" class="count">
                  {{
                  dashboardBaseData.performance.moic !== undefined &&
                  dashboardBaseData.performance.moic !== null
                  ? performanceValue(dashboardBaseData.performance.moic)
                  : '-'
                  }}<span *ngIf="
                              dashboardBaseData.performance.moic !== undefined &&
                              dashboardBaseData.performance.moic !== null
                            " class="suffixCount">x</span>
                </p>
                <p class="postCount">MOIC</p>
              </div>
            </div>
            <div class="item-tile hidden"></div>
            <div class="item">
              <div class="item-count">
                <p *ngIf="dashboardBaseData.performance; else KPILoader" class="count">
                  {{
                  dashboardBaseData.performance.irr !== undefined &&
                  dashboardBaseData.performance.irr !== null
                  ? performanceValue(
                  dashboardBaseData.performance.irr,
                  'IRR'
                  )
                  : '-'
                  }}<span *ngIf="
                              dashboardBaseData.performance.irr !== undefined &&
                              dashboardBaseData.performance.irr !== null
                            " class="suffixCount">%</span>
                </p>
                <p class="postCount">IRR</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="grid-row-3">
    <mat-card class="dashboard-card">
      <div class="card-container">
        <div class="cardHead">
          <div class="cardHeadLeft">
            {{ 'translations.dashboard.capital.title' | translate }}
            <span *ngIf="currency; else currencyLoader">{{
              selectedVehicle.currency
            }}</span>
          </div>
          <div>
            <!-- <a
              class="cardHeadRight"
              (click)="handleMenuClick('capital-account')"
            >
              {{ 'translations.dashboard.seeDetails' | translate }}
            </a> -->
          </div>
        </div>
        <div class="cardBody">
          <div class="capital">
            <div class="item">
              <div class="item-count">
                <p
                *ngIf="dashboardBaseData.capital; else KPILoader"
                class="count"
              >
                {{
                  dashboardBaseData.capital.committed !== undefined &&
                  dashboardBaseData.capital.committed !== null
                    ? convert(dashboardBaseData.capital.committed).value
                    : 0
                }}<span
                  class="suffixCount"
                  *ngIf="convert(dashboardBaseData.capital.committed).unit"
                  >{{
                    dashboardBaseData.capital.committed !== ''
                      ? convert(dashboardBaseData.capital.committed).unit
                      : ''
                  }}</span
                >
              </p>
              <p class="postCount">
                {{ 'translations.dashboard.capital.committed' | translate }}
              </p>
              </div>
            </div>
            <div class="item-tile visible"></div>
            <div class="item">
              <div class="item-count">
                <p
                *ngIf="dashboardBaseData.capital; else KPILoader"
                class="count"
              >
                {{
                  dashboardBaseData.capital.calledIn !== undefined &&
                  dashboardBaseData.capital.calledIn !== null
                    ? convert(dashboardBaseData.capital.calledIn).value
                    : ''
                }}<span
                  class="suffixCount"
                  *ngIf="convert(dashboardBaseData.capital.calledIn).unit"
                  >{{
                    dashboardBaseData.capital.calledIn !== ''
                      ? convert(dashboardBaseData.capital.calledIn).unit
                      : ''
                  }}</span
                >
              </p>
              <p class="postCount">
                {{ 'translations.dashboard.capital.calledIn' | translate }}
              </p>
              </div>
            </div>
            <div class="item-tile hidden"></div>
            <div class="item">
              <div class="item-count">
                <p
                *ngIf="dashboardBaseData.capital; else KPILoader"
                class="count"
              >
                {{
                  dashboardBaseData.capital.distributed !== undefined &&
                  dashboardBaseData.capital.distributed !== null
                    ? convert(dashboardBaseData.capital.distributed).value
                    : ''
                }}<span
                  class="suffixCount"
                  *ngIf="convert(dashboardBaseData.capital.distributed).unit"
                  >{{
                    dashboardBaseData.capital.distributed !== ''
                      ? convert(dashboardBaseData.capital.distributed).unit
                      : ''
                  }}</span
                >
              </p>
              <p class="postCount">
                {{ 'translations.dashboard.capital.distributed' | translate }}
              </p>
              </div>
            </div>
            <div class="item-tile visible"></div>
            <!-- <div class="item">
              <p
                *ngIf="dashboardBaseData.capital; else KPILoader"
                class="count"
              >
                {{
                  dashboardBaseData.capital.contributed !== undefined &&
                  dashboardBaseData.capital.contributed !== null
                    ? convert(dashboardBaseData.capital.contributed).value
                    : ''
                }}<span
                  class="suffixCount"
                  *ngIf="convert(dashboardBaseData.capital.contributed).unit"
                  >{{
                    dashboardBaseData.capital.contributed !== ''
                      ? convert(dashboardBaseData.capital.contributed).unit
                      : ''
                  }}</span
                >
              </p>
              <p class="postCount">
                {{ 'translations.dashboard.capital.contributed' | translate }}
              </p>
            </div> -->
    
            <div class="item">
              <div class="item-count">
                <p
                *ngIf="dashboardBaseData.capital; else KPILoader"
                class="count"
              >
                {{
                  dashboardBaseData.capital.deployed !== null &&
                  dashboardBaseData.capital.deployed !== undefined
                    ? convert(dashboardBaseData.capital.deployed).value
                    : ''
                }}<span
                  class="suffixCount"
                  *ngIf="convert(dashboardBaseData.capital.deployed).unit"
                  >{{
                    dashboardBaseData.capital.deployed !== ''
                      ? convert(dashboardBaseData.capital.deployed).unit
                      : ''
                  }}</span
                >
              </p>
              <p class="postCount">
                {{ 'translations.dashboard.capital.deployed' | translate }}
              </p>
              </div>
            </div>
            <div class="item-tile item-hide hidden"></div>
            <div class="item hidden">&nbsp;</div>
            <!-- <div class="item">
              <p
                *ngIf="dashboardBaseData.capital; else KPILoader"
                class="count"
              >
                {{
                  dashboardBaseData.capital.duePayments !== undefined &&
                  dashboardBaseData.capital.duePayments !== null
                    ? convert(dashboardBaseData.capital.duePayments).value
                    : ''
                }}<span
                  class="suffixCount"
                  *ngIf="convert(dashboardBaseData.capital.duePayments).unit"
                  >{{
                    dashboardBaseData.capital.duePayments !== ''
                      ? convert(dashboardBaseData.capital.duePayments).unit
                      : ''
                  }}</span
                >
              </p>
              <p class="postCount">
                {{ 'translations.dashboard.capital.duePayments' | translate }}
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="grid-row-4">
    <div class="events_card">
      <mat-card class="dashboard-card">
        <div class="card-container">
          <div class="cardHead">
            <div class="cardHeadLeft">
              {{ 'translations.dashboard.upcomingEvents.title' | translate }}
            </div>
          </div>
          <div class="cardBody eventsContainer" [ngClass]="
          dashboardEventData && dashboardEventData.length
            ? ''
            : 'noRecordFoundContainer'
        ">
            <ng-container *ngIf="isEventLoading; else EventsUpdatesLoader">
              <ng-container *ngIf="dashboardEventData && dashboardEventData.length">
                <ng-container *ngFor="let event of dashboardEventData">
                  <div class="eventTile" [tooltip]="getTooltipText(event)" (touchstart)="iCalDownload(event)"
                    (click)="iCalDownload(event)">
                    <div class="eventTileLeft">
                      <p class="day">{{ event.start | date : 'dd' }}</p>
                      <p class="month">
                        {{
                        event.start
                        | date
                        : 'MMM'
                        : ''
                        : (languageCode === 'de' ? 'de' : 'en')
                        }}
                      </p>
                    </div>
                    <div class="eventTileRight">
                      <p *ngIf= "isAllDayEvent(event)" class="time">{{ event.start | date : 'HH:mm' }}</p>
                      <p class="eventName">{{ event.title }}</p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="noRecordFound" *ngIf="dashboardEventData.length == 0">
                <div class="noRecord-icon-container">
                  <span class="noRecord-icon">
                    <img src="../../../assets/images/upcoming_events_icon.svg" />
                  </span>
                </div>
                {{ 'translations.dashboard.events.noEvents' | translate }}
              </div>
            </ng-container>
          </div>
        </div>
      </mat-card>
    </div>
  
    <div class="news_card">
      <mat-card class="dashboard-card">
        <div class="card-container">
          <div class="cardHead">
            <div class="cardHeadLeft">
              {{ 'translations.dashboard.news.title' | translate }}
            </div>
          </div>
          <div class="cardBody newsContainer" [ngClass]="
          dashboardNewsData && dashboardNewsData.length
            ? ''
            : 'noRecordFoundContainer'
        ">
            <ng-container *ngIf="isNewsLoading; else NewsLoader">
              <ng-container *ngIf="dashboardNewsData && dashboardNewsData.length">
                <ng-container *ngFor="let news of dashboardNewsData">
                  <div class="newsTile" [ngClass]="!news.imageUrl ? '' : ''" (click)="goToLink(news.href)"
                    (touchstart)="goToLink(news.href)" [tooltip]="getTooltipText(news)">
                    <div class="newsTileRight">
                      <img *ngIf="news.imageUrl" class="imgCover" src="{{ news.imageUrl }}" />
                      <div *ngIf="!news.imageUrl" class="news-img">
                        <svg width="20" height="18" viewBox="27 18 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                          class="ng-star-inserted">
                          <path
                            d="M45 20H29V34L38.2923 24.7065C38.6828 24.3159 39.3159 24.3159 39.7065 24.7064L45 30.0104V20ZM27 18.9934C27 18.4448 27.4553 18 27.9918 18H46.0082C46.556 18 47 18.445 47 18.9934V35.0066C47 35.5552 46.5447 36 46.0082 36H27.9918C27.4441 36 27 35.5551 27 35.0066V18.9934ZM33 26C31.8954 26 31 25.1046 31 24C31 22.8954 31.8954 22 33 22C34.1046 22 35 22.8954 35 24C35 25.1046 34.1046 26 33 26Z"
                            fill-opacity="0.5" class="news-icon"></path>
                        </svg>                      
                      </div>
                    </div>
                    <div class="newsTileLeft">
                      <p class="time">
                        {{
                        news.dateTime
                        | date
                        : 'dd. MMM yyyy - HH:mm'
                        : ''
                        : (languageCode === 'de' ? 'de' : 'en')
                        }}
                      </p>
                      <p class="eventName">{{ news.title }}</p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="noRecordFound" *ngIf="dashboardNewsData.length == 0">
                <div class="noRecord-icon-container">
                  <span class="noRecord-icon">
                    <img src="../../../assets/images/news_icon.svg" />
                  </span>
                </div>
                {{ 'translations.dashboard.news.noNews' | translate }}
              </div>
            </ng-container>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #KPILoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      'max-width': '150px',
      height: '20px',
      'margin-bottom': '0'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #EventsUpdatesLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="eventsUpdatesLoader">
      <div class="left">
        <ngx-skeleton-loader
          count="1"
          appearance="circle"
          [theme]="{
            'border-radius': '5px',
            width: '44px',
            height: '44px',
            border: '1px solid white'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div class="right">
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            width: '120px',
            height: '20px',
            margin: '0px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          appearance="line"
          [theme]="{
            width: '150px',
            height: '20px',
            margin: '0px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #NewsLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="newsLoader">
      <div class="left">
        <ngx-skeleton-loader
        count="1"
        appearance="circle"
        [theme]="{
          'border-radius': '6px',
          width: '74px',
          height: '54px',
          border: '1px solid white'
        }"
      ></ngx-skeleton-loader>
      </div>
      <div class="right">
        <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          width: '60%',
          height: '20px',
          'max-width': '150px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          width: '80%',
          height: '20px',
          'max-width': '200px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          width: '80%',
          height: '20px',
          'max-width': '200px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #investmentLoader>
  <div class="investmentLoader">
    <div class="left">
      <ngx-skeleton-loader
        count="1"
        appearance="circle"
        [theme]="{
          display: 'block',
          width: '60px',
          height: '60px',
          border: '1px solid white'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="right">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          width: '70px',
          height: '20px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
      <div class="realized-unrealized">
        <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #timelineLoader>
  <div class="timelineLoader">
    <ngx-skeleton-loader count="1" appearance="line"></ngx-skeleton-loader>
    <div class="date-content">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          width: '100px',
          height: '15px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        [theme]="{
          width: '100px',
          height: '15px',
          margin: '0px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #bannerLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '100%',
      height: '100%',
      margin: '0px'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #currencyLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '50px',
      'margin-left': '5px',
      display: 'block',
      'margin-bottom': '0px'
    }"
  ></ngx-skeleton-loader>
</ng-template>

<ng-template #investmentLoadertitle>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '80px',
      'margin-left': '5px',
      display: 'block',
      'margin-bottom': '0px'
    }"
  ></ngx-skeleton-loader>
</ng-template>
