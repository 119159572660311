import { Component } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay } from 'rxjs';
import { Location } from '@angular/common';
import { Base64 } from 'js-base64';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { DocumentsService } from './documents.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent {
  isVisible$ = new BehaviorSubject(true);

  isLoading = false;
  isRoot = true;
  showlistView = true;
  showLoader = false;
  showPdfView = true;
  showViewer = false;
  selectedFile: any;
  showFolders = true;
  showFiles = true;
  filePath: any;
  initialLoad = false;
  files: any;
  folders: any;
  selectedFolderValue: any = {};
  suffix = '';
  documentRoutes: string = '/Documents';
  gpId: any;
  vehicleId: any;
  documentData: any = { name: 'root', type: 'folder' };
  pageNumber = 1;
  documentId: any;
  isInaccessible = false;

  constructor(
    private _location: Location,
    private apiHttpService: ApiHttpService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private documentsService: DocumentsService,
    public breakPointService: BreakPointService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.documentId = this.activatedRoute.snapshot.params['documentParams'];
    this.showLoader = true;
    this.getDocumentData(this.gpId, this.vehicleId);
  }

  ngAfterViewChecked(): void {
    this.documentId && this.scrollToview();
  }

  getDocumentData(gpsId: any, vehicleId: any) {
    this.apiHttpService
      .get(this.documentsService.getDocumentData(gpsId, vehicleId))
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.documentData.content = parsedResponse.data;
          this.folders = this.documentData.content.filter(
            (data: any) => data.type === 'folder'
          );
          this.files = this.documentData.content.filter(
            (data: any) => data.type === 'file'
          );
          this.showLoader = false;
          let showInvalidmessage = false;

          if (this.documentId) {
            const base64regex =
              /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            if (base64regex.test(this.documentId)) {
              let isValidDocumentPath = Base64.decode(this.documentId);
              showInvalidmessage =
                isValidDocumentPath.length > 0 ? false : true;
              if (isValidDocumentPath.length > 0) {
                let documentPath = Base64.decode(this.documentId);
                const documentPathSplitted = documentPath.split('/');
                const fileName =
                  this.getFileExtension(
                    documentPathSplitted[documentPathSplitted.length - 1]
                  ) === 'pdf'
                    ? documentPathSplitted[documentPathSplitted.length - 1]
                    : undefined;
                const folderNames = fileName
                  ? documentPathSplitted.slice(2, -1)
                  : documentPathSplitted.slice(2, documentPathSplitted.length);
                if (folderNames.length) {
                  for (let folderNow of folderNames) {
                    let selectedFolder = this.folders.find(
                      (folder: any) => folder.name === folderNow
                    );
                    if (selectedFolder) this.onFolderNavigation(selectedFolder);
                    else {
                      showInvalidmessage = true;
                    }
                  }
                }
                if (fileName) {
                  const selectedFile = this.files.find(
                    (file: any) => file.name === fileName
                  );
                  if (selectedFile) {
                    this.showWebViewer(selectedFile);
                  } else {
                    showInvalidmessage = true;
                  }
                }
              }
            } else {
              showInvalidmessage = true;
            }
          }
          // to show invlid popup on invalid path
          if (showInvalidmessage) {
            // this.notificationService.sendNotification({ isInaccessible: true });
            this.openDialog(true);
          }
        },
        _error => {
          this.filePath = '';
        }
      );
  }

  getFileExtension(filename: any) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? '' : ext[1];
  }

  showlistViewFunction() {
    this.showlistView = true;
    this.showPdfView = false;
  }

  showPdfViewFunction() {
    this.showPdfView = true;
    this.showlistView = false;
  }

  showWebViewer(file: any) {
    this.isLoading = true;
    if (!this.showViewer) {
      this.suffix += ` / ${file.name}`;
      this.documentRoutes += `/${file.name}`;
    } else {
      let suffices = this.suffix.split('/ ');
      let currentFile = suffices[suffices.length - 1];
      if (currentFile !== file.name) {
        let currentDocumentRoutes = this.documentRoutes.split('/');
        currentDocumentRoutes[currentDocumentRoutes.length - 1] = file.name;
        suffices[suffices.length - 1] = file.name;
        this.suffix = suffices.join('/ ');
        this.documentRoutes = currentDocumentRoutes.join('/');
      }
    }
    let encodedString = Base64.encode(this.documentRoutes);
    //this.router.navigate([`gp/${this.gpId}/vehicle/${this.vehicleId}/documents/${encodedString}`])'
    this.commonService.setSecondaryPath(
      this.documentRoutes.split('/').slice(2)
    );
    // console.log(this.documentRoutes);
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
    );
    this.selectedFile = file;
    this.showViewer = true;
    this.pageNumber = 1;
    this.showViewer = true;
    this.pageNumber = 1;
    this.getDocumentById(this.gpId, this.vehicleId, file.documentId);
    this.initialLoad = true;
  }

  onFolderNavigation(folderValue: any): void {
    let children: any = folderValue.content;
    this.selectedFile = null;
    this.showViewer = false;
    this.filePath = '';
    this.isRoot = false;
    if (children != undefined) {
      this.folders = children.filter((data: any) => data.type === 'folder');
      this.files = children.filter((data: any) => data.type === 'file');

      this.showFiles = true;
      this.showFolders = true;
      this.selectedFolderValue = folderValue;
      this.suffix += ` / ${folderValue.name}`;
      const documentPath = this.documentRoutes;
      const documentPathSplitted = documentPath.split('/');
      const fileName =
        this.getFileExtension(
          documentPathSplitted[documentPathSplitted.length - 1]
        ) === 'pdf'
          ? documentPathSplitted[documentPathSplitted.length - 1]
          : undefined;
      if (fileName) {
        documentPathSplitted.pop();
        this.documentRoutes = documentPathSplitted.join('/');
      }
      this.documentRoutes += `/${folderValue.name}`;
      let encodedString = Base64.encode(this.documentRoutes);
      this.commonService.setSecondaryPath(
        this.documentRoutes.split('/').slice(2)
      );
      this._location.replaceState(
        `gp/${this.gpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
      );
    }
  }

  async handleBack() {
    if (this.isRoot) {
      return;
    }
    let parentFound = this.findParentPath(
      this.documentData,
      this.selectedFolderValue.name
    );
    if (parentFound) {
      this.selectedFolderValue = parentFound;
    }
    this.selectedFile = null;
    this.showViewer = false;
    this.filePath = ''
    this.destroyAndReload();
    if (this.selectedFolderValue.name === 'root') {
      this.documentRoutes = '/Documents';
      this._location.replaceState(
        `gp/${this.gpId}/vehicle/${this.vehicleId}/documents`
      );
      this.files = this.documentData.content.filter(
        (data: any) => data.type === 'file'
      );
      this.folders = this.documentData.content.filter(
        (data: any) => data.type === 'folder'
      );
      this.selectedFolderValue = null;
      this.suffix = '';
      this.isRoot = true;
    } else {
      let encodedString = Base64.encode(this.documentRoutes);
      this._location.replaceState(
        `gp/${this.gpId}/vehicle/${this.vehicleId}/documents/${encodedString}`
      );
      this.files = this.selectedFolderValue.content.filter(
        (data: any) => data.type === 'file'
      );
      this.folders = this.selectedFolderValue.content.filter(
        (data: any) => data.type === 'folder'
      );
    }
    this.commonService.setSecondaryPath(
      this.documentRoutes.split('/').slice(2)
    );
    this.showFiles = true;
    this.showFolders = true;
  }

  destroyAndReload() {
    this.isVisible$.next(false);
    setTimeout(() => {
      this.isVisible$.next(true);
    }, 0);
  }

  findParentPath(data: any, name: any): any {
    if (data.content) {
      for (let child of data.content) {
        const childMatching = data.content.filter(
          (current: any) => current.name === name
        );
        if (childMatching.length) {
          this.suffix = ` / ${data.name}`;
          this.documentRoutes = `/Documents/${data.name}`;
          return data;
        }
        const result = this.findParentPath(child, name);
        if (result) {
          return result;
        }
      }
    }
  }

  compareFiles(o1: any, o2: any) {
    if (o1.documentId == o2.documentId && o1.name == o2.name) return true;
    else return false;
  }

  getDocumentById(gpsId: any, vehicleId: any, documentId: any) {
    this.apiHttpService
      .get(
        this.documentsService.getDocumentById(gpsId, vehicleId, documentId),
        { responseType: 'arraybuffer' }
      )
      .subscribe(
        (response: any) => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8',
          });
          this.filePath = blob;
          this.isLoading = false;
          this.destroyAndReload();
        },
        (_error: any) => {
          this.isLoading = false;
          this.filePath = '';
        }
      );
  }

  openDialog(event: boolean) {
    if (event) {
      this.isInaccessible = true;
      let dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1',
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === '1') {
          this.router.navigate([
            `gp/${this.gpId}/vehicle/${this.vehicleId}/documents`,
          ]);
        }
      });
    }
  }

  scrollToview() {
    const elementToScrollTo = document.querySelector('.selected');
    if (elementToScrollTo) {
      elementToScrollTo?.scrollIntoView();
    }
  }
}
