import { Injector, TemplateRef, Type } from '@angular/core';

export interface IFilterMenu {
  label: string | TemplateRef<any> | Type<any>;
  value: string;
  labelProps?: IFilterMenuLabelProps;
  children: {
    label: string | TemplateRef<any> | Type<any>;
    value: string;
    count?: number;
    labelProps?: IFilterMenuLabelProps;
  }[];
}

export interface IFilterMenuLabelProps {
  name: string;
  count: number;
}

/**
 * Checks if a value is an Angular component type (class constructor).
 */
export function isComponent(label: any): label is Type<any> {
    return typeof label === 'function';
}
export function createInjector(props: any) {
  return Injector.create({
    providers: Object.keys(props || {}).map((key) => ({
      provide: key,
      useValue: props[key],
    })),
  });
}