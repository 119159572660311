import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CommonService } from '../../shared/services/common.service';
import { QuarterlyDataService } from './quarterly-data.service';
import { QuarterDataDetailsComponent } from './quarter-data-details/quarter-data-details.component';

export interface IquarterlyData {
  id: string;
  year: number;
  quarter: number;
  totalItems: number;
  completedItems: number;
}

export interface Iquarter {
  year: number;
  quarter: number;
}

@Component({
  selector: 'app-quarterly-data',
  templateUrl: './quarterly-data.component.html',
  styleUrls: ['./quarterly-data.component.scss'],
})
export class QuarterlyDataComponent implements OnInit {
  search: string = '';
  gpId: any;
  vehicleId: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  private sort: MatSort;
  isShowDetails = false;
  isLoading = false;
  noQuarterlyDataFound = false;
  quarterlyData: IquarterlyData[];
  validQuarters: Iquarter[];
  disableAddQuarter = false;
  tempQuarterlyData: IquarterlyData[];
  filterValue: string | null;
  quarterlyDataId: string;
  quarterValue: string;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  @ViewChild(QuarterDataDetailsComponent, { static: false })
  quarterDataDetailsComponent!: QuarterDataDetailsComponent;

  displayedColumns: string[] = ['year', 'progress', 'status', 'details'];

  constructor(
    private router: Router,
    private _location: Location,
    private dialog: MatDialog,
    private commonService: CommonService,
    private quarterlyDataService: QuarterlyDataService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.quarterValue = this.activatedRoute.snapshot.params['quarter'];
    this.search = localStorage.getItem('search')
      ? localStorage.getItem('search')!
      : '';
    this.filterValue = localStorage.getItem('filterValue')
      ? localStorage.getItem('filterValue')!
      : '';
    this.commonService.setSecondaryPath([]);
    this.validQuarters = this.generateQuarterlyPeriods();
    this.isShowDetails = this.quarterValue !== undefined;
    !this.quarterValue && this.getQuarterlyDataList();
  }
  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }
  calulateBarPercentage(totalItems: number, completedItems: number) {
    if (completedItems) {
      const percentage = (completedItems / totalItems) * 100;
      return `${Math.ceil(percentage)}%`;
    } else {
      return 0;
    }
  }

  getQuarterlyDataList() {
    this.isLoading = true;
    this.noQuarterlyDataFound = false;
    this.quarterlyDataService.getQuarterlyData(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        if (parsedResponse.data.length == 0) {
          this.noQuarterlyDataFound = true;
        }
        this.quarterlyData = parsedResponse.data;
        this.tempQuarterlyData = parsedResponse.data;
        this.dataSource = new MatTableDataSource(parsedResponse.data);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
          if (property === 'year') {
            return item.year * 100 + item.quarter;
          }
          return item[property];
        };
        this.applyFilter();
        this.checkValidQuarters(this.quarterlyData);
      },
      error: error => {
        this.isLoading = false;
        this.commonService.errorNotification(
          'Failed to retrieve Quarterly data'
        );
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  checkValidQuarters(quarterlyData: IquarterlyData[]) {
    const filterSet = new Set(
      quarterlyData.map((item: Iquarter) => `${item.year}-${item.quarter}`)
    );
    const quarters = this.validQuarters.filter(
      (item: any) => !filterSet.has(`${item.year}-${item.quarter}`)
    );
    this.disableAddQuarter = quarters.length === 0;
  }

  applyFilter() {
    localStorage.setItem('search', this.search);
    localStorage.setItem(
      'filterValue',
      this.filterValue ? this.filterValue : ''
    );
    this.dataSource.filterPredicate = (data, filter) => {
      const [search, filterValue] = filter.split('|');
      const yearQuarter = `${data.year} Q${data.quarter}`.toLowerCase();
      const searchLowerCase = search.toLowerCase();
      const matchesSearch = yearQuarter.includes(searchLowerCase);
      let matchesFilter = true;
      if (filterValue === '1') {
        matchesFilter = data.totalItems !== data.completedItems;
      } else if (filterValue === '2') {
        matchesFilter = data.totalItems === data.completedItems;
      }
      return matchesSearch && matchesFilter;
    };
    this.dataSource.filter = `${this.search}|${
      this.filterValue ? this.filterValue : ''
    }`;
  }

  addQuater() {
    const dialogRef = this.dialog.open(AddQuarterlyDialog, {
      data: {
        vehicleId: this.vehicleId,
        quarterlyData: this.quarterlyData,
        quarters: this.validQuarters,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        this.commonService.successNotification(
          'Quarterly data created successfully'
        );
        this.getQuarterlyDataList();
      } else if (result && result.status === 'error') {
        this.commonService.errorNotification('Failed to add Quarterly data');
      }
    });
  }
  openDeleteQuarterlyDialog(quarterData: IquarterlyData) {
    const dialogRef = this.dialog.open(DeleteQuarterlyDataDialog, {
      data: {
        quarterData,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.commonService.successNotification(
          'Quarterly data deleted successfully'
        );
        this.quarterlyData = this.quarterlyData.filter(
          _item => _item.id !== quarterData.id
        );
        this.tempQuarterlyData = this.tempQuarterlyData.filter(
          _item => _item.id !== quarterData.id
        );
        const data = this.dataSource.data;
        const index = data.indexOf(quarterData);
        if (index >= 0) {
          data.splice(index, 1);
          this.dataSource.data = [...data];
        }
        this.checkValidQuarters(this.quarterlyData);
        if (this.tempQuarterlyData.length === 0) {
          this.noQuarterlyDataFound = true;
          this.search = '';
        }
      } else if (result === 'error') {
        this.commonService.errorNotification('Failed to delete Quarterly data');
      }
    });
  }
  //
  showDetails(quarterlyData: IquarterlyData) {
    this.quarterlyDataId = quarterlyData.id;
    const quarter = `${quarterlyData.year}-Q${quarterlyData.quarter}`;
    // this._location.replaceState(
    //   `gp/${this.gpId}/vehicle/${this.vehicleId}/quarterly-data/${this.quarterlyDataId}/${quarter}`
    // );
    this.router.navigate(
      [
        `gp/${this.gpId}/vehicle/${this.vehicleId}/quarterly-data/${this.quarterlyDataId}/${quarter}`,
      ],
      {
        replaceUrl: true,
      }
    );
    this.isShowDetails = true;
  }
  hideDetails() {
    // console.log('hello', this.quarterlyData)
    // this.unsavedChangesService
    //   .checkUnsavedChanges(this)
    //   .subscribe((canProceed) => {
    //     if (canProceed) {console.log('yes canProceed', this.quarterlyData);
    //       this._location.replaceState(
    //         `gp/${this.gpId}/vehicle/${this.vehicleId}/quarterly-data`
    //       );
    //       this.isShowDetails = false;
    //       this.commonService.setSecondaryPath([]);
    //       !this.quarterlyData && this.getQuarterlyDataList();
    //     } else {
    //       console.log('Navigation canceled due to unsaved changes.');
    //     }
    //   });
    this.isShowDetails = false;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/quarterly-data`
    );
    this.commonService.setSecondaryPath([]);
    !this.quarterlyData && this.getQuarterlyDataList();
  }

  generateQuarterlyPeriods(): Iquarter[] {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3) + 1;

    const quarters = [];
    let year = currentYear;
    let quarter = currentQuarter;

    for (let i = 0; i < 5; i++) {
      quarters.push({ year, quarter });
      quarter--;
      if (quarter === 0) {
        quarter = 4;
        year--;
      }
    }

    return quarters;
  }
  getChildComponent() {
    return this.quarterDataDetailsComponent;
  }
}

@Component({
  selector: 'add-quarterly-dialog',
  templateUrl: './add-quarterly-dialog.html',
  styleUrls: ['./quarterly-data.component.scss'],
})
export class AddQuarterlyDialog {
  isImport: boolean = false;
  loading: boolean = false;
  isQuarterOpen: boolean = false;
  quarters: Iquarter[] = [];
  quarter: Iquarter;
  isAddQuarterLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddQuarterlyDialog>,
    private quarterlyDataService: QuarterlyDataService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    const filterSet = new Set(
      this.data.quarterlyData.map((item: any) => `${item.year}-${item.quarter}`)
    );
    this.quarters = this.data.quarters.filter(
      (item: any) => !filterSet.has(`${item.year}-${item.quarter}`)
    );
  }

  toggleSelect() {
    this.isQuarterOpen = !this.isQuarterOpen;
  }
  addQuarter() {
    const { year, quarter } = this.quarter;
    this.isAddQuarterLoading = true;
    this.quarterlyDataService
      .addQuarter(this.data.vehicleId, { year: year, quarter: quarter })
      .subscribe({
        next: response => {
          this.dialogRef.close({
            status: 'success',
          });
        },
        error: _error => {
          this.dialogRef.close({ status: 'error' });
          this.isAddQuarterLoading = false;
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
          this.isAddQuarterLoading = false;
        },
      });
  }
}
@Component({
  selector: 'delete-quarterly-dialog',
  templateUrl: './delete-quarterly-dialog.html',
  styleUrls: ['./quarterly-data.component.scss'],
})
export class DeleteQuarterlyDataDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteQuarterlyDataDialog>,
    private quarterlyDataService: QuarterlyDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  deletequarterData() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.quarterlyDataService
      .deletequarter(this.data.quarterData.id)
      .subscribe({
        next: _response => {
          this.dialogRef.close('success');
        },
        error: _error => {
          this.dialogRef.close('error');
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }
}
