import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class BankTransactionsService {

  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService,
  ) { }

  getTransactions(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-accounts-v1/transactions`,
        false
      )
    );
  }
}
