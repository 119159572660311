import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManagementComponent } from './pages/management/management.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { InvestorsComponent } from './pages/investors/investors.component';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { UserManagementComponent } from './pages/settings/user-management/user-management.component';
import { PaymentApprovalComponent } from './pages/settings/payment-approval/payment-approval.component';
import { DnsEntriesComponent } from './pages/settings/dns-entries/dns-entries.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { QuarterlyDataComponent } from './pages/quarterly-data/quarterly-data.component';
import { unsavedQuarterlyDataChangesGuard } from './core/guards/unsaved-quarterly-data-changes.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ApprovalPoliciesComponent } from './pages/transactions/monite/approval-policies/approval-policies.component';
import { UsersComponent } from './pages/transactions/monite/users/users.component';
import { CounterpartsComponent } from './pages/transactions/counterparts/counterparts.component';
import { BankTransactionsComponent } from './pages/transactions/bank-transactions/bank-transactions.component';
import { FinoComponent } from './pages/settings/fino/fino.component';
import { InvoicesComponent } from './pages/transactions/invoices/invoices.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent,
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/dashboard',
    component: DashboardComponent,
    title: 'Suite 7 GP Portal - Dashboard',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/transactions',
    children: [
      {
        path: 'invoices',
        component: InvoicesComponent,
        title: 'Suite 7 GP Portal - Invoices',
        canActivate: [AuthGuard],
      },
      {
        path: 'counterparties',
        component: CounterpartsComponent,
        title: 'Suite 7 GP Portal - Counterparts',
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        title: 'Suite 7 GP Portal - Users',
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'approval-policies',
      //   component: ApprovalPoliciesComponent,
      //   title: 'Suite 7 GP Portal - Approval Policies',
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'bank',
        component: BankTransactionsComponent,
        title: 'Suite 7 GP Portal - Bank transactions',
        canActivate: [AuthGuard],
      },
      {
        path: 'bank/:transactionId',
        component: BankTransactionsComponent,
        title: 'Suite 7 GP Portal - Bank transactions',
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/settings',
    children: [
      {
        path: 'user-management',
        component: UserManagementComponent,
        title: 'Suite 7 GP Portal - User management',
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'fino-bank-accounts',
      //   component: FinoComponent,
      //   title: 'Suite 7 GP Portal - Bank accounts',
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'approval-policies',
      //   component: ApprovalPoliciesComponent,
      //   title: 'Suite 7 GP Portal - Approval Policies',
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'bank-accounts',
        component: FinoComponent,
        title: 'Suite 7 GP Portal - Bank accounts',
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-approval',
        component: PaymentApprovalComponent,
        title: 'Suite 7 GP Portal - Payment approval',
        canActivate: [AuthGuard],
      },
      {
        path: 'dns-entries',
        component: DnsEntriesComponent,
        title: 'Suite 7 GP Portal - DNS entries',
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'letterheads',
      //   component: LetterheadsComponent,
      //   title: 'Suite 7 GP Portal - Letterheads',
      //   canActivate: [AuthGuard],
      // },
    ],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/management',
    component: ManagementComponent,
    title: 'Suite 7 GP Portal - Management',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/reports',
    component: ReportsComponent,
    title: 'Suite 7 GP Portal - Reports',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/reports/:reportId',
    component: ReportsComponent,
    title: 'Suite 7 GP Portal - Report Inveators',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/documents',
    component: DocumentsComponent,
    title: 'Suite 7 GP Portal - Documents',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/documents/:documentParams',
    component: DocumentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/investors',
    component: InvestorsComponent,
    title: 'Suite 7 GP Portal - Investors',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/investors/:limitedPartnerId',
    component: InvestorsComponent,
    title: 'Suite 7 GP Portal - Investor Details',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/onboarding',
    component: OnboardingComponent,
    title: 'Suite 7 GP Portal - Onboarding',
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'gp/:gpId/vehicle/:vehicleId/portfolio-companies',
  //   component: PortfolioCompaniesComponent,
  //   title: 'Suite 7 GP Portal - Portfolio companies',
  //   canActivate: [AuthGuard],
  //   canDeactivate: [unsavedChangesGuard],
  // },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/portfolio',
    component: PortfolioComponent,
    title: 'Suite 7 GP Portal - Portfolio',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/quarterly-data',
    component: QuarterlyDataComponent,
    title: 'Suite 7 GP Portal - Onboarding',
    canActivate: [AuthGuard],
  },
  {
    path: 'gp/:gpId/vehicle/:vehicleId/quarterly-data/:quarterlyDataId/:quarter',
    component: QuarterlyDataComponent,
    title: 'Suite 7 GP Portal - Onboarding',
    canActivate: [AuthGuard],
    canDeactivate: [unsavedQuarterlyDataChangesGuard],
  },

  { path: '**', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
