import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class FinoService {

  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService,
  ) { }

  getBankAccounts(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-accounts-v1`,
        false
      )
    );
  }

  getCreateBankAccountUrl(vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-account-v1`,
        false
      ), {}
    );
  }

  getBankAccountManagementUrl(vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-accounts-v1/management`,
        false
      ), {}
    );
  }

  deleteBankAccount(id: string, vehicleId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/bank-account-v1/${id}`,
        false
      )
    );
  }

}
