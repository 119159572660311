import { Injectable } from '@angular/core';
import { UrlBuilder } from '../../shared/classes/url-builder';
import { QueryStringParameters } from '../../shared/classes/query-string-parameters';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiEndpointsService {
  private baseUrl: string;
  private datahubApiUrl: string;
  private lpBaseUrl: string;
  private lpDatahubApi: string;

  constructor() {
    this.baseUrl = environment.useRealAPI
      ? environment.datahubApiUrl
      : environment.baseUrl;
    this.datahubApiUrl = environment.datahubApiUrl;
    this.lpDatahubApi = environment.lpDatahubApi;
    this.lpBaseUrl = environment.useRealAPI
      ? environment.lpDatahubApi
      : environment.baseUrl;
  }

  createUrl(action: string, isMockAPI = false): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      isMockAPI ? this.baseUrl : this.datahubApiUrl,
      action
    );
    return urlBuilder.toString();
  }

  createLpUrl(action: string, isMockAPI = false): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      isMockAPI ? this.lpBaseUrl : this.lpDatahubApi,
      action
    );
    return urlBuilder.toString();
  }

  private createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(this.baseUrl, action);
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  private createUrlWithPathVariables(
    action: string,
    pathVariables: any[] = []
  ): string {
    let encodedPathVariablesUrl = '';

    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(
          pathVariable.toString()
        )}`;
      }
    }

    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.baseUrl,
      `${action}${encodedPathVariablesUrl}`
    );

    return urlBuilder.toString();
  }

  public getSessionUser(isRefresh: boolean): string {
    return this.createUrl(`session-user?isRefresh=${isRefresh}`, false);
  }

  public postSessionUser(): string {
    return this.createUrl('session-user', false);
  }

  public getBaseData(): string {
    return this.createUrl('base-data', false);
  }

  public getDashboardData(vehicleId: string): string {
    return this.createLpUrl(`vehicles/${vehicleId}/dashboard`, false);
  }

  public getDashboardEventData(vehicleId: string): string {
    return this.createLpUrl(`vehicles/${vehicleId}/events?sort=start`, false);
  }
  public getDashboardNewsData(vehicleId: string): string {
    return this.createLpUrl(
      `vehicles/${vehicleId}/news?sort=dateTime&order=desc`,
      false
    );
  }
}
