<div class="snack-bar">
    <div class="message-wrapper">
        <div class="icon info">
            <img src="../../../../assets/images/info_icon.svg" alt="icon" height="17.14px" width="17.14px">
        </div>
        <div class="message">{{data.message || 'Successfuly completed'}}</div>
    </div>
    <button (click)="dismiss()">
        <img src="../../../../assets/images/cross_icon_grey.svg" alt="close" height="20px" width="20px">
    </button>
</div>