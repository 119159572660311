<div class="pnf-page">
  <div class="access-denied-wrapper">
    <div class="content-wrapper">
      <div class="line">
        <svg
          width="125"
          height="129"
          viewBox="0 0 125 129"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="63" cy="62" r="62" fill="#9DC655" />
          <mask
            id="mask0_3786_32389"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="37"
            width="75"
            height="92">
            <path
              d="M0 79L45 37V39L46 65.5L74.5 88.5L32.5 129L1.5 118.5L0 79Z"
              fill="#74BB9F" />
          </mask>
          <g mask="url(#mask0_3786_32389)">
            <circle cx="63.5" cy="62" r="62" fill="#82B32E" />
          </g>
          <path
            d="M75.3777 64.0117V34.8597C75.3777 32.8647 73.8506 31.2435 71.9677 31.2435C70.0848 31.2435 68.5577 32.8615 68.5577 34.8597V57.6151C68.5577 58.2152 68.1798 58.7022 67.7138 58.7022C67.2478 58.7022 66.87 58.2152 66.87 57.6151V29.6161C66.87 27.618 65.3429 26 63.4632 26C61.5834 26 60.0563 27.618 60.0563 29.6161V58.4163C60.0563 59.0163 59.6785 59.5033 59.2125 59.5033C58.7465 59.5033 58.3686 59.0163 58.3686 58.4163V32.739C58.3686 30.7409 56.8447 29.1229 54.9618 29.1229C53.0789 29.1229 51.5518 30.7409 51.5518 32.739V59.2174C51.5518 59.8175 51.1739 60.3045 50.7079 60.3045C50.2419 60.3045 49.8641 59.8175 49.8641 59.2174V39.2204C49.8641 37.2222 48.337 35.6042 46.4572 35.6042C44.5775 35.6042 43.0441 37.2222 43.0441 39.2204V59.2174C43.0441 59.8175 43.0346 60.3045 43.022 60.3045C43.0094 60.3045 43 60.7914 43 61.3883V75.5387C43 82.5196 47.2224 90 53.2174 90H67.6666C76.7725 90 85 80.5057 85 75.4852V53.8105C85 51.8124 83.2399 50.1944 81.0673 50.1944C78.8947 50.1944 77.1346 51.8124 77.1346 53.8105V64.0148C77.1346 64.6149 76.5081 65.1019 76.2562 65.1019C76.0043 65.1019 75.3777 64.6149 75.3777 64.0148V64.0117Z"
            fill="white" />
        </svg>
      </div>
      <div class="line bottom-separator">
        <div class="hr1"></div>
        <div class="hr2"></div>
      </div>
      <div class="line">
        <p class="content-access">You do not have the permission to access.</p>
      </div>
      <div class="line">
        <button
          (click)="Login()"
          class="LoginButton"
          mat-raised-button
          style="color: #ffffff; background-color: #9dc655; box-shadow: none">
          Log in with another user
        </button>
      </div>
    </div>
  </div>
</div>
