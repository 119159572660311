import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ConfigService } from 'src/app/initializer/config.service';

@Injectable({
  providedIn: 'root',
})
export class MoniteService {
  baseData: any;

  constructor(
    private apiHttpService: ApiHttpService,
    private config: ConfigService,
    private apiEndpointsService: ApiEndpointsService
  ) {
    this.config.baseDataObservable$.subscribe((res: any) => {
      if (res) {
        this.baseData = res;
      }
    });
   }

  updateBaseData(
    gpId: string,
    vehicleId: string,
    moniteEntityId: string,
    moniteEntityUserId: string
  ) {
    // Find the GP by its ID
    const gp = this.baseData.gps.find((gp: any) => gp.id === gpId);

    if (gp) {
      // Find the vehicle by its ID within the GP's vehicles array
      const vehicle = gp.vehicles.find((v: any) => v.id === vehicleId);

      if (vehicle) {
        // Update the moniteEntityId and moniteEntityUserId
        vehicle.moniteEntityId = moniteEntityId;
        vehicle.moniteEntityUserId = moniteEntityUserId;

        console.log('Updated vehicle:', vehicle);
      } else {
        console.log('Vehicle not found!');
      }
    } else {
      console.log('GP not found!');
    }

    this.config.setBaseData(this.baseData);
  }

  // GET /users/:userId/vehicles/:vehicleId/entity-details
  getMoniteIds(userId: string, vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `users/${userId}/vehicles/${vehicleId}/entity-details`,
        false
      )
    );
  }

  // GET /entity-user/:entityUserId/entity-user-login
  getEntityUserAuthUrl(entityUserId: string) {
    return this.apiEndpointsService.createUrl(
      `entity-user/${entityUserId}/entity-user-login`,
      false
    );
  }

  // POST /users/:userId/vehicles/:vehicleId/create-monite-entity
  createMoniteEntity(userId: string, vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `users/${userId}/vehicles/${vehicleId}/monite/create-monite-entity`,
        false
      ),
      {}
    );
  }

  // POST /users/:userId/vehicles/:vehicleId/create-monite-entity-user
  createMoniteEntityUser(userId: string, vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `users/${userId}/vehicles/${vehicleId}/monite/create-monite-entity-user`,
        false
      ),
      {}
    );
  }
}
