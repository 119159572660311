import { Component, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { CounterpartDetailService } from './counterpart.service';

@Component({
  selector: 'app-counterpart-side-draw',
  templateUrl: './counterpart-side-draw.component.html',
  styleUrls: ['./counterpart.component.scss'],
})
export class CounterpartSideDrawComponent {
  @ViewChild('counterpartDetailsSection')
  counterpartDetailsSection!: ElementRef;
  titles: any = [];
  countries = [
    { name: 'Germany', value: 'DE' },
    { name: 'France', value: 'FR' },
  ];
  currencies = [{ name: 'Euro', value: 'EUR' }];
  vatTypes = [{ name: 'Value Added Tax (EU)', value: 'eu_vat' }];
  basicDetails: any;
  selectedCounterpart: any;
  selectedVatId: any;
  selectedBankaccount: any;
  selectedContact: any;
  selectedCounterpartAddress: any;
  address = {
    'Address line 1': '',
    'Address line 2': '',
    City: '',
    'Zip code': '',
    State: '',
    Country: '',
  };
  vatIds: any = [];
  bankAccounts: any = [];
  contacts: any = [];
  createCounterpart = {
    type: 'organization',
    is_customer: true,
    legal_name: undefined,
    first_name: undefined,
    last_name: undefined,
    phone: undefined,
    email: undefined,
    reminders_enabled: false,
    address: {
      line1: undefined,
      line2: undefined,
      city: undefined,
      postal_code: undefined,
      state: undefined,
      country: undefined,
    },
    tax_id: undefined,
  };
  createVatId = {
    country: undefined,
    type: undefined,
    value: undefined,
  };
  createBankAccount = {
    name: undefined,
    account_number: undefined,
    country: undefined,
    currency: undefined,
    iban: undefined,
    bic: undefined,
    is_default_for_currency: false,
  };
  createContact = {
    first_name: undefined,
    last_name: undefined,
    phone: undefined,
    email: undefined,
    address: {
      line1: undefined,
      line2: undefined,
      city: undefined,
      postal_code: undefined,
      state: undefined,
      country: undefined,
    },
    // isPrimary: undefined,
  };
  editAddress = {
    line1: undefined,
    line2: undefined,
    city: undefined,
    postal_code: undefined,
    state: undefined,
    country: undefined,
  };
  editBasicDetail = {
    legal_name: undefined,
    first_name: undefined,
    last_name: undefined,
    reminders_enabled: undefined,
    phone: undefined,
    email: undefined,
    tax_id: undefined,
  };
  @ViewChild('createCounterpartForm') createCounterpartForm!: NgForm;
  private counterpartDetailSubscription!: Subscription;
  creatingVatId = false;
  creatingBankAccount = false;
  creatingContact = false;
  creatingCounterpart = false;
  editingAddress = false;
  editingBasic = false;
  isCreate = true;
  vehicleId: string;
  counterpartId: string;
  fetchingAddress: boolean = false;
  fetchingContacts: boolean = true;
  fetchingVatIds: boolean = false;
  fetchingBankAccounts: boolean = false;
  showContactForm: boolean = false;
  showBankAccountForm: boolean = false;
  showVatIdForm: boolean = false;
  showAddressForm: boolean = false;
  showBasicForm: boolean = false;
  editForm: boolean = false;

  constructor(
    public counterpartDetailService: CounterpartDetailService,
    private commonService: CommonService
  ) {}

  createCounterpartHandler() {
    this.creatingCounterpart = true;
    let updatedUser = {};

    if (this.createCounterpart.type === 'organization') {
      updatedUser = {
        type: 'organization',
        organization: {
          is_customer: true,
          is_vendor: false,
          legal_name: this.createCounterpart.legal_name,
          phone: this.createCounterpart.phone,
          email: this.createCounterpart.email,
          address: {
            line1: this.createCounterpart.address.line1,
            line2: this.createCounterpart.address.line2,
            city: this.createCounterpart.address.city,
            postal_code: this.createCounterpart.address.postal_code,
            state: this.createCounterpart.address.state,
            country: this.createCounterpart.address.country,
          },
        },
        reminders_enabled: this.createCounterpart.reminders_enabled,
        tax_id: this.createCounterpart.tax_id,
      };
    } else {
      updatedUser = {
        type: 'individual',
        individual: {
          is_customer: true,
          is_vendor: false,
          first_name: this.createCounterpart.first_name,
          last_name: this.createCounterpart.last_name,
          phone: this.createCounterpart.phone,
          email: this.createCounterpart.email,
          address: {
            line1: this.createCounterpart.address.line1,
            line2: this.createCounterpart.address.line2,
            city: this.createCounterpart.address.city,
            postal_code: this.createCounterpart.address.postal_code,
            state: this.createCounterpart.address.state,
            country: this.createCounterpart.address.country,
          },
        },
        reminders_enabled: this.createCounterpart.reminders_enabled,
        tax_id: this.createCounterpart.tax_id,
      };
    }
    this.counterpartDetailService
      .createCounterpart(this.vehicleId, updatedUser)
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Counterparty created successfully'
            );
            this.counterpartDetailService.closeDrawer();
            this.counterpartDetailService.setCounterpartListRefresh(true);
          }
        },
        error: _error => {
          this.creatingCounterpart = false;
          this.commonService.errorNotification('Failed to create counterparty');
        },
        complete: () => {
          this.creatingCounterpart = false;
        },
      });
  }

  createvatIdHandler() {
    this.creatingVatId = true;
    this.counterpartDetailService
      .createVatId(this.vehicleId, this.counterpartId, this.createVatId)
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'The VAT Id added successfully'
            );
            this.hideSubForms();
            this.getCounterpartVatIds();
          }
        },
        error: _error => {
          this.creatingVatId = false;
          this.commonService.errorNotification('Failed to add VAT Id');
        },
        complete: () => {
          this.creatingVatId = false;
        },
      });
  }

  createBankAccountHandler() {
    this.creatingBankAccount = true;
    this.counterpartDetailService
      .createBankAccount(
        this.vehicleId,
        this.counterpartId,
        this.createBankAccount
      )
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Bank account added successfully'
            );

            this.hideSubForms();
            this.getCounterpartBankAccounts();
          }
        },
        error: _error => {
          this.creatingBankAccount = false;
          this.commonService.errorNotification('Failed to add bank account');
        },
        complete: () => {
          this.creatingBankAccount = false;
        },
      });
  }

  createContactHandler() {
    this.creatingContact = true;
    this.counterpartDetailService
      .createCounterpartContact(
        this.vehicleId,
        this.counterpartId,
        this.createContact
      )
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Contact person added successfully'
            );

            this.hideSubForms();
            this.getCounterpartContacts();
          }
        },
        error: error => {
          this.creatingContact = false;
          if (error.error.error.message === 'Object already exists') {
            this.hideSubForms();
            this.commonService.infoNotification(
              'Contact person already available'
            );
          } else {
            this.commonService.errorNotification(
              'Failed to add contact person'
            );
          }
        },
        complete: () => {
          this.creatingContact = false;
        },
      });
  }

  editCounterpartBasicDetail() {
    this.editBasicDetail = {
      phone: this.selectedCounterpart.phone,
      email: this.selectedCounterpart.email,
      first_name: this.selectedCounterpart.first_name,
      last_name: this.selectedCounterpart.last_name,
      legal_name: this.selectedCounterpart.legal_name,
      reminders_enabled: this.selectedCounterpart.reminders_enabled,
      tax_id: this.selectedCounterpart.tax_id,
    } as any;
    this.scrollToTop();
    this.showBasicForm = true;
  }

  editCounterpartAddress() {
    this.editAddress = {
      line1: this.address['Address line 1'],
      line2: this.address['Address line 2'],
      city: this.address.City,
      postal_code: this.address['Zip code'],
      state: this.address.State,
      country: this.countries.find(c => c.name === this.address.Country)!.value,
    } as any;
    this.scrollToTop();
    this.showAddressForm = true;
    this.titles.push('Edit Counterparty address');
  }

  addContactHandler() {
    this.titles.push('Add contact person');
    this.resetContactForm();
    this.scrollToTop();
    this.showContactForm = true;
  }

  addVatIdsHandler() {
    this.titles.push('Add VAT ID');
    this.resetVATIdForm();
    this.scrollToTop();
    this.showVatIdForm = true;
  }

  addBankAccountHandler() {
    this.titles.push('Add bank account');
    this.resetBankaccountForm();
    this.scrollToTop();
    this.showBankAccountForm = true;
  }

  getCounterpartAddress() {
    this.fetchingAddress = true;
    this.counterpartDetailService
      .getCounterpartAddress(this.vehicleId, this.counterpartId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          const [address] = parsedResponse.data.data;
          this.selectedCounterpartAddress = address;
          this.setCounterpartAddress(address);
        },
        error: _error => {
          this.fetchingAddress = false;
        },
        complete: () => {
          this.fetchingAddress = false;
        },
      });
  }

  getCounterpartBankAccounts() {
    this.fetchingBankAccounts = true;
    this.counterpartDetailService
      .getCounterpartBankAccounts(this.vehicleId, this.counterpartId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          const accounts = parsedResponse.data.data;
          this.bankAccounts = accounts;
        },
        error: _error => {
          this.fetchingBankAccounts = false;
        },
        complete: () => {
          this.fetchingBankAccounts = false;
        },
      });
  }

  getCounterpartVatIds() {
    this.fetchingVatIds = true;
    this.counterpartDetailService
      .getCounterpartVatIds(this.vehicleId, this.counterpartId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          const vatIds = parsedResponse.data.data;
          this.vatIds = vatIds.map((x: any) => ({
            id: x.id,
            value: x.value,
            country: this.countries.find(c => c.value === x.country)!.name,
            countryCode: x.country,
            type: x.type,
          }));
        },
        error: _error => {
          this.fetchingVatIds = false;
        },
        complete: () => {
          this.fetchingVatIds = false;
        },
      });
  }

  getCounterpartContacts() {
    this.fetchingContacts = true;
    this.counterpartDetailService
      .getCounterpartContacts(this.vehicleId, this.counterpartId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          const contacts = parsedResponse.data.data;
          this.contacts = contacts;
        },
        error: _error => {
          this.fetchingContacts = false;
        },
        complete: () => {
          this.fetchingContacts = false;
        },
      });
  }

  makePrimaryAccount(item: any) {
    this.counterpartDetailService
      .makeDefaultBankaccount(this.vehicleId, this.counterpartId, item.id)
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Default account changed successfully'
            );
            this.getCounterpartBankAccounts();
          }
        },
        error: _error => {
          this.commonService.errorNotification(
            'Failed to change default account'
          );
        },
        complete: () => {},
      });
  }

  makePrimaryContact(item: any) {
    this.counterpartDetailService
      .makeDefaultContact(this.vehicleId, this.counterpartId, item.id)
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Default contact changed successfully'
            );
            this.getCounterpartContacts();
          }
        },
        error: _error => {
          this.commonService.errorNotification(
            'Failed to change default contact'
          );
        },
        complete: () => {},
      });
  }

  editVatId(item: any) {
    this.selectedVatId = item;
    this.resetVATIdForm();
    this.editForm = true;
    this.createVatId = {
      value: item.value,
      country: item.countryCode,
      type: item.type,
    };
    this.scrollToTop();
    this.showVatIdForm = true;
    this.titles.push(item.value);
  }

  editContact(item: any) {
    this.selectedContact = item;
    this.resetContactForm();
    this.editForm = true;
    this.createContact = (({
      id,
      counterpart_id,
      is_default,
      title,
      ...rest
    }) => rest)(item);
    this.scrollToTop();
    this.showContactForm = true;
    this.titles.push(`${item.first_name} ${item.last_name}`);
  }

  editBankAccount(item: any) {
    this.selectedBankaccount = item;
    this.resetBankaccountForm();
    this.editForm = true;
    this.createBankAccount = (({
      id,
      counterpart_id,
      account_holder_name,
      partner_metadata,
      routing_number,
      sort_code,
      is_default_for_currency,
      ...rest
    }) => rest)(item);
    this.scrollToTop();
    this.showBankAccountForm = true;
    this.titles.push(item.name);
  }

  editAddressHandler() {
    this.counterpartDetailService
      .updateAddress(
        this.vehicleId,
        this.counterpartId,
        this.selectedCounterpartAddress.id,
        this.editAddress
      )
      .subscribe({
        next: response => {
          if (response) {
            let parsedResponse = JSON.parse(JSON.stringify(response));
            this.commonService.successNotification(
              'Address updated successfully'
            );
            this.setCounterpartAddress(parsedResponse.data);
            this.hideSubForms();
          }
        },
        error: _error => {
          this.editingBasic = false;
          this.commonService.errorNotification('Failed to update address');
        },
        complete: () => {
          this.editingBasic = false;
        },
      });
  }

  editBasicHandler() {
    this.editingBasic = true;
    let updatedUser = {};

    if (this.selectedCounterpart.type === 'organization') {
      updatedUser = {
        organization: {
          legal_name: this.editBasicDetail.legal_name,
          phone: this.editBasicDetail.phone,
          email: this.editBasicDetail.email,
        },
        reminders_enabled: this.editBasicDetail.reminders_enabled,
        tax_id: this.editBasicDetail.tax_id,
      };
    } else {
      updatedUser = {
        individual: {
          first_name: this.editBasicDetail.first_name,
          last_name: this.editBasicDetail.last_name,
          phone: this.editBasicDetail.phone,
          email: this.editBasicDetail.email,
        },
        reminders_enabled: this.editBasicDetail.reminders_enabled,
        tax_id: this.editBasicDetail.tax_id,
      };
    }
    this.counterpartDetailService
      .updateCounterpart(this.vehicleId, this.counterpartId, updatedUser)
      .subscribe({
        next: response => {
          if (response) {
            let parsedResponse = JSON.parse(JSON.stringify(response));
            let res: any = {};
            if (parsedResponse.data.type === 'organization') {
              res = {
                type: 'organization',
                legal_name: parsedResponse.data.organization.legal_name,
                phone: parsedResponse.data.organization.phone,
                email: parsedResponse.data.organization.email,
                reminders_enabled: parsedResponse.data.reminders_enabled,
                tax_id: parsedResponse.data.tax_id,
              };
              this.basicDetails = this.titles = [res.legal_name];
            } else {
              res = {
                type: 'individual',
                first_name: parsedResponse.data.individual.first_name,
                last_name: parsedResponse.data.individual.last_name,
                phone: parsedResponse.data.individual.phone,
                email: parsedResponse.data.individual.email,
                reminders_enabled: parsedResponse.data.reminders_enabled,
                tax_id: parsedResponse.data.tax_id,
              };
              this.titles = [`${res.first_name} ${res.last_name}`];
            }
            this.selectedCounterpart.first_name = res.first_name;
            this.selectedCounterpart.last_name = res.last_name;
            this.selectedCounterpart.legal_name = res.legal_name;
            this.selectedCounterpart.phone = res.phone;
            this.selectedCounterpart.email = res.email;
            this.selectedCounterpart.reminders_enabled = res.reminders_enabled;
            this.selectedCounterpart.tax_id = res.tax_id;
            this.setCounterpartBasicDetails(res);
            this.counterpartDetailService.setCounterpartListRefresh(true);
            this.commonService.successNotification(
              'Basic details updated successfully'
            );
            this.hideSubForms();
          }
        },
        error: _error => {
          this.editingBasic = false;
          this.commonService.errorNotification(
            'Failed to update basic details'
          );
        },
        complete: () => {
          this.editingBasic = false;
        },
      });
  }

  updateContactHandler() {
    this.creatingContact = true;
    this.counterpartDetailService
      .updateContact(
        this.vehicleId,
        this.counterpartId,
        this.selectedContact.id,
        this.createContact
      )
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Contact information updated successfully.'
            );

            this.hideSubForms();
            this.getCounterpartContacts();
          }
        },
        error: _error => {
          this.creatingContact = false;
          this.commonService.errorNotification(
            'Failed to update contact person'
          );
        },
        complete: () => {
          this.creatingContact = false;
        },
      });
  }

  updateVatIdHandler() {
    this.creatingVatId = true;
    this.counterpartDetailService
      .updateVatId(
        this.vehicleId,
        this.counterpartId,
        this.selectedVatId.id,
        this.createVatId
      )
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'The VAT Id updated successfully'
            );
            this.hideSubForms();
            this.getCounterpartVatIds();
          }
        },
        error: _error => {
          this.creatingVatId = false;
          this.commonService.errorNotification('Failed to update VAT Id');
        },
        complete: () => {
          this.creatingVatId = false;
        },
      });
  }

  updateBankaccountHandler() {
    this.creatingBankAccount = true;
    this.counterpartDetailService
      .updateBankAccount(
        this.vehicleId,
        this.counterpartId,
        this.selectedBankaccount.id,
        this.createBankAccount
      )
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Bank account updated successfully'
            );

            this.hideSubForms();
            this.getCounterpartBankAccounts();
          }
        },
        error: _error => {
          this.creatingBankAccount = false;
          this.commonService.errorNotification('Failed to update bank account');
        },
        complete: () => {
          this.creatingBankAccount = false;
        },
      });
  }

  deleteVatId(vatId: string) {
    this.counterpartDetailService
      .deleteVatId(this.vehicleId, this.counterpartId, vatId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.deleteFromArray(this.vatIds, vatId);

          this.commonService.successNotification('VAT Id deleted successfully');
        },
        error: _error => {
          this.commonService.errorNotification('Failed to delete VAT id');
        },
        complete: () => {},
      });
  }

  deleteContact(contactId: string) {
    this.counterpartDetailService
      .deleteContact(this.vehicleId, this.counterpartId, contactId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.deleteFromArray(this.contacts, contactId);

          this.commonService.successNotification(
            'Contact deleted successfully'
          );
        },
        error: _error => {
          this.commonService.errorNotification('Failed to delete contact');
        },
        complete: () => {},
      });
  }

  deleteBankAccount(bankaccountId: string) {
    this.counterpartDetailService
      .deleteBankAccount(this.vehicleId, this.counterpartId, bankaccountId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.deleteFromArray(this.bankAccounts, bankaccountId);
          this.commonService.successNotification(
            'Bank account deleted successfully'
          );
        },
        error: _error => {
          this.commonService.errorNotification('Failed to delete bank account');
        },
        complete: () => {},
      });
  }

  ngOnInit() {
    this.counterpartDetailSubscription =
      this.counterpartDetailService.counterpartDetailsObservable$.subscribe(
        (res: any) => {
          if (res && !res.isCreate) {
            this.selectedCounterpart = res;
            //counterpart details
            this.isCreate = false;
            this.counterpartId = res.counterpartId;
            this.vehicleId = res.vehicleId;
            const titles = [];
            titles.push(
              res.type === 'organization'
                ? res.legal_name
                : `${res.first_name} ${res.last_name}`
            );
            this.titles = [...titles];
            this.setCounterpartBasicDetails(res);
            this.hideSubForms();
            this.getCounterpartAddress();
            this.selectedCounterpart.type === 'organization' &&
              this.getCounterpartContacts();
            this.getCounterpartVatIds();
            this.getCounterpartBankAccounts();
          } else if (res && res.isCreate) {
            // create counterpart
            this.resetCreateCounterpartForm();
            this.isCreate = true;
            this.vehicleId = res.vehicleId;
            this.titles = [...['Create Counterparty']];
          }
        }
      );
  }

  setCounterpartAddress(address: any) {
    this.address['Address line 1'] = address.line1;
    this.address['Address line 2'] = address.line2;
    this.address['Zip code'] = address.postal_code;
    this.address.City = address.city;
    this.address.State = address.state;
    this.address.Country = address.country
      ? this.countries.find(c => c.value === address.country)!.name
      : '';
  }

  setCounterpartBasicDetails(res: any) {
    console.log({ res });
    if (res.type === 'organization') {
      this.basicDetails = {
        'Company name': res.legal_name,
        Category: 'Legal entity',
        Phone: res.phone,
        Email: res.email,
      };
    } else {
      this.basicDetails = {
        'Private Individual Name': res.first_name + ' ' + res.last_name,
        Category: 'Private individual',
        Phone: res.phone,
        Email: res.email,
      };
    }
  }

  convertObjectToArray(
    obj: Record<string, any>
  ): { key: string; value: any }[] {
    return Object.entries(obj).map(([key, value]) => ({ key, value }));
  }

  hideSubForms() {
    this.titles.length = 1;
    this.showContactForm = false;
    this.showVatIdForm = false;
    this.showBankAccountForm = false;
    this.showAddressForm = false;
    this.showBasicForm = false;
    this.editForm = false;
    this.scrollToTop();
  }

  deleteFromArray(array: any, id: string) {
    const index = array.findIndex((item: any) => item.id === id);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  scrollToTop() {
    if (this.counterpartDetailsSection) {
      this.counterpartDetailsSection.nativeElement.scrollTo({
        top: 0,
      });
    }
  }

  resetCreateCounterpartForm() {
    this.createCounterpart = {
      type: 'organization',
      is_customer: true,
      legal_name: undefined,
      first_name: undefined,
      last_name: undefined,
      phone: undefined,
      email: undefined,
      reminders_enabled: false,
      address: {
        line1: undefined,
        line2: undefined,
        city: undefined,
        postal_code: undefined,
        state: undefined,
        country: undefined,
      },
      tax_id: undefined,
    };
  }

  resetBankaccountForm() {
    this.createBankAccount = {
      name: undefined,
      account_number: undefined,
      country: undefined,
      currency: undefined,
      iban: undefined,
      bic: undefined,
      is_default_for_currency: false,
    };
  }

  resetVATIdForm() {
    this.createVatId = {
      country: undefined,
      type: undefined,
      value: undefined,
    };
  }

  resetContactForm() {
    this.createContact = {
      first_name: undefined,
      last_name: undefined,
      phone: undefined,
      email: undefined,
      address: {
        line1: undefined,
        line2: undefined,
        city: undefined,
        postal_code: undefined,
        state: undefined,
        country: undefined,
      },
    };
  }

  ngOnDestroy() {
    if (this.counterpartDetailSubscription) {
      this.counterpartDetailSubscription.unsubscribe();
    }
  }
}
