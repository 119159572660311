<div class="rename-letterhead">
    <div class="content">
        <div class="heading">Rename
            <button (click)="dialogRef.close()" [disabled]="isLoading">
                <img src="../../../assets/images/cross_icon_grey.svg" alt="close">
            </button>
        </div>
        <div class="form-field">
            <div class="lable">Letterhead name</div>
            <mat-form-field class="letterhead-name">
                <input matInput placeholder="Name" [(ngModel)]="letterheadName" autocomplete="off">
            </mat-form-field>
        </div>
    </div>
    <div class="action">
        <button mat-flat-button class="cancel" [disabled]="isLoading" (click)="dialogRef.close()">Cancel</button>
        <button mat-flat-button class="save" [disabled]="!letterheadName || isLoading"
            (click)="renameLetterhead()">Save</button>
    </div>
</div>