<div class="invester-details-container">
  <div class="investor-subheader">
    <ng-container *ngIf="!isLoading; else nameLoader">
      <div class="title-content">
        <span class="image-border" (click)="navigateBack()">
          <img src="assets/images/back_icon.svg" height="24px" width="24px" />
        </span>
        <span class="subheader-label" (click)="navigateBack()">{{lpDetails.name}}</span>
        <span class="badge-investor-id">{{lpDetails.investorId ? lpDetails.investorId : ''}}</span>
      </div>
    </ng-container>
  </div>
  <div class="investor-tab-info">
    <mat-tab-group animationDuration="0ms" class="custom-tab-group">
      <mat-tab label="General info">
        <div class="general-info-tab">
          <div class="tabs-container">
            <ng-container *ngIf="!isLoading; else cardLoader">
              <div class="tab-container-header">
                <span class="img-circle">
                  <img src="assets/images/basic_documents_icon.svg" alt="Icon" height="16px" width="16px" />
                </span>
                <span class="container-title">Basic data</span>
              </div>
              <div class="tab-container-details">
                <div class="list-col">
                  <p class="label">Legal form</p>
                  <p class="value">{{lpDetails.legalForm ? lpDetails.legalForm : '-'}}</p>
                </div>
                <div class="list-col">
                  <p class="label">Trade register number</p>
                  <p class="value">{{lpDetails.tradeRegisterNumber ? lpDetails.tradeRegisterNumber : '-'}}</p>
                </div>
                <div class="list-col">
                  <p class="label">Investor type</p>
                  <p class="value">{{lpDetails.investorType ? ('translations.investors.' + lpDetails.investorType |
                    translate) : '-'}}</p>
                </div>
                <div class="list-col">
                  <p class="label">Investor qualification</p>
                  <p class="value">{{lpDetails.investorQualification ? ('translations.investors.' +
                    lpDetails.investorQualification |
                    translate) : '-'}}</p>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- another container -->
          <div class="tabs-container-address">
            <ng-container *ngIf="!isLoading; else cardLoader">
              <div class="tab-container-header">
                <span class="img-circle">
                  <img src="assets/images/address_map_icon.svg" alt="Icon" height="16px" width="16px" />
                </span>
                <span class="container-title">Address</span>
              </div>
              <div class="tab-container-details">
                <div class="list-col">
                  <p class="label">Street</p>
                  <p class="value">{{lpDetails.street ? lpDetails.street : '-'}}</p>
                </div>
                <div class="list-col">
                  <p class="label">Zip</p>
                  <p class="value">{{lpDetails.zip ? lpDetails.zip : '-'}}</p>
                </div>
                <div class="list-col">
                  <p class="label">City</p>
                  <p class="value">{{lpDetails.city ? lpDetails.city : '-'}}</p>
                </div>
                <div class="list-col">
                  <p class="label">Country</p>
                  <p class="value">{{lpDetails.country ? lpDetails.country : '-'}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Closings">
        <div class="investor-closing-table">
          <ng-container *ngIf="!isLoading">
            <table mat-table [dataSource]="closingDataSource" matSort matSortActive="date" matSortDirection="asc"
              matSortDisableClear #closingMatSort="matSort">

              <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> Number </th>
                <td mat-cell *matCellDef="let element">
                  <div class="closing-number">{{element.number}}</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Closing date </th>
                <td mat-cell *matCellDef="let element">
                  {{element.date ? commonService.formateDate(element.date) : '-'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Onboarding status </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.status !== null; else nullValue">
                    <div class="onboarding-status-badge invited" *ngIf="element.status === 'invited'">
                      <img src="../../../../assets/images/invited_circle_icon.svg" alt="investor-icon" height="16px"
                        width="16px" />
                      Invited
                    </div>
                    <div class="onboarding-status-badge in-progress" *ngIf="element.status === 'signingInProgress'">
                      <img src="../../../../assets/images/clock_icon.svg" alt="investor-icon" height="16px"
                        width="16px" />
                      Signing
                    </div>
                    <div class="onboarding-status-badge in-progress" *ngIf="element.status === 'inProgress'">
                      <img src="../../../../assets/images/clock_icon.svg" alt="investor-icon" height="16px"
                        width="16px" />
                      In Progress
                    </div>
                    <div class="onboarding-status-badge in-progress" *ngIf="element.status === 'reviewCompleted'">
                      <img src="../../../../assets/images/checked_circle_orange.svg" alt="investor-icon" height="16px"
                        width="16px" />
                      Reviewed
                    </div>
                    <div class="onboarding-status-badge completed" *ngIf="element.status === 'done'">
                      <img src="../../../../assets/images/circle_tick_green_icon.svg" alt="investor-icon" height="16px"
                        width="16px" />
                      Completed
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="offeredAmount">
                <th mat-header-cell *matHeaderCellDef> Offered amount (in {{selectVehicle.currency}}) </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.offeredAmount !== null; else nullValue">
                    {{commonService.convertWithDecimal(element.offeredAmount, selectVehicle)}}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef> Accepted amount (in {{selectVehicle.currency}}) </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.amount !== null; else nullValue">
                    {{commonService.convertWithDecimal(element.amount, selectVehicle)}}
                  </ng-container>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedClosingsColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedClosingsColumns;"></tr>
            </table>
          </ng-container>
        </div>
      </mat-tab>
      <mat-tab label="Persons">
        <ng-container *ngIf="!noPersons; else noPersonsTemplate">
          <div class="person-table">
            <ng-container *ngIf="!isLoading">
              <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
                matSortDisableClear #matSort="matSort">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                  <td mat-cell *matCellDef="let element" (mouseenter)="nameHoverIn(menuTrigger)"
                    (mouseleave)="nameHoverOut($event)">
                    <div class="person-column">
                      <div class="initials" mat-icon-button [matMenuTriggerFor]="Menu" #menuTrigger="matMenuTrigger">
                        <span class="short-name">{{shortenName(element)}}</span>
                      </div>
                      <mat-menu #Menu="matMenu" hasBackdrop="false" yPosition="below" xPosition="after"
                        class="name-popup" person-name-position>
                        <div class="popup-content" (mouseleave)="closeMenu(menuTrigger)">
                          <!-- <div class="popup-content"> -->
                          <div class="investor-info">
                            <div class="initial-details">
                              <span class="short-name">{{shortenName(element)}}</span>
                            </div>
                            <div class="person-name">
                              <div class="name-main">
                                <h5>{{getDisplayName(element)}}</h5>
                                <div class="badge-circle" *ngIf="element.isMainContact">
                                  <span class="badge-text">Main</span>
                                </div>
                              </div>
                              <p *ngIf="element.capacity">{{element.capacity}}</p>
                            </div>
                          </div>
                          <div class="inverstor-email">
                            <div class="email-icon">
                              <img src="assets/images/mail_icon.svg" alt="Icon">
                              <span *ngIf="!element.emailAddress">-</span>
                              <a *ngIf="element.emailAddress"
                                [href]="getMailToLink(element.emailAddress)">{{element.emailAddress}}</a>
                            </div>
                            <div class="copy-icon">
                              <button (click)="copyEmail(element.emailAddress); $event.stopPropagation();">
                                <img src="assets/images/copy_icon.svg" height="18px" width="18px" alt="Icon">
                              </button>
                            </div>
                          </div>
                        </div>
                      </mat-menu>
                      <div class="person-name">
                        <div class="name-main">
                          <h5>{{getDisplayName(element)}}</h5>
                          <div class="badge-circle" *ngIf="element.isMainContact">
                            <span class="badge-text">Main</span>
                          </div>
                        </div>
                        <p *ngIf="element.capacity">{{element.capacity}}</p>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <!-- Address Column -->
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef> Form of address </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.foaEn; else nullValue">
                      <p style="margin-bottom: 4px;">{{element.foaEn}}</p>
                    </ng-container>
                    <ng-container *ngIf="element.foaDe; else nullValue">
                      <p>{{element.foaDe}}</p>
                    </ng-container>
                  </td>
                </ng-container>

                <!-- Roles Column -->
                <ng-container matColumnDef="roles">
                  <th mat-header-cell *matHeaderCellDef> Roles </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.roles !== null; else nullValue">
                      <div class="roles">
                        <span class="roles-list" *ngFor="let role of element.roles">{{ role }}</span>
                      </div>
                    </ng-container>
                  </td>
                </ng-container>

                <!-- Receives Reports Column -->
                <ng-container matColumnDef="receivesReports">
                  <th mat-header-cell *matHeaderCellDef> Receives reports </th>
                  <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.receivesReports !== null; else nullValue">
                      <img *ngIf="element.receivesReports" src="assets/images/done_circle.svg" alt="ace-icon">
                      <img *ngIf="!element.receivesReports" src="assets/images/blocked_icon.svg" alt="ace-icon">
                    </ng-container>
                  </td>
                </ng-container>

                <!-- Table rows and header -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </ng-container>
            <!-- <ng-container *ngIf="isLoading">
              <table mat-table [dataSource]="[1,2,3,4]">
                
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                        width: '200px',
                        height: '45px',
                        marginBottom: 0
                    }"></ngx-skeleton-loader>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef> Address </th>
                  <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      width: '280px',
                      height: '45px',
                      marginBottom: 0
                  }"></ngx-skeleton-loader>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="roles">
                  <th mat-header-cell *matHeaderCellDef> Roles </th>
                  <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      width: '110px',
                      height: '21px',
                      marginBottom: 0
                  }"></ngx-skeleton-loader>
                  </td>
                </ng-container>
  
                <ng-container matColumnDef="receivesReports">
                  <th mat-header-cell *matHeaderCellDef> Receives Reports </th>
                  <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      width: '24px',
                      height: '24px',
                      marginBottom: 0
                  }"></ngx-skeleton-loader>
                  </td>
                </ng-container>
  
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </ng-container> -->
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<ng-template #nameLoader>
  <div style="display: flex; gap: 12px; align-items: center;">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '38px',
      height: '38px',
      marginBottom: 0
  }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '200px',
            height: '24px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '88px',
          height: '24px',
          marginBottom: 0
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #cardLoader>
  <div style="width: 600px; display: flex; flex-direction: column; gap: 24px;">
    <div>
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '170px',
        height: '34px',
        marginBottom: 0
    }"></ngx-skeleton-loader>
    </div>
    <div style="display: flex; flex-direction: column;">
      <div
        style="display: flex; justify-content: space-between;border-bottom: 1px solid var(--border-color);padding: 12px 0;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '200px',
          height: '24px',
          marginBottom: 0
      }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '100px',
        height: '24px',
        marginBottom: 0
    }"></ngx-skeleton-loader>
      </div>
      <div
        style="display: flex; justify-content: space-between;border-bottom: 1px solid var(--border-color);padding: 12px 0;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '200px',
          height: '24px',
          marginBottom: 0
      }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '100px',
        height: '24px',
        marginBottom: 0
    }"></ngx-skeleton-loader>
      </div>
      <div
        style="display: flex; justify-content: space-between;border-bottom: 1px solid var(--border-color);padding: 12px 0;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '200px',
          height: '24px',
          marginBottom: 0
      }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '100px',
        height: '24px',
        marginBottom: 0
    }"></ngx-skeleton-loader>
      </div>
      <div
        style="display: flex; justify-content: space-between;border-bottom: 1px solid var(--border-color);padding: 12px 0;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '200px',
          height: '24px',
          marginBottom: 0
      }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '100px',
        height: '24px',
        marginBottom: 0
    }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #nullValue>
  <div>-</div>
</ng-template>


<ng-template #noPersonsTemplate>
  <div class="no-investor-wrapper">
    <div class="no-investor-container">
      <div class="icon">
        <img src="../../../../assets/images/crm_icon.svg" alt="icon" height="24px" width="24px">
      </div>
      <div class="text-area">
        <div class="main">No persons available</div>
        <div class="sub-text">Looks like there are no persons added for now.
          Please check again later.</div>
      </div>
    </div>
  </div>
</ng-template>