<div class="pdf-options">

    <div class="content">
        <div class="heading-section">
            <div class="heading">Page rules
                <button (click)="dialogRef.close()">
                    <img src="../../../assets/images/cross_icon_grey.svg" alt="close">
                </button>
            </div>
            <div class="description">Uploaded PDFs can have a maximum of two pages</div>
        </div>
        <div class="content-section">
            <div class="card">
                <img src="../../../../assets/images/pdf_option1.svg" alt="icon" height="44px" width="44px">
                <div class="card-content">
                    <div class="title">Only one page</div>
                    <div class="description">This page is used as letterhead for all pages of a rendered document.</div>
                </div>
            </div>
            <div class="card">
                <img src="../../../../assets/images/pdf_option2.svg" alt="icon" height="44px" width="44px">
                <div class="card-content">
                    <div class="title">Two pages</div>
                    <div class="description">The first page will be used for the first page and the second for all
                        subsequent pages of a rendered document.</div>
                </div>
            </div>
        </div>
    </div>
</div>