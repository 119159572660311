import { Component, Inject, Type } from '@angular/core';
import { capitalize } from 'src/app/utils/formating.utils';
@Component({
  selector: 'app-counter-party-label',
  template: `<span style="width: 200px !important; display: inline-block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ name }}</span>`,
})
export class CounterPartyLabelComponent {
  constructor(
    @Inject('name') public name: string,
    @Inject('count') public count: number
  ) {}
}
@Component({
  selector: 'app-bank-account-label',
  template: `
    <div class="bank-account-label">
      <span class="account-name">{{ capitalize(name) }}</span>
      <span class="account-number">{{ accountNo }}</span>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .bank-account-label {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .account-name {
        width: 200px;
        display: inline-block;
        overflow: hidden;
        line-height: 20px;
        font-style: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #111;
      }

      .account-number {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-style: normal;
        color: #828282;
      }
    `
  ]
})

export class BankAccountLabelComponent {
  constructor(
    @Inject('name') public name: string,
    @Inject('accountNo') public accountNo: number
  ) {}
  capitalize(str: string) {
    return capitalize(str);
  }
}
