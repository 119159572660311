import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { InvoicesService } from './invoices.service';
import { ActivatedRoute } from '@angular/router';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements AfterViewInit {
  vehicleId: string;
  sortOptions: any[] = [];
  statuses: any[] = [];
  senders: any[] = [];
  selectedFile: File | null = null;
  isLoading: boolean = false;
  isUploading: boolean = false;
  moniteIdMissing: boolean;
  search: string;
  displayedColumns: string[] = [
    'document_id',
    'supplier',
    'issued_at',
    'due_date',
    'added_by',
    'amount',
    'status',
    'action',
  ];
  invoiceList: any[] = [];

  serviceNotAvailableDesc =
    'This vehicle has not yet been configured for the use of transactions. Please contact support at';
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);

  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  constructor(
    private apiHttpService: ApiHttpService,
    private invoicesService: InvoicesService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    public breakPointService: BreakPointService
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.getTransactions();
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Invoices']);
    }, 0);
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  getTransactions() {
    if (environment.layer === 'stage' || environment.layer === 'prod') {
      this.moniteIdMissing = true;
      return
    }
    this.moniteIdMissing = false;
    this.isLoading = true;
    this.apiHttpService
      .get(this.invoicesService.getTransctionsRoute(this.vehicleId))
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.invoiceList = parsedResponse.data;
          this.dataSource = new MatTableDataSource(parsedResponse.data);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            if (property === 'issued_at') {
              return new Date(item[property]).getTime();
            }
            return item[property].toLocaleLowerCase()
          };
          this.isLoading = false;
        },
        error => {
          if (
            error.error.message ===
            'moniteEntityId is not exist for this vehicle'
          ) {
            this.moniteIdMissing = true;
          }
        }
      );
  }

  uploadInvoice() {
    this.isUploading = true;
    const formData = new FormData();
    this.selectedFile && formData.append('file', this.selectedFile);
    this.apiHttpService
      .post(this.invoicesService.uploadInvoiceRoute(this.vehicleId), formData)
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.getTransactions();
          this.isUploading = false;
        },
        error => {
          console.log({ error });
          this.isUploading = false;
        }
      );
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.uploadInvoice();
  }

  openFileDialog() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  convertDateFormat(dateString: string) {
    let date = new Date(dateString);

    let monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    let paddedDay = day.toString().padStart(2, '0');

    return `${paddedDay}. ${month} ${year}`;
  }

  currencyFormater(amount: number) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  }
}
