<div *ngIf="(portfolioService.portfolioDetailsObservable$| async)">
    <div class="add-company-conatiner">
        <div class="add-comapny-header">
            <div class="heading">
                {{isEdit ? 'Edit a company' : 'Add a company'}}
            </div>
            <img src="../../../assets/images/cross_icon_grey.svg" alt="close" height="24px" width="24px"
                (click)="closePopup()">
        </div>
        <form [formGroup]="addPortfolioForm">
            <div class="add-comapny-form">
                <div class="form-field">
                    <div class="lable">Name *</div>
                    <input type="text" class="title-field" placeholder="Enter a name" autocomplete="off"
                        formControlName="name" [ngClass]="{'has-error' : name?.touched && !name.valid}">
                    <span *ngIf="name?.touched && !name.valid">
                        <img src="../../../assets/images/warning_icon.svg" height="20px" width="20px" alt="warning_icon"
                            class="input-error-icon">
                        <div class="error-message">Please enter Name</div>
                    </span>
                </div>
                <div class="form-field">
                    <div class="lable">In portfolio since *</div>
                    <mat-form-field [ngClass]="{'has-error' : inPortfolioSince?.touched && !inPortfolioSince.valid}">
                        <input matInput [matDatepicker]="picker" placeholder="Select a date" (click)="picker.open()"
                            formControlName="inPortfolioSince" 
                            >
                            <mat-datepicker-toggle matSuffix [for]="picker" class="custom-datepicker-toggle">
                                <img mat-icon-button class="date-icon" src="../../../assets/images/date_icon.svg" 
                                     alt="Calendar icon" width="16px" height="16px" (click)="picker.open()" />
                            </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <span *ngIf="inPortfolioSince?.touched && !inPortfolioSince.valid">
                        <!-- <img src="../../../assets/images/warning_icon.svg" height="20px" width="20px" alt="warning_icon"
                            class="input-error-icon"> -->
                        <div class="error-message">Please select In portfolio since</div>
                    </span>
                </div>
                <div class="form-field">
                    <div class="lable">Upload company logo *</div>
                    <div class="company-logo-container"
                        [ngClass]="{'container-error' : (fileDimensionError || fileValidationError || isFileSizeExceed)}">
                        <div class="file-upload-show" (click)="triggerFileInput()">
                            <ng-container *ngIf="base64Image">
                                <img src="{{base64Image}}" alt="plus_icon" height="130px" width="261px" />
                            </ng-container>
                            <ng-container *ngIf="!base64Image">
                                <img src="assets/images/plus_icon.svg" height="20px" width="20px" alt="plus_icon" />
                            </ng-container>
                        </div>
                        <div class="upload-section">
                            <div class="show-msg">
                                Min. 200x200px, .PNG or .JPEG
                            </div>
                            <span class="upload-btn" [ngClass]="{'replace-image-btn' : base64Image}" (click)="triggerFileInput()">{{(base64Image ) ? 'Replace image' :
                                'Upload image'}}</span>
                            <input #fileInput type="file" accept=".png, .jpeg, .jpg"
                                (change)="onFileSelected($event, fileInput)" />
                        </div>
                    </div>
                    <span *ngIf="fileValidationError">
                        <div class="error-message">Invalid file format. Please upload a .png, .jpeg, or .jpg file.</div>
                    </span>
                    <span *ngIf="fileDimensionError">
                        <div class="error-message">Image must be at least 200x200 pixels.</div>
                    </span>
                    <span *ngIf="isFileSizeExceed">
                        <div class="error-message">File size exceeds 10MB limit. Please upload a smaller file.</div>
                    </span>
                </div>
                <div class="cta">
                    <button class="cancel" type="button"
                        (click)="closePopup()">Cancel</button>
                    <button class="submit" type="button"
                        [disabled]="!addPortfolioForm.valid || !base64Image || submitted || addPortfolioForm.pristine"
                        (click)="submitAddPortfolio()">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>