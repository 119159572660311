import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { InvoiceDetailService } from "./invoice-detail.service";
import { BehaviorSubject, catchError, finalize, forkJoin, Observable, of, Subject, Subscription, switchMap, takeUntil, tap } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { CommonService } from "src/app/shared/services/common.service";
import { MAT_DATE_FORMATS } from "@angular/material/core";

interface LineItem {
    id: string;
    description: string;
    quantity: number;
    unit_price: number;
    amount: number;
    tax_rate?: number;
    tax_amount?: number;
    total_amount: number;
}

export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD MMM, YYYY',
    },
    display: {
      dateInput: 'DD MMM, YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  

@Component({
    selector: 'app-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss'],
    providers: [{provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}],
})
export class InvoiceDetailComponent implements OnDestroy, OnInit {

    private destroy$: Subject<void> = new Subject<void>();
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private editModeSubject = new BehaviorSubject<boolean>(false);
    private mainLoader = new BehaviorSubject<boolean>(false);
    private isItemsDirtySubject = new BehaviorSubject<boolean>(false);
    private isInvoceDirtySubject = new BehaviorSubject<boolean>(false);
    private showQrCodeSubject = new BehaviorSubject<boolean>(false);

    showQrCode$ = this.showQrCodeSubject.asObservable();
    mainLoader$ = this.mainLoader.asObservable();
    loading$ = this.loadingSubject.asObservable();
    editMode$ = this.editModeSubject.asObservable();
    error: string | null = null;


    vehicleId: string;
    entityId: string;
    selectedInvoice: any | null = null;
    counterPartyName: any = "";
    lineItems: any = [];
    editInvoiceDetails: any = {};
    editInvoiceLineItems: any = {};
    lineItemsColumns: string[] = ["Item name", "Quantity", "Price", "Total", "Tax"];
    lineItemDataSource: MatTableDataSource<LineItem>;
    counterpartsList: any[] = [];
    counterpartAddressMap: any = {};
    selectedCounterpart: any = null;
    counterpartBankAccounts: any[] = [];
    selectedBankAccount: any = null;
    selectedBankAccountName: any = '';
    qrCodeData: any = null;
    constructor(
        private invoiceDetailService: InvoiceDetailService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private commonService: CommonService,
    ) {
        this.lineItemDataSource = new MatTableDataSource<LineItem>([]);
    }
    ngOnDestroy(): void {
        this.commonService.onPopState();
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {

        this.activatedRoute.params.pipe(takeUntil(this.destroy$), tap(params => {
            const urlSegments = this.router.url.split('/');
            urlSegments.forEach((segment, index) => {
                if (segment === 'vehicle' && urlSegments[index + 1]) {
                    this.vehicleId = urlSegments[index + 1];
                }
                if (segment === 'gp' && urlSegments[index + 1]) {
                    this.entityId = urlSegments[index + 1];
                }
            });
            console.info('VehicleId:', this.vehicleId, 'EntityId:', this.entityId);
            if (this.vehicleId && this.entityId) {
                this.initializeData();
            }
        })).subscribe()

    }

    private initializeData(): void {
        if (!this.vehicleId || !this.entityId) {
            console.warn('VehicleId or EntityId is missing');
            return;
        }

        this.loadingSubject.next(true);
        this.invoiceDetailService.invoiceDetailsAsObservables$.pipe(
            takeUntil(this.destroy$),
            switchMap(invoiceDetails => {
                if (!invoiceDetails) {
                    this.error = 'No invoice details found';
                    return of(null);
                }

                this.selectedInvoice = invoiceDetails;
                console.log('Selected Invoice:', this.selectedInvoice);
                //prepare api calls

                const apiCalls: { lineItems: Observable<any>, counterPart?: Observable<any>, bankAccounts: Observable<any> } = {
                    lineItems: this.invoiceDetailService.getPayableLineItems(this.vehicleId, this.selectedInvoice.id).pipe(catchError(() => of(null))),
                    bankAccounts: this.invoiceDetailService.getAllBankAccountsForCounterPart(this.vehicleId, invoiceDetails.counterpart_id).pipe(catchError(() => of(null))),
                }
                //Add counterpart if raw data is not available
                if (invoiceDetails.counterpart_raw_data) {
                    this.counterPartyName = invoiceDetails.counterpart_raw_data.name;
                }
                else if (invoiceDetails.counterpart_id) {
                    apiCalls['counterPart'] = this.invoiceDetailService.getCounterPart(this.vehicleId, invoiceDetails.counterpart_id).pipe(catchError(() => of(null)));
                }

                return forkJoin(apiCalls);

            }),
            catchError((error) => {
                this.error = 'Failed to load invoice details. Please try again.';
                console.error('Error loading invoice details:', error);
                return of(null);
            }),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe((results) => {
            if (!results) return;

            if (results.lineItems && !results.lineItems.error_code) {
                this.lineItems = results.lineItems.data;
                this.lineItemDataSource.data = this.lineItems.data;
            }

            if (results.counterPart && !results.counterPart.error_code && !this.counterPartyName) {
                this.counterPartyName = results.counterPart.organization.legal_name;
            }

            if(results.bankAccounts && !results.bankAccounts.error_code) {
                this.counterpartBankAccounts = results.bankAccounts.data.data.map((account: any) => ({
                    id: account.id,
                    name: account.name,
                    iban: account.iban,
                    bic: account.bic
                }));


                const bankAccount = this.selectedBankAccountName = this.counterpartBankAccounts.find((account: any) => account.id === this.selectedInvoice.counterpart_bank_account_id);

                if(bankAccount) {
                    this.selectedBankAccountName =  bankAccount.iban;
                }
                
            }

            this.loadingSubject.next(false);
        })
    }

    closeInvoiceDetailDrawer(): void {
        this.invoiceDetailService.closeInvoiceDetailDrawer();
    }

    closeQrCode(): void {
        this.showQrCodeSubject.next(false);
        this.qrCodeData = null;
    }
    markAsPaid(): void {
        this.mainLoader.next(true);
        this.showQrCodeSubject.next(false);
        this.qrCodeData = null;
        this.invoiceDetailService.updatePayableStatusToPaid(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap((response) => {
                const invoiceResponse =JSON.parse(JSON.stringify(response));
                this.invoiceDetailService.invoiceDetails.next(invoiceResponse.data);
                this.selectedInvoice.status = 'paid';
                this.mainLoader.next(false);
                this.commonService.successNotification('Invoice marked as paid successfully');
            }),
            catchError((error) => {
                console.error('Error marking invoice as paid:', error);
                this.commonService.errorNotification('Failed to mark invoice as paid. Please try again.');
                this.mainLoader.next(false);
                return of(null);
            })
        ).subscribe(() => {
            this.selectedInvoice = { ...this.selectedInvoice, ...this.editInvoiceDetails };
        });
    }

    onItemChange(index: number): void {

        const subtotal = this.editInvoiceLineItems.reduce((acc: number, item: any) => acc + (item.unit_price * item.quantity), 0);
        const tax = this.editInvoiceLineItems.reduce((acc: number, item: any) => acc + ((item.unit_price * item.quantity) * (item.tax || 0) / 100), 0);
        const total = subtotal + tax;
        this.editInvoiceDetails.subtotal = subtotal * 100;
        this.editInvoiceDetails.tax_amount = tax * 100;
        this.editInvoiceDetails.total_amount = total * 100;
    }

    onSaveInvoice(): void {
        this.mainLoader.next(true);
        this.isInvoceDirtySubject.next(false);
        this.isItemsDirtySubject.next(false);
        this.editInvoiceDetails.counterpart_id = this.selectedCounterpart;
        this.editInvoiceDetails.counterpart_bank_account_id = this.selectedBankAccount;
        this.editInvoiceDetails.counterpart_address_id = this.counterpartAddressMap[this.selectedCounterpart]?.addressId;
        this.invoiceDetailService.updateInvoiceDetails(this.vehicleId, this.selectedInvoice.id, {
            ...this.editInvoiceDetails,
            line_items: this.editInvoiceLineItems.map((item: any) => ({
                ...item,
                unit_price: item.unit_price * 100,
                tax: item.tax * 100,
            })),
        }).pipe(
            takeUntil(this.destroy$),
            tap(() => {
                this.commonService.successNotification('Invoice updated successfully');
                this.mainLoader.next(false);
                this.counterPartyName = this.counterpartAddressMap[this.selectedCounterpart]?.name;
                this.selectedBankAccountName = this.counterpartBankAccounts.find((account: any) => account.id === this.selectedBankAccount)?.iban;
                this.editModeSubject.next(false);
                this.isInvoceDirtySubject.next(false);
                this.isItemsDirtySubject.next(false);
                this.selectedInvoice = { ...this.selectedInvoice, ...this.editInvoiceDetails };
                this.selectedInvoice.line_items = this.editInvoiceLineItems.map((item: any) => ({
                    ...item,
                    unit_price: item.unit_price * 100,
                    total: item.unit_price * item.quantity * 100,
                    tax: item.tax * 100,
                }));
                this.lineItemDataSource.data = this.selectedInvoice.line_items;
                this.lineItems = this.selectedInvoice.line_items;
                this.lineItemDataSource._updateChangeSubscription();
            }),
            catchError((error) => {
                console.error('Error updating invoice:', error);    
                this.commonService.errorNotification('Failed to update invoice. Please try again.');
                this.mainLoader.next(false);
                this.isInvoceDirtySubject.next(false);
                this.isItemsDirtySubject.next(false);
                return of(null);
            })
        ).subscribe(() => {
            this.selectedInvoice = { ...this.selectedInvoice, ...this.editInvoiceDetails };
        });
    }

    removeItem(index: number): void {
        this.editInvoiceLineItems.splice(index, 1);
        this.onItemChange(index);
    }

    onAddItem(): void {
        this.editInvoiceLineItems.push({
            name: '',
            quantity: 1,
            unit_price: 0,
            tax: 0
        });
        this.editInvoiceLineItems = [...this.editInvoiceLineItems];
    }

    toggleEditMode() {
        this.editModeSubject.next(!this.editModeSubject.value);
        if (this.editModeSubject.value) {
            this.editInvoiceDetails = { ...this.selectedInvoice };
            this.editInvoiceLineItems = this.lineItems.data.map((item: any) => ({
                ...item,
                unit_price: item.unit_price / 100,
                tax: item.tax / 100,
            }));
            this.selectedCounterpart = this.editInvoiceDetails.counterpart_id;
            this.selectedBankAccount = this.editInvoiceDetails.counterpart_bank_account_id;
            this.loadingSubject.next(true);
            this.invoiceDetailService.getAllCounterParts(this.vehicleId).pipe(
                takeUntil(this.destroy$),
                tap((counterParts: any) => {
                    if (counterParts && counterParts.data) {
                        this.counterpartsList = counterParts.data.map((counterPart: any) => {

                            this.counterpartAddressMap[counterPart.id] = {
                                addressId:counterPart.default_billing_address_id,
                                name: counterPart.organization?.legal_name ?? `${counterPart.individual?.first_name} ${counterPart.individual?.last_name}`,
                            }
                            return {
                                value: counterPart.id,
                                label: counterPart.organization?.legal_name ?? `${counterPart.individual?.first_name} ${counterPart.individual?.last_name}`,
                                selected: this.selectedInvoice.counterpart_id === counterPart.id,
                            }
                        });
                    }
                }
                ),
                catchError((error) => {
                    console.error('Error fetching counterparts:', error);
                    this.commonService.errorNotification('Failed to fetch counterparts. Please try again.');
                    this.loadingSubject.next(false);
                    return of(null);
                }
                )
            ).subscribe(
                () => {
                    this.loadingSubject.next(false);
                    this.selectedInvoice = { ...this.selectedInvoice, ...this.editInvoiceDetails };
                }
            );
            
        }
        else {
            this.editInvoiceDetails = {};
            this.editInvoiceLineItems = {};
        }

        
    }

    handleApproveInProgress(): void {
        if(this.selectedInvoice.counterpart && this.selectedInvoice.counterpart.bank_account 
            && this.selectedInvoice.counterpart.bank_account.iban === null) {
            this.commonService.errorNotification('Please select a bank account before approving the invoice.');
            return;
        }
        if(this.selectedInvoice.counterpart_bank_account_id === null ||
         this.selectedInvoice.counterpart_bank_account_id === undefined) {

            this.commonService.errorNotification('Please select a bank account before approving the invoice.');
            return;
        }
        this.mainLoader.next(true);

        this.invoiceDetailService.updatePayableStatusToApproveInProgress(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap(() => { this.selectedInvoice.status = 'approve_in_progress'; this.mainLoader.next(false); }),
            catchError((error) => {
                console.error('Error approving invoice:', error);
                this.commonService.errorNotification('Failed to approve invoice. Please try again.');
                this.mainLoader.next(false);
                return of(null);
            })
        ).subscribe();
    }

    handleCancelInvoice(): void {
        this.mainLoader.next(true);
        this.invoiceDetailService.updatePyableStatusToCancelled(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap(() => { this.selectedInvoice.status = 'canceled'; this.mainLoader.next(false); }),
            catchError((error) => {
                console.error('Error cancelling invoice:', error);
                this.commonService.errorNotification('Failed to cancel invoice. Please try again.');
                this.mainLoader.next(false);
                return of(null);
            })
        ).subscribe();
    }

    onCounterpartyChange(value: any): void {
        
        this.loadingSubject.next(true);
        this.invoiceDetailService.getAllBankAccountsForCounterPart(this.vehicleId, value).pipe(
            takeUntil(this.destroy$),
            tap((bankAccounts: any) => {
                if (bankAccounts && bankAccounts.data) {
                    this.counterpartBankAccounts = bankAccounts.data.data.map((account: any) => ({
                        id: account.id,
                        name: account.name,
                        iban: account.iban,
                        bic: account.bic
                    }));
                }

                this.loadingSubject.next(false);
            }
            ),
            catchError((error) => {
                console.error('Error fetching bank accounts:', error);
                this.commonService.errorNotification('Failed to fetch bank accounts. Please try again.');
                this.loadingSubject.next(false);
                return of(null);
                
            }
            )
        ).subscribe(() => {
            this.loadingSubject.next(false);
        }
        );
    }


    handlePayInvoice(): void {
        this.mainLoader.next(true);
        this.invoiceDetailService.generatePaymentCode(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap((response: any) => {
                if (response && response.data) {
                    console.log('QR code generated:', response);
                    this.qrCodeData = response.data;
                    this.showQrCodeSubject.next(true);
                }
                this.mainLoader.next(false);
            }
            ),
            catchError((error) => {
                console.error('Error generating QR code:', error);
                this.commonService.errorNotification(error.error.message || 'Failed to generate QR code. Please try again.');
       
                this.mainLoader.next(false);
                return of(null);
            }
            )
        ).subscribe(()=>{
            this.mainLoader.next(false);
        });
    }

    handleReopenInvoice(): void {
        this.mainLoader.next(true);
        this.invoiceDetailService.updatePayableStatusToReopened(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap(() => { this.selectedInvoice.status = 'new'; this.mainLoader.next(false); }),
            catchError((error) => {
                console.error('Error reopening invoice:', error);
                this.commonService.errorNotification('Failed to reopen invoice. Please try again.');
                this.mainLoader.next(false);
                return of(null);
            })
        ).subscribe();
    }

    handleRejectInvoice(): void {
        this.mainLoader.next(true);
        this.invoiceDetailService.updatePayableStatusToRejected(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap(() => {
                this.selectedInvoice.status = 'rejected';
                this.mainLoader.next(false);
            }),
            catchError((error) => {
                console.error('Error rejecting invoice:', error);
                this.commonService.errorNotification('Failed to reject invoice. Please try again.');
                this.mainLoader.next(false);
                return of(null);
            })
        ).subscribe();
    }

    handleApproveInvoice(): void {
        this.mainLoader.next(true);
        this.invoiceDetailService.updatePayableStatusToApproved(this.vehicleId, this.selectedInvoice.id).pipe(
            takeUntil(this.destroy$),
            tap(() => {
                this.selectedInvoice.status = 'waiting_to_be_paid';
                this.mainLoader.next(false);
            }),
            catchError((error) => {
                console.error('Error approving invoice:', error);
                this.commonService.errorNotification('Failed to approve invoice. Please try again.');
                this.mainLoader.next(false);
                return of(null);
            })
        ).subscribe();
    }
}