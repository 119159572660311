<div class="delete-portfolio-company">
    <div class="content">
        <div class="heading">You have unsaved changes
            <button (click)="dialogRef.close()" [disabled]="loading">
                <img src="../../../assets/images/cross_icon_grey.svg" alt="close">
            </button>
        </div>
        <div class="form-field">
            <div class="description">Do you want to discard or save the changes?</div>
        </div>
    </div>
    <div class="action">
        <button mat-flat-button class="cancel" (click)="discard()">Discard</button>
        <button mat-flat-button class="save" (click)="saveDraft()" [disabled]="loading">Save</button>
    </div>
</div>