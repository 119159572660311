<div class="portfolio-company-container">
    <input type="hidden" id="valueChanged" value="false" #changed>
    <ng-container *ngIf="companies?.length !=0; else noPortfolioCompanies">
        <div class="company-content">
            <div class="left-panel">
                <div class="companies-header">
                    <ng-container *ngIf="!isLoading; else companyHeaderLoader">
                        {{companies?.length}} {{companies?.length ===1 ? 'company' : 'companies'}}
                        <button (click)="addPortfolioCompanyHandler()">
                            <img src="../../../assets/images/plus_icon.svg" alt="add" height="20px" width="20px">
                        </button>
                    </ng-container>
                </div>
                <div class="company-list">
                    <ng-container *ngIf="!isLoading; else companyListLoader">
                        <div *ngFor="let company of companies" class="company-item"
                            [ngClass]="{ 'active': selectedCompany?.id === company.id, 'action-open': menuRef.menuOpen }"
                            (click)="company.id !== selectedCompany?.id && portfolioCompanyChangeHandler(company)">
                            <img *ngIf="selectedCompany?.id !== company.id" src="../../../assets/images/pc_icon.svg"
                                alt="icon">
                            <img *ngIf="selectedCompany?.id === company.id"
                                src="../../../assets/images/pc_icon_active.svg" alt="icon">
                            <span class="company-name">
                                {{company.name}}
                            </span>
                            <button class="more-btn" [matMenuTriggerFor]="menu" #menuRef="matMenuTrigger"
                                (click)="$event.stopPropagation();">
                                <img src="../../../assets/images/more_icon.svg" alt="more">
                            </button>
                            <mat-menu #menu="matMenu" xPosition="before" class="more-menu" action-position>
                                <div mat-menu-item class="rename-btn" (click)="openRenamePortfolioCompany(company)">
                                    <img src="../../../assets/images/edit_icon.svg" alt="delete_icon" height="20px"
                                        width="20px">
                                    Rename
                                </div>
                                <div mat-menu-item class="delete-btn" (click)="openDeletePortfolioCompany(company)">
                                    <img src="../../../assets/images/delete_icon.svg" alt="delete_icon" height="20px"
                                        width="20px">
                                    Delete
                                </div>
                            </mat-menu>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="right-panel">
                <ng-container *ngIf="selectedCompany?.availableQuarterlyData?.length !=0; else noReportingPeriod">
                    <div class="tool-bar">
                        <ng-container *ngIf="(!isLoading && !isQuarterLoading); else toolBarLoader">
                            <div class="reporting-period">
                                <span class="lable">Reporting period</span>
                                <mat-form-field appearance="fill" class="quarter-field">
                                    <mat-select [(value)]="selectedQuarter" [position]="'right'"
                                        (selectionChange)="quarterChangeHandler($event)" #select>
                                        <mat-option *ngFor="let quarter of selectedCompany?.availableQuarterlyData"
                                            [value]="quarter" class="quarter-option">
                                            <div class="option-text">
                                                <img class="default-icon"
                                                    src="../../../assets/images/documents_icon.svg" alt="report-icon"
                                                    height="20px" width="20px">
                                                <img class="active-icon"
                                                    src="../../../assets/images/documents_icon_active.svg"
                                                    alt="report-icon" height="20px" width="20px">
                                                {{ quarter.year + ' Q' + quarter.quarter }}
                                            </div>
                                        </mat-option>
                                        <div *ngIf="!hideAddBtn" (click)="addReportingPeriodHandler(); select.close()"
                                            class="add-quarter-btn quarter-option">
                                            <img src="../../../assets/images/plus_icon.svg" alt="add-icon" height="20px"
                                                width="20px">
                                            Add
                                        </div>
                                    </mat-select>
                                    <img class="arrow-down" [ngClass]="{ 'open': select.panelOpen}"
                                        src="../../assets/images/arrow-down.svg" height="20px" width="20px" matSuffix />
                                </mat-form-field>
                            </div>
                            <div class="cta-section">
                                <button
                                    *ngIf="this.quarterData?.status === 'draft' && !((breakPointService.isTablet$ | async) || (breakPointService.isHandset$ | async))"
                                    class="save" mat-button (click)="saveReportData()"
                                    [disabled]="isSavingInProgress">Save draft
                                </button>
                                <button
                                    *ngIf="this.quarterData?.status === 'final' && !((breakPointService.isTablet$ | async) || (breakPointService.isHandset$ | async))"
                                    class="edit" mat-button (click)="editReportData()"
                                    [disabled]="isEditInProgress">Edit
                                    report</button>
                                <button class="cta-small"
                                    *ngIf="this.quarterData?.status === 'draft' && ((breakPointService.isTablet$ | async) || (breakPointService.isHandset$ | async))"
                                    (click)="saveReportData()"
                                    [disabled]="isSavingInProgress"><mat-icon>save</mat-icon></button>
                                <button class="cta-small"
                                    *ngIf="this.quarterData?.status === 'final' && ((breakPointService.isTablet$ | async) || (breakPointService.isHandset$ | async))"
                                    (click)="editReportData()"
                                    [disabled]="isEditInProgress"><mat-icon>edit</mat-icon></button>
                                <button *ngIf="this.quarterData?.status === 'draft'" class="submit" mat-button
                                    (click)="submitReportData()"
                                    [disabled]="this.quarterData?.status !== 'draft' || isSubmittingInProgress">Submit</button>
                                <button *ngIf="this.quarterData?.status === 'final'" class="submitted"><img
                                        src="../../../assets/images/check_icon.svg" alt="icon" height="16px"
                                        width="16px">Submitted</button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="form-section">
                        <ng-container *ngIf="(!isLoading && !isQuarterLoading); else reportLoader">
                            <div class="form-container">
                                <survey [model]="model" style="font-family: Inter"></survey>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #noPortfolioCompanies>
    <div class="wrapper" *ngIf="companies">
        <div class="no-portfolio-companies">
            <div class="icon">
                <img src="../../../assets/images/pc_icon.svg" alt="">
            </div>
            <div class="text">
                <div class="heading">No companies added...</div>
                <div class="support-text">Quickly add a portfolio company</div>
            </div>
            <div class="action">
                <button mat-flat-button (click)="openAddPortfolioCompany()"><mat-icon>add</mat-icon>Add portfolio
                    company</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #noReportingPeriod>
    <div class="wrapper">
        <div class="no-reporting-period">
            <div class="icon">
                <img src="../../../assets/images/calendar_icon.svg" alt="">
            </div>
            <div class="text">
                <div class="heading">No reporting period added...</div>
                <div class="support-text">Add a new quarter and start reporting</div>
            </div>
            <div class="action">
                <button mat-flat-button (click)="openAddReportingPeriod()"><mat-icon>add</mat-icon>Add reporting
                    period</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #companyHeaderLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '220px',
            height: '34px',
            'margin-top': '10px'
          }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '34px',
            height: '34px',
            'margin-top': '10px'
          }"></ngx-skeleton-loader>
</ng-template>

<ng-template #toolBarLoader>
    <div style="display: flex; width: 100%; justify-content: space-between;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '250px',
            height: '34px',
            'margin-top': '10px'
          }"></ngx-skeleton-loader>
        <div>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '140px',
            height: '34px',
            'margin-top': '10px',
            'margin-right': '16px'

          }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '140px',
            height: '34px',
            'margin-top': '10px'
          }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>

<ng-template #reportLoader>
    <div style="padding-top: 8px; border-bottom: 1px solid var(--border-color);">
        <ngx-skeleton-loader *ngFor="let number of [0, 1, 2, 3]" count="1" appearance="line" [theme]="{
            width: '110px',
            height: '34px',
            'margin-right': '16px',
            'margin-top': '10px'
          }"></ngx-skeleton-loader>
    </div>
    <div style="padding: 0 24px;">
        <div *ngFor="let number of [0, 1, 2, 3, 4]">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                          width: '100%',
                          height: '76px',
                          margin: 0,
            'margin-top': '20px'
                        }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>

<ng-template #companyListLoader>
    <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8]">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      width: '312px',
                      height: '34px',
                      margin: '10px 0'
                    }"></ngx-skeleton-loader>
    </div>
</ng-template>