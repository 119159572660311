<div class="counterparts-container" [hidden]="counterpartId">
    <ng-container *ngIf="!noCounterparts; else noDataTemplate">
        <div class="counterparts-subheader">
            <div class="left-section">
                <ng-container *ngIf="!isLoading; else searchLoader">
                    <mat-form-field class="counterparts-search-fieldd">
                        <img src="../../assets/images/search.svg" alt="search" matPrefix />
                        <input matInput placeholder="Search by name" [(ngModel)]="search"
                            (keyup)="isPageLoading=true; searchHandler()" (keyup)="handleBackspaceClear($event)"
                            autocomplete="off">
                        <div matIconSuffix class="clear-date" *ngIf="search">
                            <button class="btn-clear" (click)="clearFilter('search');search = ''">
                                <img src="../../../../assets/images/cross_icon_grey.svg" height="19px" width="19px"
                                    alt="add" />
                            </button>
                        </div>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!isLoading; else selectLoader">
                    <mat-form-field appearance="fill" class="category-mat-select">
                        <mat-select placeholder="All" [position]="'left'" [(value)]="category"
                            (selectionChange)="categoryFilter($event.value)" #selectCategory>
                            <mat-select-trigger>
                                <div class="category-item category-placeholder">
                                    {{category ? (category === 'individual'? 'Private individual' : 'Legal entity' ) : 'All'}}
                                </div>
                            </mat-select-trigger>
                            <mat-option [ngClass]="{'value-not-selected' : !category}"
                                class="category-sub-item category-item" value="">All</mat-option>
                            <mat-option [ngClass]="{'value-not-selected' : !category}" class="category-sub-item"
                                value="organization">Legal entity</mat-option>
                            <mat-option [ngClass]="{'value-not-selected' : !category}"
                                class="category-sub-item category-item" value="individual">Private
                                individual</mat-option>
                        </mat-select>
                        <img class="arrow-down" [ngClass]="{ 'open': selectCategory.panelOpen}"
                            src="../../assets/images/arrow-down.svg" matSuffix alt=" " />
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="right-section">
                <div class="cta">
                    <button class="sync" *ngIf="!isLoading; else ctaBtnLoader" [matTooltip]="'create counterparty'"
                        (click)="createCounterpart()">
                        <ng-container *ngIf="!isSyncInProgress; else spinner">
                            <img src="assets/images/plus_icon_white.svg" /> Create new
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
        <div class="counterparts-section" #tableSection>
            <div class="counterparts-table">
                <table [hidden]="isPageLoading" mat-table [dataSource]="dataSource" matSort
                    matSortActive="counterpart_name" matSortDisableClear [matSortDirection]="currentFilterOrder"
                    (matSortChange)="sortChange()">
                    <ng-container matColumnDef="counterpart_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Name </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="avatar-container">
                                <div class="avatar">
                                    {{ getInitials(element.individual ? (element.individual.first_name)
                                    : element.organization ? element.organization.legal_name
                                    : '-') }}
                                </div>
                                <div class="details">
                                    <span class="name">
                                        {{ element.individual ? (element.individual.first_name + ' ' +
                                        element.individual.last_name)
                                        : element.organization ? element.organization.legal_name
                                        : '-' }}
                                    </span>
                                    <!-- <span class="location">{{ element.language }}</span> -->

                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef> Category </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.individual ? 'Private individual' : (element.organization ? 'Legal entity' : '')
                            }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="contactInformation">
                        <th mat-header-cell *matHeaderCellDef> Contact Information </th>
                        <td mat-cell *matCellDef="let element">
                            <p class="contact" *ngIf="element.individual?.email || element.organization?.email">
                                <img src="../../../../assets/images/mail_icon.svg" alt="Mail Icon">
                                {{ element.individual ? (element.individual.email)
                                : element.organization ? element.organization.email
                                : '' }}
                            </p>
                            <p class="contact" *ngIf="element.individual?.phone || element.organization?.phone">
                                <img class="phone-icon" *ngIf="element.individual?.phone || element.organization?.phone"
                                    src="../../../../assets/images/phone_icon.svg" alt="Mail Icon">
                                {{
                                element.individual ? (element.individual.phone)
                                : element.organization ? element.organization.phone
                                : ''
                                }}
                            </p>
                            <!-- <p *ngIf="!(element.individual?.email || element.organization?.email) && !(element.individual?.phone || element.organization?.phone)" class="add-contact">
                            <button class="add-contact" mat-button>+ Add Contact</button>
                        </p> -->
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="more">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <img [matMenuTriggerFor]="CPMenu" class="more_img"
                                src="../../../assets/images/more_icon.svg" alt="more"
                                (click)="$event.stopPropagation();">
                            <mat-menu class="counterpart-mat-menu" #CPMenu="matMenu" xPosition="before"
                                counterpart-detail-action-position>
                                <div mat-menu-item (click)="deleteCounterpart(element); $event.preventDefault()">
                                    <img src="../../../../assets/images/delete_icon.svg" height="20px" width="20px" />
                                    Delete
                                </div>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <tr style="width: 100% !important;" mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showCounterpartDetail(row)">
                    </tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell no-row" colspan="6" style="padding:1rem;">
                            <div class="no-counterpart-found-container">
                                <div class="no-counterparts">
                                    <img src="../../../../assets/images/Vector.svg" alt="no-invoice">
                                </div>
                                <div class="text-section">
                                    <div class="heading">No counterparties found</div>
                                    <div class="supporting-text">It looks like there are no counterparties available at
                                        the moment. Please check again
                                        later.</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <table *ngIf="isLoading || isPageLoading || !updatePageSize" mat-table [dataSource]="[1, 2, 3, 4, 5]"
                    class="loading-table">
                    <ng-container matColumnDef="counterpart_name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '300px',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef> Category </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '100px',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="contactInformation">
                        <th mat-header-cell *matHeaderCellDef> Contact Information</th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '90%',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="more">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator [disabled]="isPageLoading" [hidden]="isLoading || !updatePageSize" #paginator
                [pageSize]="pageSize" [length]="totalRecords" (page)="onPageChange($event)"
                class="counterpart-pagination"></mat-paginator>
        </div>
    </ng-container>

    <ng-template #noDataTemplate>
        <div class="no-counterpart-wrapper">
            <div class="no-counterpart-container">
                <div class="no-counterparts">
                    <img src="../../../../assets/images/Vector.svg" alt="icon">
                </div>
                <div class="text-area">
                    <div class="main">No counterparties found</div>
                    <div class="sub-text">It looks like there are no counterparties available at the moment. Please
                        create a new counterparty by clicking below.
                    </div>
                </div>
                <div class="cta">
                    <button (click)="createCounterpart()" class="sync" *ngIf="!isLoading; else ctaBtnLoader"
                        [matTooltip]="'create counterparty'">
                        <ng-container *ngIf="!isSyncInProgress; else spinner">
                            <img src="assets/images/plus_icon_white.svg" /> Create new
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '278px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #selectLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '172px',
            height: '38px',
            marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #ctaBtnLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '38px',
                height: '38px',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>