<div class="bank-transactions-container" *ngIf="!transactionId">
    <ng-container *ngIf="!noTransactions; else noDataTemplate">
        <div class="transactions-subheader">
            <div class="left-section">
                <ng-container *ngIf="!isLoading; else searchLoader">
                    <mat-form-field class="transactions-search-field">
                        <img src="../../assets/images/search.svg" alt="search" matPrefix />
                        <input matInput placeholder="Search" (input)="applyFilter()" autocomplete="off"
                            [(ngModel)]="search" autocomplete="off">
                        <button class="clear-btn" matSuffix *ngIf="search" matSuffix (click)="search=''; applyFilter()">
                            <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px"
                                alt="clear">
                        </button>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="!isLoading; else filterClosing">
                    <mat-form-field appearance="fill" class="account-mat-select">
                        <mat-select placeholder="Bank account" [position]="'left'" [(value)]="bankAccount" #select>
                            <mat-select-trigger>
                                <div class="account-placeholder">
                                    {{bankAccount ? bankAccount.accountNo : 'Bank account'}}
                                </div>
                            </mat-select-trigger>
                            <ng-container *ngFor="let account of accountList">
                                <mat-option class="account-item" [value]="account"
                                    [ngClass]="{'value-not-selected' : !bankAccount}">
                                    <div class="account-option">
                                        <div class="account-number">{{account.accountNo}}</div>
                                        <div class="bank">{{account.bank}}</div>
                                    </div>
                                </mat-option>
                            </ng-container>
                            <div class="dropdown-clear-btn" *ngIf="bankAccount">
                                <button type="button" class="btn-clear"
                                    (click)="this.bankAccount = null; select.close();applyFilter()">Clear</button>
                            </div>
                        </mat-select>
                        <img class="arrow-down" [ngClass]="{ 'open': select.panelOpen}"
                            src="../../assets/images/arrow-down.svg" matSuffix />
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="right-section">
                <!-- <span class="account-id">{{this.accountNumber}}</span> -->
                <div class="cta" *ngIf="false">
                    <button class="sync" *ngIf="!isLoading; else ctaBtnLoader" (click)="syncTransactions()">
                        <img src="../../../../assets/images/plus_icon_white.svg" height="20px" width="20px" alt="add" />
                        Sync Transactions
                    </button>
                </div>
            </div>
        </div>
        <div class="transactions-section">
            <div class="transactions-table">
                <ng-container *ngIf="!isLoading">
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc"
                        matSortDisableClear>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.date ? commonService.formateUnixDate(element.date) : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="bankAccount">
                            <th mat-header-cell *matHeaderCellDef> Bank account </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.account ? element.account : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="purpose">
                            <th mat-header-cell *matHeaderCellDef> Purpose </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.purpose ? element.purpose : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef> Amount </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.amount ? element.amount : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="currency">
                            <th mat-header-cell *matHeaderCellDef> Currency </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.currency ? element.currency : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="partnerName">
                            <th mat-header-cell *matHeaderCellDef> Partner name </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.name ? element.name : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <img src="../../../../assets/images/arrow_right_icon.svg" alt="icon">
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="accountId">
                            <th mat-header-cell *matHeaderCellDef> Account ID </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.name ? element.name : '-'}}
                            </td>
                        </ng-container> -->

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></tr>

                        <!-- <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell no-row" colspan="8" style="padding:1rem;">
                                <div class="no-transaction-found-container">
                                    <img src="../../../../assets/images/invoice_empty_icon.svg" alt="no-invoice">
                                    <div class="text-section">
                                        <div class="heading">No Bank transactions found</div>
                                        <div class="supporting-text">It looks like there are no bank transactions found in
                                            linked accounts. Please check again after sometime.</div>
                                    </div>
                                </div>
                            </td>
                        </tr> -->
                    </table>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <table mat-table [dataSource]="[1, 2, 3, 4, 5]" class="loading-table">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '90%',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="bankAccount">
                            <th mat-header-cell *matHeaderCellDef> Bank account </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '250px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="purpose">
                            <th mat-header-cell *matHeaderCellDef> Purpose </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '300px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef> Amount </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '100px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="currency">
                            <th mat-header-cell *matHeaderCellDef> Currency </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '100px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="partnerName">
                            <th mat-header-cell *matHeaderCellDef> Partner name </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '90%',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                            <td mat-cell *matCellDef="let element">
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="accountId">
                            <th mat-header-cell *matHeaderCellDef> Account ID </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '90%',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container> -->

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataTemplate>
        <div class="no-transaction-wrapper">
            <div class="no-transaction-container">
                <div class="icon">
                    <img src="../../../../assets/images/invoice_empty_icon.svg" alt="icon">
                </div>
                <div class="text-area">
                    <div class="main">No Bank transactions found</div>
                    <div class="sub-text">Looks like there are no bank transactions found for now. Please check again
                        later.
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<bank-transaction-detail *ngIf="transactionId" (backClick)="hideDetails()"
    [transaction]="selecetdTransaction"></bank-transaction-detail>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #filterClosing>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '180px',
            height: '38px',
            marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #countLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '180px',
            height: '24px',
            marginBottom: 0
      }"></ngx-skeleton-loader>
</ng-template>

<ng-template #ctaBtnLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '160px',
                height: '38px',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #backBtnLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '38px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #accountIdLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '80px',
            height: '24px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>