export const menuItems = [
  {
    key: 'viewDashboard',
    value: 'Dashboard',
    path: 'dashboard',
    viewValue: 'Dashboard',
    imgURL: '../../assets/images/home_icon.svg',
    activeimgURL: '../../assets/images/home_icon_active.svg',
  },
  {
    key: 'viewManagement',
    value: 'Management',
    path: 'management',
    viewValue: 'Management',
    imgURL: '../../assets/images/dashboard_icon.svg',
    activeimgURL: '../../assets/images/dashboard_icon_active.svg',
  },
  {
    key: 'viewTransactions',
    value: 'Transactions',
    path: 'transactions',
    viewValue: 'Transactions',
    imgURL: '../../assets/images/transactions_icon.svg',
    activeimgURL: '../../assets/images/transactions_icon_active.svg',
    subMenus: [
      {
        key: 'viewInvoices',
        value: 'Invoices',
        path: 'invoices',
        viewValue: 'Invoices',
      },
      {
        key: 'viewCounterparts',
        value: 'Counterparts',
        path: 'counterparts',
        viewValue: 'Counterparts',
      },
      // {
      //   key: 'viewUsers',
      //   value: 'Users',
      //   path: 'users',
      //   viewValue: 'Users',
      // },
      // {
      //   key: 'viewApprovalPolicies',
      //   value: 'Approval Policies',
      //   path: 'approval-policies',
      //   viewValue: 'Approval Policies',
      // },
      {
        key: 'viewBankTransactions',
        value: 'Transactions',
        path: 'transactions',
        viewValue: 'Transactions',
      },
    ],
    isToggled: false,
  },
  {
    key: 'viewReports',
    value: 'Reports',
    path: 'reports',
    viewValue: 'Reports',
    imgURL: '../../assets/images/reports_icon_v1.svg',
    activeimgURL: '../../assets/images/reports_icon_active_v1.svg',
  },
  {
    key: 'viewDocuments',
    value: 'Documents',
    path: 'documents',
    viewValue: 'Documents',
    imgURL: '../../assets/images/documents_icon.svg',
    activeimgURL: '../../assets/images/documents_icon_active.svg',
  },
  // {
  //   key: 'viewPortfolioCompanies',
  //   value: 'Portfolio companies',
  //   path: 'portfolio-companies',
  //   viewValue: 'Portfolio companies',
  //   imgURL: '../../assets/images/pc_icon.svg',
  //   activeimgURL: '../../assets/images/pc_icon_active.svg',
  // },
  {
    key: 'viewPortfolioCompanies',
    value: 'Portfolio',
    path: 'portfolio',
    viewValue: 'Portfolio',
    imgURL: '../../assets/images/pc_icon.svg',
    activeimgURL: '../../assets/images/pc_icon_active.svg',
  },
  {
    key: 'viewQuarterlyData',
    value: 'Quarterly data',
    path: 'quarterly-data',
    viewValue: 'Quarterly data',
    imgURL: '../../assets/images/quarterly_data_icon.svg',
    activeimgURL: '../../assets/images/quarterly_data_icon_active.svg',
  },
  {
    key: 'viewInvestors',
    value: 'Investors',
    path: 'investors',
    viewValue: 'Investors',
    imgURL: '../../assets/images/crm_icon.svg',
    activeimgURL: '../../assets/images/crm_icon_active.svg',
  },
  // {
  //   key: 'viewOnboarding',
  //   value: 'Onboarding',
  //   path: 'onboarding',
  //   viewValue: 'Onboarding',
  //   imgURL: '../../assets/images/support_icon.svg',
  //   activeimgURL: '../../assets/images/support_icon_active.svg',
  // },
  {
    key: 'viewSettings',
    value: 'Settings',
    path: 'settings',
    viewValue: 'Settings',
    imgURL: '../../assets/images/settings_icon.svg',
    activeimgURL: '../../assets/images/settings_icon_active.svg',
    subMenus: [
      {
        key: 'viewUserManagement',
        value: 'User management',
        path: 'user-management',
        viewValue: 'User management',
      },
      {
        key: 'viewApprovalPolicies',
        value: 'Approval Policies',
        path: 'approval-policies',
        viewValue: 'Approval Policies',
      },
      {
        key: 'viewBankAccounts',
        value: 'Bank accounts',
        path: 'bank-accounts',
        viewValue: 'Bank accounts',
      },
      {
        key: 'viewFinoBankAccounts',
        value: 'Fino Bank acc',
        path: 'fino-bank-accounts',
        viewValue: 'Fino Bank acc',
      },
      {
        key: 'viewPaymentApproval',
        value: 'Payment approval',
        path: 'payment-approval',
        viewValue: 'Payment approval',
      },
      {
        key: 'viewDnsEntries',
        value: 'DNS entries',
        path: 'dns-entries',
        viewValue: 'DNS entries',
      },
      {
        key: 'viewLetterHeads',
        value: 'Letterheads',
        path: 'letterheads',
        viewValue: 'Letterheads',
      },
    ],
    isToggled: false,
  },
];
