import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-snack-bar',
  templateUrl: './sucess-snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SucessSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  dismiss() {
    this.data.preClose();
  }
}

@Component({
  selector: 'app-success-big-snack-bar',
  templateUrl: './sucess-big-snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SucessBigSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  dismiss() {
    this.data.preClose();
  }
}

@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class ErrorSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  dismiss() {
    this.data.preClose();
  }
}

@Component({
  selector: 'app-info-snack-bar',
  templateUrl: './info-snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class InfoSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  dismiss() {
    this.data.preClose();
  }
}
