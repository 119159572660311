import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiEndpointsService } from "src/app/core/services/api-endpoints.service";
import { ApiHttpService } from "src/app/core/services/api-http.service";

export interface IRouteParams {
    vehicleId: string;
    entityId: string;
}
@Injectable({
    providedIn: 'root'
})
export class InvoiceDetailService {

    public openInvoiceDetail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public invoiceDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public openQrCode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public entityUserToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    entityUserTokenAsObservables$ = this.entityUserToken.asObservable();

    qrCodeAsObservables$ = this.openQrCode.asObservable();

    invoiceDetailsAsObservables$ = this.invoiceDetails.asObservable();

    public routeParams: BehaviorSubject<IRouteParams | null> = new BehaviorSubject<IRouteParams | null>(null);

    routeParamsAsObservables$ = this.routeParams.asObservable();

    constructor(
        private apiEndpointsService: ApiEndpointsService,
        private apiHttpService: ApiHttpService
    ) { }


    public openInvoiceDetailDrawer(): void {
        this.openInvoiceDetail.next(true);
    }

    public closeInvoiceDetailDrawer(): void {
        this.openInvoiceDetail.value && this.openInvoiceDetail.next(false);
    }

    getAllBankAccountsForCounterPart(vehicleId: string, counterPartId: string) {
        return this.apiHttpService.get(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/counterparts/${counterPartId}/bank_accounts`,
                false
            )
        );
    }


    getAllCounterParts(vehicleId:string){
        return this.apiHttpService.get(this.apiEndpointsService.createUrl(
            `vehicles/${vehicleId}/counterparts`,
            false
        ));
    }

    getCounterPart(vehicleId: string, counterpartId: string) {
        return this.apiHttpService.get(
            this.apiEndpointsService.createUrl(
                `counterparts/${vehicleId}/${counterpartId}`,
                false
            )
        );
    }

    getPayableLineItems(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.get(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/line-items`,
                false
            )
        );
    }
    updateInvoiceDetails(vehicleId: string, invoiceId: string, data: any) {
        return this.apiHttpService.put(
            this.apiEndpointsService.createUrl(
                `payables/vehicle/${vehicleId}/invoice/${invoiceId}`,
                false
            ),
            data
        );
    }

    generatePaymentCode(vehicleId: string, ivoiceId: string) {
        return this.apiHttpService.get(
            this.apiEndpointsService.createUrl(
                `payments/vehicle/${vehicleId}/payable/${ivoiceId}/generatepayment`,
                false
            ),
            {}
        );
    }

    updatePayableStatusToPaid(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.post(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/mark-as-paid`,
                false
            ),
            {}
        );
    }

    updatePayableStatusToApproveInProgress(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.post(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/submit-for-approval`,
                false
            ),
            {}
        );
    }

    updatePayableStatusToReopened(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.post(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/reopen`,
                false
            ),
            {}
        );
    }


    updatePyableStatusToCancelled(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.post(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/cancel`,
                false
            ),
            {}
        );
    }

    updatePayableStatusToRejected(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.post(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/reject`,
                false
            ),
            {}
        );
    }

    updatePayableStatusToApproved(vehicleId: string, invoiceId: string) {
        return this.apiHttpService.post(
            this.apiEndpointsService.createUrl(
                `vehicles/${vehicleId}/payables/${invoiceId}/approve-payment-operation`,
                false
            ),
            {}
        );
    }
}