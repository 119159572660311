import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root',
})
export class QuarterlyDataService {
  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) {}

  getFormDefinitions(designation: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `form-definitions/${designation}`,
        false
      )
    );
  }
  getQuarterReportData(
    portfolioCompanyId: string,
    year: string,
    quarter: string
  ) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `portfolio-companies/${portfolioCompanyId}/quarterly-data/${year}/${quarter}`,
        false
      )
    );
  }
  getQuarterlyData(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/quarterly-data`,
        false
      )
    );
  }

  addQuarter(vehicleId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/quarterly-data`,
        false
      ),
      data
    );
  }

  deletequarter(quarterlyDataId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `quarterly-data/${quarterlyDataId}`,
        false
      )
    );
  }
  getQuestionnaire(quarterlyDataId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `quarterly-data/${quarterlyDataId}`,
        false
      )
    );
  }

  questionnairePreFill(quarterlyDataId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `quarterly-data/${quarterlyDataId}/pre-fill`,
        false
      ),
      data
    );
  }

  questionnairePreFillForForm(
    quarterlyDataId: string,
    portfolioCompanyId: string,
    portfolioType: string,
    data: any
  ) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `quarterly-data/${quarterlyDataId}/${portfolioType}/${portfolioCompanyId}/pre-fill`,
        false
      ),
      data
    );
  }

  getPortfolioQuarterlyFormData(
    quarterlyDataId: string,
    portfolioCompanyId: string,
    portfolioType: string
  ) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `quarterly-data/${quarterlyDataId}/${portfolioType}/${portfolioCompanyId}`,
        false
      )
    );
  }

  updatePortfolioCompanyForm(
    quarterlyDataId: string,
    portfolioCompanyId: string,
    portfolioType: string,
    data: any
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `quarterly-data/${quarterlyDataId}/${portfolioType}/${portfolioCompanyId}`,
        false
      ),
      data
    );
  }
}
