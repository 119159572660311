export const funds = [
  {
    key: 'vehicle',
    value: 'vehicle',
    viewValue: 'vehicle',
  },
  {
    key: 'fund',
    value: 'fund',
    viewValue: 'fund',
  },
];
