<div
  class="transaction-page"
  [ngClass]="{ tablet: (breakPointService.isTablet$ | async) }">
  <!-- <div class="tool-bar">
        <ng-container *ngIf="!isLoading; else countLoader">
            <div class="transction-count">{{invoiceList.length}} invoices</div>
        </ng-container>
        <ng-container *ngIf="!isLoading; else createNewLoader">
            <button (click)="openFileDialog()" class="create-new-btn" mat-flat-button [disabled]="isUploading">
                <ng-container *ngIf="!isUploading; else spinner">
                    <mat-icon>add</mat-icon>Create new
                </ng-container>
            </button>
        </ng-container>
        <input type="file" id="fileInput" style="display: none;" (change)="onFileSelected($event)">
    </div> -->
  <ng-container *ngIf="!noInvoiceFound; else noDataTemplate">
    <div class="table-filter">
      <ng-container *ngIf="!isLoading; else searchLoader">
        <mat-form-field class="search-field">
          <img src="../../assets/images/search.svg" alt="search" matPrefix />
          <input
            matInput
            placeholder="Search by number or counterparty"
            autocomplete="off"
            [(ngModel)]="search"
            (keyup)="isPageLoading = true; searchHandler()"
            (keyup)="handleBackspaceClear($event)"
            autocomplete="off" />
          <button
            class="clear-btn"
            matSuffix
            *ngIf="search"
            matSuffix
            (click)="clearFilter('search'); search = ''">
            <img
              src="../../../assets/images/cross_icon_grey.svg"
              height="20px"
              width="20px"
              alt="clear" />
          </button>
        </mat-form-field>
      </ng-container>

      <div class="right-section">
        <ng-container *ngIf="!isLoading; else commonLoader">
          <mat-form-field appearance="fill" class="topic-mat-select">
            <mat-select
              placeholder="Filter"
              [(ngModel)]="filterStatus"
              [position]="'left'"
              multiple
              (selectionChange)="changeStatus($event)"
              #select>
              <mat-select-trigger>
                <div class="topic">
                  Filter
                  <div *ngIf="filterStatus" class="topic-count">
                    {{ filterStatus.length }}
                  </div>
                </div>
              </mat-select-trigger>
              <mat-option
                class="topic-item"
                *ngFor="let status of statuses"
                [value]="status.value">
                <span class="topic-name">{{ status.name }}</span></mat-option
              >
            </mat-select>
            <img
              class="arrow-down"
              [ngClass]="{ open: select.panelOpen }"
              src="../../assets/images/arrow-down.svg"
              matSuffix />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="!isLoading; else filterDateRangeLoader">
          <mat-form-field class="mat-range-filter" [ngClass]="{'range-input-color' : (startDate.value || endDate.value)}">
            <mat-date-range-picker
              #picker
              (closed)="
                dateRangeFilter(startDate, endDate)
              "></mat-date-range-picker>
            <mat-datepicker-toggle
              matIconPrefix
              [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-input
              [rangePicker]="picker"
              [formGroup]="range"
              [max]="maxDate">
              <input
                matStartDate
                placeholder="Start date"
                #startDate
                formControlName="due_date__gte"
                readonly />
              <input
                matEndDate
                placeholder="End date"
                #endDate
                readonly
                formControlName="due_date__lte" />
            </mat-date-range-input>
            <div
              matIconSuffix
              class="clear-date"
              *ngIf="startDate.value || endDate.value">
              <button class="btn-clear">
                <img
                  (click)="clearFilter('dateRange')"
                  src="../../../../assets/images/cross_icon_grey.svg"
                  height="19px"
                  width="19px"
                  alt="add" />
              </button>
            </div>
          </mat-form-field>
        </ng-container>
      </div>
      <ng-container *ngIf="!isLoading; else createNewLoader">
        <button class="create-btn" mat-button [matMenuTriggerFor]="uploadMenu" tabindex="0">
          <div class="btn-container">
            <div class="btn-text">Create new</div>
            <img
              src="../../assets/images/black-down-arrow.svg"
              alt="down-img" />
          </div>
        </button>
      </ng-container>
      <ng-container>
        <input type="file" (change)="fileChangeHandler($event)" hidden="true" accept="application/pdf, image/png, image/jpeg, image/tiff"  aria-label="Upload payable files" multiple="" class="upload-file-picker" aria-hidden="true" #fileUpload>
        <mat-menu #uploadMenu="matMenu" class="invoice-mat-menu" xPosition="before" invoice-action-position>
          <button mat-menu-item (click)="fileUpload.click()">
            <img src="../../assets/images/upload_icon_brown.svg" alt="upload-icon" />
            Upload Invoice</button>
        </mat-menu>
      </ng-container>
    </div>
    <ng-container>
      <div class="invoice-list" #tableSection>
        <div class="invoice-list-gp">
          <table [hidden]="isPageLoading" mat-table [dataSource]="dataSource">
            <!-- document_id Column -->
            <ng-container matColumnDef="document_id">
              <th mat-header-cell *matHeaderCellDef>Number</th>
              <td mat-cell *matCellDef="let element">
                {{ element.document_id ? '#' + element.document_id : '' }}
              </td>
            </ng-container>

            <!-- supplier Column -->
            <ng-container matColumnDef="supplier">
              <th mat-header-cell *matHeaderCellDef>Counterparty</th>
              <td mat-cell *matCellDef="let element">
                <div
                  class="supplier-value"
                  *ngIf="
                    element.counterpart?.name ||
                      element.other_extracted_data?.counterpart_name;
                    else noData
                  ">
                  <!-- <img src="../../assets/images/ace_icon.png" alt="ace-icon"> -->
                  <div class="supplier-icon">
                    {{
                      (element.counterpart?.name ||
                        element.other_extracted_data?.counterpart_name)[0]
                    }}
                  </div>
                  <span>{{
                    element.counterpart?.name ||
                      element.other_extracted_data?.counterpart_name
                  }}</span>
                </div>
              </td>
            </ng-container>

            <!-- Invoice date Column -->
            <ng-container matColumnDef="issued_at">
              <th mat-header-cell *matHeaderCellDef>Invoice date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.issued_at && convertDateFormat(element.issued_at) }}
              </td>
            </ng-container>
            <!-- due_date Column -->
            <ng-container matColumnDef="due_date">
              <th mat-header-cell *matHeaderCellDef>Due date</th>
              <td mat-cell *matCellDef="let element">
                <ng-container
                  *ngIf="
                    getDueDateDisplay(
                      element.due_date,
                      element.status
                    ) as dueDateData
                  ">
                  <ng-container
                    *ngIf="dueDateData?.showRemainingDays; else showDueDate">
                    <div class="info-overdue">
                      <span>{{ dueDateData?.displayText }}</span>
                      <img
                        matTooltipClass="tooltip-overdue-icon"
                        matTooltipPosition="right"
                        matTooltip="This invoice is {{
                          dueDateData.diffDays
                        }} days past its due date. Please make the payment as soon as possible"
                        src="../../../../assets/images/info_icon_red.svg"
                        alt="sender-icon"
                        height="16px"
                        width="16px" />
                    </div>
                  </ng-container>
                  <ng-template #showDueDate>
                    {{ dueDateData?.displayText }}
                  </ng-template>
                </ng-container>
              </td>
            </ng-container>

            <!-- added_by Column -->
            <ng-container matColumnDef="added_by">
              <th mat-header-cell *matHeaderCellDef>Added by</th>
              <td mat-cell *matCellDef="let element">
                <div class="sender-value" *ngIf="element.was_created_by_user_id; else noData">
                  <!-- <img src="../../assets/images/matias.png" alt="sender-icon"> -->
                  <!-- <div class="sender-icon">
                    <img
                      src="../../../../assets/images/user_icon.svg"
                      alt="sender-icon"
                      height="20px"
                      width="20px" />
                  </div> -->
                  <ng-container *ngIf="getAddedByDetails(element.was_created_by_user_id) as data; else noAddedBy">
                    <span>{{ (data?.first_name || data?.last_name)? data?.first_name +' '+ data?.last_name : data?.email}}</span>
                  </ng-container>
                  <ng-template #noAddedBy>
                    <span style="padding-left: 12px">-</span>
                  </ng-template>
                </div>
              </td>
            </ng-container>

            <!-- status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <ng-container
                  *ngIf="element.status === 'draft'; then draft"></ng-container>
                <ng-container
                  *ngIf="element.status === 'new'; then new"></ng-container>
                <ng-container
                  *ngIf="
                    element.status === 'approve_in_progress';
                    then inApproval
                  "></ng-container>
                <ng-container
                  *ngIf="
                    element.status === 'waiting_to_be_paid';
                    then Approved
                  "></ng-container>
                <ng-container
                  *ngIf="
                    element.status === 'partially_paid';
                    then partiallyPaid
                  "></ng-container>
                <ng-container
                  *ngIf="element.status === 'paid'; then paid"></ng-container>
                <ng-container
                  *ngIf="
                    element.status === 'canceled';
                    then cancelled
                  "></ng-container>
                <ng-container
                  *ngIf="
                    element.status === 'rejected';
                    then rejected
                  "></ng-container>
                <ng-container
                  *ngIf="
                    element.status === 'expired';
                    then expired
                  "></ng-container>
              </td>
            </ng-container>
            <!-- amount Column -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element">
                {{ currencyFormater(element.total_amount) }}
              </td>
            </ng-container>
            <!-- action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <ng-container *ngIf="!isLoading; else countLoader">
                  <!-- <div class="count-details">
                    {{ dataSource.filteredData.length }}
                  </div> -->
                </ng-container>
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-flat-button>
                  <img
                    src="../../assets/images/arrow_right_icon.svg"
                    alt="more-icon" />
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openInvoiceDetailHandler(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell no-row" colspan="8" style="padding: 1rem">
                <div class="no-invoice-found-container">
                  <img
                    src="../../../assets/images/invoice_empty_icon.svg"
                    alt="no-invoice" />
                  <div class="text-section">
                    <div class="heading">No invoices found</div>
                    <div class="supporting-text">
                      It looks like there are no invoices available at the
                      moment. Please check back later or try adjusting your
                      search criteria.
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <table
            *ngIf="isPageLoading"
            mat-table
            [dataSource]="[1, 2, 3, 4, 5]"
            class="loading-table">
            <!-- document_id Column -->
            <ng-container matColumnDef="document_id">
              <th mat-header-cell *matHeaderCellDef>Number</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- supplier Column -->
            <ng-container matColumnDef="supplier">
              <th mat-header-cell *matHeaderCellDef>Counterparty</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- issue_date Column -->
            <ng-container matColumnDef="issued_at">
              <th mat-header-cell *matHeaderCellDef>Invoice date</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- due_date Column -->
            <ng-container matColumnDef="due_date">
              <th mat-header-cell *matHeaderCellDef>Due date</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- added_by Column -->
            <ng-container matColumnDef="added_by">
              <th mat-header-cell *matHeaderCellDef>Added by</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>
            <!-- status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>
            <!-- amount Column -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>Amount</th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <!-- action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>
                <ng-container *ngIf="!isLoading; else countLoader">
                  <div class="count-details">
                    {{ dataSource.filteredData.length }}
                  </div>
                </ng-container>
              </th>
              <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader
                  count="1"
                  appearance="line"
                  [theme]="{
                    width: '90%',
                    height: '24px',
                    marginBottom: 0
                  }">
                </ngx-skeleton-loader>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator [hidden]="isLoading || !updatePageSize" #paginator [pageSize]="pageSize" [length]="totalRecords"
          [disabled]="isPageLoading" class="invoice-pagination" (page)="onPageChange($event)"></mat-paginator>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noDataTemplate>
    <div class="wrapper">
      <div class="no-invoice-found">
        <div class="icon">
          <img src="../../../assets/images/invoice_empty_icon.svg" alt="" />
        </div>
        <div class="text">
          <div class="heading">No invoices found</div>
          <div class="support-text">
            It looks like there are no invoices available at the moment. Please
            create or upload invoices by clicking below.
          </div>
        </div>
        <button class="create-btn" mat-button [matMenuTriggerFor]="uploadMenu" tabindex="0">
          <div class="btn-container">
            <div class="btn-text">Create new</div>
            <img
              src="../../assets/images/black-down-arrow.svg"
              alt="down-img" />
          </div>
        </button>
        <ng-container>
          <input type="file" (change)="fileChangeHandler($event)" hidden="true" accept="application/pdf, image/png, image/jpeg, image/tiff"  aria-label="Upload payable files" multiple="" class="upload-file-picker" aria-hidden="true" #fileUpload>
          <mat-menu #uploadMenu="matMenu" class="invoice-mat-menu">
            <button mat-menu-item  (click)="fileUpload.click()">
              <img src="../../assets/images/upload_icon.svg" alt="upload-icon" />
              Upload Invoice</button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #draft>
  <div class="status-badge draft">
    <img src="../../assets/images/edit_icon.svg" alt="edit-icon" />
    <span>Draft</span>
  </div>
</ng-template>
<ng-template #new>
  <div class="status-badge new">
    <img src="../../assets/images/bolt_icon.svg" alt="new icon" />
    <span>New</span>
  </div>
</ng-template>
<ng-template #inApproval>
  <div class="status-badge in-approval">
    <img src="../../assets/images/clock_icon.svg" alt="approval icon" />
    <span>In Approval</span>
  </div>
</ng-template>
<ng-template #Approved>
  <div class="status-badge in-approval">
    <img src="../../assets/images/clock_icon.svg" alt="approval icon" />
    <span>Approved</span>
  </div>
</ng-template>
<ng-template #toBePaid>
  <div class="status-badge to-be-paid">
    <img src="../../assets/images/clock_icon.svg" alt="to paid icon" />
    <span>To be Paid</span>
  </div>
</ng-template>
<ng-template #partiallyPaid>
  <div class="status-badge partially-paid">
    <img src="../../assets/images/partial_icon.svg" alt="partial icon" />
    <span>Partially Paid</span>
  </div>
</ng-template>
<ng-template #paid>
  <div class="status-badge paid">
    <img src="../../assets/images/check_icon.svg" alt="paid icon" />
    <span>Paid</span>
  </div>
</ng-template>
<ng-template #cancelled>
  <div class="status-badge cancelled">
    <img src="../../assets/images/cross_icon.svg" alt="cancelled icon" />
    <span>Canceled</span>
  </div>
</ng-template>
<ng-template #rejected>
  <div class="status-badge rejected">
    <img src="../../assets/images/cross_icon.svg" alt="rejected icon" />
    <span>Rejected</span>
  </div>
</ng-template>
<ng-template #expired>
  <div class="status-badge expired">
    <img src="../../assets/images/warning_icon.svg" alt="expired icon" />
    <span>Expired</span>
  </div>
</ng-template>
<ng-template #tableLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6]">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{
        width: '100%',
        height: '50px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>
<ng-template #countLoader>
  <ngx-skeleton-loader
    count="1"
    appearance="line"
    [theme]="{
      width: '130px',
      height: '24px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
<ng-template #createNewLoader>
  <div style="margin-left: auto">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{
        width: '160px',
        height: '38px',
        marginBottom: 0
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #searchLoader>
  <ngx-skeleton-loader
    *ngIf="!(breakPointService.isTablet$ | async)"
    count="1"
    appearance="line"
    [theme]="{
      width: '400px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="breakPointService.isTablet$ | async"
    count="1"
    appearance="line"
    [theme]="{
      width: '280px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
<ng-template #filterDateRangeLoader>
  <ngx-skeleton-loader
    *ngIf="!(breakPointService.isTablet$ | async)"
    count="1"
    appearance="line"
    [theme]="{
      width: '300px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="breakPointService.isTablet$ | async"
    count="1"
    appearance="line"
    [theme]="{
      width: '250px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
<ng-template #commonLoader>
  <ngx-skeleton-loader
    *ngIf="!(breakPointService.isTablet$ | async)"
    count="1"
    appearance="line"
    [theme]="{
      width: '150px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
  <ngx-skeleton-loader
    *ngIf="breakPointService.isTablet$ | async"
    count="1"
    appearance="line"
    [theme]="{
      width: '135px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>
<ng-template #moniteIdMissingerror>
  <app-service-not-available
    [description]="serviceNotAvailableDesc"></app-service-not-available>
</ng-template>
<ng-template #spinner>
  <div
    style="
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
    ">
    <span class="loader"></span>
  </div>
</ng-template>
<ng-template #noData>
  <div style="padding-left: 12px">-</div>
</ng-template>
