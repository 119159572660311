import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ILetterhead } from './letterheads.component';

@Injectable({
  providedIn: 'root'
})
export class LetterheadService {

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  // GET /vehicles/{vehicleId}/letterheads
  getAllLetterheads(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/letterheads`,
        false
      )
    );
  }

  getLetterhead(letterheadId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `letterhead/${letterheadId}`,
        false
      ), { responseType: 'arraybuffer' }
    );
  }

  createLetterhead(vehicleId: string, formData: FormData) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/letterhead`,
        false
      ), formData)
  }

  reUploadLetterhead(letterHeadId: string, formData: FormData) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `letterhead/${letterHeadId}`,
        false
      ), formData)
  }

  updateLetterhead(vehicleId: string, letterheadId: string, data: Partial<ILetterhead>) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/letterhead/${letterheadId}`,
        false
      ), data)
  }

  removeDefault(vehicleId: string) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/letterheads`,
        false
      ), {})
  }

  deleteLetterhead(letterheadId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `letterhead/${letterheadId}`,
        false
      )
    );
  }
}
