import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root',
})
export class BankAccountService {
  constructor(private apiEndpointsService: ApiEndpointsService) {}

  getAccountDataRout(vehicleId: string): string {
    return this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/bank-accounts`,
      false
    );
  }

  deleteAccountDataRout(vehicleId: string, accountNumber: string): string {
    return this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/bank-accounts/${accountNumber}`,
      false
    );
  }

  editAccountDataRout(vehicleId: string, accountNumber: string): string {
    return this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/bank-accounts/${accountNumber}`,
      false
    );
  }
}
