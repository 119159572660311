<div class="transaction-detail">
    <div class="transaction-subheader">
        <ng-container>
            <div class="title-content">
                <span class="image-border" (click)="navigateBack()">
                    <img src="assets/images/back_icon.svg" height="24px" width="24px" />
                </span>
                <!-- <span class="subheader-label">{{lpDetails.name}}</span> -->
                <!-- <span class="badge-investor-id">{{lpDetails.investorId ? lpDetails.investorId : ''}}</span> -->
            </div>
        </ng-container>
    </div>
    <div class="transaction-section">
        <ng-container *ngFor="let item of transactionDetails">
            <div class="detail-item" *ngIf="!hideFields.includes(item.key)">
                <div class="detail-key">{{item.key}}</div>
                <div class="detail-value">
                    <ng-container
                        *ngIf="dateFields.includes(item.key)">{{commonService.formateUnixDate(item.value)}}</ng-container>
                    <ng-container *ngIf="!dateFields.includes(item.key)">{{item.value ? item.value :'-'}}</ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>