import { Base64 } from 'js-base64';
import { BehaviorSubject, Observable } from 'rxjs';
import { menuItems } from 'src/app/core/constants/menuItems';
import { funds } from 'src/app/core/constants/vehicle';
import { Imenu } from 'src/app/core/interfaces/menu';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { ConfigService } from 'src/app/initializer/config.service';
import { ModalDialogData } from 'src/app/models/modelDialog.model';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { TermsOfUseComponent } from 'src/app/shared/components/dialog/terms-of-use/terms-of-use.component';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';

import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() baseData: any;

  menuItems = menuItems;
  ddlfund = funds;
  selectedfund = funds[0].value;
  title = 'GP Portal';
  activePathName = '';
  isInaccessible = false;
  currentGPS: any;
  sessionUser: any = {};
  selectedInvestor: any = 'Vsquared';
  investors: any[] = [];
  selectedFund: any | undefined = 'Vsquared Ventures';
  persistInvestor: any;
  persistFund: any
  funds: any[] = [];
  privilegesOrder = [
    'viewManagement',
    'viewTransactions',
    'viewReports',
    'viewDocuments',
    'viewPortfolioCompanies',
    'viewInvestors',
    'viewOnboarding',
    'viewSettings',
  ];

  selectedMenu: any;
  selectedSubMenu: any;
  isInvestorSelectOpen: boolean = false;
  isFundSelectOpen: boolean = false;
  isMobileInvestorSelectOpen: boolean = false;

  private urlData = new BehaviorSubject({ bigUrl: '', smallUrl: '' });

  urlDataObservable$ = this.urlData.asObservable();
  lpPortalLoginEnabled: boolean = false;
  lpPortalUrl: any;

  public async setUrlData(data: any) {
    if (data) {
      this.urlData.next(data);
    }
  }

  constructor(
    private router: Router,
    public breakPointService: BreakPointService,
    public dialog: MatDialog,
    private authService: AuthService,
    public commonService: CommonService,
    private userService: UserService,
    private config: ConfigService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);
      }
    });
  }
  onSelectedMenu(value: any): void {
    // this.selectedMenu = value;
  }
  navigateToComponent(data: Imenu) {
    this.router.navigate([
      `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${data.path}`,
    ]);
  }
  navigateToSubmenu(path: string) {
    this.router.navigate([
      `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${path}`,
    ]);
  }
  onVehicleChange(value: any) {
    this.router.navigate([
      `gp/${this.selectedInvestor.id}/vehicle/${value.id}/${this.selectedMenu.path
      }${this.selectedSubMenu ? '/' + this.selectedSubMenu : ''}`,
    ]);
  }
  onGPSChange(value: any) {
    this.router.navigate([
      `gp/${value.id}/vehicle/${value.vehicles[0].id}/${this.selectedMenu.path
      }${this.selectedSubMenu ? '/' + this.selectedSubMenu : ''}`,
    ]);
  }
  ngOnInit() {
    this.selectedMenu = this.menuItems[0];
    this.investors = this.baseData.gps;
    this.funds = this.reorderPrivileges(this.investors[0].vehicles);
    this.userService.userDataObservable$.subscribe((res: any) => {
      this.sessionUser = res;
      if (!res.confirmedTermsOfUse) {
        this.openTermsOfUse();
      }
    });
    this.lpPortalLoginEnabled = this.config.sessionuser.data?.lpPortalLoginEnabled || false;
    if (Object.keys(this.baseData).length > 0) {
      if (localStorage.getItem('tpKey')) {
        this.router.navigate([localStorage.getItem('tpKey')]);
        localStorage.removeItem('tpKey');
      }
    }

    this.userService.resetValuesObservable$.subscribe((res: any) => {
      if (res === 'reset' && (this.selectedFund.id !== this.persistFund.id || this.selectedInvestor.id !== this.persistInvestor.id)) {
        this.selectedFund = this.persistFund;
        this.selectedInvestor = this.persistInvestor;
      }
    });
  }

  reorderPrivileges(data: any) {
    return data.map((item: any) => {
      item.privileges = this.privilegesOrder.filter(privilege =>
        item.privileges.includes(privilege)
      );
      return item;
    });
  }

  changeTheme() {
    if (this.selectedInvestor) {
      document.documentElement.style.setProperty(
        '--primary-color',
        this.selectedInvestor.colors.primary
      );
      document.documentElement.style.setProperty(
        '--primary-light-color',
        this.selectedInvestor.colors.primaryLight
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        this.selectedInvestor.colors.secondary
      );
      document.documentElement.style.setProperty(
        '--secondary-light-color',
        this.selectedInvestor.colors.secondaryLight
      );
    }
  }

  handleNavigationEnd(event: NavigationEnd) {
    const urlSegments = event.url.split('/');
    let gpName = urlSegments[1];
    let gpId = urlSegments[2];
    let vehicleName = urlSegments[3];
    let vehicleId = urlSegments[4];
    let tabName = urlSegments[5];
    let tabParam = urlSegments[6] ? urlSegments[6].split('?')[0] : urlSegments[6];
    this.menuItems = menuItems;
    this.selectedSubMenu = undefined;

    let isValidGpId = () => this.baseData.gps.find((x: any) => x.id === gpId);
    let isValidVehicleId = () =>
      this.selectedInvestor.vehicles.find((x: any) => x.id === vehicleId);
    // console.log('urlSegment', urlSegments, event.url);
    // console.log(gpName, gpId, vehicleName, vehicleId, tabName);
    if (gpName === 'gp' && vehicleName === 'vehicle') {
      if (isValidGpId()) {
        this.selectedInvestor = isValidGpId();
        this.lpPortalUrl = this.selectedInvestor.lpPortalUrl;
        this.funds = this.reorderPrivileges(this.selectedInvestor.vehicles);
        if (!isValidVehicleId()) {
          // valid GP ID and invalid Vehicle ID
          this.selectedFund = this.reorderPrivileges(this.selectedInvestor.vehicles)[0];
          this.selectedMenu = this.menuItems.filter(
            x => x.key === this.selectedFund.privileges[0]
          )[0];
          if (urlSegments.length === 4) {
            this.router.navigate([
              `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`,
            ]);
          } else {
            const urlRedirect = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`;
            this.openDialog(true, urlRedirect);
          }
        } else {
          // valid GP and valid Vehicle ID
          this.selectedFund = isValidVehicleId();
          this.selectedMenu =
            this.menuItems
              .filter(
                x =>
                  this.selectedFund.privileges.find((y: any) => y === x.key) !==
                  undefined
              )
              .filter(z => z.path === tabName)[0] ||
            this.menuItems.filter(
              x => x.key === this.selectedFund.privileges[0]
            )[0];
          if (urlSegments.length <= 6) {
            if (this.isValidPath(tabName) || tabName === undefined) {
              const url = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id
                }/${'subMenus' in this.selectedMenu
                  ? `${this.selectedMenu.path}/${this.selectedMenu.subMenus[0].path}`
                  : `${this.selectedMenu.path}`
                }`;
              this.router.navigate([url]);
            } else {
              const urlRedirect = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`;
              this.openDialog(true, urlRedirect);
            }
          } else if (this.selectedMenu.path !== 'documents' && this.selectedMenu.path !== 'investors' && this.selectedMenu.path !== 'reports') {
            this.menuItems.forEach(item => {
              if ('subMenus' in item && item.path === this.selectedMenu.path) {
                item.isToggled = true;
              }
            });
            if (this.isValidPath(tabName)) {
              if (
                'subMenus' in this.selectedMenu &&
                this.selectedMenu.subMenus.some((x: any) => x.path === tabParam)
              ) {
                const url = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id
                  }/${'subMenus' in this.selectedMenu
                    ? `${this.selectedMenu.path}/${tabParam}`
                    : `${this.selectedMenu.path}`
                  }`;
                this.selectedSubMenu = tabParam;
                this.router.navigate([url]);
              } else {
                const urlRedirect = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id
                  }/${'subMenus' in this.selectedMenu
                    ? `${this.selectedMenu.path}/${this.selectedMenu.subMenus[0].path}`
                    : `${this.selectedMenu.path}`
                  }`;
                this.selectedSubMenu = this.selectedMenu.subMenus[0].path;
                this.openDialog(true, urlRedirect);
              }
            } else {
              const urlRedirect = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`;
              this.openDialog(true, urlRedirect);
            }
          }
        }
      } else if (!isValidGpId()) {
        //invalid GP ID
        this.defaultGPSelection(false);
      }
    } else {
      if (urlSegments.length === 2 && gpName === '') {
        // first time login or only base URL
        this.defaultGPSelection(true);
      } else if (gpName === 'gp' && vehicleName !== 'vehicle') {
        if (isValidGpId()) {
          this.selectedInvestor = isValidGpId();
          this.selectedFund = this.reorderPrivileges(this.selectedInvestor.vehicles)[0]
          this.selectedMenu = this.menuItems.filter(
            x => x.key === this.selectedFund.privileges[0]
          )[0];
          if (vehicleName !== undefined) {
            const urlRedirect = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`;
            this.openDialog(true, urlRedirect);
          } else {
            this.router.navigate([
              `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`,
            ]);
          }
        } else {
          if (!gpId) {
            this.defaultGPSelection(true);
          } else {
            this.defaultGPSelection(false);
          }
        }
      } else {
        urlSegments.length <= 6 && this.defaultGPSelection(false);
      }
    }

    this.menuItems = menuItems.filter(
      x =>
        this.selectedFund.privileges.find((y: any) => y === x.key) !== undefined
    );
    // this.setUrlData(this.selectedInvestor.logos);
    // this.changeTheme();
    this.userService.setFundAdminCockpitUrl(
      this.selectedFund.fundAdminCockpitUrl
    );
    this.userService.setSelectedVehicle(
      this.selectedFund
    )
    this.commonService.setSecondaryPath([]);
    this.persistFund = this.selectedFund;
    this.persistInvestor = this.selectedInvestor;
  }

  defaultGPSelection(isInitial: boolean) {
    this.selectedInvestor = this.baseData.gps[0];
    this.funds = this.reorderPrivileges(this.selectedInvestor.vehicles);
    this.selectedFund = this.funds[0];
    this.selectedMenu = this.menuItems.filter(
      x => x.key === this.selectedFund.privileges[0]
    )[0];
    if (isInitial) {
      this.router.navigate([
        `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`,
      ]);
    } else {
      const urlRedirect = `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${this.selectedMenu.path}`;
      this.openDialog(true, urlRedirect);
    }
  }

  isValidPath(tabName: string): boolean {
    return (
      this.menuItems
        .filter(x => this.selectedFund.privileges.find((y: any) => y === x.key))
        .filter(z => z.path === tabName)[0] !== undefined
    );
  }

  checkPrivileges(userPreviligeData: string[], menuItem: any): boolean {
    if (!userPreviligeData) return false;
    return userPreviligeData.some(privilege => privilege === menuItem.key);
  }

  openDialog(event: boolean, redirectUrl: any) {
    if (event) {
      this.isInaccessible = true;
      const dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'Access Denied',
          contentKey: 'access denied',
          positiveMessageKey: 'OK',
          negativeMessageKey: '',
          isValid: '1',
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === '1') {
          this.redirectToValidURL(redirectUrl);
          this.isInaccessible = false;
        }
      });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: <ModalDialogData>{
          titleKey: 'settings.logOut',
          contentKey: 'settings.logoutConfirmation',
          positiveMessageKey: 'yesMessage',
          negativeMessageKey: 'cancelMessage',
          currentGP: this.selectedInvestor,
          isValid: '0',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === '1') {
          this.authService.logout();
        }
      });
    }
  }
  redirectToValidURL(redirectUrl: any) {
    this.router.navigate([redirectUrl]);
  }

  openTermsOfUse() {
    this.dialog.open(TermsOfUseComponent, {
      data: {
        confirmedTermsOfUse: this.sessionUser.confirmedTermsOfUse,
      },
      disableClose: !this.sessionUser.confirmedTermsOfUse,
    });
  }

  openLpPortal() {
    const lpPortalUrl = this.lpPortalUrl;
    if (typeof lpPortalUrl === 'string' && lpPortalUrl.trim() !== '') {
      window.open(lpPortalUrl, '_blank');
    }
  }

  openHelpDesk() {
    const helpDeskUrl = this.baseData['helpDeskUrl'];
    if (typeof helpDeskUrl === 'string' && helpDeskUrl.trim() !== '') {
      window.open(helpDeskUrl, '_blank');
    }
  }

  breadCrumbNavigate(path: string[], primary: string, type: string) {
    if (type === 'primary') {
      this.router.navigate([
        `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${path[0].split(' ').join('-').toLowerCase()}`,
      ], { onSameUrlNavigation: 'reload' });
    } else {
      const endPath = primary === 'Documents' ? Base64.encode(`/Documents/${path.join('/')}`) : path.map(x => x.toLowerCase()).join('/')
      console.log(endPath)
      this.router.navigate([
        `gp/${this.selectedInvestor.id}/vehicle/${this.selectedFund.id}/${primary.toLowerCase()}/${endPath}`,
      ], { onSameUrlNavigation: 'reload' });
    }
  }

  hideBankAccount(key: string) {
    if (['viewBankAccounts', 'viewBankTransactions'].includes(key)) {
      if (environment.layer === 'prod') {
        return true
      }
      return [
        // dev & QA
        '95c19d8c-7a98-4bfd-81d8-cc8b7d0ab3a3',
        'a62cfbe0-658a-492f-a3e6-de5f16dce4fd',
        'b8a69254-5d57-42cd-81fd-bce6b9329aef',
        '2801c07a-9b8e-4f17-aaae-cb50cf21c728',
        'bfe3ab72-4b05-48f8-b60b-7edc3ed4ee55',
        'e782dafc-4fea-4365-b07c-12c2e7b05a61',
        '2801c07a-9b8e-4f17-aaae-cb50cf21c728',
        'fcac0345-d33f-4315-8edd-f2c4a8b195a0',
        'b8a69254-5d57-42cd-81fd-bce6b9329aef',
        '90d1e3f1-db9e-4c16-a324-fe832731a258',
        // staging
        '95c19d8c-7a98-4bfd-81d8-cc8b7d0ab3a3',
        'bfe3ab72-4b05-48f8-b60b-7edc3ed4ee55',
        'e782dafc-4fea-4365-b07c-12c2e7b05a61',
        '2801c07a-9b8e-4f17-aaae-cb50cf21c728',
        'fcac0345-d33f-4315-8edd-f2c4a8b195a0',
        'b8a69254-5d57-42cd-81fd-bce6b9329aef'
      ].includes(this.selectedFund.id)
    }
    return true
  }

}
