import { Component } from '@angular/core';

@Component({
  selector: 'app-dns-entries',
  templateUrl: './dns-entries.component.html',
  styleUrls: ['./dns-entries.component.scss']
})
export class DnsEntriesComponent {
  serviceNotAvailableDesc = 'This vehicle is currently not configured to use this service. Please contact support at';
}
