import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvestorsService } from './investors.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/core/services/user.service';
import { BreakPointService } from 'src/app/shared/services/break-point.service';

export interface Iinvestor {
  id: string,
  investorId: string,
  name: string,
  investorType: string | null,
  closingIds: string[] | [] | null,
  currentClosingStatus: string | null,
  completedClosingsCount: number | null,
  totalAmount: number | null,
}

export interface Iclosings {
  id: string,
  number: string,
  date: string,
  status: string | null
}

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.scss'],
})
export class InvestorsComponent {
  displayedColumns: string[] = [
    'investorId',
    'name',
    'type',
    'currentClosingStatus',
    'completedClosingsCount',
    'totalAmount',
    'details',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  selectedInvestor: Iinvestor;
  isLoading: boolean = false;
  vehicleId: string;
  gpId: string;
  limitedPartnerId: string;
  noInvestor: boolean = false;
  isShowDetails: boolean = false;
  investorData: Iinvestor[];
  search: string;
  isDownloading: boolean = false;
  selectVehicle: any;
  closerValue: Iclosings | null;
  closingsList: Iclosings[];

  // @ViewChild(MatSort) sort: MatSort;

  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }

  constructor(
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private investorService: InvestorsService,
    public commonService: CommonService,
    public breakpointService: BreakPointService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.limitedPartnerId = this.activatedRoute.snapshot.params['limitedPartnerId'];
    this.isShowDetails = this.limitedPartnerId !== undefined
    this.commonService.setSecondaryPath([]);
    !this.limitedPartnerId && this.getAllInvestors();
    this.userService.selectedVehicleObservable$.subscribe((res: any) => {
      if (res) {
        this.selectVehicle = res;
      }
    });
  }

  getAllInvestors() {
    this.isLoading = true;
    this.investorService
      .getAllInvestors(this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          if (parsedResponse.data.limitedPartners.length == 0) {
            this.noInvestor = true
          }
          this.investorData = parsedResponse.data.limitedPartners;
          this.closingsList = parsedResponse.data.closings;
          this.dataSource = new MatTableDataSource(parsedResponse.data.limitedPartners);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => item[property].toLocaleLowerCase();
        },
        error: error => {
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve Investors')
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  showDetails(investor: Iinvestor) {
    this.limitedPartnerId = investor.id;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/investors/${investor.id}`
    );
    this.isShowDetails = true;
  }

  hideDetails() {
    this.isShowDetails = false;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/investors`
    );
    this.commonService.setSecondaryPath([]);
    !this.investorData && this.getAllInvestors();
  }

  applyFilter() {
    // const filterValue = (this.search || '').trim().toLowerCase();
    const filterData = {
      filterValue : (this.search || '').trim().toLowerCase(),
      closerFiltervalue: (this.closerValue ? this.closerValue.id : ''),
    }
    // Update filterPredicate to handle the specific columns
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const searchTerms = JSON.parse(filter);
      const id = data.investorId
        ? data.investorId.toString().toLowerCase()
        : '';
      const name = data.name ? data.name.toLowerCase() : '';
      const closingId = data.closingIds;
      return (id.includes(searchTerms.filterValue) || name.includes(searchTerms.filterValue)) && (!searchTerms.closerFiltervalue || closingId.includes(searchTerms.closerFiltervalue));
    };

    this.dataSource.filter = JSON.stringify(filterData);
  }

  exportExcel() {
    this.isDownloading = true;
    this.investorService
      .getExcelFile(this.vehicleId)
      .subscribe({
        next: response => {
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const blobURL = URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = blobURL;
          a.download = `${this.formatDate(new Date())} ${this.selectVehicle.name} investors`
          a.click();

          URL.revokeObjectURL(blobURL);
        },
        error: error => {
          this.isDownloading = false;
          this.commonService.errorNotification('Failed to retrieve the file.')
        },
        complete: () => {
          this.isDownloading = false;
        },
      });
  }

  formatDate(date: Date) { // 2024-09-17
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

}
