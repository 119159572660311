<div class="view-transaction-dialog" *ngIf="(bankTransactionsService.transactionDetailsObservable$| async)">
  <div class="view-transaction-header">
    <div class="view-transaction-txt">Transaction details</div>
    <button class="close-button"
      (click)="bankTransactionsService.closeDrawer()"
      (keydown)="bankTransactionsService.closeDrawer()">
      <img src="../../../assets/images/cross_icon_grey.svg" alt="close" height="24px" width="24px">
    </button>
  </div>
  <div class="view-transaction-body">
    <div class="details-box">
      <div class="details-header">
        <button class="icon-circle">
          <img src="../../../../assets/images/transactions_icon_white.svg" height="24px" width="24px" alt="sync" />
        </button>
        <span class="view-transaction-txt">Basic details</span>
      </div>
      <div class="details-box-body">
        <p>
          <span class="key">Bank Account</span>
          <span class="value bank-name">{{(bankTransactionsService.transactionDetailsObservable$| async)?.account || '-'}}</span>
        </p>
        <p *ngIf="bankTransactionsService.transactionDetailsObservable$ | async as tx">
          <span class="key">Date</span>
          <span class="value">{{commonService.formateDate(tx.bookingDate) || '-'}}</span>
        </p>
        <p *ngIf="bankTransactionsService.transactionDetailsObservable$ | async as tx">
          <span class="key">Transaction Type</span>
          <span class="value">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <circle cx="6" cy="6" r="6" [attr.fill]="tx.amount < 0 ? '#fa5430' : '#81BD2E'" style="margin-right: 8px;" />
            </svg>
            {{ tx.amount < 0 ? 'Outgoing' : 'Incoming' }}
          </span>
        </p>
        <p>
          <span class="key">Currency</span>
          <span class="value">{{(bankTransactionsService.transactionDetailsObservable$| async)?.currency || '-'}}</span>
        </p>
        <p>
          <span class="key">Amount</span>
          <span class="value">{{(bankTransactionsService.transactionDetailsObservable$| async)?.amount || '-'}}€</span>
        </p>
        <p>
          <span class="key">Purpose</span>
          <span class="value" style="margin-left: 20px;">{{(bankTransactionsService.transactionDetailsObservable$| async)?.purpose || '-'}}</span>
        </p>
      </div>
    </div>
    <div class="details-box">
      <div class="details-header">
        <button class="icon-circle">
          <img src="../../../../assets/images/user_icon_white.svg" height="24px" width="24px" alt="sync" />
        </button>
        <span class="view-transaction-txt">Counterparty Details</span>
      </div>
      <div class="details-box-body">
        <p>
          <span class="key">Counterparty</span>
          <span class="value">{{(bankTransactionsService.transactionDetailsObservable$| async)?.paymentPartner?.name ||
            '-'}}</span>
        </p>
        <p>
          <span class="key">Bank Account</span>
          <span class="value">
            {{(bankTransactionsService.transactionDetailsObservable$| async)?.paymentPartner?.name || '-'}} /
            {{(bankTransactionsService.transactionDetailsObservable$| async)?.paymentPartner?.iban || '-'}}
          </span>
        </p>
        <p>
          <span class="key">BIC</span>
          <span class="value">{{(bankTransactionsService.transactionDetailsObservable$| async)?.paymentPartner?.bic ||
            '-'}}</span>
        </p>
        <p>
          <span class="key">Creditor ID</span>
          <span class="value">{{(bankTransactionsService.transactionDetailsObservable$|
            async)?.paymentPartner?.creditorId || '-'}}</span>
        </p>
      </div>
    </div>
  </div>
</div>