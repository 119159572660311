import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  HostListener,
  QueryList,
  ViewChildren,
  ChangeDetectorRef,
  TemplateRef,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { IFilterMenu, isComponent, createInjector } from './filter-menu.model';
import { capitalize } from 'src/app/utils/formating.utils';
@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenuComponent implements AfterViewInit {
  constructor(private cdr: ChangeDetectorRef) {}

  @Input() filterMenuItems: IFilterMenu[] = [];
  @Input() filterMenuLabel = '';
  @Input() filterMenuCount = 0;
  @Input() filterMenuIcon = '';
  @Input() filterMenuIsDisabled = false;
  @Input() filterMenuIsSelected = false;
  @Input() filterMenuIsClearable = false;
  @Input() filterMenuIsOpen = false;
  @Input() isLoading = false;
  @Input() isClear = false;

  @Output() filtersChanged = new EventEmitter<any>();

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @ViewChild('filterBtn') filterBtn!: any;
  @ViewChildren('subMenuRefs') subMenus!: QueryList<any>;

  isComponent = isComponent;
  createInjector = createInjector;
  selectedMenuFilters: {
    parentValue: string;
    selectedChildren: string[];
  }[] = [];

  totalSelectedFilters = 0;

  mainFilterSelect = {
    panelOpen: false,
    value: '',
    children: [],
  };

  customPanelClass = 'filter-menu-panel';
  customPanelSubMenuClass = 'filter-menu-panel';

  ngAfterViewInit(): void {
    this.setMenuWidth();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setMenuWidth();
  }

  private setMenuWidth() {
    const buttonWidth = this.filterBtn._elementRef.nativeElement.offsetWidth;
    document.documentElement.style.setProperty(
      '--filter-menu-width',
      `${buttonWidth}px`
    );
  }

  getMenuRef(item: any) {
    const index = this.filterMenuItems.indexOf(item);
    return this.subMenus?.get(index);
  }

  //Filter menu functions starts here
  /**
   * This function is used to handle the click event of the submenu item
   * @param event - The click event
   * @param subItem - The submenu item
   */
  onItemClick(event: Event, subItem: any) {
    event.stopPropagation(); // Prevents menu from closing
    subItem.forEach((item: any) => {
      this.onCheckboxToggle(item.value, item.value, true);
    });
  }

  /**
   * This function is used to check if the child item is selected
   * @param parentValue - The parent value
   * @param childValue - The child value
   * @returns true if the child item is selected, false otherwise
   */
  isChildSelected(parentValue: string, childValue: string): boolean {
    const parent = this.selectedMenuFilters.find(
      f => f.parentValue === parentValue
    );
    return parent ? parent.selectedChildren.includes(childValue) : false;
  }

  /**
   * This function is used to update the filter count
   */
  private updateFilterCount(): void {
    const total = this.selectedMenuFilters.reduce(
      (count, filter) => count + filter.selectedChildren.length,
      0
    );
    this.totalSelectedFilters = total;
    this.cdr.detectChanges();
  }

  /**
   * This function is used to toggle the child item
   * @param parentValue - The parent value
   * @param childValue - The child value
   * @param checked - The checked value
   */
  onCheckboxToggle(
    parentValue: string,
    childValue: string,
    checked: boolean
  ): void {
    const parent = this.selectedMenuFilters.find(
      f => f.parentValue === parentValue
    );

    if (checked) {
      if (!parent) {
        this.selectedMenuFilters.push({
          parentValue,
          selectedChildren: [childValue],
        });
      } else {
        if (!parent.selectedChildren.includes(childValue)) {
          parent.selectedChildren.push(childValue);
        }
      }
    } else {
      if (parent) {
        parent.selectedChildren = parent.selectedChildren.filter(
          val => val !== childValue
        );
        if (parent.selectedChildren.length === 0) {
          this.selectedMenuFilters = this.selectedMenuFilters.filter(
            f => f.parentValue !== parentValue
          );
        }
      }
    }

    this.updateFilterCount(); // 👈 Recalculate count here
  }

  /**
   * This function is used to emit the selected filters
   * @param event - The event
   */
  filterApplied(event: any) {
    this.filtersChanged.emit(this.selectedMenuFilters);
  }

  /**
   * This function is used to clear all the filters
   */
  clearAllFilter() {
    this.selectedMenuFilters = [];
    this.totalSelectedFilters = 0;
    this.filtersChanged.emit(this.selectedMenuFilters);
  }

  /**
   * This function is used to toggle the child item
   * @param parentValue - The parent value
   * @param childValue - The child value
   */
  toggleChild(parentValue: string, childValue: string): void {
    const isSelected = this.isChildSelected(parentValue, childValue);
    this.onCheckboxToggle(parentValue, childValue, !isSelected);
  }

  /**
   * This function is used to check if all the children are selected
   * @param item - The item
   * @returns true if all the children are selected, false otherwise
   */
  areAllChildrenSelected(item: any): boolean {
    const parent = this.selectedMenuFilters.find(
      f => f.parentValue === item.value
    );
    if (!parent) return false;
    return item.children.every((child: any) =>
      parent.selectedChildren.includes(child.value)
    );
  }

  /**
   * This function is used to toggle the select all item
   * @param item - The item
   * @param checked - The checked value
   */
  toggleSelectAll(item: any, checked: boolean): void {
    const childValues = item.children.map((child: any) => child.value);
    const parent = this.selectedMenuFilters.find(
      f => f.parentValue === item.value
    );

    if (checked) {
      if (!parent) {
        this.selectedMenuFilters.push({
          parentValue: item.value,
          selectedChildren: childValues,
        });
      } else {
        parent.selectedChildren = [
          ...new Set([...parent.selectedChildren, ...childValues]),
        ];
      }
    } else {
      this.selectedMenuFilters = this.selectedMenuFilters.filter(
        f => f.parentValue !== item.value
      );
    }

    this.updateFilterCount();
  }

  hasSelectedFilters(item: any): boolean {
    return this.selectedMenuFilters.some(
      f => f.parentValue === item.value
    );
  }
  //Filter menu functions ends here

  formatString(str:any) {
    if (str === 'ingoing') {
      return 'Incoming';
    }
    return str ? capitalize(str) : '';
  }
}
