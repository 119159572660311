import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class InvestorsService {

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  // GET /vehicles/{vehiclesId}/limitedPartners
  getAllInvestors(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/limitedPartners`,
        false
      )
    );
  }

  // GET /vehicles/{vehiclesId}/limitedPartners/{limitedPartnerId}
  getInvestorDetails(vehicleId: string, limitedPartnerId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/limitedPartners/${limitedPartnerId}`,
        false
      )
    );
  }

  // GET /vehicles/{vehiclesId}/limitedPartners/{limitedPartnerId}/users
  getAllInvestorPersons(vehicleId: string, limitedPartnerId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/limitedPartners/${limitedPartnerId}/users`,
        false
      )
    );
  }
   // GET /vehicles/{vehicleId}/limitedPartners/{limitedPartnerId}/closings
   getAllClosingsInvestor(vehicleId: string, limitedPartnerId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/limitedPartners/${limitedPartnerId}/closings`,
        false
      )
    );
  }

  getExcelFile(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/limitedPartners?format=xlsx`,
        false
      ), { responseType: 'arraybuffer' }
    );
  }
}
