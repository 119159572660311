<div class="transaction-page" [ngClass]="{tablet: (breakPointService.isTablet$ | async)}" *ngIf="!moniteIdMissing;
    else moniteIdMissingerror">
    <!-- <div class="tool-bar">
        <ng-container *ngIf="!isLoading; else countLoader">
            <div class="transction-count">{{invoiceList.length}} invoices</div>
        </ng-container>
        <ng-container *ngIf="!isLoading; else createNewLoader">
            <button (click)="openFileDialog()" class="create-new-btn" mat-flat-button [disabled]="isUploading">
                <ng-container *ngIf="!isUploading; else spinner">
                    <mat-icon>add</mat-icon>Create new
                </ng-container>
            </button>
        </ng-container>
        <input type="file" id="fileInput" style="display: none;" (change)="onFileSelected($event)">
    </div> -->
    <div class="table-filter">
        <ng-container *ngIf="!isLoading; else searchLoader">
            <mat-form-field class="search-field">
                <img src="../../assets/images/search.svg" alt="search" matPrefix />
                <input matInput placeholder="Search" autocomplete="off" [(ngModel)]="search" autocomplete="off">
                <button class="clear-btn" matSuffix *ngIf="search" matSuffix (click)="search=''">
                    <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px" alt="clear">
                </button>
            </mat-form-field>
        </ng-container>

        <div class="right-section">

            <ng-container *ngIf="!isLoading; else commonLoader">
                <mat-form-field appearance="fill" class="status-field">
                    <mat-select placeholder="Filter">
                        <mat-option *ngFor="let status of statuses" [value]="status">
                            {{ status.name }}
                        </mat-option>
                    </mat-select>
                    <img src="../../assets/images/arrow-down.svg" matSuffix />
                </mat-form-field>
            </ng-container>
            <!-- <ng-container *ngIf="!isLoading; else commonLoader">
                <mat-form-field appearance="fill" class="sender-field">
                    <mat-select placeholder="Added by">
                        <mat-option *ngFor="let sender of senders" [value]="sender">
                            {{ sender.name }}
                        </mat-option>
                    </mat-select>
                    <img src="../../assets/images/arrow-down.svg" matSuffix />
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isLoading; else commonLoader">
                <mat-form-field appearance="fill" class="sort-field">
                    <mat-select placeholder="Sort by">
                        <mat-option *ngFor="let sort of sortOptions" [value]="sort">
                            {{ sort.name }}
                        </mat-option>
                    </mat-select>
                    <img src="../../assets/images/arrow-down.svg" matSuffix />
                </mat-form-field>
            </ng-container> -->

        </div>
        <ng-container *ngIf="!isLoading; else createNewLoader">
            <button (click)="openFileDialog()" class="create-new-btn" mat-flat-button [disabled]="isUploading">
                <ng-container *ngIf="!isUploading; else spinner">
                    <mat-icon>add</mat-icon>Create new
                </ng-container>
            </button>
        </ng-container>
        <input type="file" id="fileInput" style="display: none;" (change)="onFileSelected($event)">
    </div>
    <ng-container *ngIf="!isLoading">
        <div class="invoice-list">
            <div class="invoice-list-gp">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="issued_at" matSortDirection="desc"
                    matSortDisableClear>

                    <!-- document_id Column -->
                    <ng-container matColumnDef="document_id">
                        <th mat-header-cell *matHeaderCellDef> Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.document_id? ("#"+element.document_id):""}}
                        </td>
                    </ng-container>

                    <!-- supplier Column -->
                    <ng-container matColumnDef="supplier">
                        <th mat-header-cell *matHeaderCellDef> Supplier </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="supplier-value"
                                *ngIf="element.counterpart?.name || element.other_extracted_data?.counterpart_name; else noData">
                                <!-- <img src="../../assets/images/ace_icon.png" alt="ace-icon"> -->
                                <div class="supplier-icon">{{(element.counterpart?.name ||
                                    element.other_extracted_data?.counterpart_name)[0]}}</div>
                                <span>{{element.counterpart?.name ||
                                    element.other_extracted_data?.counterpart_name}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- issue_date Column -->
                    <ng-container matColumnDef="issued_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Issue date </th>
                        <td mat-cell *matCellDef="let element"> {{element.issued_at &&
                            convertDateFormat(element.issued_at)}} </td>
                    </ng-container>

                    <!-- due_date Column -->
                    <ng-container matColumnDef="due_date">
                        <th mat-header-cell *matHeaderCellDef> Due date </th>
                        <td mat-cell *matCellDef="let element"> {{element.due_date &&
                            convertDateFormat(element.due_date)}}
                        </td>
                    </ng-container>

                    <!-- added_by Column -->
                    <ng-container matColumnDef="added_by">
                        <th mat-header-cell *matHeaderCellDef> Added by </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="sender-value" *ngIf="element.sender; else noData">
                                <!-- <img src="../../assets/images/matias.png" alt="sender-icon"> -->
                                <div class="sender-icon">
                                    <img src="../../../../assets/images/user_icon.svg" alt="sender-icon" height="20px"
                                        width="20px">
                                </div>
                                <span>{{element.sender}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef> Amount </th>
                        <td mat-cell *matCellDef="let element"> {{currencyFormater(element.total_amount)}} </td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element.status === 'draft'; then draft"></ng-container>
                            <ng-container *ngIf="element.status === 'new'; then new"></ng-container>
                            <ng-container
                                *ngIf="element.status === 'approve_in_progress'; then inApproval"></ng-container>
                            <ng-container *ngIf="element.status === 'waiting_to_be_paid'; then toBePaid"></ng-container>
                            <ng-container
                                *ngIf="element.status === 'partially_paid'; then partiallyPaid"></ng-container>
                            <ng-container *ngIf="element.status === 'paid'; then paid"></ng-container>
                            <ng-container *ngIf="element.status === 'canceled'; then cancelled"></ng-container>
                            <ng-container *ngIf="element.status === 'rejected'; then rejected"></ng-container>
                            <ng-container *ngIf="element.status === 'expired'; then expired"></ng-container>
                        </td>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            <ng-container *ngIf="!isLoading; else countLoader">
                                <div class="count-details">{{dataSource.filteredData.length}}</div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-flat-button>
                                <img src="../../assets/images/more-vertical.svg" alt="more-icon">
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell no-row" colspan="8" style="padding:1rem;">
                            <div class="no-invoice-found-container">
                                <img src="../../../assets/images/invoice_empty_icon.svg" alt="no-invoice">
                                <div class="text-section">
                                    <div class="heading">No invoices found</div>
                                    <div class="supporting-text">It looks like there are no invoices available at the
                                        moment. Please check back
                                        later or try adjusting your search criteria.</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <div class="invoice-list">
            <div class="invoice-list-gp">
                <table mat-table [dataSource]="[1,2,3,4,5]" class="loading-table">

                    <!-- document_id Column -->
                    <ng-container matColumnDef="document_id">
                        <th mat-header-cell *matHeaderCellDef> Number </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '90%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <!-- supplier Column -->
                    <ng-container matColumnDef="supplier">
                        <th mat-header-cell *matHeaderCellDef> Supplier </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '90%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <!-- issue_date Column -->
                    <ng-container matColumnDef="issued_at">
                        <th mat-header-cell *matHeaderCellDef> Issue date </th>
                        <td mat-cell *matCellDef="let element"><ngx-skeleton-loader count="1" appearance="line" [theme]="{
                            width: '90%',
                            height: '24px',
                            marginBottom: 0
                            }">
                            </ngx-skeleton-loader> </td>
                    </ng-container>

                    <!-- due_date Column -->
                    <ng-container matColumnDef="due_date">
                        <th mat-header-cell *matHeaderCellDef> Due date </th>
                        <td mat-cell *matCellDef="let element"><ngx-skeleton-loader count="1" appearance="line" [theme]="{
                            width: '90%',
                            height: '24px',
                            marginBottom: 0
                            }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <!-- added_by Column -->
                    <ng-container matColumnDef="added_by">
                        <th mat-header-cell *matHeaderCellDef> Added by </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '90%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <!-- amount Column -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef> Amount </th>
                        <td mat-cell *matCellDef="let element"> <ngx-skeleton-loader count="1" appearance="line"
                                [theme]="{
                            width: '90%',
                            height: '24px',
                            marginBottom: 0
                            }">
                            </ngx-skeleton-loader></td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '90%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            <ng-container *ngIf="!isLoading; else countLoader">
                                <div class="count-details">{{dataSource.filteredData.length}}</div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '90%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #draft>
    <div class="status-badge draft">
        <img src="../../assets/images/edit_icon.svg" alt="edit-icon">
        <span>Draft</span>
    </div>
</ng-template>
<ng-template #new>
    <div class="status-badge new">
        <img src="../../assets/images/bolt_icon.svg" alt="new icon">
        <span>New</span>
    </div>
</ng-template>
<ng-template #inApproval>
    <div class="status-badge in-approval">
        <img src="../../assets/images/clock_icon.svg" alt="approval icon">
        <span>In Approval</span>
    </div>
</ng-template>
<ng-template #toBePaid>
    <div class="status-badge to-be-paid">
        <img src="../../assets/images/clock_icon.svg" alt="to paid icon">
        <span>To be Paid</span>
    </div>
</ng-template>
<ng-template #partiallyPaid>
    <div class="status-badge partially-paid">
        <img src="../../assets/images/partial_icon.svg" alt="partial icon">
        <span>Partially Paid</span>
    </div>
</ng-template>
<ng-template #paid>
    <div class="status-badge paid">
        <img src="../../assets/images/check_icon.svg" alt="paid icon">
        <span>Paid</span>
    </div>
</ng-template>
<ng-template #cancelled>
    <div class="status-badge cancelled">
        <img src="../../assets/images/cross_icon.svg" alt="cancelled icon">
        <span>Canceled</span>
    </div>
</ng-template>
<ng-template #rejected>
    <div class="status-badge rejected">
        <img src="../../assets/images/cross_icon.svg" alt="rejected icon">
        <span>Rejected</span>
    </div>
</ng-template>
<ng-template #expired>
    <div class="status-badge expired">
        <img src="../../assets/images/warning_icon.svg" alt="expired icon">
        <span>Expired</span>
    </div>
</ng-template>
<ng-template #tableLoader>
    <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6]">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      width: '100%',
                      height: '50px'
                    }"></ngx-skeleton-loader>
    </div>
</ng-template>
<ng-template #countLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '130px',
            height: '24px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
</ng-template>
<ng-template #createNewLoader>
    <div style="margin-left: auto">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '160px',
            height: '38px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #searchLoader>
    <ngx-skeleton-loader *ngIf="!(breakPointService.isTablet$ | async)" count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="(breakPointService.isTablet$ | async)" count="1" appearance="line" [theme]="{
            width: '280px',
            height: '38px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
</ng-template>
<ng-template #commonLoader>
    <ngx-skeleton-loader *ngIf="!(breakPointService.isTablet$ | async)" count="1" appearance="line" [theme]="{
          width: '150px',
          height: '38px',
          marginBottom: 0
        }"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="(breakPointService.isTablet$ | async)" count="1" appearance="line" [theme]="{
            width: '135px',
            height: '38px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
</ng-template>
<ng-template #moniteIdMissingerror>
    <app-service-not-available [description]="serviceNotAvailableDesc"></app-service-not-available>
</ng-template>
<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>
<ng-template #noData>
    <div style="padding-left: 12px;">-</div>
</ng-template>