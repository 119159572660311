import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { InvoicesService } from './invoices.service';
import { ActivatedRoute } from '@angular/router';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  BehaviorSubject,
  forkJoin,
  merge,
  of as observableOf,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { InvoiceDetailService } from '../invoice-detail/invoice-detail.service';
import { UserService } from 'src/app/core/services/user.service';
import { HttpHeaders } from '@angular/common/http';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MMM DD, YYYY',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class InvoicesComponent implements AfterViewInit, OnDestroy {
  vehicleId: string;
  entityId: string;
  sortOptions: any[] = [];
  filters: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private searchSubject = new Subject<boolean>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableSection', { static: false }) tableSection!: ElementRef;
  @ViewChild('paginator', { static: false }) paginator!: MatPaginator;

  statuses: any[] = [
    { id: 1, name: 'New', value: 'new' },
    { id: 2, name: 'In Approval', value: 'approve_in_progress' },
    { id: 3, name: 'Approved', value: 'waiting_to_be_paid' },
    { id: 4, name: 'Partially Paid', value: 'partially_paid' },
    { id: 5, name: 'Paid', value: 'paid' },
    { id: 6, name: 'Canceled', value: 'canceled' },
    { id: 7, name: 'Rejected', value: 'rejected' },
    { id: 8, name: 'Draft', value: 'draft' },
  ];
  senders: any[] = [];
  rowHeight = 60;
  isPageLoading = false;
  selectedFile: File | null = null;
  isLoading: boolean = false;
  isUploading: boolean = false;
  moniteIdMissing: boolean;
  search: string;
  displayedColumns: string[] = [
    'document_id',
    'supplier',
    'issued_at',
    'due_date',
    'added_by',
    'status',
    'amount',
    'action',
  ];
  invoiceList: any[] = [];
  filterStatus: any[] = [];
  serviceNotAvailableDesc =
    'This vehicle has not yet been configured for the use of transactions. Please contact support at';
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  currentFilterOrder: SortDirection = 'desc';
  activePaginationBtn = '';
  next_pagination_token = '';
  prev_pagination_token = '';
  pageSize = 10;
  updatePageSize = false;
  isClearBtn = false;
  totalRecords: number = 0;
  range = new FormGroup({
    due_date__gte: new FormControl<Date | null>(null),
    due_date__lte: new FormControl<Date | null>(null),
  });
  noInvoiceFound: boolean = false;
  maxDate: Date = new Date();
  openUploadDialog = false;
  invoiceUserDetailsMap = new Map<string, any>();
  private subscriptions: Subscription[] = [];
  selectedVehicle: any;
  entityUserToken: string | null = null;
  constructor(
    private apiHttpService: ApiHttpService,
    private invoicesService: InvoicesService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    public breakPointService: BreakPointService,
    public invoiceDetailService: InvoiceDetailService,
    private cdr: ChangeDetectorRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.entityId = this.activatedRoute.snapshot.params['gpId'];
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Invoices']);
    }, 0);
    this.searchSubject.pipe(debounceTime(1000)).subscribe(flag => {
      flag && this.searchFilter();
    });

  }

  ngAfterViewInit() {
    merge(this.paginator.page, this.filters)
      .pipe(
        startWith({}),
        tap(() => this.adjustPageSize()),
        switchMap(() => this.loadInvoices()),
        catchError(() => observableOf([]))
      )
      .subscribe(data => {
        this.dataSource.data = data;
      });
  }

  adjustPageSize() {
    const availableHeight =
      this.tableSection.nativeElement.offsetHeight - 70 - 45;
    const rowsPerPage = Math.floor(availableHeight / this.rowHeight);
    const roundedRowsPerPage = Math.ceil(rowsPerPage / 5) * 5;
    this.pageSize = Math.max(roundedRowsPerPage, 10);
    this.paginator.pageSize = this.pageSize;
    this.updatePageSize = true;
  }

  loadInvoices() {
    let paginationToken = '';
    const filters = this.filters.getValue();
    const isFilterApplied = Object.values(filters).some(value => value);
    if (this.activePaginationBtn) {
      paginationToken =
        this.activePaginationBtn === 'next'
          ? this.next_pagination_token
          : this.prev_pagination_token;
    } else {
      paginationToken = '';
    }
    if (this.dataSource.data.length > 0 || isFilterApplied || this.isClearBtn) {
      this.isPageLoading = true;
    } else {
      this.isLoading = true;
      this.isPageLoading = true;
      this.cdr.detectChanges();
    }
    return this.invoicesService
      .getInvoiceLists(
        this.vehicleId,
        this.paginator.pageSize,
        paginationToken,
        filters
      )
      .pipe(
        map(data => {
          const parsedResponse = JSON.parse(JSON.stringify(data));
          this.isLoading = false;
          this.isPageLoading = false;
          this.isClearBtn = false;

          this.next_pagination_token = parsedResponse.next_pagination_token;
          this.prev_pagination_token = parsedResponse.prev_pagination_token;
          if (this.next_pagination_token) {
            this.totalRecords =
              parsedResponse.data.length +
              this.pageSize * (this.paginator.pageIndex + 1);
          } else {
            this.totalRecords = parsedResponse.data.length;
          }
          // this.totalRecords = parsedResponse.data.length;
          if (
            !isFilterApplied &&
            parsedResponse.data &&
            parsedResponse.data.length === 0
          ) {
            this.noInvoiceFound = true;
          }
          const createdByUserIds: any[] = [
            ...new Set(
              parsedResponse.data.map(
                (item: any) => item.was_created_by_user_id
              )
            ),
          ].filter(
            (createdId: any) => !this.invoiceUserDetailsMap.has(createdId)
          );
          if (createdByUserIds.length > 0) {
            this.fetchInvoiceDetailsByCreatedIds(createdByUserIds);
          }
          return parsedResponse.data;
        })
      );
  }
  dateRangeFilter(startDate: any, endDate: any) {
    if (startDate.value && endDate.value) {
      this.activePaginationBtn = '';
      const startDateString = this.convertFilterDateFormat(startDate.value);
      const endDateString = this.convertFilterDateFormat(endDate.value);
      this.filters.next({
        ...this.filters.getValue(),
        due_date__gte: startDateString,
        due_date__lte: endDateString,
      });
      this.paginator.pageIndex = 0;
    }
  }
  searchFilter() {
    this.activePaginationBtn = '';
    const filterValue = (this.search || '').trim().toLowerCase();
    if (filterValue) {
      this.filters.next({
        ...this.filters.getValue(),
        search: filterValue,
      });
    }
    this.paginator.pageIndex = 0;
  }
  sortChange() {
    this.activePaginationBtn = '';
    this.filters.next({
      ...this.filters.getValue(),
      sort: this.sort.active,
      order: this.sort.direction,
    });
  }
  searchHandler() {
    this.searchSubject.next(true);
  }
  changeStatus(event: any) {
    this.activePaginationBtn = '';
    this.filters.next({
      ...this.filters.getValue(),
      status__in: this.filterStatus,
    });
    this.paginator.pageIndex = 0;
  }
  handleBackspaceClear(event: KeyboardEvent) {
    const filterValue = (this.search || '').trim().toLowerCase();
    // Detect backspace or delete, and reset the filter if the search is empty
    if ((event.key === 'Backspace' || event.key === 'Delete') && !filterValue) {
      this.clearFilter('search');
    }
  }
  clearFilter(filterKey: string) {
    this.isClearBtn = true;
    this.activePaginationBtn = '';
    if (filterKey === 'dateRange') {
      this.range.reset();
    }
    const currentFilters = this.filters.getValue();
    let updatedFilters = { ...currentFilters };

    if (filterKey === 'dateRange') {
      delete updatedFilters.due_date__gte;
      delete updatedFilters.due_date__lte;
    } else {
      delete updatedFilters[filterKey];
    }
    this.filters.next(updatedFilters);
    this.paginator.pageIndex = 0;
  }

  openUploadDialogHandler() {
    this.openUploadDialog = !this.openUploadDialog;
  }

  openInvoiceDetailHandler(row: any) {
    this.invoiceDetailService.routeParams.next({
      vehicleId: this.vehicleId,
      entityId: this.entityId
    });
    this.invoiceDetailService.invoiceDetails.next(row);
    this.invoiceDetailService.openInvoiceDetailDrawer();
  }

  fileChangeHandler(event: any) {
    this.selectedFile = event.target.files[0];
    
    const formData = new FormData();
    if(this.selectedFile) {
      formData.append('file', this.selectedFile);
    }
    this.openUploadDialog = false;
    const infoNotification = this.commonService.infoNotification('Uploading Invoice', 100000);
    this.apiHttpService
      .post(this.invoicesService.uploadInvoiceToOcr(this.vehicleId), formData)
      .subscribe(
        response => {
          this.openUploadDialog = false;
          infoNotification.dismiss();
          this.loadInvoices().subscribe(
            data => {
              this.dataSource.data = data;
            }
          );
          this.commonService.successNotification('Invoice uploaded successfully');
        },
        error => {
          infoNotification.dismiss();
          this.commonService.errorNotification('Failed to upload invoice', 5*1000);
          this.openUploadDialog = false;
          
        }
      );
  }
  convertFilterDateFormat(inputDate: string) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  uploadInvoice() {
    this.isUploading = true;
    const formData = new FormData();
    this.selectedFile && formData.append('file', this.selectedFile);
    this.apiHttpService
      .post(this.invoicesService.uploadInvoiceRoute(this.vehicleId), formData)
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          // this.getTransactions();
          this.isUploading = false;
        },
        error => {
          console.log({ error });
          this.isUploading = false;
        }
      );
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.uploadInvoice();
  }

  openFileDialog() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }

  convertDateFormat(dateString: string) {
    let date = new Date(dateString);

    let monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    let day = date.getDate();
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    let paddedDay = day.toString().padStart(2, '0');
    return `${month} ${paddedDay}, ${year}`;
  }

  currencyFormater(amount: number) {
    if (!amount) {
      return;
    }
    return (
      '€' +
      new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
        .format(amount / 100)
        .replace('€', '')
        .trim()
    );
  }
  onPageChange(event: PageEvent) {
    if (event.previousPageIndex !== undefined) {
      if (event.pageIndex > event.previousPageIndex) {
        this.activePaginationBtn = 'next';
      } else {
        this.activePaginationBtn = 'prev';
      }
    }
  }
  getDueDateDisplay(dueDate: string | null | '', status: string | null) {
    if (!dueDate) {
      return { showRemainingDays: false, displayText: '-' };
    }
    const today = new Date();
    const due = new Date(dueDate);
    // Set time to 00:00:00 to ensure accurate day difference calculation
    today.setHours(0, 0, 0, 0);
    due.setHours(0, 0, 0, 0);
    const diffTime = today.getTime() - due.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (status === 'paid' || status === 'rejected') {
      return {
        showRemainingDays: false,
        displayText: this.convertDateFormat(dueDate),
      };
    }

    if (diffDays > 0) {
      return {
        showRemainingDays: true,
        displayText: `${diffDays} days overdue`,
        diffDays: diffDays,
      };
    } else {
      return {
        showRemainingDays: false,
        displayText: this.convertDateFormat(dueDate),
      };
    }
  }
  fetchInvoiceDetailsByCreatedIds(creadedId: string[]) {
    const requests = creadedId.map(id =>
      this.invoicesService.getAddedByInvoiceDetails(this.vehicleId, id).pipe(
        catchError(error => {
          return observableOf(null);
        })
      )
    );
    const usersSub = forkJoin(requests).subscribe((items: any) => {
      items.forEach((user: any) => {
        if (user) {
          this.invoiceUserDetailsMap.set(user.id, user);
        }
      });
    });
    this.subscriptions.push(usersSub);
  }
  getAddedByDetails(id: string) {
    return this.invoiceUserDetailsMap.get(id) || null;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
