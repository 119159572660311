<div class="bank-transactions-container" [hidden]="transactionId">
    <ng-container *ngIf="!noTransactions; else noDataTemplate">
        <div class="transactions-subheader">
            <div class="left-section">
                <ng-container *ngIf="!isLoading; else searchLoader">
                    <mat-form-field class="transactions-search-field">
                        <img src="../../assets/images/search.svg" alt="search" matPrefix />
                        <input matInput placeholder="Search" [(ngModel)]="search"
                            (keyup)="isPageLoading=true; searchHandler()" (keyup)="handleBackspaceClear($event)"
                            autocomplete="off">
                        <!-- <button class="clear-btn" matSuffix *ngIf="search" matSuffix (click)="search=''; applyFilter()">
                            <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px"
                                alt="clear">
                        </button> -->
                        <div matIconSuffix class="clear-date" *ngIf="search">
                            <button class="btn-clear" (click)="clearFilter('search');search = ''">
                                <img src="../../../../assets/images/cross_icon_grey.svg" height="19px" width="19px"
                                    alt="add" />
                            </button>
                        </div>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!isLoading; else selectLoader">
                    <mat-form-field appearance="fill" class="account-mat-select">
                        <mat-select placeholder="Bank account" [position]="'left'" [(value)]="bankAccount"
                            #selectAccount (selectionChange)="accountFilter($event)">
                            <mat-select-trigger>
                                <div class="amount-item">
                                    {{bankAccount ? bankAccount.accountNo : 'Bank account'}}
                                </div>
                            </mat-select-trigger>
                            <mat-option *ngFor="let account of accountList" class="account-item" [value]="account"
                                [ngClass]="{'value-not-selected' : !bankAccount}">
                                <div class="account-option">
                                    <div class="axccount-number">{{account.accountNo}}</div>
                                    <div class="bank">{{account.bank}}</div>
                                </div>
                            </mat-option>
                            <div class="dropdown-clear-btn" *ngIf="bankAccount">
                                <button type="button" class="btn-clear"
                                    (click)="this.bankAccount = null; selectAccount.close();clearFilter('iban')">Clear</button>
                            </div>
                        </mat-select>
                        <img matSuffix class="arrow-down" [ngClass]="{ 'open': selectAccount.panelOpen}"
                            src='../../assets/images/arrow-down.svg' alt=" " />
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!isLoading; else selectLoader">
                    <mat-form-field appearance="fill" class="account-mat-select amount-mat-select">
                        <mat-select placeholder="Amount type" [position]="'left'" [(value)]="amountType"
                            (selectionChange)="amountFilter($event.value)" #selectAmount>
                            <mat-select-trigger>
                                <div class="amount-item">
                                    {{amountType ? (amountType === 'ingoing'? 'Incoming' : amountType ) : 'Amount
                                    type'}}
                                </div>
                            </mat-select-trigger>
                            <mat-option [ngClass]="{'value-not-selected' : !amountType}"
                                class="account-item amount-item" value="ingoing">Incoming</mat-option>
                            <mat-option [ngClass]="{'value-not-selected' : !amountType}" class="account-item"
                                value="outgoing">Outgoing</mat-option>
                            <div class="dropdown-clear-btn" *ngIf="amountType">
                                <button type="button" class="btn-clear"
                                    (click)="this.amountType = ''; selectAmount.close();clearFilter('transactionType')">Clear</button>
                            </div>
                        </mat-select>
                        <img class="arrow-down" [ngClass]="{ 'open': selectAmount.panelOpen}"
                            src="../../assets/images/arrow-down.svg" matSuffix alt=" " />
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="!isLoading; else searchLoader">
                    <mat-form-field class="mat-range-filter">
                        <mat-date-range-picker #picker
                            (closed)="dateRangeFilter(startDate,endDate)"></mat-date-range-picker>
                        <mat-datepicker-toggle matIconPrefix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker" [max]="maxDate">
                            <input matStartDate formControlName="start" placeholder="Start date" #startDate readonly>
                            <input matEndDate formControlName="end" placeholder="End date" #endDate readonly>
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                        <div matIconSuffix class="clear-date" *ngIf="startDate.value || endDate.value">
                            <button class="btn-clear" (click)="clearFilter('dateRange')">
                                <img src="../../../../assets/images/cross_icon_grey.svg" height="19px" width="19px"
                                    alt="add" />
                            </button>
                        </div>
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="right-section">
                <!-- <span class="account-id">{{this.accountNumber}}</span> -->
                <div class="cta">
                    <button class="sync" *ngIf="!isLoading; else ctaBtnLoader" (click)="syncTransactions()"
                        [matTooltip]="'Sync transactions'">
                        <ng-container *ngIf="!isSyncInProgress; else spinner">
                            <img src="../../../../assets/images/sync_transactions.svg" height="20px" width="20px"
                                alt="sync" />
                            <!-- Sync Transactions -->
                        </ng-container>
                    </button>
                </div>
                <div class="cta">
                    <button class="sync export" *ngIf="!isLoading; else ctaBtnLoader" (click)="downloadExcel()"
                        [disabled]="isDownloading"
                        [matTooltip]="showExportTooltip ? 'Export transactions for the last 6 months': 'Export filtered transactions'">
                        <ng-container *ngIf="!isDownloading; else spinner">
                            <img src="../../../../assets/images/download_icon.svg" height="20px" width="20px"
                                alt="export" />
                            <!-- Export as Excel -->
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
        <div class="transactions-section" #tableSection>
            <div class="transactions-table">
                <table [hidden]="isPageLoading" mat-table [dataSource]="dataSource" matSort matSortActive="bookingDate"
                    matSortDisableClear [matSortDirection]="currentFilterOrder" (matSortChange)="sortChange()">
                    <ng-container matColumnDef="bookingDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Date
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.bookingDate ?
                            commonService.formateDate(element.bookingDate) : '-'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="purpose">
                        <th mat-header-cell *matHeaderCellDef> Purpose </th>
                        <td mat-cell *matCellDef="let element">
                            <p>{{element.purpose ? element.purpose : '-'}}</p>
                        </td>
                        <!-- <td mat-cell *matCellDef="let element"> {{element.purpose ? element.purpose : '-'}} </td> -->
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef> Amount </th>
                        <td mat-cell *matCellDef="let element"> {{element.amount ? element.amount : '-'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="currency">
                        <th mat-header-cell *matHeaderCellDef> Currency </th>
                        <td mat-cell *matCellDef="let element"> {{element.currency ? element.currency : '-'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="partnerName">
                        <th mat-header-cell *matHeaderCellDef> Partner </th>
                        <td mat-cell *matCellDef="let element"> {{
                            element.paymentPartner ?
                            (element.paymentPartner.name ? element.paymentPartner.name : '')
                            + ((element.paymentPartner.iban && element.paymentPartner.name) ? ' / ' : '')
                            + (element.paymentPartner.iban ? element.paymentPartner.iban : '')
                            : '-'
                            }} </td>
                    </ng-container>
                    <ng-container matColumnDef="bankAccount">
                        <th mat-header-cell *matHeaderCellDef> Bank account </th>
                        <td mat-cell *matCellDef="let element"> {{element.account ? element.account : '-'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> <img
                                src="../../../../assets/images/arrow_right_icon.svg" alt="icon">
                        </td>
                    </ng-container>
                    <!-- 
                <ng-container matColumnDef="accountId">
                <th mat-header-cell *matHeaderCellDef> Account ID </th>
                <td mat-cell *matCellDef="let element">
                    {{element.name ? element.name : '-'}}
                </td>
                </ng-container>
                -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell no-row" colspan="6" style="padding:1rem;">
                            <div class="no-transaction-found-container"> <img
                                    src="../../../../assets/images/invoice_empty_icon.svg" alt="no-invoice">
                                <div class="text-section">
                                    <div class="heading">No Bank transactions found</div>
                                    <div class="supporting-text">It looks like there are no bank transactions found in
                                        linked accounts.
                                        Please check again after sometime.</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <table *ngIf="isLoading || isPageLoading || !updatePageSize" mat-table
                    [dataSource]="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]" class="loading-table">
                    <ng-container matColumnDef="bookingDate">
                        <th mat-header-cell *matHeaderCellDef> Date </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '90%',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="bankAccount">
                        <th mat-header-cell *matHeaderCellDef> Bank account </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '250px',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="purpose">
                        <th mat-header-cell *matHeaderCellDef> Purpose </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '300px',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef> Amount </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '100px',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="currency">
                        <th mat-header-cell *matHeaderCellDef> Currency </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '100px',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="partnerName">
                        <th mat-header-cell *matHeaderCellDef> Partner</th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                            width: '90%',
                                            height: '24px',
                                            marginBottom: 0
                                          }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="accountId">
                        <th mat-header-cell *matHeaderCellDef> Account ID </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '90%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-paginator [hidden]="isLoading || !updatePageSize" #paginator [pageSize]="pageSize"
                [length]="totalRecords"></mat-paginator>
        </div>
    </ng-container>

    <ng-template #noDataTemplate>
        <div class="no-transaction-wrapper">
            <div class="no-transaction-container">
                <div class="icon">
                    <img src="../../../../assets/images/invoice_empty_icon.svg" alt="icon">
                </div>
                <div class="text-area">
                    <div class="main">No Bank transactions found</div>
                    <div class="sub-text">Looks like there are no bank transactions found for now. Please check again
                        later.
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<bank-transaction-detail *ngIf="transactionId" (backClick)="hideDetails()"
    [transaction]="selecetdTransaction"></bank-transaction-detail>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '278px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #selectLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '172px',
            height: '38px',
            marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #countLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '172px',
            height: '24px',
            marginBottom: 0
      }"></ngx-skeleton-loader>
</ng-template>

<ng-template #ctaBtnLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '38px',
                height: '38px',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #backBtnLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '38px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #accountIdLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '80px',
            height: '24px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>