<div class="pnf-page">
    <div class="pageNotFound">
        <img src="../../../assets/images/warning_icon_transparant.svg" />
        <div class="text-section">
            <div class="heading">
                Service not available...
            </div>
            <div class="supporting-text">
                {{description}}<br />
                <a href="mailto:support@ace-alternatives.com">support@ace-alternatives.com</a>
            </div>
        </div>
    </div>
</div>