import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  NgZone,
  HostListener,
  OnDestroy,
} from '@angular/core';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { PopstateService } from './popstate.service';
import { ReportsService } from '../../../pages/reports/reports.service';

export interface IAnnotationLogs {
  status: string;
  userId: string;
  filename: any;
  id?: string;
}
@Component({
  selector: 'app-pdf-apryse',
  templateUrl: './pdf-apryse.component.html',
  styleUrls: ['./pdf-apryse.component.scss'],
})
export class PdfApryseComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('viewer') viewer!: ElementRef;

  @Input() filePath?: any;
  @Input() fileName?: string;
  @Input() vehicleId?: string;
  @Input() reportId?: number;
  @Input() isShowAnnotation: boolean = false;
  @Input() documentEditable: boolean = false;
  @Input() showToggleBtn: boolean = false;
  @Input() documentAnnotation: any = [];
  @Output() savePdfEvent = new EventEmitter<{
    userId: string;
    annotation: string;
    documentEditable: boolean;
    formDataFile: any;
  }>();
  wvInstance?: WebViewerInstance | null;
  isViewerInitialized = false;
  userId: string;
  viewPortMode = false;
  innerWidth: any;
  userName: string;
  private isDocumentLoaded = false;
  private annotationQueue: any[] = [];
  activeAnnotationFile = false;
  currentDocumentDetails: any;
  documentCurrentStatus = false;
  private intervalId: any;
  activeState = '';
  viewerOptions: any = {
    licenseKey: environment.pdfApryseViewerLicenseKey,
    path: 'assets/lib',
    css: 'assets/apryse-pdf.css',
  };
  constructor(
    private zone: NgZone,
    private userService: UserService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private popstateService: PopstateService,
    private reportsService: ReportsService
  ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event?: any) {
    this.commonService.onPopState();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth && this.viewPortMode) {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        if (!el.classList.contains('invoice-details-side-nav')) {
          el.style.transform = 'translate3d(-100%, 0, 0)';
          el.style.display = 'none';
        }
      });
    }
  }
  ngOnInit() {
    this.commonService.onPopState();
    this.commonService.resetAnotationData();
    const { id, name } = JSON.parse(this.userService.getUserConfig());
    this.userId = id;
    this.userName = name;
    this.popstateService.popstate$.subscribe(() => {
      if (this.viewPortMode) {
        this.viewPortMode = false;
        this.commonService.onPopState();
      }
    });
    this.commonService.disablePdfToolbarObservable$.subscribe(
      (status: boolean) => {
        const shadowRoot: any =
          document.querySelector('apryse-webviewer')!.shadowRoot;
        if (status && this.isDocumentLoaded) {
          shadowRoot
            .querySelectorAll('.ModularHeader')
            .forEach((element: any) => {
              if (element) {
                element.style.pointerEvents = 'none';
              }
            });
        } else {
          shadowRoot
            .querySelectorAll('.ModularHeader')
            .forEach((element: any) => {
              if (element) {
                element.style.pointerEvents = 'auto';
              }
            });
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.initializeViewer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isDocumentLoaded) {
      this.mergeAnnotations(this.documentAnnotation);
    } else {
      this.annotationQueue.push(...this.documentAnnotation);
    }
  }

  private initializeViewer(): void {
    this.wvInstance = null;
    this.viewer.nativeElement.innerHTML = '';
    WebViewer(this.viewerOptions, this.viewer.nativeElement).then(instance => {
      this.wvInstance = instance;
      this.isViewerInitialized = true;
      const { UI } = instance;
      const { documentViewer, annotationManager } = instance.Core;
      const header = instance.UI.getModularHeader('default-top-header');
      const existingItems = header.getItems();
      annotationManager.setCurrentUser(this.userName);
      // disable toolbar groups
      instance.UI.disableElements(['toolbarGroup-Forms']);
      instance.UI.disableElements(['fullscreenButton']);
      instance.UI.disableElements(['addReplyAttachmentButton']);
      instance.UI.disableElements(['fileAttachmentButton']);
      instance.UI.disableElements(['stampToolButton']);
      instance.UI.disableElements(['addImageContentToolGroupButton']);
      instance.UI.disableElements(['markInsertTextToolButton']);
      instance.UI.disableElements(['markReplaceTextToolButton']);
      instance.UI.disableElements(['toolbarGroup-Edit']);
      // instance.UI.disableElements(['addParagraphToolGroupButton']);
      // instance.UI.enableFeatures([instance.UI.Feature.ContentEdit]);
      // Define the maximize/minimize stateful button
      // instance.UI.enableElements(['toolbarGroup-Annotate']);
      // instance.UI.enableFeatures([instance.UI.Feature.Annotations]);
      const maximizeMinimizeButton = {
        type: 'statefulButton',
        initialState: this.activeState ? this.activeState : 'Maximize',
        states: {
          Maximize: {
            img: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 0C19.5523 0 20 0.44772 20 1V9H18V2H2V18H8V20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H19ZM19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V12C10 11.4477 10.4477 11 11 11H19ZM9.5 4L7.45711 6.04311L9.7071 8.2929L8.2929 9.7071L6.04311 7.4571L4 9.5V4H9.5Z" fill="#868E96"/></svg>',
            title: 'Maximize',
            onClick: (update: any, activeState: any) => {
              this.activeState = 'Minimize';
              this.handleViewPortMode();
              update('Minimize');
            },
          },
          Minimize: {
            img: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 0C19.5523 0 20 0.44772 20 1V9H18V2H2V18H8V20H1C0.44772 20 0 19.5523 0 19V1C0 0.44772 0.44772 0 1 0H19ZM19 11C19.5523 11 20 11.4477 20 12V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V12C10 11.4477 10.4477 11 11 11H19Z" fill="#868E96"/><path d="M6.24992 7.66392L4.20703 9.70703L9.70703 9.70703L9.70703 4.20703L7.66392 6.24993L5.41413 3.99993L3.99993 5.41413L6.24992 7.66392Z" fill="#868E96"/></svg>',
            title: 'Minimize',
            onClick: (update: any, activeState: any) => {
              this.activeState = 'Maximize';
              this.handleViewPortMode();
              update('Maximize');
            },
          },
        },
        dataElement: 'maximizeMinimizeButton',
      };

      const pdfSaveButtom = {
        type: 'statefulButton',
        initialState: this.showToggleBtn ? 'Docx' : 'Pdf',
        states: {
          Pdf: {
            img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"><rect x="0" y="0" width="20" height="20" rx="3" ry="3" fill="#f14e28"/><text x="50%" y="50%" font-size="5" text-anchor="middle" fill="#fff" dy=".3em">PDF</text></svg>',
            title: 'Save Pdf Annotation',
            onClick: (update: any, activeState: any) => {
              this.zone.run(() => this.saveAnnotations());
              update('Pdf');
            },
          },
          Docx: {
            img: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20"><rect x="0" y="0" width="20" height="20" rx="3" ry="3" fill="#1e70c1"/><text x="50%" y="50%" font-size="5" text-anchor="middle" fill="#fff" dy=".3em">DOC</text></svg>',
            title: this.documentEditable
              ? 'Save Document'
              : 'Save Document Annotation',
            onClick: (update: any, activeState: any) => {
              this.zone.run(() => this.saveAnnotations());
              update('Docx');
            },
          },
        },
        dataElement: 'save-pdf-doc-Button',
      };

      const toggleButton: any = {
        type: 'statefulButton',
        initialState: this.documentEditable ? 'annotate' : 'Edit',
        states: {
          Edit: {
            img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 20h9M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"/></svg>',
            title: 'Edit Document',
            onClick: (update: any, activeState: any) => {
              this.zone.run(() => this.toggleBtn());
              update('Edit');
            },
          },
          annotate: {
            img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/></svg>',
            title: 'Annotate Document',
            onClick: (update: any, activeState: any) => {
              this.zone.run(() => this.toggleBtn());
              update('annotate');
            },
          },
        },
        dataElement: 'save-toogle-button',
      };

      const updatedItems = [
        ...existingItems,
        // pdfSaveButtom,
        {
          type: 'customButton',
          dataElement: 'save-pdf-Button',
          label: 'Save',
          title: this.showToggleBtn
            ? this.documentEditable
              ? 'Save Document'
              : 'Save Document Annotation'
            : 'Save Pdf Annotation',
          class: 'custom-save-pdf-button',
          onClick: () => {
            this.zone.run(() => this.saveAnnotations());
          },
        },
      ];
      const downloadButton = new instance.UI.Components.PresetButton({
        buttonType: 'downloadButton',
        dataElement: 'presetButton-download',
      });
      header.setItems([
        ...updatedItems,
        this.showToggleBtn ? toggleButton : '',
        !this.isShowAnnotation ? downloadButton : '',
        maximizeMinimizeButton,
      ]);
      if (!this.isShowAnnotation) {
        instance.UI.disableElements([
          'default-ribbon-group',
          'save-pdf-doc-Button',
          'save-pdf-Button',
          'notesPanelToggle',
          'tools-header',
          'save-toogle-button',
        ]);
      }
      this.isShowAnnotation &&
        !this.activeAnnotationFile &&
        this.AccessAnotationToolbar(false);
      // documentViewer.updateView();
      instance.UI.loadDocument(this.filePath, { filename: this.fileName });
      this.setupDocumentViewerEvents(instance);
    });
  }
  toggleBtn() {
    this.documentEditable = !this.documentEditable;
    if (this.documentEditable) {
      this.viewerOptions.enableOfficeEditing = true;
    } else {
      this.viewerOptions.enableOfficeEditing = false;
      this.isDocumentLoaded = false;
      this.getDocumentAnnotation(this.vehicleId, this.reportId);
    }
    this.activeAnnotationFile = true;
    this.initializeViewer();
  }
  private setupDocumentViewerEvents(instance: WebViewerInstance): void {
    const { documentViewer, annotationManager } = instance.Core;

    documentViewer.addEventListener('documentLoaded', async () => {
      if (this.isShowAnnotation && !this.activeAnnotationFile) {
        this.checkAnnotationLogStatus(this.vehicleId, this.reportId);
        this.startInterval();
      }
      instance.UI.setZoomLevel('85%');
      if (!this.documentEditable) {
        instance.UI.setToolbarGroup('toolbarGroup-View');
      } else {
        instance.UI.setToolbarGroup('toolbarGroup-oe-Home');
      }
      this.isDocumentLoaded = true;
      if (this.annotationQueue.length > 0) {
        const setannotation = await this.mergeAnnotations(this.annotationQueue);
        this.annotationQueue = [];
      }
      documentViewer
        .getDocument()
        .addEventListener('officeDocumentEdited', () => {
          this.handleEvents();
        });
    });

    annotationManager.addEventListener(
      'annotationChanged',
      async (annotations, action, { imported }) => {
        if (imported) return;
        const xfdfString = await annotationManager.exportAnnotations();
        this.commonService.setAnnotationChanges({
          userId: this.userId,
          annotation: xfdfString,
          annotationChangeDetect: true,
          fileName: this.fileName,
          documentEditableChange: false,
        });
      }
    );
    documentViewer.addEventListener('textSelected', () => {
      this.handleEvents();
    });
  }

  async handleEvents() {
    const { documentViewer } = this.wvInstance!.Core;
    if (this.documentEditable) {
      const extension = this.fileName?.split('.').pop();
      const docData = await documentViewer.getDocument().getFileData();
      const blob = new Blob([docData], {
        type:
          extension == 'doc'
            ? 'application/msword'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const formData = new FormData();
      formData.append('file', blob, this.fileName);
      formData.append('userId', this.userId ? this.userId : '');
      this.commonService.setAnnotationChanges({
        userId: this.userId,
        annotation: '',
        annotationChangeDetect: false,
        fileName: this.fileName,
        formDataFile: formData,
        documentEditableChange: true,
      });
    }
  }

  private async mergeAnnotations(documentAnnotations: any[]): Promise<void> {
    console.log('documentAnnotations');
    if (documentAnnotations.length > 0 && !this.documentEditable) {
      const { annotationManager } = this.wvInstance?.Core!;
      // first way
      // const promises = documentAnnotations.map(async (anno: any) => {
      //   try {
      //     const annotations = await annotationManager.importAnnotationCommand(
      //       anno.annotation ? anno.annotation : ''
      //     );
      //     annotations.forEach((annotation: any) => {
      //       annotationManager.redrawAnnotation(annotation);
      //     });
      //   } catch (error) {
      //     console.error('Error processing annotation:', error);
      //   }
      // });
      // second way
      // const promises = documentAnnotations.map(async (anno: any) => {
      //   try {
      //     if (anno.annotation) {
      //       await annotationManager.importAnnotations(anno.annotation);
      //     }
      //   } catch (error) {
      //     console.error('Error processing annotation:', error);
      //   }
      // });
      // await Promise.allSettled(promises);
      // third way
      const promises = documentAnnotations.forEach((anno: any) => {
        try {
          if (anno.annotation) {
            annotationManager.importAnnotations(anno.annotation);
          }
        } catch (error) {
          console.error('Error processing annotation:', error);
        }
      });
    }
  }

  async saveAnnotations() {
    if (this.wvInstance && this.wvInstance.Core) {
      const { annotationManager, documentViewer } = this.wvInstance.Core;
      // save the file
      const extension = this.fileName?.split('.').pop();
      const xfdfString = await annotationManager.exportAnnotations();
      const docData = await documentViewer.getDocument().getFileData();
      const blob = new Blob([docData], {
        type:
          extension == 'doc'
            ? 'application/msword'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const formData = new FormData();
      formData.append('file', blob, this.fileName);
      formData.append('userId', this.userId ? this.userId : '');
      this.savePdfEvent.emit({
        userId: this.userId,
        annotation: xfdfString,
        documentEditable: this.documentEditable,
        formDataFile: formData,
      });
    }
  }

  handleViewPortMode(): void {
    this.viewPortMode = !this.viewPortMode;
    if (this.viewPortMode) {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        if (!el.classList.contains('invoice-details-side-nav')) {
          el.style.transform = 'translate3d(-100%, 0, 0)';
        }
      });
      document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
        el.style.zIndex = '0';
        el.style.marginLeft = '0px';
      });
    } else {
      document.querySelectorAll('mat-sidenav').forEach((el: any) => {
        el.style.transform = 'none';
        el.style.display = 'block';
      });
      if (window.innerWidth > 959 && window.innerWidth < 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '70px';
        });
      }
      if (window.innerWidth >= 1280) {
        document.querySelectorAll('mat-sidenav-content').forEach((el: any) => {
          el.style.marginLeft = '200px';
        });
      }
    }
    this.cdr.detectChanges();
  }
  getDocumentAnnotation(vehicleId: any, reportId: any) {
    this.documentAnnotation = [];
    this.reportsService
      .getReportAnnotation(vehicleId, reportId, this.fileName)
      .subscribe({
        next: (response: any) => {
          if (response && response.length > 0) {
            this.documentAnnotation = [response[0]];
            if (this.isDocumentLoaded) {
              this.mergeAnnotations(this.documentAnnotation);
            } else {
              this.annotationQueue.push(...this.documentAnnotation);
            }
          }
        },
        error: error => {
          this.documentAnnotation = [];
        },
        complete: () => {},
      });
  }
  checkAnnotationLogStatus(vehicleId: any, reportId: any) {
    this.reportsService
      .getAnnotationLogStatus(vehicleId, reportId, this.fileName)
      .subscribe({
        next: (response: any) => {
          if (response && response.data) {
            if (response.data) {
              this.createAnotationLog(this.vehicleId, this.reportId);
            }
          }
        },
        error: error => {},
        complete: () => {},
      });
  }
  intervalAnnotationLogStatus(vehicleId: any, reportId: any) {
    this.reportsService
      .getAnnotationLogStatus(vehicleId, reportId, this.fileName)
      .subscribe({
        next: (response: any) => {
          if (
            response &&
            response.data &&
            response.data.isBeingAnnotated === false
          ) {
            this.createAnotationLog(this.vehicleId, this.reportId);
          }
        },
        error: error => {},
        complete: () => {},
      });
  }

  startInterval() {
    this.intervalId = setInterval(() => {
      this.intervalAnnotationLogStatus(this.vehicleId, this.reportId);
      this.getDocumentAnnotation(this.vehicleId, this.reportId);
    }, 30000);
  }
  clearInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  createAnotationLog(vehicleId: any, reportId: any) {
    const params: IAnnotationLogs = {
      userId: this.userId,
      status: 'IN_PROGRESS',
      filename: this.fileName,
    };

    this.reportsService
      .createAnnotationLogs(vehicleId, reportId, params)
      .subscribe({
        next: (response: any) => {
          if (
            response &&
            response.status &&
            response.status === 'ACTION_NOT_ALLOWED'
          ) {
            if (this.userId === response.userId) {
              this.AccessAnotationToolbar(true);
              this.documentCurrentStatus = true;
              this.currentDocumentDetails = { id: response.id };
            } else {
              this.commonService.infoNotification(response.message);
              this.AccessAnotationToolbar(false);
            }
            // this.commonService.infoNotification(response.message);
            // this.AccessAnotationToolbar(false);
          }
          if (
            response &&
            response.status &&
            response.status === 'IN_PROGRESS'
          ) {
            this.currentDocumentDetails = response;
            this.AccessAnotationToolbar(true);
            this.documentCurrentStatus = true;
          }
        },
        error: error => {
          console.log('error', error);
        },
        complete: () => {},
      });
  }

  updateAnnotationLog(vehicleId: any, reportId: any, status: string) {
    const params: IAnnotationLogs = {
      userId: this.userId,
      status: status,
      filename: this.fileName,
      id: this.currentDocumentDetails.id ? this.currentDocumentDetails.id : '',
    };

    this.reportsService
      .updateAnnotationLogs(vehicleId, reportId, params)
      .subscribe({
        next: (response: any) => {},
        error: error => {
          console.log('error', error);
        },
        complete: () => {
          this.currentDocumentDetails = {};
        },
      });
  }

  AccessAnotationToolbar(status: any) {
    if (status) {
      this.wvInstance?.UI.enableElements([
        'default-ribbon-group',
        'save-pdf-doc-Button',
        'save-pdf-Button',
        'notesPanelToggle',
        'tools-header',
        'textPopup',
        'save-toogle-button',
        'annotationPopup',
        'officeEditorToolsHeader',
        'office-editor-default-ribbon-group',
        'editorModeAndSearch',
        'annotationCommentButton',
        'annotationStyleEditButton',
        'linkButton',
        'annotationDeleteButton',
        'contextMenuPopup',
      ]);
    } else {
      this.wvInstance?.UI.disableElements([
        'default-ribbon-group',
        'save-pdf-doc-Button',
        'save-pdf-Button',
        'notesPanelToggle',
        'tools-header',
        'textPopup',
        'save-toogle-button',
        'annotationPopup',
        'officeEditorToolsHeader',
        'office-editor-default-ribbon-group',
        'editorModeAndSearch',
        'annotationCommentButton',
        'annotationStyleEditButton',
        'linkButton',
        'annotationDeleteButton',
        'contextMenuPopup',
      ]);
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: Event) {
    this.documentCurrentStatus &&
      this.currentDocumentDetails &&
      this.currentDocumentDetails.id &&
      this.updateAnnotationLog(this.vehicleId, this.reportId, 'DONE');
    this.clearInterval();
  }
  ngOnDestroy(): void {
    this.documentCurrentStatus &&
      this.currentDocumentDetails &&
      this.currentDocumentDetails.id &&
      this.updateAnnotationLog(this.vehicleId, this.reportId, 'DONE');
    this.clearInterval();
  }
}
