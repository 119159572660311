import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvestorsService } from '../../investors/investors.service';
import { ReportsService } from '../reports.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ReportPreviewComponent,
  SaveAnnotationDataDialog,
} from '../report-preview/report-preview.component';
import { BreakPointService } from '../../../shared/services/break-point.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../core/services/user.service';

export interface IReportDetail {
  id: number;
  name: string;
  type: string;
  state: string;
  limitedPartners: IlimitedPartner[];
}
export interface IlimitedPartner {
  limitedPartnerId: string;
  investorId: string;
  name: string;
  renderedDocumentsCount: number;
  staticDocumentsCount: number;
  sentAt: string;
}
export interface IAnnotation {
  userId?: string;
  annotation?: string;
  fileName?: string;
  annotationChangeDetect?: boolean;
  formDataFile?: any;
  documentEditable?: boolean;
  documentEditableChange?: boolean;
}
@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
})
export class ReportDetailsComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() reportId: string;
  @Input() gpId: string;
  @Input() vehicleId: string;
  @Output() backClick = new EventEmitter();
  displayedColumns: string[] = [
    'investorId',
    'name',
    'documents',
    'email_sents',
    'details',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  reportDetail: IReportDetail;
  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  search: string;
  isLoading: boolean = false;
  uploadFileLoading = false;
  typesConst: any = {
    crr: 'CRR',
    quarterlyReportings: 'Quarterly report',
    capitalCallsAndClosings: 'Capital call/Closing',
    distributions: 'Distribution',
    shareholderResolutions: 'Shareholder resolution',
    annualReportsAndAuditReports: 'Annual report/Audit report',
    statementsOfAccount: 'Statement of account',
    tax: 'Tax',
    principalLevelTopics: 'Principal level topic',
  };
  private subscription: Subscription = new Subscription();
  userId: string | undefined;
  annotationChange: boolean = false;
  xfdfString: string | undefined;
  fileName: string | undefined;
  pdfSaving: boolean = false;
  formDataFile: any;
  documentEditable: boolean = false;
  constructor(
    private reportsService: ReportsService,
    public commonService: CommonService,
    public breakpointService: BreakPointService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    const { id } = JSON.parse(this.userService.getUserConfig());
    this.userId = id;
    this.getReportDetail();
    this.subscription =
      this.commonService.annotationChangesObservable$.subscribe(
        async (value: IAnnotation) => {
          this.annotationChange = false;
          this.xfdfString = '';
          this.formDataFile = '';
          this.fileName = '';
          this.documentEditable = false;
          if (value.annotationChangeDetect) {
            this.annotationChange = value.annotationChangeDetect;
            this.xfdfString = value.annotation;
            this.fileName = value.fileName;
            this.userId = value.userId;
          }
          if (value.documentEditableChange) {
            this.documentEditable = value.documentEditableChange;
            this.fileName = value.fileName;
            this.formDataFile = value.formDataFile;
          }
        }
      );
    this.commonService.disablePdfToolbarObservable$.subscribe(
      (status: boolean) => {
        this.pdfSaving = status;
      }
    );
  }

  getReportDetail() {
    this.isLoading = true;
    this.reportsService
      .getReportDetails(this.vehicleId, this.reportId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.reportDetail = parsedResponse.data;
          if (!this.reportDetail.name) {
            this.commonService.OpenAccessDenied(
              this.gpId,
              this.vehicleId,
              'reports'
            );
            return;
          }
          this.dataSource = new MatTableDataSource(
            parsedResponse.data.limitedPartners
          );
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) =>
            item[property].toLocaleLowerCase();
          this.commonService.setSecondaryPath([this.reportDetail.name]);
        },
        error: error => {
          if (
            error.error.message.includes('Limited partner does not exist') ||
            error.error.message.includes('uuid')
          ) {
            this.commonService.OpenAccessDenied(
              this.gpId,
              this.vehicleId,
              'reports'
            );
            return;
          }
          this.isLoading = false;
          this.commonService.errorNotification(
            'Failed to retrieve report details'
          );
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();

    // Update filterPredicate to handle the specific columns
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const id = data.investorId
        ? data.investorId.toString().toLowerCase()
        : '';
      const name = data.name ? data.name.toLowerCase() : '';

      return id.includes(filter) || name.includes(filter);
    };

    this.dataSource.filter = filterValue;
  }

  navigateBack() {
    this.backClick.emit();
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event, fileInput: HTMLInputElement) {
    const input = event.target as HTMLInputElement;
    let uploadFile: any;
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
    ];
    if (input.files && input.files[0]) {
      uploadFile = input.files[0];
      if (!allowedTypes.includes(uploadFile.type)) {
        this.commonService.infoNotification(
          'Invalid file format. Please upload a .docx file.'
        );
        fileInput.value = '';
        return;
      }
    }
    const formData = new FormData();
    formData.append('file', uploadFile);
    formData.append('userId', this.userId ? this.userId : '');
    this.uploadFileLoading = true;
    this.reportsService
      .saveUploadReportFile(this.vehicleId, this.reportId, formData)
      .subscribe({
        next: response => {
          fileInput.value = '';
          this.commonService.successNotification('File uploaded successfully.');
          this.uploadFileLoading = false;
        },
        error: error => {
          fileInput.value = '';
          this.commonService.infoNotification('File Upload Error.');
          this.uploadFileLoading = false;
        },
        complete: () => {},
      });
  }

  openPreview(limitedPartner: IlimitedPartner) {
    if (
      !limitedPartner.renderedDocumentsCount &&
      !limitedPartner.staticDocumentsCount &&
      !limitedPartner.sentAt
    ) {
      return;
    }
    const dialogRef = this.dialog.open(ReportPreviewComponent, {
      data: {
        vehicleId: this.vehicleId,
        reportId: this.reportId,
        limitedPartner: limitedPartner,
      },
      disableClose: true,
    });
    dialogRef.backdropClick().subscribe((data: any) => {
      if (!this.pdfSaving) {
        if (this.annotationChange || this.documentEditable) {
          const confirmationDialogRef = this.dialog.open(
            SaveAnnotationDataDialog,
            {
              data: {
                annotationChange: this.annotationChange,
                xfdfString: this.xfdfString,
                vehicleId: this.vehicleId,
                reportId: this.reportId,
                filename: this.fileName,
                userId: this.userId,
                documentEditable: this.documentEditable,
                formDataFile: this.formDataFile,
              },
            }
          );
          confirmationDialogRef.afterClosed().subscribe(result => {
            if (result && result.status) {
              confirmationDialogRef.close();
              dialogRef.close();
              this.commonService.resetAnotationData();
            }
          });
        } else {
          dialogRef.close();
          this.commonService.resetAnotationData();
        }
      }
    });
    dialogRef.keydownEvents().subscribe(key => {
      if (key.code === 'Escape') {
        if (!this.pdfSaving) {
          if (this.annotationChange || this.documentEditable) {
            const confirmationDialogRef = this.dialog.open(
              SaveAnnotationDataDialog,
              {
                data: {
                  annotationChange: this.annotationChange,
                  xfdfString: this.xfdfString,
                  vehicleId: this.vehicleId,
                  reportId: this.reportId,
                  filename: this.fileName,
                  userId: this.userId,
                  documentEditable: this.documentEditable,
                  formDataFile: this.formDataFile,
                },
              }
            );
            confirmationDialogRef.afterClosed().subscribe(result => {
              if (result && result.status) {
                confirmationDialogRef.close();
                dialogRef.close();
                this.commonService.resetAnotationData();
              }
            });
          } else {
            dialogRef.close();
            this.commonService.resetAnotationData();
          }
        }
      }
    });
  }
}
