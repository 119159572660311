<div class="add-quarter">
  <div class="content">
    <div class="heading">
      Add new quarter
      <button (click)="dialogRef.close()">
        <img src="../../../assets/images/cross_icon_grey.svg" alt="close" />
      </button>
    </div>
    <div class="form-field">
      <mat-form-field appearance="fill" class="quarter">
        <mat-select
          placeholder="Quarter"
          [(ngModel)]="quarter"
          (openedChange)="toggleSelect()">
          <mat-option
            *ngFor="let quarter of quarters"
            [value]="quarter"
            class="add-quarter-options">
            {{ quarter.year + ' Q' + quarter.quarter }}
          </mat-option>
        </mat-select>
        <img
          class="arrow-down"
          [ngClass]="{ 'open': isQuarterOpen}"
          src="../../assets/images/arrow-down.svg"
          matSuffix />
      </mat-form-field>
    </div>
    <!-- <mat-checkbox class="import-checkbox">Copy data from the latest quarter</mat-checkbox> -->
  </div>
  <div class="action">
    <button mat-flat-button class="cancel" (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="save"
      type="button"
      [disabled]="!quarter"
      (click)="addQuarter()">
      <ng-container *ngIf="!isAddQuarterLoading; else  whiteSpinner">
        Add
      </ng-container>
    </button>
  </div>
</div>

<ng-template #whiteSpinner>
  <app-spiner-loader [color]="'white'"></app-spiner-loader>
</ng-template>
