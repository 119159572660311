import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ApiHttpService } from '../../core/services/api-http.service';
import { ApiEndpointsService } from '../../core/services/api-endpoints.service';

export interface IportfolioDetails {
  id?: string,
  name?: string,
  inPortfolioSince?: string,
  logo?: string,
  type?: string,
  isEdit?: boolean,
  vehicleId?: string,
}
export interface Iportfolio {
  id: string,
  name: string,
  inPortfolioSince: string,
  logo: string,
  type?: string,
  isEdit?: boolean
}

@Injectable({
  providedIn: 'root'
})

export class PortfolioService {
  public openPortfolioDrawerFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private portfolioDetails: BehaviorSubject<IportfolioDetails | null> = new BehaviorSubject<IportfolioDetails | null>(null);
  portfolioDetailsObservable$ = this.portfolioDetails.asObservable();
  public refreshPortfolioList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshPortfolioListObservable$ = this.refreshPortfolioList.asObservable();

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  public openPortfolioDrawer(): void {
    this.openPortfolioDrawerFlag.next(true);
  }

  public closePortfolioDrawer(): void {
    this.openPortfolioDrawerFlag.value && this.openPortfolioDrawerFlag.next(false);
  }

  public setRefreshPortfolio(data: boolean): void {
    this.refreshPortfolioList.next(data);
  }

  public setPortfolioDetails(data: IportfolioDetails | null): void {
    this.portfolioDetails.next(data);
  }

  getPortfolioList(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/portfolio`,
        false
      )
    );
  }

  addPortfolio(vehicleId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/portfolio/company`,
        false
      ),
      data
    );
  }

  updatePortfolio(portfolioCompanyId: string, data: any) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `portfolio/company/${portfolioCompanyId}`,
        false
      ),
      data
    );
  }

  deletePortfolio(portfolioCompanyId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `portfolio/company/${portfolioCompanyId}`,
        false
      )
    );
  }
}