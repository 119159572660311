<div class="delete-portfolio">
  <div class="content">
    <div class="heading">
      Delete “{{data.portfolio.name}}” ?
      <button (click)="dialogRef.close()" [disabled]="loading">
        <img src="../../../assets/images/cross_icon_grey.svg" alt="close" />
      </button>
    </div>
    <div class="form-field">
      <div class="description">
        Are you sure you want to delete? This will remove all the data including
        the reports for all period.
      </div>
    </div>
  </div>
  <div class="action">
    <button mat-flat-button class="cancel" (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="delete"
      (click)="deletePortfolioCompany()"
      [disabled]="loading">
      Delete
    </button>
  </div>
</div>
