import { Directive, AfterViewInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Directive({
  selector: 'mat-menu[portfolio-action-position]',
})
export class PMenuPositionDirective implements AfterViewInit {
  constructor(private menu: MatMenu) {}

  ngAfterViewInit(): void {
    this.menu.overlayPanelClass = `portfolio-action`;
  }
}