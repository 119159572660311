import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spiner-loader',
  templateUrl: './spiner-loader.component.html',
  styleUrls: ['./spiner-loader.component.scss'],
})
export class SpinerLoaderComponent {
  constructor() {}
  @Input() color: string;
}
