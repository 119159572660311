import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { ReportsService } from '../reports.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserService } from '../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';

export interface IDocument {
  id: number;
  name: string;
  mimeType: string;
}
export interface IAnnotation {
  userId?: string;
  annotation?: string;
  annotationChangeDetect?: boolean;
  formDataFile?: any;
  documentEditable?: boolean;
  documentEditableChange?: boolean;
  fileName?: string;
}

export interface IMailPreview {
  sentAt: string;
  subject: string;
  htmlContent: string;
  recipients: string[];
  cc: string[];
}

export interface IUploadedDocument {
  id: string;
  name: string;
  mimeType: string;
  reportId: number;
  filePath: number;
}
@Component({
  selector: 'app-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.scss'],
})
export class ReportPreviewComponent implements OnDestroy {
  reportHeading: string;
  selectedFile: any;
  selectedType = 'email';
  renderedDocuments: IDocument[] = [];
  staticDocuments: IDocument[] = [];
  mailPreview: IMailPreview[] = [];
  uploadedDocuments: IUploadedDocument[] = [];
  filePath: any;
  isLoading: boolean = false;
  isLoadingDoc: boolean = false;
  isLoadingList: boolean = false;
  mobPdfActive: boolean = true;
  safeHtmlContent: SafeHtml;
  @ViewChild('iframeElement', { static: true })
  iframeElement: ElementRef<HTMLIFrameElement>;
  documentAnnotation: any;
  userId: string;
  annotationChange: boolean = false;
  xfdfString: string | undefined;
  private subscription: Subscription = new Subscription();
  savePdfLoading = false;
  isEditable = false;
  showToggleBtn: boolean = false;
  formDataFile: any;
  documentEditable: boolean = false;
  fileName: any;
  constructor(
    public breakPointService: BreakPointService,
    private reportsService: ReportsService,
    public commonService: CommonService,
    private dialogRef: MatDialogRef<ReportPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.commonService.resetAnotationData();
    this.commonService.setDisablePdfToolbarStatus(false);
    const { id } = JSON.parse(this.userService.getUserConfig());
    this.userId = id;
    this.reportHeading = this.data.limitedPartner.name;
    this.getLPReportDetail();
    this.subscription =
      this.commonService.annotationChangesObservable$.subscribe(
        async (value: IAnnotation) => {
          this.annotationChange = false;
          this.xfdfString = '';
          this.formDataFile = '';
          this.documentEditable = false;
          this.fileName = '';
          if (value.annotationChangeDetect) {
            this.annotationChange = value.annotationChangeDetect;
            this.xfdfString = value.annotation;
            this.fileName = value.fileName;
          }
          if (value.documentEditableChange) {
            this.documentEditable = value.documentEditableChange;
            this.fileName = value.fileName;
            this.formDataFile = value.formDataFile;
          }
        }
      );
  }

  getLPReportDetail(isRefreshDoc = true, saveType = '', data?: any) {
    this.isLoadingList = true;
    this.mailPreview = [];
    this.reportsService
      .getLPReportDetails(
        this.data.vehicleId,
        this.data.reportId,
        this.data.limitedPartner.limitedPartnerId
      )
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.renderedDocuments = parsedResponse.data.renderedDocuments;
          this.staticDocuments = parsedResponse.data.staticDocuments;
          this.uploadedDocuments = parsedResponse.data.uploadedDocuments;
          if (parsedResponse.data.mailPreview.sentAt) {
            this.mailPreview.push(parsedResponse.data.mailPreview);
          }
          if (isRefreshDoc) {
            if (this.mailPreview.length) {
              this.selectedFile = [...this.mailPreview];
              this.selectedType = 'email';
            } else if (this.renderedDocuments.length) {
              this.selectedFile = [this.renderedDocuments[0]];
              this.selectedType = 'renderedPdf';
            } else if (this.staticDocuments.length) {
              this.selectedFile = [this.staticDocuments[0]];
              this.selectedType = 'staticPdf';
            } else {
              this.selectedFile = [this.uploadedDocuments[0]];
              this.selectedType = 'uploadedDoc';
            }
            if (
              this.selectedType !== 'email' &&
              this.selectedType !== 'uploadedDoc'
            ) {
              this.getFileData(this.selectedFile[0].id);
            }
            if (this.selectedType == 'uploadedDoc') {
              this.getDocFileData(this.selectedFile[0].filePath);
            }
          } else {
            if (
              this.selectedType == 'uploadedDoc' &&
              saveType == 'manualSave'
            ) {
              // this.selectedFile = [
              //   this.uploadedDocuments[this.uploadedDocuments.length - 1],
              // ];
              this.selectedFile = [{ ...data, name: data.fileName }];
              this.getDocFileData(this.selectedFile[0].filePath);
            }
          }
        },
        error: error => {
          this.commonService.errorNotification(
            'Failed to retrieve report details'
          );
          this.handleClose();
        },
        complete: () => {
          this.isLoadingList = false;
        },
      });
  }

  getFileData(fileId: number) {
    this.isLoading = true;
    this.filePath = '';
    this.reportsService
      .getFilebyId(this.data.vehicleId, this.data.reportId, fileId)
      .subscribe({
        next: response => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8',
          });
          this.isEditable = false;
          this.showToggleBtn = false;
          this.filePath = blob;
          this.getDocumentAnnotation(this.data.vehicleId, this.data.reportId);
        },
        error: error => {
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve the file.');
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  //
  getDocFileData(filePathValue: string) {
    this.isLoading = true;
    this.filePath = '';
    this.reportsService
      .getDocumentFilebyFilePath(
        this.data.vehicleId,
        this.data.reportId,
        filePathValue
      )
      .subscribe({
        next: (response: any) => {
          this.isEditable = false;
          this.showToggleBtn = true;
          this.filePath = response?.url;
          this.getDocumentAnnotation(this.data.vehicleId, this.data.reportId);
        },
        error: error => {
          console.log('error', error);
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve the file.');
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  handleClose(): void {
    // this.dialogRef.close();
    if (this.annotationChange || this.documentEditable) {
      this.openAnnotationDailog(true);
    } else {
      this.dialogRef.close();
    }
  }

  onSelectionChange(type: string, event: MatSelectionListChange) {
    this.selectedType = type;
    this.selectedFile = event.options.map(option => option.value);
    if (type != 'email' && type != 'uploadedDoc') {
      this.getFileData(this.selectedFile[0].id);
    }
    if (type == 'uploadedDoc') {
      this.getDocFileData(this.selectedFile[0].filePath);
    }

    // if (this.annotationChange) {
    //   this.openAnnotationDailog(false, type, event);
    // } else {
    //   this.selectedType = type;
    //   this.selectedFile = event.options.map(option => option.value);
    //   if (type != 'email') {
    //     this.getFileData(this.selectedFile[0].id);
    //   }
    // }
  }
  handleOptionClick(type: string, file: any) {
    if (this.savePdfLoading || this.isLoading) {
      return;
    }
    if (this.selectedFile[0].id === file.id) {
      return;
    }
    if (this.annotationChange || this.documentEditable) {
      this.openAnnotationDailog(false, type, file);
    } else {
      this.selectedType = type;
      this.selectedFile = [{ ...file }];
      if (type != 'email' && type != 'uploadedDoc') {
        this.getFileData(this.selectedFile[0].id);
      }
      if (type != 'email' && type == 'uploadedDoc') {
        this.getDocFileData(this.selectedFile[0].filePath);
      }
    }
  }

  toggleMenu() {
    if (this.savePdfLoading || this.isLoading) {
      return;
    }
    this.mobPdfActive = !this.mobPdfActive;
  }

  joinRecipients(recipients: string[]) {
    return recipients.join(', ');
  }

  isPDF(filename: any) {
    const extension = filename.split('.').pop().toLowerCase();
    return extension === 'pdf' || extension === 'docx' || extension === 'doc';
  }

  downloadFile() {
    const blobURL = URL.createObjectURL(this.filePath);

    const a = document.createElement('a');
    a.href = blobURL;
    a.download = this.selectedFile[0].name;
    a.click();

    URL.revokeObjectURL(blobURL);
  }

  getHtmlContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
  onSavePdf(annotationData: IAnnotation) {
    this.commonService.showLoader(true);
    this.commonService.setDisablePdfToolbarStatus(true);
    this.savePdfLoading = true;
    if (annotationData.documentEditable) {
      this.reportsService
        .saveUploadReportFile(
          this.data.vehicleId,
          this.data.reportId,
          annotationData.formDataFile
        )
        .subscribe({
          next: async (response: any) => {
            if (response && response.id) {
              this.commonService.successNotification(
                'Document saved successfully.'
              );
              this.commonService.showLoader(false);
              this.commonService.setDisablePdfToolbarStatus(false);
              this.savePdfLoading = false;
              this.commonService.resetAnotationData();
              this.getLPReportDetail(false, 'manualSave', response);
              this.commonService.onPopState();
              // this.selectedFile = [{ ...response, name: response.fileName }];
              // this.getDocFileData(this.selectedFile[0].filePath);
            }
          },
          error: error => {
            this.commonService.showLoader(false);
            this.commonService.errorNotification('Failed to save Document');
            this.commonService.setDisablePdfToolbarStatus(false);
            this.savePdfLoading = false;
          },
          complete: () => {},
        });
    } else {
      this.reportsService
        .savepdfAnnotation(this.data.vehicleId, this.data.reportId, {
          ...annotationData,
          filename: this.selectedFile[0].name,
        })
        .subscribe({
          next: async (response: any) => {
            if (response && response.length > 0) {
              this.commonService.successNotification(
                'Annotations saved successfully.'
              );
              this.commonService.showLoader(false);
              this.commonService.setDisablePdfToolbarStatus(false);
              this.savePdfLoading = false;
              this.commonService.resetAnotationData();
            }
          },
          error: error => {
            this.commonService.showLoader(false);
            this.commonService.errorNotification('Failed to save annotations');
            this.commonService.setDisablePdfToolbarStatus(false);
            this.savePdfLoading = false;
          },
          complete: () => {},
        });
    }
  }
  getDocumentAnnotation(vehicleId: string, reportId: string) {
    this.documentAnnotation = [];
    this.reportsService
      .getReportAnnotation(vehicleId, reportId, this.selectedFile[0].name)
      .subscribe({
        next: (response: any) => {
          if (response && response.length > 0) {
            this.documentAnnotation = [response[0]];
          }
        },
        error: error => {
          this.documentAnnotation = [];
        },
        complete: () => {},
      });
  }

  openAnnotationDailog(modelClose = false, docType = '', file?: any) {
    const fileData = file;
    const dialogRef = this.dialog.open(SaveAnnotationDataDialog, {
      data: {
        annotationChange: this.annotationChange,
        xfdfString: this.xfdfString,
        vehicleId: this.data.vehicleId,
        reportId: this.data.reportId,
        filename: this.selectedFile[0].name,
        userId: this.userId,
        documentEditable: this.documentEditable,
        formDataFile: this.formDataFile,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'discard') {
        if (modelClose) {
          this.dialogRef.close();
        } else {
          this.selectedType = docType;
          this.selectedFile = [{ ...fileData }];
          if (docType != 'email' && this.selectedType !== 'uploadedDoc') {
            this.getFileData(this.selectedFile[0].id);
          }
          if (this.selectedType == 'uploadedDoc') {
            this.getDocFileData(this.selectedFile[0].filePath);
          }
        }
        this.commonService.resetAnotationData();
      }
      if (result && result.status === 'success') {
        if (modelClose) {
          this.dialogRef.close();
        } else {
          this.selectedFile = [{ ...fileData }];
          this.selectedType = docType;
          if (this.selectedType == 'uploadedDoc' || this.documentEditable) {
            // this.documentEditable && this.getLPReportDetail(false);
            this.documentEditable &&
              (this.uploadedDocuments = this.uploadedDocuments.map(
                (item: any) =>
                  item.name == result.successResponse.fileName
                    ? { ...item, filePath: result.successResponse.filePath }
                    : item
              ));
            this.selectedType == 'uploadedDoc' &&
              this.getDocFileData(this.selectedFile[0].filePath);
          }
          if (docType != 'email' && this.selectedType !== 'uploadedDoc') {
            this.getFileData(this.selectedFile[0].id);
          }
        }
        this.commonService.resetAnotationData();
      }
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

@Component({
  selector: 'save-quarer-Data-dialog',
  templateUrl: './save.annotation-dialog.html',
  styleUrls: ['./report-preview.component.scss'],
})
export class SaveAnnotationDataDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<SaveAnnotationDataDialog>,
    private commonService: CommonService,
    private reportsService: ReportsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  saveAnnotation() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.commonService.setDisablePdfToolbarStatus(true);
    if (this.data.documentEditable) {
      this.reportsService
        .saveUploadReportFile(
          this.data.vehicleId,
          this.data.reportId,
          this.data.formDataFile
        )
        .subscribe({
          next: _response => {
            this.commonService.successNotification(
              'Document saved successfully.'
            );
            this.dialogRef.close({
              status: 'success',
              successResponse: _response,
            });
            this.commonService.setDisablePdfToolbarStatus(false);
          },
          error: _error => {
            this.commonService.errorNotification(
              'Failed to save the Document.'
            );
            this.loading = false;
            this.commonService.setDisablePdfToolbarStatus(false);
          },
          complete: () => {
            this.dialogRef.disableClose = false;
            this.loading = false;
          },
        });
    } else {
      this.reportsService
        .savepdfAnnotation(this.data.vehicleId, this.data.reportId, {
          userId: this.data.userId,
          annotation: this.data.xfdfString,
          filename: this.data.filename,
        })
        .subscribe({
          next: _response => {
            this.commonService.successNotification(
              'Annotation saved successfully.'
            );
            this.dialogRef.close({
              status: 'success',
            });
            this.commonService.setDisablePdfToolbarStatus(false);
          },
          error: _error => {
            this.commonService.errorNotification(
              'Failed to save the Annotation.'
            );
            this.loading = false;
            this.commonService.setDisablePdfToolbarStatus(false);
          },
          complete: () => {
            this.dialogRef.disableClose = false;
            this.loading = false;
          },
        });
    }
  }
  discard() {
    this.dialogRef.close({
      status: 'discard',
    });
  }
}
