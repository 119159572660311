import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuarterlyDataService } from '../quarterly-data.service';
import { CommonService } from '../../../shared/services/common.service';
import { Model } from 'survey-core';
import { themeJson } from '../survey/theme';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

export interface IquestionaireItem {
  id: string;
  name: string | null | undefined;
  type: string;
  status: string;
}
export interface IquarterlyData {
  id: string;
  year: number;
  quarter: number;
  totalItems: number;
  completedItems: number;
}

export interface IGroupedQuestionaireItems {
  [key: string]: IquestionaireItem[];
}

@Component({
  selector: 'quarter-data-details',
  templateUrl: './quarter-data-details.component.html',
  styleUrls: ['./quarter-data-details.component.scss'],
})
export class QuarterDataDetailsComponent implements OnInit {
  @Input() gpId: string;
  @Input() vehicleId: string;
  @Input() quarterlyData: IquarterlyData[] = [];
  @Output() backClick = new EventEmitter();
  quarterlyDataId: string;
  quarter: string;
  formDefinition: any;
  isQuarterFormLoading: boolean = false;
  model: Model;
  isLoading: boolean = false;
  quarterlyDataTypes: IGroupedQuestionaireItems = {};
  questionaireTypeLoading = false;
  selectedportfolioCompany: IquestionaireItem;
  formQuarterStatus: string;
  isEditInProgress: boolean = false;
  draftQuarterLoading: boolean = false;
  submitQuarterLoading: boolean = false;
  questionaireImageStatusImage: any = {
    final: { path: '../../../../assets/images/circle_tick_green_icon.svg' },
    open: { path: '../../../../assets/images/gray_circle_icon.svg' },
    draft: { path: '../../../../assets/images/gray_clock_icon.svg' },
  };
  portfolioType: string;
  search: string;
  filterValue: string;
  availableQuarters: string[] = [];
  questionaireList: IquestionaireItem[] = [];
  prefilltoolTip = {
    global_pre_fill:
      'The global pre-fill action is only available if at least one questionnaire is still being edited.',
    questionaire_pre_fill:
      'The pre-fill action is only available if the questionnaire is still being edited.',
    no_availableQuarters: 'No data available for pre-fill',
  };

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private quarterlyDataService: QuarterlyDataService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.quarterlyDataId =
      this.activatedRoute.snapshot.params['quarterlyDataId'];
    this.quarter = this.activatedRoute.snapshot.params['quarter']
      ? this.activatedRoute.snapshot.params['quarter'].replace(/-/g, ' ')
      : '';
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.getQuestionaireList();
    !this.quarterlyData && this.getQuarterlyDataList();
    setTimeout(() => {
      this.commonService.setSecondaryPath([this.quarter as string]);
    });
  }

  getQuestionaireList(isRefresh = false, loading = true) {
    this.questionaireTypeLoading = loading;
    this.quarterlyDataService.getQuestionnaire(this.quarterlyDataId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.questionaireList = parsedResponse.data;
        this.quarterlyDataTypes = this.groupedDatabasedType(
          parsedResponse.data
        );
        // this.commonService.setSecondaryPath([this.quarter as string]);
        if (isRefresh) {
          this.getFormDefinitionsData(
            this.selectedportfolioCompany.id,
            this.portfolioType
          );
        } else {
          if (this.quarterlyDataTypes['questionnaire']) {
            this.selectedportfolioCompany =
              this.quarterlyDataTypes['questionnaire'][0];
            this.portfolioType = 'questionnaire';
            this.getFormDefinitionsData(
              this.selectedportfolioCompany.id,
              'questionnaire'
            );
          } else {
            this.selectedportfolioCompany =
              this.quarterlyDataTypes['portfolioCompany'][0];
            this.getFormDefinitionsData(
              this.selectedportfolioCompany.id,
              'portfolio-company'
            );
            this.portfolioType = 'portfolio-company';
          }
        }
      },
      error: _error => {
        this.questionaireTypeLoading = false;
        this.commonService.errorNotification(
          'Error fetching form Questionaire.'
        );
      },
      complete: () => {
        this.questionaireTypeLoading = false;
      },
    });
  }
  getQuarterlyDataList() {
    this.quarterlyDataService.getQuarterlyData(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.quarterlyData = parsedResponse.data;
      },
      error: error => {},
      complete: () => {},
    });
  }

  changePortfolioType(questionaire: IquestionaireItem, portfolioType: string) {
    if (this.getFormStatus()) {
      const dialogRef = this.dialog.open(SaveQuarterDataDialog, {
        data: {
          quarterlyDataId: this.quarterlyDataId,
          selectedportfolioCompany: this.selectedportfolioCompany,
          portfolioType: this.portfolioType,
          formData: this.getFormData(),
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (
          result &&
          (result.status === 'success' || result.status === 'discard')
        ) {
          if (result.status === 'success') {
            this.questionaireList = this.questionaireList.map(
              (item: IquestionaireItem) => {
                if (item.id === this.selectedportfolioCompany.id) {
                  return { ...item, status: 'draft' };
                }
                return item;
              }
            );
            this.quarterlyDataTypes = this.groupedDatabasedType(
              this.questionaireList
            );
          }
          this.selectedportfolioCompany = questionaire;
          this.portfolioType = portfolioType;
          this.getFormDefinitionsData(questionaire.id, portfolioType);
        }
      });
    } else {
      this.selectedportfolioCompany = questionaire;
      this.portfolioType = portfolioType;
      this.getFormDefinitionsData(
        this.selectedportfolioCompany.id,
        portfolioType
      );
    }
  }
  getFormDefinitionsData(
    portfolioCompanyId: string,
    portfolioType: string,
    isRefresh = true
  ) {
    // this.isQuarterFormLoading = true;
    this.isQuarterFormLoading = isRefresh;
    this.quarterlyDataService
      .getPortfolioQuarterlyFormData(
        this.quarterlyDataId,
        portfolioCompanyId,
        portfolioType
      )
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.formDefinition = parsedResponse.data.formDefinition;
          this.formQuarterStatus = parsedResponse.data.status;
          this.availableQuarters = parsedResponse.data.availableQuarters;
          if (isRefresh) {
            this.createFormModel(parsedResponse.data.formDefinition);
            this.setFormData(parsedResponse.data.data);
          }
          parsedResponse.data.status === 'final' && this.disableForm();
          parsedResponse.data.status === 'draft' && this.enableForm();
          if (isRefresh === false) {
            this.questionaireList = this.questionaireList.map(
              (item: IquestionaireItem) => {
                if (item.id === this.selectedportfolioCompany.id) {
                  this.selectedportfolioCompany['status'] =
                    parsedResponse.data.status;
                  return { ...item, status: parsedResponse.data.status };
                }
                return item;
              }
            );
            this.quarterlyDataTypes = this.groupedDatabasedType(
              this.questionaireList
            );
            this.resetFormStatus();
          }
        },
        error: _error => {
          this.isQuarterFormLoading = false;
          this.isLoading = false;
          this.commonService.errorNotification(
            'Error fetching form definition.'
          );
        },
        complete: () => {
          this.isQuarterFormLoading = false;
        },
      });
  }

  setFormData(data: any) {
    if (!!data) {
      Object.keys(data).forEach(key => {
        if (key.endsWith('-Comment')) {
          const baseKey = key.slice(0, -8);
          if (Array.isArray(data[baseKey])) {
            data[baseKey] = [data[key]];
          } else {
            data[baseKey] = data[key];
          }
          delete data[key];
        } else if (
          [
            'pageGeneralData.establishmentDay',
            'pageCapital.investmentDate',
            'pageCapital.exitDate',
          ].includes(key)
        ) {
          let value = data[key];
          if (value) {
            let monthPart = value.split('-')[1];

            if (monthPart && monthPart.length === 1) {
              monthPart = '0' + monthPart;
              data[key] = value.split('-')[0] + '-' + monthPart;
            }
          }
        }
      });
      for (const key in data) {
        this.model.setValue(key, data[key]);
      }
    }
    this.resetFormStatus();
  }

  groupedDatabasedType(quarterTypes: IquestionaireItem[]) {
    return quarterTypes.reduce((acc, item) => {
      if (!acc[item.type]) {
        acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
    }, {} as IGroupedQuestionaireItems);
  }

  createFormModel(json: any) {
    this.model = new Model(json);
    this.model.applyTheme(themeJson as any);
    this.model.onValueChanged.add(this.changeFormStatus);
    this.resetFormStatus();
  }

  changeFormStatus() {
    const inputElement = document.getElementById(
      'valueChanged'
    ) as HTMLInputElement;
    if (inputElement) inputElement.value = 'true';
  }
  resetFormStatus() {
    const inputElement = document.getElementById(
      'valueChanged'
    ) as HTMLInputElement;
    if (inputElement) inputElement.value = 'false';
  }

  getFormStatus() {
    const inputElement = document.getElementById(
      'valueChanged'
    ) as HTMLInputElement;
    return inputElement.value === 'true';
  }

  backtoQuarterlyData() {
    // this.backClick.emit();
    this.router.navigate([
      `gp/${this.gpId}/vehicle/${this.vehicleId}/quarterly-data`,
    ]);
    this.commonService.setSecondaryPath([]);
  }
  openPreFillDialog(type: string) {
    const dialogRef = this.dialog.open(PreFillDialogComponent, {
      data: {
        vehicleId: this.vehicleId,
        quarterlyData: this.quarterlyData,
        quarter: this.quarter,
        quarterlyDataId: this.quarterlyDataId,
        preFillType: type,
        portfolioCompanyId: this.selectedportfolioCompany.id,
        portfolioType: this.portfolioType,
        availableQuarters: this.availableQuarters,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        this.getQuestionaireList(true);
      } else if (result && result.status === 'error') {
        this.commonService.errorNotification('Failed to add reporting period');
      }
    });
  }
  addReportData(type: string, notification = true) {
    this.isEditInProgress = true;
    if (type === 'draft') {
      this.draftQuarterLoading = true;
    }
    if (type === 'final') {
      this.submitQuarterLoading = true;
    }
    this.quarterlyDataService
      .updatePortfolioCompanyForm(
        this.quarterlyDataId,
        this.selectedportfolioCompany.id,
        this.portfolioType,
        {
          data: this.getFormData(),
          status: type,
        }
      )
      .subscribe({
        next: _response => {
          if (notification) {
            this.commonService.successNotification(
              type == 'final'
                ? 'Report submitted successfully.'
                : 'Report saved successfully.'
            );
          }
          this.getFormDefinitionsData(
            this.selectedportfolioCompany.id,
            this.portfolioType,
            false
          );
          // this.getQuestionaireList(true, false);
        },
        error: _error => {
          if (notification) {
            this.commonService.errorNotification(
              type == 'final'
                ? 'Failed to submit the report.'
                : 'Failed to save the report.'
            );
          }
          this.isEditInProgress = false;
          this.draftQuarterLoading = false;
          this.submitQuarterLoading = false;
        },
        complete: () => {
          this.isEditInProgress = false;
          this.draftQuarterLoading = false;
          this.submitQuarterLoading = false;
        },
      });
  }
  getFormData() {
    return this.model.data;
  }
  capitalizeStatusValue(word: string) {
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return '';
    }
  }
  disableForm() {
    this.model.mode = 'display';
  }
  enableForm() {
    this.model.mode = 'edit';
  }
}

@Component({
  selector: 'pre-fill-dialog',
  templateUrl: './pre-fill-dialog.html',
  styleUrls: ['./quarter-data-details.component.scss'],
})
export class PreFillDialogComponent implements OnInit {
  isQuarterOpen: boolean = false;
  preFillQuarters: IquarterlyData[] = [];
  preFillSelectedQuarter: IquarterlyData;
  isImport: boolean = false;
  preFillFormLoading: boolean = false;
  questionaireLoading: boolean = false;
  constructor(
    private dialog: MatDialog,
    private quarterlyDataService: QuarterlyDataService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<PreFillDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.preFillType === 'questionaire') {
      const [selectedYear, selectedQuarter] = this.data.quarter.split(' ');
      const parsedQuarter = parseInt(selectedQuarter.replace('Q', ''), 10);
      this.preFillQuarters = this.data.quarterlyData.filter(
        (item: IquarterlyData) =>
          !(
            item.year === parseInt(selectedYear) &&
            item.quarter === parsedQuarter
          )
      );
      this.preFillSelectedQuarter = this.preFillQuarters[0];
    } else {
      this.preFillQuarters = this.data.quarterlyData.filter(
        (item: IquarterlyData) => this.data.availableQuarters.includes(item.id)
      );
      this.preFillSelectedQuarter = this.preFillQuarters[0];
    }
  }
  createQuestionairePreFill() {
    this.questionaireLoading = true;
    this.quarterlyDataService
      .questionnairePreFill(this.data.quarterlyDataId, {
        sourceQuarterId: this.preFillSelectedQuarter.id,
        overwrite: this.isImport,
      })
      .subscribe({
        next: response => {
          this.dialogRef.close({
            status: 'success',
          });
          this.commonService.successNotification(
            'Quarterlydata prefilled successfully'
          );
        },
        error: _error => {
          this.dialogRef.close({ status: 'error' });
          this.commonService.errorNotification('Failed to add pre-fill');
          this.questionaireLoading = false;
        },
        complete: () => {
          this.dialogRef.disableClose = false;
          this.questionaireLoading = false;
        },
      });
  }
  createQuestionairePreFillForm() {
    this.preFillFormLoading = true;
    this.quarterlyDataService
      .questionnairePreFillForForm(
        this.data.quarterlyDataId,
        this.data.portfolioCompanyId,
        this.data.portfolioType,
        {
          sourceQuarterId: this.preFillSelectedQuarter.id,
          overwrite: this.isImport,
        }
      )
      .subscribe({
        next: response => {
          this.dialogRef.close({
            status: 'success',
          });
          this.commonService.successNotification(
            'Quarterlydata prefilled successfully'
          );
        },
        error: _error => {
          this.dialogRef.close({ status: 'error' });
          this.commonService.errorNotification('Failed to add pre-fill');
          this.preFillFormLoading = false;
        },
        complete: () => {
          this.dialogRef.disableClose = false;
          this.preFillFormLoading = false;
        },
      });
  }
  toggleSelect() {
    this.isQuarterOpen = !this.isQuarterOpen;
  }
}

@Component({
  selector: 'save-quarer-Data-dialog',
  templateUrl: './save.quarter-data-dialog.html',
  styleUrls: ['./quarter-data-details.component.scss'],
})
export class SaveQuarterDataDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<SaveQuarterDataDialog>,
    private quarterlyDataService: QuarterlyDataService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  saveDraft() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.quarterlyDataService
      .updatePortfolioCompanyForm(
        this.data.quarterlyDataId,
        this.data.selectedportfolioCompany.id,
        this.data.portfolioType,
        {
          data: this.data.formData,
          status: 'draft',
        }
      )
      .subscribe({
        next: _response => {
          this.commonService.successNotification('Report saved successfully.');
          this.dialogRef.close({
            status: 'success',
          });
        },
        error: _error => {
          this.commonService.errorNotification('Failed to save the report.');
          this.loading = false;
        },
        complete: () => {
          this.dialogRef.disableClose = false;
          this.loading = false;
        },
      });
  }
  discard() {
    this.dialogRef.close({
      status: 'discard',
    });
  }
}
