import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ReportsService } from './reports.service';

export interface Ireport {
  id: number,
  name: string,
  type: string,
  state: string,
  createdAt: string,
  updatedAt: string,
  limitedPartnerCount: number
}
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {

  displayedColumns: string[] = [
    'name',
    'type',
    'createdAt',
    'updatedAt',
    'investors',
    'status',
    'details'
  ];
  noReportFound: Boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);

  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  isShowDetails: boolean = false;
  search: string;
  vehicleId: string;
  gpId: string;
  reportId: string;
  isLoading: boolean = false;
  reportData: Ireport[];
  migratingSoon: boolean = false;
  typesConst: any = {
    crr: "CRR",
    quarterlyReportings: "Quarterly report",
    capitalCallsAndClosings: "Capital call/Closing",
    distributions: "Distribution",
    shareholderResolutions: "Shareholder resolution",
    annualReportsAndAuditReports: "Annual report/Audit report",
    statementsOfAccount: "Statement of account",
    tax: "Tax",
    principalLevelTopics: "Principal level topic"
  }


  constructor(
    private dialog: MatDialog,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private reportsService: ReportsService
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.reportId = this.activatedRoute.snapshot.params['reportId'];
    this.isShowDetails = this.reportId !== undefined
    this.commonService.setSecondaryPath([]);
    !this.reportId && this.getReportList();
  }

  getReportList() {
    this.isLoading = true;
    this.reportsService
      .getAllReports(this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          if (parsedResponse.data.length == 0) {
            this.noReportFound = true
          }
          this.reportData = parsedResponse.data;
          this.dataSource = new MatTableDataSource(parsedResponse.data);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            if (property === 'createdAt' || property === 'updatedAt') {
              return new Date(item[property]).getTime();
            }
            return item[property].toLocaleLowerCase()
          };
        },
        error: error => {
          this.isLoading = false;
          if (error.error.message.includes('Cockpit Database connection properties are missing')) {
            this.noReportFound = true
            // this.migratingSoon = true;
            return
          }
          this.commonService.errorNotification('Failed to retrieve Reports')
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  hideDetails() {
    this.isShowDetails = false;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/reports`
    );
    this.commonService.setSecondaryPath([]);
    !this.reportData && this.getReportList();
  }

  showDetails(report: Ireport) {
    if (!report.limitedPartnerCount) {
      return
    }
    this.reportId = report.id.toString();
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/reports/${report.id}`
    );
    this.isShowDetails = true;
  }

  applyFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();

    // Update filterPredicate to handle the specific columns
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const name = data.name ? data.name.toLowerCase() : '';
      return name.includes(filter);
    };

    this.dataSource.filter = filterValue;
  }
}
