import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { MoniteService } from '../monite.service';
import { UserService } from 'src/app/core/services/user.service';
import moniteTheme from '../monite.theme';
import "@monite/sdk-drop-in";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoices',
  template: `
    <div *ngIf="!moniteIdMissing; else moniteIdMissingerror"></div>
    <div *ngIf="!moniteUserIdMissing; else userIdMissingerror"></div>
    <ng-template #moniteIdMissingerror>
        <app-service-not-available [description]="serviceNotAvailableDesc"></app-service-not-available>
    </ng-template>
    <ng-template #userIdMissingerror>
        <app-service-not-available [description]="accessNotAvailableDesc"></app-service-not-available>
    </ng-template>
  `,
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent {

  vehicleId: string;
  userId: string;
  entityId: string;
  entiryUserId: string;
  serviceNotAvailableDesc =
    'This vehicle has not yet been configured for the use this service. Please contact support at';
  accessNotAvailableDesc =
    'This user has not yet been configured for the use this service. Please contact support at';
  moniteIdMissing = false;
  moniteUserIdMissing = false;
  selectVehicle: any;

  constructor(
    private commonService: CommonService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private moniteService: MoniteService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Invoices']);
    }, 0);
    this.userService.userDataObservable$.subscribe((res: any) => {
      if (res) {
        this.userId = res.id;
      }
    });
    this.userService.selectedVehicleObservable$.subscribe((res: any) => {
      if (res) {
        this.selectVehicle = res;
      }
    });
    this.loadMoniteSDK()
  }

  private loadMoniteSDK(): void {
    if (!(this.selectVehicle && this.selectVehicle.moniteEntityId && this.selectVehicle.moniteEntityUserId)) {
      // this.moniteIdMissing = !!this.selectVehicle.moniteEntityId;
      // this.moniteUserIdMissing = !!this.selectVehicle.moniteEntityUserId;
      // return
      if (!this.selectVehicle.moniteEntityId) {
        this.createMoniteEntity()
      }else if (!this.selectVehicle.moniteEntityUserId) {
        this.entityId = this.selectVehicle.moniteEntityId;
        this.createMoniteEntityUser()
      }
    } else {
      this.entityId = this.selectVehicle.moniteEntityId;
      this.entiryUserId = this.selectVehicle.moniteEntityUserId;
      this.addMoniteApp(this.entityId, this.entiryUserId);
    }
  }

  private createMoniteEntity() {
    this.moniteService
      .createMoniteEntity(this.userId, this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.entityId = parsedResponse.data.moniteEntityId
          this.entiryUserId = parsedResponse.data.moniteEntityUserId
          this.addMoniteApp(this.entityId, this.entiryUserId);
        },
        error: _error => {
          this.moniteIdMissing = true
        }
      });
  }

  private createMoniteEntityUser() {
    this.moniteService
      .createMoniteEntityUser(this.userId, this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.entiryUserId = parsedResponse.data.moniteEntityUserId
          this.addMoniteApp(this.entityId, this.entiryUserId);
        },
        error: _error => {
          this.moniteUserIdMissing = true
        }
      });
  }

  // Add the Monite `<monite-app>` element to the DOM
  private addMoniteApp(entityId: string, entityUserId: string): void {
    const moniteApp = this.renderer.createElement('monite-app');

    this.renderer.setAttribute(moniteApp, 'entity-id', `${entityId}`);
    this.renderer.setAttribute(
      moniteApp,
      'api-url',
      environment.moniteDropinUrl
    );
    this.renderer.setAttribute(moniteApp, 'component', 'payables');
    this.renderer.setAttribute(moniteApp, 'basename', '/');

    // Add the theming script
    const themeScript = this.renderer.createElement('script');
    themeScript.setAttribute('slot', 'theme');
    themeScript.setAttribute('type', 'application/json');
    themeScript.textContent = JSON.stringify(moniteTheme);
    this.renderer.appendChild(moniteApp, themeScript);


    // Add the custom CSS for Monite component
    const styleTag = this.renderer.createElement('style');
    styleTag.textContent = `
        * {
          "font-family": "Inter !important"
        }
        #monite-app-root {
          height: calc(100vh - 85px);
        }
        .Monite-Payables-Header-Header{
          margin: 0;
          inset: 0;
          height: 60px;
          position: absolute;
          top: 85px;
          right: 24px;
        }
       .Monite-Payables-Header-Header h2 {
          visibility: hidden;
          font-size: 0;
        }
       .MuiTable-root {
          font-family: 'Inter' !important;
        }
        h6.MuiTypography-subtitle1, h6.MuiTypography-subtitle2{
          font-family: 'Inter' !important;
          font-size: 16px !important;
        }
        .Monite-PayablesTable{
          padding: 0;
          height: calc(100vh - 85px);
        }
        .Monite-PayablesTable > div:first-child{
          display: none !important;
        }
        .Monite-PayablesTable .Monite-SummaryCard-Skeleton{
          display: none !important;
        } 
        .Monite-PayableDueDateFilter{
          margin: 0px;
        }
        .Monite-PayableStatusFilter{
          margin: 10px 0;
        }
        .MuiDataGrid-filler{
          display: none;
        }
        .MuiDataGrid-row:hover{
          background-color: unset !important;
        }
        .Monite-PayableDetails-Header .MuiTypography-h3{
          font-size: 24px;
        }
        .Monite-PayableFilters{
          border-bottom: 1px solid #E9E9E9;
          margin: 0;
          height: 73px;
          padding: 0 !important;
        }
        .Monite-PayablesTable .Monite-Filters .Monite-Filters-Group{
          justify-content: flex-start;
        }
        .MuiDataGrid-footerContainer{
          margin: 0;
          // border-top: 0px !important;
        }
        .MuiDataGrid-footerContainer .Monite-RowsPerPageSelector{
          padding: 0 10px;
        }
        .MuiDataGrid-footerContainer button{
          scale: 0.7;
        }
        .MuiDataGrid-footerContainer div div {
        justify-content: flex-end;
        }
        .MuiDataGrid-footerContainer div {
          margin: 0 !important;
        }

        .MuiButton-root{
          background-color: #111111;
          color: #ffffff;
          border-radius: 8px;
          box-shadow: none;
        }
        .MuiButton-root:hover{
          background-color: #111111;
          border-radius: 8px;
        }

        .MuiInputBase-root {
          background-color: #ffffff !important;
          border: 1px solid #E9E9E9 !important;
          border-radius: 8px !important;
          color: #111111 !important;
        }
        .MuiInputBase-root.Mui-focused {
          box-shadow: none !important;
        }
        .MuiInputBase-root .MuiSvgIcon-root > * {
          color: #111111 !important;
        }

        .MuiDataGrid-root {
          font-family: 'Inter';
          border: 0;
          max-width: 1920px;
          min-width: 1132px;
        }

        .MuiDataGrid-columnHeaders {
          font-weight: bold;
          text-transform: capitalize;
          height: 45px !important;
        }

        .MuiDataGrid-columnHeader {
          height: 45px !important;
        }

        .MuiDataGrid-columnHeaderTitle {
          font-size: 12px !important;
          font-weight: 500px !important;
          color: #828282;
        }

        .MuiDataGrid-row {
          border: 0;
          min-height: 68px !important;
        }

        .MuiDataGrid-row:hover {
          background-color: none;
        }

        .MuiDataGrid-cell {
          height: 68px;
          border: 0px;
          border-bottom: 1px solid #E9E9E9;
          border-top: 0px !important;
          cursor: pointer;
        }

        .MuiDataGrid-cell:focus {
          outline: none !important;
        }

        .MuiDataGrid-cell:focus-within {
          outline: none !important;
        }

        .MuiDataGrid-footerContainer {
          min-height: 45px;
        } 

     `;
    moniteApp.shadowRoot.appendChild(styleTag);

    // Add the fetch-token script
    const fetchTokenScript = this.renderer.createElement('script');
    fetchTokenScript.setAttribute('slot', 'fetch-token');
    fetchTokenScript.textContent = `async function fetchToken() {
      const token = localStorage.getItem('access_token');
        if (!token) {
          console.log("token not found in localStorage");
        }
      const response = await fetch("${this.moniteService.getEntityUserAuthUrl(entityUserId)}", {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + token
        }
      });
      return await response.json();
    }`
    this.renderer.appendChild(moniteApp, fetchTokenScript);
    this.renderer.appendChild(this.el.nativeElement, moniteApp);
  }
}
