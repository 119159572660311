import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../core/services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndpointsService } from '../core/services/api-endpoints.service';
import { UserService } from '../core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  response: any;

  constructor(
    private http: HttpClient,
    private apiEndpointsService: ApiEndpointsService,
    private userService: UserService,
    public translate: TranslateService,
    private authService: AuthService
  ) { }

  sessionuser: any;
  baseData: any;
  public notify = new BehaviorSubject<any>('');
  private baseDataSubject = new BehaviorSubject<any>(null);
  notifyObservable$ = this.notify.asObservable();
  baseDataObservable$ = this.baseDataSubject.asObservable();

  public async setBaseData(data: any) {
    if (data) {
      this.baseDataSubject.next(data);
    }
  }

  public async notifyOther(data: any) {
    if (data) {
      await this.fetchuserData();
      this.notify.next(data);
    }
  }

  async init(): Promise<void> {
    // const pathname = window.location.pathname;
    try {
      let isRefresh = true;
      if (!this.authService.isAuthenticated()) {
        isRefresh = false;
      }
      await this.authService.authenticate();
      await this.fetchuserData(isRefresh);
      await this.fetchbaseData();
    } catch (error: any) {
      if (error.statusCode == 403) {
        this.sessionuser = {
          error: 'ACCESS_DENIED',
        };
      }
    }
  }

  async fetchuserData(isRefresh = false) {
    return new Promise((resolve, _reject) => {
      this.http
        .get(this.apiEndpointsService.getSessionUser(isRefresh))
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err.error || 'Server error');
          })
        )
        .subscribe(
          data => {
            this.sessionuser = JSON.parse(JSON.stringify(data));
            // const userLanguage = ['en', 'de'].includes(
            //   this.sessionuser.data.state.lang.toLowerCase()
            // )
            //   ? this.sessionuser.data.state.lang.toLowerCase()
            //   : 'en';
            const userLanguage = 'en';
            this.translate.use(userLanguage);
            this.userService.setUserConfig(
              `${this.sessionuser.data.firstName} ${this.sessionuser.data.lastName}`,
              userLanguage,
              this.sessionuser.data.id
            );
            this.userService.setUserData(this.sessionuser.data);
            resolve(true);
          },
          error => {
            if (error.statusCode == 403) {
              this.sessionuser = {
                error: 'ACCESS_DENIED',
              };
            }
            this.translate.use('en');
            resolve(true);
          }
        );
    });
  }

  async fetchbaseData() {
    return new Promise((resolve, _reject) => {
      this.http
        .get(this.apiEndpointsService.getBaseData())
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err.error || 'Server error');
          })
        )
        .subscribe(
          data => {
            this.baseData = JSON.parse(JSON.stringify(data));
            this.setBaseData(this.baseData.data);
            if (this.baseData.data.gps.length == 0) {
              this.sessionuser = {
                error: 'ACCESS_DENIED',
              };
            }
            resolve(true);
          },
          error => {
            if (error.statusCode == 403) {
              this.baseData = {
                error: 'ACCESS_DENIED',
              };
            }
            resolve(true);
          }
        );
    });
  }
}
