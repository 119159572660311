import { Directive, AfterViewInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Directive({
  selector: 'mat-menu[counterpart-detail-action-position]',
})
export class CPMenuPositionDirective implements AfterViewInit {
  constructor(private menu: MatMenu) {}

  ngAfterViewInit(): void {
    this.menu.overlayPanelClass = `counterpart-detail-action`;
  }
}
