import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { CounterpartsService } from './counterparts.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Location } from '@angular/common';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  BehaviorSubject,
  merge,
  of as observableOf,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  map,
  startWith,
  switchMap,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { CounterpartDetailService } from '../counterpart-detail/counterpart.service';

interface ICounterparts {
  id: string;
  accountId: string;
  category: string;
  counterpart_name: string;
  date: string;
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-counterparts',
  templateUrl: './counterparts.component.html',
  styleUrls: ['./counterparts.component.scss'],
})
export class CounterpartsComponent implements AfterViewInit {
  private counterpartListRefreshSubscription!: Subscription;
  search: string;
  vehicleId: string;
  gpId: string;
  counterpartId: string | undefined;
  selecetdCounterpart: any;
  displayedColumns: string[] = [
    'counterpart_name',
    'category',
    'contactInformation',
    'more',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  nextPage: string;
  previousPage: string;
  paginationAction: string;
  totalRecords = 0;
  counterparts: ICounterparts[] = [];
  noCounterparts = false;
  bankAccount: any | null;
  accountList: any;
  filters: BehaviorSubject<any> = new BehaviorSubject<any>({});
  refresh: BehaviorSubject<any> = new BehaviorSubject<string>('');
  isLoading = true;
  isClearBtn = false;
  isPageLoading = false;
  isDownloading = false;
  isSyncInProgress = false;
  category = '';
  rowHeight = 60; // Adjust row height as per your table styles
  private searchSubject = new Subject<boolean>();
  maxDate: Date = new Date();
  monthsBack = 6;
  showExportTooltip = true;
  currentFilterOrder: SortDirection = 'desc';

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableSection', { static: false }) tableSection!: ElementRef;
  @ViewChild('paginator', { static: false }) paginator!: MatPaginator;

  pageSize = 10;
  updatePageSize = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    private counterpartsService: CounterpartsService,
    public counterpartDetailService: CounterpartDetailService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.counterpartId = this.activatedRoute.snapshot.params['counterpartId'];
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Counterparties']);
    }, 0);
    this.searchSubject.pipe(debounceTime(1000)).subscribe(flag => {
      flag && this.searchFilter();
    });
    this.filters.subscribe(filters => {
      if (
        filters &&
        filters.order &&
        (filters.order === 'asc' || filters.order === 'desc')
      ) {
        this.currentFilterOrder = filters.order;
      } else {
        this.currentFilterOrder = 'desc';
      }
    });
    this.counterpartListRefreshSubscription =
      this.counterpartDetailService.counterpartListRefreshObservable$.subscribe(
        (res: any) => {
          if (res) {
            this.noCounterparts = false;
            this.cdr.detectChanges();
            this.refresh.next('refresh');
          }
        }
      );
  }

  sortChange() {
    this.paginationAction = '';
    this.filters.next({
      ...this.filters.getValue(),
      sort: this.sort.active,
      order: this.sort.direction,
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    // Handle sort changes, paginator changes, and filter updates in merge
    merge(this.sort.sortChange, this.paginator.page, this.filters, this.refresh)
      .pipe(
        startWith({}), // Emit an initial empty object when the stream starts
        tap(() => this.adjustPageSize()),
        switchMap(() => this.loadCounterparts()),
        catchError(() => observableOf([]))
      )
      .subscribe(data => {
        this.dataSource.data = data.data;
        this.nextPage = data.next_pagination_token
          ? data.next_pagination_token
          : null;
        this.previousPage = data.prev_pagination_token
          ? data.prev_pagination_token
          : null;
      });
  }

  adjustPageSize() {
    const availableHeight =
      this.tableSection.nativeElement.offsetHeight - 70 - 45;
    const rowsPerPage = Math.floor(availableHeight / this.rowHeight);
    const roundedRowsPerPage = Math.ceil(rowsPerPage / 5) * 5;
    this.pageSize = Math.max(roundedRowsPerPage, 10);
    this.paginator.pageSize = this.pageSize;
    this.updatePageSize = true;
  }

  loadCounterparts() {
    const filters = this.filters.getValue();
    const isFilterApplied = Object.values(filters).some(value => value);

    if (this.dataSource.data.length > 0 || isFilterApplied || this.isClearBtn) {
      this.isPageLoading = true; // Show pagination loader
    } else {
      this.isLoading = true; // Show initial loader
      this.isPageLoading = true;
      this.cdr.detectChanges();
    }

    return this.counterpartsService
      .getCounterparts(
        this.vehicleId,
        filters,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.sort.active,
        this.sort.direction,
        this.paginationAction ? this.paginationAction : ''
      )
      .pipe(
        map(data => {
          const isFilterApplied = Object.keys(filters).some(
            key => key !== 'order' && key !== 'sort'
          );
          const parsedResponse = JSON.parse(JSON.stringify(data));

          if (data === null) {
            return [];
          }

          if (isFilterApplied) {
            this.showExportTooltip = false;
          }

          // Process data and handle account list if no filter is applied
          if (!isFilterApplied) {
            this.showExportTooltip = true;
            this.noCounterparts = !parsedResponse.data.length;
          }

          if (!parsedResponse.next_pagination_token) {
            this.totalRecords =
              this.paginator.pageIndex * this.pageSize +
              parsedResponse.data.length;
          } else {
            this.totalRecords =
              this.paginator.pageIndex * this.pageSize +
              parsedResponse.data.length +
              this.pageSize;
          }

          this.isLoading = false;
          this.isPageLoading = false;
          this.isClearBtn = false;

          return parsedResponse;
        })
      );
  }

  onPageChange(event: PageEvent) {
    const previousIndex = event.previousPageIndex ?? 0;

    if (event.pageIndex > previousIndex && this.nextPage) {
      this.paginationAction = this.nextPage;
    } else if (event.pageIndex < previousIndex && this.previousPage) {
      this.paginationAction = this.previousPage;
    }
  }

  searchHandler() {
    this.searchSubject.next(true);
  }

  searchFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();
    this.paginationAction = '';
    if (filterValue) {
      this.filters.next({
        ...this.filters.getValue(),
        search: filterValue,
      });
    }
    this.paginator.pageIndex = 0;
  }

  categoryFilter(category: string) {
    this.paginationAction = '';
    this.category = category;
    this.filters.next({
      ...this.filters.getValue(),
      category: category,
    });
    this.paginator.pageIndex = 0;
  }

  getInitials(name: string): string {
    if (!name) return '?'; // Default fallback
    const words = name.split(' ').filter(word => word.length > 0);
    return words
      .map(word => word[0].toUpperCase())
      .slice(0, 2)
      .join('');
  }

  clearFilter(filterKey: string) {
    this.isClearBtn = true;
    const currentFilters = this.filters.getValue();
    let updatedFilters = { ...currentFilters };
    delete updatedFilters[filterKey];
    this.filters.next(updatedFilters);
    this.paginator.pageIndex = 0;
  }

  handleBackspaceClear(event: KeyboardEvent) {
    const filterValue = (this.search || '').trim().toLowerCase();
    // Detect backspace or delete, and reset the filter if the search is empty
    if ((event.key === 'Backspace' || event.key === 'Delete') && !filterValue) {
      this.clearFilter('search');
    }
  }

  createCounterpart() {
    this.counterpartDetailService.setCounterpartDetails({
      isCreate: true,
      vehicleId: this.vehicleId,
    });
    this.counterpartDetailService.openDrawer();
  }

  deleteCounterpart(row: any) {
    this.counterpartDetailService
      .deleteCounterpart(this.vehicleId, row.id)
      .subscribe({
        next: response => {
          if (response) {
            this.commonService.successNotification(
              'Counterparty deleted successfully'
            );
            this.refresh.next('refresh');
          }
        },
        error: _error => {
          this.commonService.errorNotification('Failed to delete Counterparty');
        },
        complete: () => {},
      });
  }

  showCounterpartDetail(data: any) {
    const typeData =
      data.type === 'organization' ? data.organization : data.individual;
    this.counterpartDetailService.setCounterpartDetails({
      isCreate: false,
      vehicleId: this.vehicleId,
      counterpartId: data.id,
      type: data.type,
      legal_name: typeData?.legal_name,
      first_name: typeData?.first_name,
      last_name: typeData?.last_name,
      phone: typeData.phone,
      email: typeData.email,
      reminders_enabled: data.reminders_enabled,
      tax_id: data.tax_id,
    });
    this.counterpartDetailService.openDrawer();
  }
}
