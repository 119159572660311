import { Component, ViewChild, Input, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { InvestorsService } from '../investors.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserService } from 'src/app/core/services/user.service';

export interface IlpDetails {
  id: string | null,
  investorId: string | null,
  name: string | null,
  investorType: string | null,
  legalForm: string | null,
  tradeRegisterNumber: string | null,
  investorQualification: string | null,
  street: string | null,
  additionalLine1: string | null,
  additionalLine2: string | null,
  zip: string | null,
  city: string | null,
  state: string | null,
  country: string | null,
}

export interface Ipersons {
  firstName: string | null,
  lastName: string | null,
  emailAddress: string | null,
  isMainContact: boolean | null,
  capacity: string | null,
  foaEn: string | null,
  foaDe: string | null,
  receivesReports: boolean | null,
  roles: string[] | null,
}
@Component({
  selector: 'app-investor-detail',
  templateUrl: './investor-detail.component.html',
  styleUrls: ['./investor-detail.component.scss'],
})
export class InvestorDetailComponent {

  @Input() lpId: string;
  @Input() gpId: string;
  @Input() vehicleId: string;
  @Output() backClick = new EventEmitter();

  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  closingDataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);

  private sort: MatSort;
  private closingSort: MatSort;
  @ViewChild('matSort', { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  @ViewChild('closingMatSort', { static: false }) set matClosingSort(sort: MatSort) {
    this.closingSort = sort;
    this.closingDataSource.sort = this.closingSort;
  }

  displayedColumns: string[] = [
    'name',
    'address',
    'roles',
    'receivesReports',
  ];

  displayedClosingsColumns: string[] = [
    'number',
    'date',
    'status',
    'offeredAmount',
    'amount'
  ];
  isLoading: boolean = false;
  lpDetails: IlpDetails;
  @ViewChildren(MatMenuTrigger) menuTriggers!: QueryList<MatMenuTrigger>;
  timeoutId: any;
  selectVehicle: any;
  noPersons: boolean = false;

  constructor(
    private investorService: InvestorsService,
    public commonService: CommonService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.getInvestorDetails();
    this.userService.selectedVehicleObservable$.subscribe((res: any) => {
      if (res) {
        this.selectVehicle = res;
      }
    });
  }

  getInvestorDetails() {
    this.isLoading = true;
    this.investorService
      .getInvestorDetails(this.vehicleId, this.lpId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.lpDetails = parsedResponse.data;
          this.commonService.setSecondaryPath([this.lpDetails.name as string]);
          this.getInvestorPersons();
          this.getInvestorClosings();
        },
        error: error => {
          if (error.error.message.includes('Limited partner does not exist') || error.error.message.includes('uuid')) {
            this.commonService.OpenAccessDenied(this.gpId, this.vehicleId, 'investors')
            return
          }
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve Investor details')
        }
      });
  }

  getInvestorPersons() {
    this.investorService
      .getAllInvestorPersons(this.vehicleId, this.lpId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          if (!parsedResponse.data.length) {
            this.noPersons = true;
          }
          this.dataSource = new MatTableDataSource(parsedResponse.data);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => {
            if (property === 'name') {
              return (item.firstName || item.lastName || item.emailAddress).toLocaleLowerCase();
            }
            return item[property];
          };
        },
        error: error => {
          this.commonService.errorNotification('Failed to retrieve Investor persons')
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  getInvestorClosings() {
    this.investorService
      .getAllClosingsInvestor(this.vehicleId, this.lpId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.closingDataSource = new MatTableDataSource(parsedResponse.data);
          this.closingDataSource.sort = this.closingSort;
          this.closingDataSource.sortingDataAccessor = (item, property) => {
            if (property === 'date') {
              return new Date(item[property]).getTime();
            }
            return item[property].toLocaleLowerCase();
          };
        },
        error: error => {
          this.commonService.errorNotification('Failed to retrieve Investor Closings')
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
    if (this.closingDataSource) {
      this.closingDataSource.sort = this.closingSort;
    }
  }

  navigateBack() {
    this.backClick.emit();
  }

  shortenName(row: Ipersons) {
    const initials = row.firstName?.[0] && row.lastName?.[0]
      ? row.firstName[0] + row.lastName[0]
      : (row.firstName?.[0] || row.lastName?.[0] || row.emailAddress?.[0]);

    return initials?.toUpperCase() || '';
  }

  copyEmail(email: string) {
    navigator.clipboard.writeText(email)
    this.commonService.successNotification('Email address copied successfully.')
  }

  getMailToLink(email: string): string {
    return email ? `mailto:${email}` : '';
  }

  nameHoverIn(menuTrigger: MatMenuTrigger) {
    this.menuTriggers.forEach(trigger => trigger.closeMenu());
    this.timeoutId = setTimeout(() => {
      menuTrigger.openMenu()
    }, 1000);
  }

  nameHoverOut(event: MouseEvent) {
    const relatedTarget = event.relatedTarget as HTMLElement;

    if (relatedTarget && (relatedTarget.classList.contains('investor-info') || relatedTarget.classList.contains('inverstor-email'))) {
      return;
    }
    this.menuTriggers.forEach(trigger => trigger.closeMenu());
    clearTimeout(this.timeoutId)
  }

  closeMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.closeMenu()
  }

  getDisplayName(row: Ipersons) {
    return row.firstName && row.lastName
      ? `${row.firstName} ${row.lastName}`
      : row.firstName || row.lastName || row.emailAddress;
  }
}
