<button #filterBtn mat-stroked-button [matMenuTriggerFor]="mainMenu" (menuOpened)="mainFilterSelect.panelOpen = true"
    (menuClosed)="mainFilterSelect.panelOpen = false; filterApplied($event)" class="filter-btn multi-filter">

    <div class="filter-btn-container">
        <div class="filter-prefix">
            <div class="filter-prefix-text">{{ filterMenuLabel }}</div>
            <div class="filter-count" *ngIf="totalSelectedFilters > 0">{{ totalSelectedFilters }}</div>
        </div>
        <!-- Arrow Up: Show when menu is open -->
        <mat-icon class="filter-icon" *ngIf="mainFilterSelect.panelOpen">keyboard_arrow_up</mat-icon>

        <!-- Arrow Down: Show when no filters applied and menu is closed -->
        <mat-icon class="filter-icon" *ngIf="!mainFilterSelect.panelOpen && totalSelectedFilters === 0">
            keyboard_arrow_down
        </mat-icon>

        <!-- Clear Button: Show when filters applied and menu is closed -->
        <button *ngIf="!mainFilterSelect.panelOpen && totalSelectedFilters > 0" mat-icon-button class="filter-clear-btn"
            (click)="clearAllFilter(); $event.stopPropagation()">
            <mat-icon class="filter-icon">clear</mat-icon>
        </button>
    </div>
</button>


<mat-menu #mainMenu="matMenu" [overlapTrigger]="false" [class]="customPanelClass">
    <ng-container *ngFor="let item of filterMenuItems">
        <button mat-menu-item class="menu-item-container" [matMenuTriggerFor]="item.children ? getMenuRef(item) : null">
            <!-- <div class="menu-item-container"> -->
            <ng-container *ngIf="isComponent(item.label); else stringLabel">
                <ng-container *ngComponentOutlet="item.label; injector: createInjector(item.labelProps)">
                </ng-container>
            </ng-container>
            <ng-template #stringLabel>
                {{ formatString(item.label) }}
            </ng-template>
            <div class="filter-icon-container">
                <mat-icon class="filter-selected-icon"
                    [ngClass]="{'hidden-icon': !hasSelectedFilters(item)}">circle</mat-icon>
                <img class="arrow-right" *ngIf="item.children" alt=" "
                    src="../../assets/images/arrow_right.svg" matSuffix />
            </div>
            <!-- </div> -->
        </button>
    </ng-container>
</mat-menu>

<!-- Dynamically Render Submenus -->
<ng-container *ngFor="let item of filterMenuItems">
    <mat-menu *ngIf="item.children" [overlapTrigger]="false" [class]="customPanelClass + ' sub-menu-panel'"
        #subMenuRefs="matMenu">
        <button mat-menu-item (click)="toggleSelectAll(item, !areAllChildrenSelected(item)); $event.stopPropagation()"
            class="sub-menu-item-container menu-all">
            <mat-checkbox [disableRipple]="true" class="sub-menu-checkbox" [checked]="areAllChildrenSelected(item)"
                (click)="$event.stopPropagation()" (change)="toggleSelectAll(item, $event.checked)">
            </mat-checkbox>
            <div class="sub-menu-item-text">
                All
            </div>
        </button>
        <!-- Individual Child Options -->
        <button mat-menu-item *ngFor="let subItem of item.children"
            (click)="toggleChild(item.value, subItem.value); $event.stopPropagation()" class="sub-menu-item-container">
            <div class="checkbox-text">
                <mat-checkbox [disableRipple]="true" class="sub-menu-checkbox"
                    [checked]="isChildSelected(item.value, subItem.value)" (click)="$event.stopPropagation()"
                    (change)="onCheckboxToggle(item.value, subItem.value, $event.checked)">
                </mat-checkbox>
                <div class="sub-menu-item-text">
                    <ng-container *ngIf="isComponent(subItem.label); else stringLabel">
                        <ng-container *ngComponentOutlet="subItem.label; injector: createInjector(subItem.labelProps)">
                        </ng-container>
                    </ng-container>
                    <ng-template #stringLabel>
                        {{ formatString(subItem.label) }}
                    </ng-template>
                </div>
            </div>
            <div class="sub-menu-count" *ngIf="(subItem?.count ?? 0) > 0">{{ subItem.count }}</div>
        
        </button>
    </mat-menu>
</ng-container>