import { AfterContentInit, Component, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { BankAccountService } from './bank-account.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss'],
})
export class BankAccountsComponent implements AfterContentInit {
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);

  // private paginator: MatPaginator;
  // @ViewChild(MatPaginator, { static: false }) set matPaginator(
  //   mp: MatPaginator
  // ) {
  //   this.paginator = mp;
  //   this.dataSource.paginator = this.paginator;
  // }
  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }

  columns: string[] = [];
  vehicleId: string;
  bankAccounts: any[];
  filter: string | undefined;
  isTableLoading: boolean;
  moniteIdMissing: boolean;
  serviceNotAvailableDesc =
    'This vehicle has not yet been configured for the use of bank accounts. Please contact support at';

  constructor(
    private dialog: MatDialog,
    private apiHttpService: ApiHttpService,
    private bankAccountService: BankAccountService,
    private activatedRoute: ActivatedRoute,
    public breakPointService: BreakPointService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.getBankAccounts(this.vehicleId);
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Bank accounts']);
    }, 0);
  }

  ngAfterContentInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  getBankAccounts(vehicleId: string) {
    if (environment.layer === 'stage' || environment.layer === 'prod') {
      this.moniteIdMissing = true;
      return
    }
    this.moniteIdMissing = false;
    this.isTableLoading = true;
    this.apiHttpService
      .get(this.bankAccountService.getAccountDataRout(vehicleId))
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.bankAccounts = parsedResponse.data.map((x: any) => ({
            id: x.id,
            'AC Holder Name': x.account_holder_name,
            BIC: x.bic,
            IBAN: x.iban,
            // 'AC Number': x.account_number,
            // 'Bank Name': x.bank_name,
            // Country: x.country,
            // 'Display Name': x.display_name,
            // 'Deault Currency': x.is_default_for_currency,
          }));
          this.loadTableData();
        },
        error => {
          if (
            error.error.message ===
            'moniteEntityId is not exist for this vehicle'
          ) {
            this.moniteIdMissing = true;
          }
        }
      );
  }

  loadTableData() {
    const hiddenColumn = ['id'];
    if (!this.bankAccounts.length) {
      this.columns = ['AC Holder Name', 'BIC', 'IBAN', 'actions'];
      this.dataSource = new MatTableDataSource(this.bankAccounts);
      this.isTableLoading = false;
      return;
    }
    this.columns = Object.keys(this.bankAccounts[0]).filter(
      (item: string) => !hiddenColumn.includes(item)
    );
    this.columns.push('actions');
    this.dataSource = new MatTableDataSource(this.bankAccounts);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isTableLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  openAddAccountDialog() {
    const dialogRef = this.dialog.open(AddBankaccountDialog, {
      data: { vehicleId: this.vehicleId },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'success') {
          this.commonService.successNotification(
            'Bank account successfully added.'
          );
          this.getBankAccounts(this.vehicleId);
        } else {
          this.commonService.errorNotification(result);
        }
      }
    });
  }

  openEditAccountDialog(rowData: any) {
    const dialogRef = this.dialog.open(EditBankaccountDialog, {
      data: {
        accHolderName: rowData['AC Holder Name'],
        id: rowData.id,
        iBan: rowData.IBAN,
        bic: rowData.BIC,
        currencr: 'EUR',
        country: 'DE',
        vehicleId: this.vehicleId,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'success') {
          this.commonService.successNotification(
            'Bank account successfully updated.'
          );
          this.getBankAccounts(this.vehicleId);
        } else {
          this.commonService.errorNotification(result);
        }
      }
    });
  }

  deleteBankAccount(rowData: any) {
    this.apiHttpService
      .delete(
        this.bankAccountService.deleteAccountDataRout(
          this.vehicleId,
          rowData.id
        )
      )
      .subscribe(
        _response => {
          this.commonService.successNotification(
            'Bank account successfully deleted.'
          );
          this.getBankAccounts(this.vehicleId);
        },
        (error: any) => {
          let msg = '';
          if ('detail' in error.error) {
            msg = error.error.detail[0].msg;
          } else {
            msg = error.error.error.message;
          }
          this.commonService.errorNotification(msg);
        }
      );
  }
}

@Component({
  selector: 'add-bankaccount-dialog',
  templateUrl: './add-bank-account-dialog.html',
  styleUrls: ['./bank-accounts.component.scss'],
})
export class AddBankaccountDialog {
  bic: string;
  country: string;
  currency: string;

  accHolderName = new FormControl('', [Validators.required]);
  iBan = new FormControl('', [Validators.required]);

  constructor(
    private apiHttpService: ApiHttpService,
    private bankAccountService: BankAccountService,
    public dialogRef: MatDialogRef<AddBankaccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.country = 'DE';
    this.currency = 'EUR';
  }

  addAccount() {
    this.apiHttpService
      .post(this.bankAccountService.getAccountDataRout(this.data.vehicleId), {
        account_holder_name: this.accHolderName.value,
        bic: this.bic,
        iban: this.iBan.value,
        country: this.country,
        currency: this.currency,
      })
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          // console.log({ parsedResponse });
          this.dialogRef.close('success');
        },
        error => {
          let msg = '';
          if ('detail' in error.error) {
            msg = error.error.detail[0].msg;
          } else {
            msg = error.error.error.message;
          }
          this.dialogRef.close(msg);
        }
      );
  }
}

@Component({
  selector: 'edit-bankaccount-dialog',
  templateUrl: './edit-bank-account-dialog.html',
  styleUrls: ['./bank-accounts.component.scss'],
})
export class EditBankaccountDialog {
  initialaccHolderName: string;
  iBan: string;
  bic: string;
  currency: string;
  country: string;
  accHolderName = new FormControl('', [Validators.required]);

  constructor(
    private apiHttpService: ApiHttpService,
    private bankAccountService: BankAccountService,
    public dialogRef: MatDialogRef<EditBankaccountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.accHolderName.setValue(this.data.accHolderName);
    this.initialaccHolderName = this.data.accHolderName;
    this.iBan = this.data.iBan;
    this.bic = this.data.bic;
    this.currency = this.data.currency;
    this.country = this.data.country;
  }

  editAccount() {
    this.apiHttpService
      .put(
        this.bankAccountService.editAccountDataRout(
          this.data.vehicleId,
          this.data.id
        ),
        {
          account_holder_name: this.accHolderName.value,
        }
      )
      .subscribe(
        response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          // console.log({ parsedResponse });
          this.dialogRef.close('success');
        },
        error => {
          let msg = '';
          if ('detail' in error.error) {
            msg = error.error.detail[0].msg;
          } else {
            msg = error.error.error.message;
          }
          this.dialogRef.close(msg);
        }
      );
  }
}
