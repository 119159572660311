<div class="invoice-detail">
    <div class="invoice-header">
        <div class="invoice-header-wrapper">
            <div class="invoice-header-left">
                <button class="invoice-header-button" (click)="closeInvoiceDetailDrawer()">
                    <mat-icon class="back-icon" fontIcon="close"></mat-icon>
                </button>
                <h4>{{selectedInvoice?.document_id}}</h4>
                <ng-container *ngIf="selectedInvoice?.status === 'draft'; then draft"></ng-container>
                <ng-container *ngIf="selectedInvoice?.status === 'new'; then new"></ng-container>
                <ng-container *ngIf="
                      selectedInvoice?.status === 'approve_in_progress';
                      then inApproval
                    "></ng-container>
                <ng-container *ngIf="
                      selectedInvoice?.status === 'waiting_to_be_paid';
                      then Approved
                    "></ng-container>
                <ng-container *ngIf="
                      selectedInvoice?.status === 'partially_paid';
                      then partiallyPaid
                    "></ng-container>
                <ng-container *ngIf="selectedInvoice?.status === 'paid'; then paid"></ng-container>
                <ng-container *ngIf="
                      selectedInvoice?.status === 'canceled';
                      then cancelled
                    "></ng-container>
                <ng-container *ngIf="
                      selectedInvoice?.status === 'rejected';
                      then rejected
                    "></ng-container>
                <ng-container *ngIf="
                      selectedInvoice?.status === 'expired';
                      then expired
                    "></ng-container>
            </div>

            <div class="invoice-header-right" *ngIf="selectedInvoice?.status !== 'canceled'">
                <button mat-stroked-button [disabled]="loading$ | async" (click)="toggleEditMode()"
                    *ngIf="selectedInvoice?.status !== 'waiting_to_be_paid' && (editMode$ | async) === false  && selectedInvoice?.status !== 'partially_paid' && selectedInvoice?.status !== 'paid' && selectedInvoice?.status !== 'canceled' && selectedInvoice?.status !== 'rejected' && selectedInvoice?.status !== 'approve_in_progress'">
                    Edit
                </button>
                <button mat-stroked-button (click)="handleCancelInvoice()"
                    *ngIf="(selectedInvoice?.status === 'draft' || selectedInvoice?.status === 'new')&& (editMode$ | async) === false">
                    Cancel Bill
                </button>
                <button mat-stroked-button (click)="handleRejectInvoice()"
                    *ngIf="selectedInvoice?.status === 'approve_in_progress' && (editMode$ | async) === false">
                    Reject
                </button>
                <button mat-stroked-button (click)="handleReopenInvoice()"
                    *ngIf="selectedInvoice?.status === 'rejected' && (editMode$ | async) === false">
                    Reopen
                </button>
                <button mat-stroked-button (click)="toggleEditMode()" *ngIf="editMode$ | async">
                    Cancel
                </button>
                <button mat-stroked-button class="btn-sucess" (click)="handleApproveInvoice()"
                    *ngIf="selectedInvoice?.status === 'approve_in_progress' && (editMode$ | async) === false">
                    Approve
                </button>
                <button mat-stroked-button class="btn-sucess" (click)="handleApproveInProgress()"
                    *ngIf="selectedInvoice?.status === 'new' && (editMode$ | async) === false">
                    Submit
                </button>
                <button mat-stroked-button class="btn-sucess"
                    *ngIf="(selectedInvoice?.status === 'waiting_to_be_paid') && (editMode$ | async) === false"
                    (click)="handlePayInvoice()">
                    Pay
                </button>
                <button mat-stroked-button class="btn-sucess extended-btn"
                    *ngIf="(selectedInvoice?.status === 'paid') && (editMode$ | async) === false"
                    (click)="handlePayInvoice()">
                    Payment details
                </button>
                <button mat-stroked-button class="btn-sucess" (click)="onSaveInvoice()" *ngIf="editMode$ | async">
                    Save
                </button>

            </div>

        </div>
    </div>
    <div class="invoice-content">
        <div class="invoice-content-left">
            <app-pdf-apryse [filePath]="selectedInvoice?.file?.url" [fileName]="selectedInvoice?.file?.name"
                [isShowAnnotation]="false"></app-pdf-apryse>
        </div>
        <div class="invoice-content-right">
            <div class="invoice-detail-view" *ngIf="(editMode$ | async) === false">
                <div class="invoice-details-section">
                    <div class="invoice-details-title">
                        Invoice details
                    </div>
                    <div class="invoice-details-content">
                        <div class="invoice-details-row">
                            <div class="invoice-details-label">Invoice number</div>
                            <div class="invoice-details-value">{{selectedInvoice?.document_id }}</div>
                        </div>
                        <div class="invoice-details-row">
                            <div class="invoice-details-label">Currency</div>
                            <div class="invoice-details-value">{{selectedInvoice?.currency}}</div>
                        </div>
                        <div class="invoice-details-row">
                            <div class="invoice-details-label">Issue date</div>
                            <div class="invoice-details-value">{{selectedInvoice?.issued_at | date: 'dd MMM, yyyy'}}
                            </div>
                        </div>
                        <div class="invoice-details-row">
                            <div class="invoice-details-label">Due date</div>
                            <div class="invoice-details-value">{{(selectedInvoice?.due_date | date: 'dd MMM, yyyy') || "-"}}
                            </div>
                        </div>

                    </div>
                </div>
                <div class="invoice-details-section">
                    <div class="invoice-details-title">
                        Counterparty details
                    </div>
                    <div class="invoice-details-content">
                        <div class="invoice-details-row">
                            <div class="invoice-details-label">Company name</div>
                            <div class="invoice-details-value">
                                <ngx-skeleton-loader *ngIf="(loading$ | async) === true" [theme]="{
                                          width: '200px',
                                          'border-radius': '0',
                                          height: '20px',
                                          'margin-bottom': '10px'
                                        }"></ngx-skeleton-loader>
                                <span *ngIf="(loading$ | async) === false ">{{selectedInvoice?.counterparty?.name ||
                                    counterPartyName}}</span>
                            </div>
                        </div>
                        <div class="invoice-details-row">
                            <div class="invoice-details-label">Bank account</div>
                            <div class="invoice-details-value">
                                <ngx-skeleton-loader *ngIf="(loading$ | async) === true" [theme]="{
                                          width: '200px',
                                          'border-radius': '0',
                                          height: '20px',
                                          'margin-bottom': '10px'
                                        }"></ngx-skeleton-loader>
                                <span *ngIf="(loading$ | async) === false ">{{ selectedBankAccountName ||
                                    selectedInvoice?.counterpart?.bank_account?.iban || ""}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="invoice-details-section">
                    <div class="invoice-details-title">
                        Service description
                    </div>
                    <div class="invoice-details-content">
                        <div class="invoce-details-lineItems-table">
                            <table mat-table [dataSource]="lineItemDataSource" [hidden]="loading$|async">
                                <!-- Item name column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Item name </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.name}}
                                    </td>
                                </ng-container>
                                <!-- Quantity Column -->
                                <ng-container matColumnDef="quantity">
                                    <th mat-header-cell *matHeaderCellDef class="align-left"> Quantity </th>
                                    <td mat-cell *matCellDef="let element" class="align-left">
                                        {{element.quantity}}
                                    </td>
                                </ng-container>
                                <!-- Price Column -->
                                <ng-container matColumnDef="price">
                                    <th mat-header-cell *matHeaderCellDef class="align-left"> Price </th>
                                    <td mat-cell *matCellDef="let element" class="align-left">
                                        <span>&#8364;</span>{{(element.unit_price / 100) | number : '1.2'}}
                                    </td>
                                </ng-container>
                                <!-- Total Column -->
                                <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef class="align-left"> Total </th>
                                    <td mat-cell *matCellDef="let element" class="align-left">
                                        <span>&#8364;</span>{{(element.total / 100) | number : '1.2'}}
                                    </td>
                                </ng-container>
                                <!-- Tax Column -->
                                <ng-container matColumnDef="tax">
                                    <th mat-header-cell *matHeaderCellDef class="align-left"> Tax </th>
                                    <td mat-cell *matCellDef="let element" class="align-left">
                                        {{element.tax / 100}}%
                                    </td>
                                </ng-container>

                                <tr mat-header-row
                                    *matHeaderRowDef="['name', 'quantity', 'price', 'total', 'tax'];sticky: true">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ['name', 'quantity', 'price', 'total', 'tax'];">
                                </tr>
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell no-row" colspan="8" style="padding: 1rem">
                                        <div class="no-invoice-found-container">

                                            <div class="text-section">
                                                <div class="supporting-text">
                                                    It looks like there are no line items are avaiable at the
                                                    moment. Please check back later or try adjusting your
                                                    search criteria.
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <table mat-table [dataSource]="[1]" *ngIf="loading$|async">
                                <!-- Item name column -->
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> Item name </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                  width: '90%',
                                                  height: '24px',
                                                  marginBottom: 0
                                                }"></ngx-skeleton-loader>
                                    </td>
                                </ng-container>
                                <!-- Quantity Column -->
                                <ng-container matColumnDef="quantity">
                                    <th mat-header-cell *matHeaderCellDef> Quantity </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                  width: '90%',
                                                  height: '24px',
                                                  marginBottom: 0
                                                }"></ngx-skeleton-loader>
                                    </td>
                                </ng-container>
                                <!-- Price Column -->
                                <ng-container matColumnDef="price">
                                    <th mat-header-cell *matHeaderCellDef> Price </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                  width: '90%',
                                                  height: '24px',
                                                  marginBottom: 0
                                                }"></ngx-skeleton-loader>
                                    </td>
                                </ng-container>
                                <!-- Total Column -->
                                <ng-container matColumnDef="total">
                                    <th mat-header-cell *matHeaderCellDef> Total </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                  width: '90%',
                                                  height: '24px',
                                                  marginBottom: 0
                                                }"></ngx-skeleton-loader>
                                    </td>
                                </ng-container>
                                <!-- Tax Column -->
                                <ng-container matColumnDef="tax">
                                    <th mat-header-cell *matHeaderCellDef> Tax </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                                  width: '90%',
                                                  height: '24px',
                                                  marginBottom: 0
                                                }"></ngx-skeleton-loader>
                                    </td>
                                </ng-container>

                                <tr mat-header-row
                                    *matHeaderRowDef="['name', 'quantity', 'price', 'total', 'tax'];sticky: true">
                                </tr>
                                <tr mat-row
                                    *matRowDef="let row; columns: ['name', 'quantity', 'price', 'total', 'tax'];">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="invoice-details-section no-gap">
                    <div class="invoice-details-title">
                    </div>
                    <div class="invoice-details-content">
                        <div class="invoice-details-row">
                            <div class="invoice-details-label total">Subtotal</div>
                            <div class="invoice-details-value total"><span>&#8364;</span>{{(selectedInvoice?.subtotal / 100)|
                                number : '1.2'}}</div>
                        </div>
                        <div class="invoice-details-row">
                            <div class="invoice-details-label total">VAT Total</div>
                            <div class="invoice-details-value total"><span>&#8364;</span>{{(selectedInvoice?.tax_amount / 100)
                                |
                                number : '1.2'}}</div>
                        </div>
                        <div class="invoice-details-row total-section">
                            <div class="invoice-details-label total">Total</div>
                            <div class="invoice-details-value total"><span>&#8364;</span>{{(selectedInvoice?.total_amount /
                                100) |
                                number : '1.2'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="invoice-edit-view" *ngIf="editMode$|async">
                <div class="invoice-edit-details-section">
                    <form #editInvoice="ngForm" (change)="editInvoice.control.markAsTouched()">
                        <div class="invoice-edit-column">
                            <div class="invoice-details-row">
                                <div class="invoice-details-title">
                                    Invoice Details
                                </div>
                                <div class="invoice-details-row-items">
                                    <div class="invoice-details-row-item">
                                        <!-- Invoice Number -->
                                        <div class="invoice-details-row-item-container w-half">
                                            <mat-label>
                                                Invoice number
                                                <span class="mat-form-field-required-marker">*</span>
                                            </mat-label>
                                            <mat-form-field appearance="fill" floatLabel="always">
                                                <input matInput [(ngModel)]="editInvoiceDetails.document_id"
                                                    name="invoiceNumber" #invoiceNumber="ngModel" type="text"
                                                    placeholder="Invoice Number" required>
                                            </mat-form-field>
                                        </div>

                                        <!-- Currency -->
                                        <div class="invoice-details-row-item-container w-half">
                                            <mat-label>
                                                Currency
                                                <span class="mat-form-field-required-marker">*</span>
                                            </mat-label>
                                            <mat-form-field appearance="fill" floatLabel="always">
                                                <input matInput [(ngModel)]="editInvoiceDetails.currency"
                                                    name="currency" #currency="ngModel" disabled type="text"
                                                    placeholder="Currency">
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="invoice-details-row-item" floatLabel="always">

                                        <!-- Issue date -->
                                        <div class="invoice-details-row-item-container w-half">
                                            <mat-label>
                                                Issue date
                                            </mat-label>
                                            <mat-form-field appearance="fill">
                                                <input matInput [matDatepicker]="issueDatePicker"
                                                    [(ngModel)]="editInvoiceDetails.issued_at"
                                                    [value]="editInvoiceDetails.issued_at" name="issueDate"
                                                    #issueDate="ngModel" required (click)="issueDatePicker.open()">
                                                <mat-datepicker #issueDatePicker></mat-datepicker>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="issueDatePicker"></mat-datepicker-toggle>
                                            </mat-form-field>
                                        </div>


                                        <!-- Due Date -->

                                        <div class="invoice-details-row-item-container w-half">
                                            <mat-label>
                                                Due date
                                            </mat-label>
                                            <mat-form-field appearance="fill" floatLabel="always">
                                                <input matInput [matDatepicker]="dueDatePicker"
                                                    [(ngModel)]="editInvoiceDetails.due_date" name="dueDate"
                                                    [value]="editInvoiceDetails.due_date" #dueDate="ngModel" required
                                                    (click)="dueDatePicker.open()">
                                                <mat-datepicker #dueDatePicker></mat-datepicker>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="dueDatePicker"></mat-datepicker-toggle>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- Counterparty details -->
                            <div class="invoice-details-row">
                                <div class="invoice-details-title">
                                    Counterparty Details
                                </div>
                                <div class="invoice-details-row-items">
                                    <div class="invoice-details-row-item">
                                        <!-- Company name -->

                                        <div class="invoice-details-row-item-container w-full">
                                            <mat-label>
                                                Company name
                                                <span class="mat-form-field-required-marker">*</span>
                                            </mat-label>
                                            <ngx-skeleton-loader *ngIf="(loading$ | async) === true" [theme]="{
                                                width: '100%',
                                                'border-radius': '0',
                                                height: '44px',
                                                'margin-bottom': '10px'
                                              }"></ngx-skeleton-loader>
                                            <mat-form-field appearance="fill" *ngIf="(loading$ | async) === false"
                                                floatLabel="always">
                                                <mat-select name="counterparty" [(ngModel)]="selectedCounterpart"
                                                    #counterpartPanel #counterparty="ngModel"
                                                    (ngModelChange)="onCounterpartyChange($event)" required
                                                    disableRipple="true">
                                                    <mat-option *ngFor="let item of counterpartsList"
                                                        [value]="item.value">
                                                        {{ item.label }}
                                                    </mat-option>
                                                </mat-select>
                                                <img class="arrow-down" [ngClass]="{ open: counterpartPanel.panelOpen }"
                                                    src="../../assets/images/arrow-down.svg" matSuffix
                                                    alt="Arrow down icon" />
                                            </mat-form-field>
                                        </div>



                                    </div>
                                    <div class="invoice-details-row-item">

                                        <!-- Bank Details -->

                                        <div class="invoice-details-row-item-container w-full">
                                            <mat-label>
                                                Bank Account
                                                <span class="mat-form-field-required-marker">*</span>
                                            </mat-label>
                                            <ngx-skeleton-loader *ngIf="(loading$ | async) === true" [theme]="{
                                                width: '100%',
                                                'border-radius': '0',
                                                height: '44px',
                                                'margin-bottom': '10px'
                                              }"></ngx-skeleton-loader>
                                            <mat-form-field appearance="fill" *ngIf="(loading$ | async) === false"
                                                floatLabel="always">
                                                <mat-select name="bank" [(ngModel)]="selectedBankAccount" #bankAccount
                                                    #bank="ngModel" required disableRipple="true">
                                                    <mat-option *ngFor="let item of counterpartBankAccounts"
                                                        [value]="item.id">
                                                        {{ item.name || item.iban }}
                                                    </mat-option>
                                                </mat-select>
                                                <img class="arrow-down" [ngClass]="{ open: bankAccount.panelOpen }"
                                                    src="../../assets/images/arrow-down.svg" matSuffix
                                                    alt="Arrow down icon" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Service Description -->
                            <div class="invoice-details-row">
                                <div class="invoice-details-title">
                                    Service Description
                                </div>
                                <div class="invoice-details-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style="width: 217px;">Item Name<span
                                                        class="mat-form-field-required-marker">*</span></th>
                                                <th style="width: 50px;">Qty<span
                                                        class="mat-form-field-required-marker">*</span></th>
                                                <th style="width: 80px;">Price <span>&#8364;</span><span
                                                        class="mat-form-field-required-marker">*</span></th>
                                                <th style="width: 50px;">VAT%</th>
                                                <th style="width: 72px;"></th>
                                                <th style="width: 24px;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of editInvoiceLineItems; let i = index">
                                                <td style="width: 217px;">
                                                    <mat-form-field appearance="fill" floatLabel="always"
                                                        style="width: 217px;">
                                                        <input matInput [(ngModel)]="item.name" name="itemName{{i}}"
                                                            #itemName="ngModel" type="text" placeholder="Item Name"
                                                            required>
                                                    </mat-form-field>
                                                </td>
                                                <td style="width: 50px;">
                                                    <mat-form-field appearance="fill" floatLabel="always"
                                                        style="width: 50px;">
                                                        <input matInput [(ngModel)]="item.quantity" name="quantity{{i}}"
                                                            #quantity="ngModel" type="number" placeholder="Qty"
                                                            (ngModelChange)="onItemChange(i)" required>
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <mat-form-field appearance="fill" floatLabel="always"
                                                        style="width: 90px;">
                                                        <input matInput [(ngModel)]="item.unit_price"
                                                            name="unitPrice{{i}}" #unitPrice="ngModel" type="number"
                                                            placeholder="Price" (ngModelChange)="onItemChange(i)"
                                                            required>
                                                    </mat-form-field>
                                                </td>
                                                <td style="width: 50px;">
                                                    <mat-form-field appearance="fill" floatLabel="always"
                                                        style="width: 50px;">
                                                        <input matInput [(ngModel)]="item.tax" name="tax{{i}}"
                                                            (ngModelChange)="onItemChange(i)" #tax="ngModel"
                                                            type="number" placeholder="%">
                                                    </mat-form-field>
                                                </td>
                                                <td>
                                                    <div style="text-align: end;">
                                                        <span>&#8364;</span>
                                                        {{(item.quantity * item.unit_price)+((item.quantity *
                                                        item.unit_price)*(item.tax/100)) | number : '1.2'}}
                                                    </div>
                                                </td>
                                                <td class="remove-item">
                                                    <button mat-icon-button (click)="removeItem(i)">
                                                        <img src="../../assets/images/delete_icon.svg" alt="delete_icon"
                                                            style=" height: 20px;width: 20px;" />
                                                    </button>
                                                </td>
                                        </tbody>
                                    </table>
                                    <button mat-button mat-stroked-button style="height: 34px;margin-top: 10px;"
                                        (click)="onAddItem()">
                                        <mat-icon class="add-icon" fontIcon="add"></mat-icon>
                                        Add item
                                    </button>
                                </div>
                            </div>

                            <!-- Footer total -->
                            <div class="invoice-details-row">
                                <div class="invoice-details-row-items no-gap">
                                    <div class="invoice-details-row-item">
                                        <!-- Subtotal -->
                                        <div class="invoice-details-row-item-container w-full footer small-title">
                                            <mat-label>
                                                Subtotal
                                            </mat-label>
                                            <div>
                                                <span>&#8364;</span>{{( editInvoiceDetails.subtotal / 100) | number :
                                                '1.2'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="invoice-details-row-item">
                                        <!-- VAT Total -->
                                        <div class="invoice-details-row-item-container w-full footer small-title">
                                            <mat-label>
                                                VAT total
                                            </mat-label>
                                            <div>
                                                <span>&#8364;</span>{{( editInvoiceDetails.tax_amount / 100) | number :
                                                '1.2'}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="invoice-details-row-item">
                                        <!-- Total -->
                                        <div class="invoice-details-row-item-container w-full footer large-title">
                                            <mat-label>
                                                Total
                                            </mat-label>
                                            <div>
                                                <span>&#8364;</span>{{editInvoiceDetails.total_amount / 100 | number :
                                                '1.2'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
           
        </div>


    </div>
</div>
<app-qrcode [qrCodeBase64]="qrCodeData.qrcode" [metaData]="qrCodeData.embeddedMetaData"
    [status]="selectedInvoice.status" (closeQrCodeEvent)="closeQrCode()" (markAsPaidEvent)="markAsPaid()"
     *ngIf="showQrCode$ | async">
</app-qrcode>
<div class="main-loader" *ngIf="mainLoader$ | async">
    <mat-spinner class="spinner"></mat-spinner>
</div>
<ng-template #draft>
    <div class="status-badge draft">
        <img src="../../assets/images/edit_icon.svg" alt="edit-icon" />
        <span>Draft</span>
    </div>
</ng-template>
<ng-template #new>
    <div class="status-badge new">
        <img src="../../assets/images/bolt_icon.svg" alt="new icon" />
        <span>New</span>
    </div>
</ng-template>
<ng-template #inApproval>
    <div class="status-badge in-approval">
        <img src="../../assets/images/clock_icon.svg" alt="approval icon" />
        <span>In Approval</span>
    </div>
</ng-template>
<ng-template #Approved>
    <div class="status-badge in-approval">
        <img src="../../assets/images/clock_icon.svg" alt="approval icon" />
        <span>Approved</span>
    </div>
</ng-template>
<ng-template #toBePaid>
    <div class="status-badge to-be-paid">
        <img src="../../assets/images/clock_icon.svg" alt="to paid icon" />
        <span>To be Paid</span>
    </div>
</ng-template>
<ng-template #partiallyPaid>
    <div class="status-badge partially-paid">
        <img src="../../assets/images/partial_icon.svg" alt="partial icon" />
        <span>Partially Paid</span>
    </div>
</ng-template>
<ng-template #paid>
    <div class="status-badge paid">
        <img src="../../assets/images/check_icon.svg" alt="paid icon" />
        <span>Paid</span>
    </div>
</ng-template>
<ng-template #cancelled>
    <div class="status-badge cancelled">
        <img src="../../assets/images/cross_icon.svg" alt="cancelled icon" />
        <span>Canceled</span>
    </div>
</ng-template>
<ng-template #rejected>
    <div class="status-badge rejected">
        <img src="../../assets/images/cross_icon.svg" alt="rejected icon" />
        <span>Rejected</span>
    </div>
</ng-template>
<ng-template #expired>
    <div class="status-badge expired">
        <img src="../../assets/images/warning_icon.svg" alt="expired icon" />
        <span>Expired</span>
    </div>
</ng-template>