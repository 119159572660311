import { Component } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/services/auth/auth.service';
import { UserService } from './core/services/user.service';
import { ConfigService } from './initializer/config.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from './shared/services/common.service';
import { ManagementService } from './pages/management/management.service';
import { PortfolioService } from './pages/portfolio/portfolio.service';
import { CounterpartDetailService } from './pages/transactions/counterpart-detail/counterpart.service';
import { BankTransactionsService } from './pages/transactions/bank-transactions/bank-transactions.service';
import { InvoiceDetailService } from './pages/transactions/invoice-detail/invoice-detail.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'gp-portal';
  isAuthenticated: boolean = false;
  baseData: any;
  sessionUser: any;
  selectedGP: any;
  selectedVehicle: any;
  error: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private config: ConfigService,
    public dialog: MatDialog,
    public managementService: ManagementService,
    public portfolioService: PortfolioService,
    public counterpartDetailService: CounterpartDetailService,
    public bankTransactionsService: BankTransactionsService,
    private authService: AuthService,
    public invoiceDetailService: InvoiceDetailService,
  ) {
    this.authService.startTokenRefreshTimer();
    this.authService.logoutAfterThreeHours();
    this.isAuthenticated = this.authService.isAuthenticated();
    if (this.config.sessionuser?.error) {
      this.error = this.config.sessionuser?.error;
    }

    router.events
      .pipe(
        filter((routerEvent: Event) => routerEvent instanceof NavigationEnd)
      )
      .subscribe(() =>
        document.getElementsByTagName('mat-sidenav-content')[0]?.scrollTo(0, 0)
      );
  }

  ngOnInit() {
    if (this.config.sessionuser?.error) {
      this.error = this.config.sessionuser?.error;
    }

    if (
      this.config.sessionuser.data &&
      this.config.baseData &&
      this.config.baseData.data
    ) {
      this.sessionUser = this.config.sessionuser?.data;
      this.baseData = this.config.baseData?.data;
      let userLanguage = 'en';
      this.userService.setUserConfig(
        `${this.sessionUser.firstName} 
        ${this.sessionUser.lastName}`,
        userLanguage,
        this.sessionUser.id
      );
      this.setAppLanguage();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  }
  setAppLanguage() {
    this.translate.addLangs(['en', 'de']);
    const { languageCode } = JSON.parse(this.userService.getUserConfig());
    this.translate.use(languageCode);
  }
}
