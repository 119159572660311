import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from '../../../core/services/api-http.service';
import { SortDirection } from '@angular/material/sort';
import { MoniteService } from '../monite/monite.service';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private apiHttpService: ApiHttpService,
    private moniteService: MoniteService
  ) { }

  getTransctionsRoute(vehicleId: string): string {
    return this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/payables`,
      false
    );
  }

  uploadInvoiceToOcr(vehicleId: string): any {
    return this.apiEndpointsService.createUrl(
      `payables/vehicle/${vehicleId}`,
      false
    );
  }

  uploadInvoiceRoute(vehicleId: string): string {
    return this.apiEndpointsService.createUrl(
      `vehicles/${vehicleId}/payables/upload-from-file`,
      false
    );
  }
  getInvoiceLists(
    vehicleId: string,
    pgSize: number,
    paginationToken?: string,
    params?: { [key: string]: any }
  ) {
    const queryParams: any = {
      limit: pgSize,
      sort:"created_at",
      order:"desc",
      ...params,
    };
    if (paginationToken) {
      queryParams['pagination'] = paginationToken;
    }
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/invoices`,
        false
      ),
      { params: queryParams }
    );
  }
  getAddedByInvoiceDetails(vehicleId: string, createdId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/entity_users/${createdId}`,
        false
      )
    );
  }

  authenticateAsEntityUser(entityUserId: string) {
    return this.apiHttpService.get(
      this.moniteService.getEntityUserAuthUrl(entityUserId)
    )
  }
}
