<div class="report-overview-gp">
    <div class="subheader-content">
        <div class="content-title">
            <div class="report-right-content">
                <div class="back-icon" *ngIf="!isLoading; else backBtnLoader">
                    <img src=" ../../../assets/images/back_icon.svg" height="24px" width="24px"
                        (click)="navigateBack()">
                </div>
                <ng-container *ngIf="!isLoading; else searchLoader">
                    <mat-form-field class="report-search-field"
                        [ngClass]="{'report-search-mob' : (breakpointService.isSmallerHandset$ | async)}">
                        <img src="../../assets/images/search.svg" alt="search" matPrefix />
                        <input matInput placeholder="Search" (input)="applyFilter()" autocomplete="off"
                            [(ngModel)]="search" autocomplete="off">
                        <button class="clear-btn" matSuffix *ngIf="search" matSuffix (click)="search=''; applyFilter()">
                            <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px"
                                alt="clear">
                        </button>
                    </mat-form-field>
                </ng-container>
            </div>
            <div class="report-right-content">
                <span class="badge-report-type" *ngIf="!isLoading; else typeLoader">{{
                    reportDetail.type ? typesConst[reportDetail.type] ? typesConst[reportDetail.type] :
                    reportDetail.type :
                    ''}}</span>
                <div class="investor-status" *ngIf="!isLoading; else reportStatusLoader">
                    <div class="status-badge report-type-sent" *ngIf="reportDetail.state === 'Sent'">
                        <img src="../../../../assets/images/check_icon.svg" alt="person icon">
                        <span>Sent</span>
                    </div>
                    <div class="status-badge report-type-draft" *ngIf="reportDetail.state === 'Draft'">
                        <img src="../../../../assets/images/edit_icon.svg" alt="person icon">
                        <span>Draft</span>
                    </div>
                    <div class="status-badge report-type-in-review" *ngIf="reportDetail.state === 'InReview'">
                        <img src="../../../../assets/images/clock_icon.svg" alt="person icon">
                        <span>In Review</span>
                    </div>
                    <div class="status-badge report-type-aborted" *ngIf="reportDetail.state === 'Aborted'">
                        <img src="../../../../assets/images/cross_icon.svg" alt="person icon">
                        <span>Aborted</span>
                    </div>
                    <div class="status-badge report-type-ready-to-send" *ngIf="reportDetail.state === 'ReadyToSend'">
                        <img src="../../../../assets/images/clock_icon.svg" alt="person icon">
                        <span>Ready To Send</span>
                    </div>
                    <div class="status-badge report-type-approved" *ngIf="reportDetail.state === 'Approved'">
                        <img src="../../../../assets/images/check_icon.svg" alt="person icon">
                        <span>Approved</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="report-section">
        <div class="report-preview-table">
            <ng-container *ngIf="!isLoading">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="investorId" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="investorId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Investor ID </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="badge-circle">
                                <span class="badge-text">{{element.investorId ? element.investorId : '-'}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="report-name-field">{{element.name ? element.name : '-'}}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="documents">
                        <th mat-header-cell *matHeaderCellDef> Documents </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="document-icon">
                                <img *ngIf="element.renderedDocumentsCount || element.staticDocumentsCount"
                                    src="../../../../assets/images/document_icon.svg" alt="Investor Icon" height="16px"
                                    width="16px">
                                <span class="doc-list">
                                    <span *ngIf="element.renderedDocumentsCount">{{element.renderedDocumentsCount}}
                                        rendered</span>
                                    <span *ngIf="element.renderedDocumentsCount && element.staticDocumentsCount">,
                                    </span>
                                    <span *ngIf="element.staticDocumentsCount">{{element.staticDocumentsCount}}
                                        static</span>
                                </span>
                                <span *ngIf="!element.renderedDocumentsCount && !element.staticDocumentsCount">-</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="email_sents">
                        <th mat-header-cell *matHeaderCellDef> Email sent on </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="email-document-icon">
                                <img *ngIf="element.sentAt" src="../../../../assets/images/mail_sent_icon.svg"
                                    alt="Investor Icon" height="16px" width="16px">
                                {{element.sentAt ? commonService.formateDateTime(element.sentAt) : '-'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef>
                            <ng-container *ngIf="!isLoading; else countLoader">
                                <div class="count-details">
                                    {{dataSource.filteredData.length}}
                                </div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <img *ngIf="element.renderedDocumentsCount || element.staticDocumentsCount || element.sentAt"
                                src="../../../../assets/images/eye_icon.svg" width="20px" height="20px"
                                alt="Investor Details">
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{ 'no-click' : (!row.renderedDocumentsCount && !row.staticDocumentsCount && !row.sentAt)  }"
                        (click)="openPreview(row)"></tr>
                </table>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <table mat-table [dataSource]="[1,2,3,4,5,6]">
                    <ng-container matColumnDef="investorId">
                        <th mat-header-cell *matHeaderCellDef> Investor ID </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '70%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '70%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="documents">
                        <th mat-header-cell *matHeaderCellDef> Documents </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '70%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="email_sents">
                        <th mat-header-cell *matHeaderCellDef> Email sent on </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                width: '70%',
                                height: '24px',
                                marginBottom: 0
                                }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="details">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>
        </div>
    </div>

</div>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #backBtnLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '38px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #countLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '180px',
            height: '24px',
            marginBottom: 0
      }"></ngx-skeleton-loader>
</ng-template>

<ng-template #reportStatusLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '80px',
            height: '24px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #typeLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '80px',
            height: '24px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>