import { Directive, AfterViewInit } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Directive({
  selector: 'mat-menu[person-name-position]',
})
export class PersonNamePositionDirective implements AfterViewInit {
  constructor(private menu: MatMenu) { }

  ngAfterViewInit(): void {
    this.menu.overlayPanelClass = `person-name-position`;
  }
}
