import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  // GET /vehicles/{vehicleId}/reports
  getAllReports(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports`,
        false
      )
    );
  }

  // GET /vehicles/{vehicleId}/reports/{reportId}/limitedPartners
  getReportDetails(vehicleId: string, reportId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/limitedPartners`,
        false
      )
    );
  }

  // GET /vehicles/{vehicleId}/reports/{reportId}/limitedPartners/{limitedPartnerId}/details
  getLPReportDetails(vehicleId: string, reportId: string, limitedPartnerId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/limitedPartners/${limitedPartnerId}/details`,
        false
      )
    );
  }

  // GET /vehicles/{vehicleId}/reports/{reportId}/files/{fileId}
  getFilebyId(vehicleId: string, reportId: string, fileId: number) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/files/${fileId}`,
        false
      ), { responseType: 'arraybuffer' }
    );
  }
}
