import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-comming-soon',
  templateUrl: './comming-soon.component.html',
  styleUrls: ['./comming-soon.component.scss'],
})
export class CommingSoonComponent {
  @Input() path: string | undefined;
  @Input() baseData: any;
  fundAdminCockpitUrl = '';

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userService.fundAdminCockpitUrlObservable$.subscribe((res: any) => {
      this.fundAdminCockpitUrl = res;
    });
  }

  redirect() {
    window.open(this.fundAdminCockpitUrl + '/' + this.path, '_blank');
  }
}
