import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Injectable({
  providedIn: 'root'
})
export class MoniteService {

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  // GET /users/:userId/vehicles/:vehicleId/entity-details
  getMoniteIds(userId: string, vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `users/${userId}/vehicles/${vehicleId}/entity-details`,
        false
      )
    );
  }

  // GET /entity-user/:entityUserId/entity-user-login
  getEntityUserAuthUrl(entityUserId: string) {
    return this.apiEndpointsService.createUrl(
      `entity-user/${entityUserId}/entity-user-login`,
      false
    )
  }

  // POST /users/:userId/vehicles/:vehicleId/create-monite-entity
  createMoniteEntity(userId: string, vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `users/${userId}/vehicles/${vehicleId}/monite/create-monite-entity`,
        false
      ), {}
    )
  }

  // POST /users/:userId/vehicles/:vehicleId/create-monite-entity-user
  createMoniteEntityUser(userId: string, vehicleId: string) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `users/${userId}/vehicles/${vehicleId}/monite/create-monite-entity-user`,
        false
      ), {}
    )
  }
}
