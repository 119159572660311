import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InvestorsService } from '../../investors/investors.service';
import { ReportsService } from '../reports.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportPreviewComponent } from '../report-preview/report-preview.component';
import { BreakPointService } from '../../../shared/services/break-point.service';

export interface IReportDetail {
  id: number,
  name: string,
  type: string,
  state: string,
  limitedPartners: IlimitedPartner[]
}
export interface IlimitedPartner {
  limitedPartnerId: string,
  investorId: string,
  name: string,
  renderedDocumentsCount: number,
  staticDocumentsCount: number,
  sentAt: string
}
@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent {

  @Input() reportId: string;
  @Input() gpId: string;
  @Input() vehicleId: string;
  @Output() backClick = new EventEmitter();
  displayedColumns: string[] = [
    'investorId',
    'name',
    'documents',
    'email_sents',
    'details'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  reportDetail: IReportDetail;

  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  search: string;
  isLoading: boolean = false;
  typesConst: any = {
    crr: "CRR",
    quarterlyReportings: "Quarterly report",
    capitalCallsAndClosings: "Capital call/Closing",
    distributions: "Distribution",
    shareholderResolutions: "Shareholder resolution",
    annualReportsAndAuditReports: "Annual report/Audit report",
    statementsOfAccount: "Statement of account",
    tax: "Tax",
    principalLevelTopics: "Principal level topic"
  }

  constructor(
    private reportsService: ReportsService,
    public commonService: CommonService,
    public breakpointService: BreakPointService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getReportDetail();
  }

  getReportDetail() {
    this.isLoading = true;
    this.reportsService
      .getReportDetails(this.vehicleId, this.reportId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.reportDetail = parsedResponse.data;
          if (!this.reportDetail.name) {
            this.commonService.OpenAccessDenied(this.gpId, this.vehicleId, 'reports')
            return
          }
          this.dataSource = new MatTableDataSource(parsedResponse.data.limitedPartners);
          this.dataSource.sort = this.sort;
          this.dataSource.sortingDataAccessor = (item, property) => item[property].toLocaleLowerCase();
          this.commonService.setSecondaryPath([this.reportDetail.name]);
        },
        error: error => {
          if (error.error.message.includes('Limited partner does not exist') || error.error.message.includes('uuid')) {
            this.commonService.OpenAccessDenied(this.gpId, this.vehicleId, 'reports')
            return
          }
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve report details')
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();

    // Update filterPredicate to handle the specific columns
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const id = data.investorId
        ? data.investorId.toString().toLowerCase()
        : '';
      const name = data.name ? data.name.toLowerCase() : '';

      return id.includes(filter) || name.includes(filter);
    };

    this.dataSource.filter = filterValue;
  }

  navigateBack() {
    this.backClick.emit();
  }

  openPreview(limitedPartner: IlimitedPartner) {
    if (!limitedPartner.renderedDocumentsCount && !limitedPartner.staticDocumentsCount && !limitedPartner.sentAt) {
      return
    }
    this.dialog.open(ReportPreviewComponent, {
      data: {
        vehicleId: this.vehicleId,
        reportId: this.reportId,
        limitedPartner: limitedPartner,
      },
    });
  }
}
