<app-comming-soon *ngIf="migratingSoon" [path]='"reports"'></app-comming-soon>
<div class="report-gp" *ngIf="!isShowDetails && !migratingSoon">
    <ng-container *ngIf="!noReportFound; else noDataTemplate">
        <div class="report-subheader">
            <!-- <ng-container *ngIf="!isLoading; else countLoader">
                <div class="report-title">
                    {{dataSource.filteredData.length}} {{dataSource.filteredData.length !== 1 ? 'reports' :
                    'report'}}
                </div>
            </ng-container> -->
            <ng-container *ngIf="!isLoading; else searchLoader">
                <mat-form-field class="report-search-field">
                    <img src="../../assets/images/search.svg" alt="search" matPrefix />
                    <input matInput placeholder="Search" (input)="applyFilter()" autocomplete="off" [(ngModel)]="search"
                        autocomplete="off">
                    <button class="clear-btn" matSuffix *ngIf="search" matSuffix (click)="search=''; applyFilter()">
                        <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px" alt="clear">
                    </button>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="report-section">
            <div class="report-table-gp">
                <ng-container *ngIf="!isLoading">
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="createdAt" matSortDirection="desc"
                        matSortDisableClear>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="report-name-field">{{element.name ? element.name : '-'}}</div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.type ? typesConst[element.type] ? typesConst[element.type] : element.type :
                                '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created on </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.createdAt ? commonService.formateDate(element.createdAt) : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="updatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated on </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.updatedAt ? commonService.formateDate(element.updatedAt) : '-'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="investors">
                            <th mat-header-cell *matHeaderCellDef> Investors</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.limitedPartnerCount}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="status-badge report-type-sent" *ngIf="element.state === 'Sent'">
                                    <img src="../../../../assets/images/check_icon.svg" alt="person icon">
                                    <span>{{element.state}}</span>
                                </div>
                                <div class="status-badge report-type-draft" *ngIf="element.state === 'Draft'">
                                    <img src="../../../../assets/images/edit_icon.svg" alt="person icon">
                                    <span>{{element.state}}</span>
                                </div>
                                <div class="status-badge report-type-in-review" *ngIf="element.state === 'InReview'">
                                    <img src="../../../../assets/images/clock_icon.svg" alt="person icon">
                                    <span>In Review</span>
                                </div>
                                <div class="status-badge report-type-aborted" *ngIf="element.state === 'Aborted'">
                                    <img src="../../../../assets/images/cross_icon.svg" alt="person icon">
                                    <span>{{element.state}}</span>
                                </div>
                                <div class="status-badge report-type-ready-to-send"
                                    *ngIf="element.state === 'ReadyToSend'">
                                    <img src="../../../../assets/images/clock_icon.svg" alt="person icon">
                                    <span>Ready To Send</span>
                                </div>
                                <div class="status-badge report-type-approved" *ngIf="element.state === 'Approved'">
                                    <img src="../../../../assets/images/check_icon.svg" alt="person icon">
                                    <span>{{element.state}}</span>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef>
                                <ng-container *ngIf="!isLoading; else countLoader">
                                    <div class="count-details">{{dataSource.filteredData.length}}</div>
                                </ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <img *ngIf="element.limitedPartnerCount" height="20px" width="20px"
                                    src="../../../../assets/images/arrow_right_icon.svg" alt="Investor Details">
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{ 'no-click' : !row.limitedPartnerCount }" (click)="showDetails(row)"></tr>
                    </table>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <table mat-table [dataSource]="[1,2,3,4,5,6]" class="loading-table">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '90%',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '160px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef> Created on </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '120px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="updatedAt">
                            <th mat-header-cell *matHeaderCellDef> Updated on </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '120px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="investors">
                            <th mat-header-cell *matHeaderCellDef> Investors</th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '50px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status</th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '140px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element"></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></tr>
                    </table>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #noDataTemplate>
        <div class="no-report-wrapper">
            <div class="no-report-container">
                <div class="icon">
                    <img src="../../../assets/images/file_text_icon.svg" alt="icon" height="24px" width="24px">
                </div>
                <div class="text-area">
                    <div class="main">No reports available</div>
                    <div class="sub-text">Looks like there are no reports available for now. Please check again later
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<app-report-details *ngIf="isShowDetails" (backClick)="hideDetails()" [reportId]="reportId" [vehicleId]="vehicleId"
    [gpId]="gpId"></app-report-details>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #countLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '180px',
            height: '24px',
            marginBottom: 0
      }"></ngx-skeleton-loader>
</ng-template>