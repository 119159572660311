import { Component, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { FinoService } from './fino.service';
import { Location } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';


interface IBankAccount {
  bankLoginId: string,
  icon: string,
  bankName: string,
  name: string,
  owner: string,
  iban: string,
  bic: string,
  currency: string,
  type: string,
  accountId: string,
}

@Component({
  selector: 'app-fino',
  templateUrl: './fino.component.html',
  styleUrls: ['./fino.component.scss']
})
export class FinoComponent {
  isLoading: boolean = false;
  isAddRedirect: boolean = false;
  isManageRedirect: boolean = false;
  showTransactions: boolean = false;
  search: string;
  vehicleId: string;
  gpId: string;
  selectedAccountId: string;
  selectedAccountNumber: string;
  selectedAccountName: string;
  displayedColumns: string[] = [
    'bank',
    'name',
    'owner',
    'iBan',
    'bic',
    'currency',
    'type',
    'action'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  private sort: MatSort;
  sessionUser: any;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  bankAccounts: IBankAccount[] = []

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private finoService: FinoService,
    private _location: Location,
    private dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.selectedAccountId = this.activatedRoute.snapshot.params['accountId'];
    this.showTransactions = !!this.selectedAccountId
    !this.selectedAccountId && this.getBankAccounts();
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Bank accounts']);
    }, 0);
    this.userService.userDataObservable$.subscribe((res: any) => {
      this.sessionUser = res;
      if (!res.events.confirmBankConsent) {
        this.openConsentDialog();
      }
    });
  }

  openConsentDialog() {
    const dialogRef = this.dialog.open(BankConsentDialog, {
      panelClass: 'confirm-bank-panel'
    });
  }


  applyFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();

    // Update filterPredicate to handle the specific columns
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const name = data.name ? data.name.toLowerCase() : '';
      return name.includes(filter);
    };

    this.dataSource.filter = filterValue;
  }

  getBankAccounts() {
    this.isLoading = true;
    this.finoService.getBankAccounts(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        this.dataSource = new MatTableDataSource(parsedResponse.data);
        this.dataSource.sort = this.sort;
        this.bankAccounts = parsedResponse.data;
      },
      error: error => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  addBankAccount() {
    this.isAddRedirect = true;
    this.finoService.getCreateBankAccountUrl(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        window.open(parsedResponse.data.redirectURL, '_self');
      },
      error: error => {
        this.isAddRedirect = false;
        console.log('Can not add bank account', error)
      },
      complete: () => {
        this.isAddRedirect = false;
      },
    })
  }

  manageBankAccount() {
    this.isManageRedirect = true;
    this.finoService.getBankAccountManagementUrl(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        window.open(parsedResponse.data.redirectURL, '_self');
      },
      error: error => {
        this.isManageRedirect = false;
        console.log('Can not manage bank account', error)
      },
      complete: () => {
        this.isManageRedirect = false;
      },
    })
  }

  deleteAccount(row: any) {

    const dialogRef = this.dialog.open(DeleteBankAccountDialog, {
      data: {
        bankLoginId: row.bankLoginId,
        vehicleId: this.vehicleId,
        item: `${row.name}`,
        msg: 'Are you sure you want to delete? This will unlink the bank account from this fund.'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getBankAccounts();
        this.commonService.successNotification('Bank account removed successfully.');
      } else if (result === 'error') {
        this.commonService.errorNotification('Failed to remove bank account.');
      }
    });
  }

  navigateToTransaction(account: IBankAccount) {
    this.selectedAccountName = account.bankName;
    this.selectedAccountId = account.accountId;
    this.selectedAccountNumber = account.iban;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/transactions/bank-accounts/${account.accountId}`
    );
    this.showTransactions = true;

  }

  hideTransactions() {
    this.showTransactions = false;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/settings/fino`
    );
    this.commonService.setSecondaryPath(['fino']);
    !this.bankAccounts && this.getBankAccounts();
  }
}

@Component({
  selector: 'delete-portfolio-company-dialog',
  templateUrl: './delete-bank-account.html',
  styleUrls: ['./fino.component.scss'],
})
export class DeleteBankAccountDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteBankAccountDialog>,
    private finoService: FinoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  delete() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.finoService
      .deleteBankAccount(this.data.bankLoginId, this.data.vehicleId)
      .subscribe({
        next: _response => {
          this.dialogRef.close('success');
        },
        error: _error => {
          this.dialogRef.close('error');
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }
}

@Component({
  selector: 'bank-consent-dialog',
  templateUrl: './bank-consent-dialog.html',
  styleUrls: ['./bank-consent-dialog.scss'],
})
export class BankConsentDialog {
  confirmBankConsent = false;
  constructor(
    private apiHttpService: ApiHttpService,
    private userService: UserService,
    private apiEndpointsService: ApiEndpointsService,
    public dialogRef: MatDialogRef<BankConsentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  checkboxChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.confirmBankConsent = true;
    } else {
      this.confirmBankConsent = false;
    }
  }

  submitConsent() {
    if (this.confirmBankConsent) {
      this.apiHttpService
        .put(this.apiEndpointsService.postSessionUser(), {
          gpConfirmedBankConsent: true,
        })
        .subscribe(
          (response: any) => {
            console.log('Bank consent successfully submitted:', response);

            this.dialogRef.close();
          },
          (error: any) => {
            console.error('Error while agreeing to Terms of Use:', error);
          }
        );
        this.userService.setUserData({ gpConfirmedBankConsent: new Date()});
      this.dialogRef.close();
    }
  }
}

