<!-- <p class="title-class">settings page!</p> -->
<!-- <app-comming-soon [path]='"settings"'></app-comming-soon> -->
<ng-container *ngIf="!moniteIdMissing; else moniteIdMissingerror">
    <div class="toolbar">
        <h2>Bank Accounts</h2>
        <div class="table-filter" *ngIf="!moniteIdMissing">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filter">
                <button mat-icon-button matSuffix (click)="filter = undefined;this.dataSource.filter = ''"
                    *ngIf="filter" style="color: var(--primary-color)">
                    <mat-icon>clear</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <button *ngIf="!moniteIdMissing" mat-flat-button class="add-account" (click)="openAddAccountDialog()"
            [ngClass]="{mobile: (breakPointService.isHandset$ | async)}"><mat-icon>add</mat-icon>
            <span *ngIf="(breakPointService.isHandset$ | async) === false">Add Account</span>

        </button>
    </div>
    <ng-container *ngIf="!isTableLoading; else tableLoader">
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container *ngFor="let column of columns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="" [disabled]="column === 'actions'">
                        {{column === 'actions' ? '' : column}}
                    </th>
                    <ng-container *ngIf="column != 'actions'; else actionsTemplate">
                        <td mat-cell *matCellDef="let row">
                            {{row[column]}}
                        </td>
                    </ng-container>
                    <ng-template #actionsTemplate>
                        <td mat-cell *matCellDef="let row" class="action-link">
                            <div class="action-wrapper">
                                <button mat-icon-button class="delete-icon" (click)="deleteBankAccount(row)">
                                    <mat-icon>delete_outline</mat-icon>
                                </button>
                                <button mat-icon-button class="edit-icon" (click)="openEditAccountDialog(row)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-template>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4" style="padding:1rem;" *ngIf="filter">No data matching the filter
                        "{{filter}}"</td>
                    <td class="mat-cell" colspan="4" style="padding:1rem;" *ngIf="!filter">No Bank Account found!</td>
                </tr>
            </table>

        </div>
        <!-- <mat-paginator style="margin: 0 1rem 1rem; border-radius:  0 0 6px 6px" [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Select page of users" #paginator></mat-paginator> -->
    </ng-container>

    <ng-template #tableLoader>
        <div class="folderLoader" *ngFor="let number of [0, 1, 2, 3, 4, 5]">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                          margin: '0 1rem',
                          width: '100%',
                          height: '50px'
                        }"></ngx-skeleton-loader>
        </div>
    </ng-template>
</ng-container>

<ng-template #moniteIdMissingerror>
    <app-service-not-available [description]="serviceNotAvailableDesc"></app-service-not-available>
</ng-template>