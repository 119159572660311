<div class="letterhead-preview">
    <div class="preview-header">
        {{data.name}}

        <button (click)="dialogRef.close()">
            <img src="../../../assets/images/cross_icon_grey.svg" alt="close">
        </button>
    </div>
    <div class="preview-section">
        <ng-container *ngIf="!isloading; else pdfViewerLoader">
            <app-pdf-viewer [filePath]="filePath"></app-pdf-viewer>
        </ng-container>
    </div>
</div>

<ng-template #pdfViewerLoader>
    <div style="height: 100%;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '100%',
                height: '100%',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>