<div class="pnf-page">
    <div class="migrating-soon-container">
        <img src="../../../assets/images/coming_soon_icon.svg" alt="comming-soon">
        <div class="text-section">
            <div class="heading">Migration soon...</div>
            <div class="supporting-text">This function is not yet available in the new GP Portal.</div>
        </div>
        <button mat-flat-button (click)="redirect()" [disabled]="fundAdminCockpitUrl === null">
            Open in Fund Admin Cockpit
        </button>
    </div>
</div>