<div *ngIf="(breakPointService.isHandset$ | async) === false">
  <div class="documents-container">
    <div class="list-container" *ngIf="files?.length !== 0 || folders?.length !== 0">
      <mat-card class="list-card">
        <div class="head">
          <div *ngIf="!showLoader; else backButtonLoader" class="backButton" [ngClass]="isRoot ? 'disabled' : ''">
            <span class="back-img" (click)="handleBack()">
              <img src="../../../assets/images/back_icon.svg" height="24px" width="24px" />
              <!-- <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.34988 6.12496H14V7.87496H3.34988L8.04335 12.5684L6.80593 13.8058L0 6.99996L6.80593 0.194016L8.04335 1.43145L3.34988 6.12496Z"
                  fill="#828282" />
              </svg> -->

              {{ 'translations.documents.back' | translate }}
            </span>
          </div>
        </div>
        <div class="body">
          <div>
            <ng-container *ngIf="!showLoader; else folderLoader">
              <div *ngIf="showFolders" class="folderList">
                <div class="folderStructure" *ngFor="let folder of folders; index as i"
                  (click)="onFolderNavigation(folder)">
                  <div class="row">
                    <img src="../../../assets/images/folder_icon.svg" height="24px" width="24px" />
                    <div class="file-metadata">
                      <div>{{ folder.name }}</div>
                    </div>
                  </div>
                  <img src="../../../assets/images/arrow_right.svg" />
                </div>
              </div>
              <div *ngIf="files?.length === 0 && folders?.length === 0" style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 2rem;
                color: #828282;
              ">
                <span>No documents are currently provided for this vehicle</span>
              </div>
              <div *ngIf="showFiles">
                <mat-selection-list [multiple]="false" [(ngModel)]="selectedFile" [compareWith]="compareFiles"
                  hideSingleSelectionIndicator="true" class="reportsList"
                  *ngIf="(breakPointService.isHandset$ | async) === false">
                  <mat-list-option class="fileStructure" *ngFor="let file of files; index as i"
                    (click)="showWebViewer(file)" [value]="file" [ngClass]="
                    !!selectedFile && selectedFile.name === file.name
                      ? 'selected'
                      : ''
                  ">
                    <div class="row">
                      <img *ngIf="
                        !!selectedFile && selectedFile.name === file.name;
                        else fileIcon
                      " src="../../../assets/images/file_icon_active.svg" />
                      <ng-template #fileIcon>
                        <img src="../../../assets/images/file_icon.svg" height="24px" width="24px" />
                      </ng-template>

                      <div class="file-metadata">
                        <div>{{ file.name }}</div>
                        <div class="datePostFile">
                          {{ file.creationTime | date : 'dd. MMM yyyy' }}
                        </div>
                      </div>
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="container-middle-border"
      *ngIf="(files?.length !== 0 || folders?.length !== 0) && !filePath && !isLoading"></div>
    <div class="content-container" *ngIf="showPdfView">
      <div class="content-body" *ngIf="!isLoading; else pdfLoader"
        [ngClass]="{ previewContainer: showViewer === false || !filePath }">
        <div class="viewerTile" *ngIf="filePath" [ngClass]="{ hideViewerContainer: showViewer === false }">
          <div class="viewer">
            <ng-container *ngIf="isVisible$ | async">
              <app-pdf-apryse [filePath]="filePath" [fileName]="selectedFile?.name" [isShowAnnotation]="false"></app-pdf-apryse>
            </ng-container>
          </div>
        </div>
        <div class="previewImgContainer" *ngIf="files?.length === 0 && folders?.length === 0" [ngClass]="{
            hidePreviewContainer: showViewer === true && !!filePath
          }">
          <div>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="56" rx="28" fill="#F4F4F4" />
              <path
                d="M28.0003 19C33.3924 19 37.8784 22.8798 38.8189 28C37.8784 33.1202 33.3924 37 28.0003 37C22.6081 37 18.1222 33.1202 17.1816 28C18.1222 22.8798 22.6081 19 28.0003 19ZM28.0003 35C32.2359 35 35.8603 32.052 36.7777 28C35.8603 23.948 32.2359 21 28.0003 21C23.7646 21 20.1402 23.948 19.2228 28C20.1402 32.052 23.7646 35 28.0003 35ZM28.0003 32.5C25.515 32.5 23.5003 30.4853 23.5003 28C23.5003 25.5147 25.515 23.5 28.0003 23.5C30.4855 23.5 32.5003 25.5147 32.5003 28C32.5003 30.4853 30.4855 32.5 28.0003 32.5ZM28.0003 30.5C29.381 30.5 30.5003 29.3807 30.5003 28C30.5003 26.6193 29.381 25.5 28.0003 25.5C26.6196 25.5 25.5003 26.6193 25.5003 28C25.5003 29.3807 26.6196 30.5 28.0003 30.5Z"
                fill="#828282" />
            </svg>
          </div>
          <p class="selectMessage">No documents are currently provided for this vehicle</p>
        </div>
        <div class="previewImgContainer" *ngIf="
            !selectedFile &&
            isVisible$ &&
            (files?.length !== 0 || folders?.length !== 0)
          " [ngClass]="{ hidePreviewContainer: showViewer === true }">
          <div>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="56" rx="28" fill="#F4F4F4" />
              <path
                d="M28.0003 19C33.3924 19 37.8784 22.8798 38.8189 28C37.8784 33.1202 33.3924 37 28.0003 37C22.6081 37 18.1222 33.1202 17.1816 28C18.1222 22.8798 22.6081 19 28.0003 19ZM28.0003 35C32.2359 35 35.8603 32.052 36.7777 28C35.8603 23.948 32.2359 21 28.0003 21C23.7646 21 20.1402 23.948 19.2228 28C20.1402 32.052 23.7646 35 28.0003 35ZM28.0003 32.5C25.515 32.5 23.5003 30.4853 23.5003 28C23.5003 25.5147 25.515 23.5 28.0003 23.5C30.4855 23.5 32.5003 25.5147 32.5003 28C32.5003 30.4853 30.4855 32.5 28.0003 32.5ZM28.0003 30.5C29.381 30.5 30.5003 29.3807 30.5003 28C30.5003 26.6193 29.381 25.5 28.0003 25.5C26.6196 25.5 25.5003 26.6193 25.5003 28C25.5003 29.3807 26.6196 30.5 28.0003 30.5Z"
                fill="#828282" />
            </svg>
          </div>

          <p class="selectMessage">
            {{ 'translations.documents.selectDocument' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="breakPointService.isHandset$ | async">
  <div class="documents-container mobileScreen">
    <mat-sidenav-container class="example-sidenav-container"
      [class.example-is-mobile]="breakPointService.isHandset$ | async">
      <mat-sidenav #snav [mode]="(breakPointService.isHandset$ | async) ? 'over' : 'side'"
        [fixedInViewport]="(breakPointService.isHandset$ | async) === false">
        <div class="list-container">
          <div class="list-card">
            <div class="head">
              <div class="backButton" [ngClass]="isRoot ? 'disabled' : ''" (click)="handleBack()">
                <span>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.34988 6.12496H14V7.87496H3.34988L8.04335 12.5684L6.80593 13.8058L0 6.99996L6.80593 0.194016L8.04335 1.43145L3.34988 6.12496Z"
                      fill="#828282" />
                  </svg>
                  {{ 'translations.documents.back' | translate }}
                </span>
              </div>
            </div>
            <div class="body">
              <div>
                <ng-container *ngIf="!showLoader; else folderLoader">
                  <div *ngIf="showFolders" class="folderList">
                    <div class="folderStructure" *ngFor="let folder of folders; index as i"
                      (click)="onFolderNavigation(folder)">
                      <div class="row">
                        <img src="../../../assets/images/folder_icon.svg" height="24px" width="24px" />
                        <div class="file-metadata">
                          <div>{{ folder.name }}</div>
                        </div>
                      </div>
                      <div>

                        <img src="../../../assets/images/arrow_right.svg" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="files?.length === 0 && folders?.length === 0" style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 2rem;
                    color: #828282;
                  ">
                    <span>No documents are currently provided for this vehicle</span>
                  </div>
                  <div *ngIf="showFiles">
                    <mat-selection-list [multiple]="false" hideSingleSelectionIndicator="true"
                      [(ngModel)]="selectedFile" [compareWith]="compareFiles" class="reportsList"
                      *ngIf="breakPointService.isHandset$ | async">
                      <mat-list-option class="fileStructure" *ngFor="let file of files; index as i"
                        (click)="showWebViewer(file)" (click)="snav.toggle()" [ngClass]="
                        !!selectedFile && selectedFile.name === file.name
                          ? 'selected'
                          : ''
                      ">
                        <div class="row">
                          <img *ngIf="
                            !!selectedFile && selectedFile.name === file.name;
                            else fileIcon
                          " src="../../../assets/images/file_icon_active.svg" />
                          <ng-template #fileIcon>
                            <img src="../../../assets/images/file_icon.svg" height="24px" width="24px" />
                          </ng-template>
                          <div class="file-metadata">
                            <div>{{ file.name }}</div>
                            <div class="datePostFile">
                              {{ file.creationTime | date : 'dd. MMM yyyy' }}
                            </div>
                          </div>
                        </div>
                      </mat-list-option>
                    </mat-selection-list>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </mat-sidenav>

      <mat-sidenav-content>
        <div class="content-container">
          <button mat-stroked-button
            *ngIf="(breakPointService.isHandset$ | async) && !(files?.length === 0 && folders?.length === 0)"
            class="strockedBackButton" (click)="snav.toggle()">
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="primary"
                d="M3.06367 6.89433L8.42617 12.2568L6.89433 13.7887L0 6.89433L6.89433 0L8.42617 1.53183L3.06367 6.89433Z"
                fill="#00B886" />
            </svg>
            <span class="text">
              {{ 'translations.documents.back' | translate }}
            </span>
          </button>

          <div class="content-body" *ngIf="!isLoading; else pdfLoaderMobile"
            [ngClass]="{ previewContainer: showViewer === false || !filePath }">
            <div class="viewerTile" *ngIf="filePath" [ngClass]="{ hideViewerContainer: showViewer === false }">
              <div class="viewer">
                <ng-container *ngIf="isVisible$ | async">
                  <app-pdf-apryse [filePath]="filePath" [fileName]="selectedFile?.name" [isShowAnnotation]="false"></app-pdf-apryse>
                </ng-container>
              </div>
            </div>
            <div class="previewImgContainer" *ngIf="files?.length === 0 && folders?.length === 0" [ngClass]="{
                hidePreviewContainer: showViewer === true && !!filePath
              }">
              <div>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="56" height="56" rx="28" fill="#F4F4F4" />
                  <path
                    d="M28.0003 19C33.3924 19 37.8784 22.8798 38.8189 28C37.8784 33.1202 33.3924 37 28.0003 37C22.6081 37 18.1222 33.1202 17.1816 28C18.1222 22.8798 22.6081 19 28.0003 19ZM28.0003 35C32.2359 35 35.8603 32.052 36.7777 28C35.8603 23.948 32.2359 21 28.0003 21C23.7646 21 20.1402 23.948 19.2228 28C20.1402 32.052 23.7646 35 28.0003 35ZM28.0003 32.5C25.515 32.5 23.5003 30.4853 23.5003 28C23.5003 25.5147 25.515 23.5 28.0003 23.5C30.4855 23.5 32.5003 25.5147 32.5003 28C32.5003 30.4853 30.4855 32.5 28.0003 32.5ZM28.0003 30.5C29.381 30.5 30.5003 29.3807 30.5003 28C30.5003 26.6193 29.381 25.5 28.0003 25.5C26.6196 25.5 25.5003 26.6193 25.5003 28C25.5003 29.3807 26.6196 30.5 28.0003 30.5Z"
                    fill="#828282" />
                </svg>
              </div>
              <p class="selectMessage">No documents are currently provided for this vehicle</p>
            </div>
            <div class="previewImgContainer" *ngIf="
                !selectedFile &&
                isVisible$ &&
                (files?.length !== 0 || folders?.length !== 0)
              " [ngClass]="{ hidePreviewContainer: showViewer === true }">
              <div>

                <div>
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="56" height="56" rx="28" fill="#F4F4F4" />
                    <path
                      d="M28.0003 19C33.3924 19 37.8784 22.8798 38.8189 28C37.8784 33.1202 33.3924 37 28.0003 37C22.6081 37 18.1222 33.1202 17.1816 28C18.1222 22.8798 22.6081 19 28.0003 19ZM28.0003 35C32.2359 35 35.8603 32.052 36.7777 28C35.8603 23.948 32.2359 21 28.0003 21C23.7646 21 20.1402 23.948 19.2228 28C20.1402 32.052 23.7646 35 28.0003 35ZM28.0003 32.5C25.515 32.5 23.5003 30.4853 23.5003 28C23.5003 25.5147 25.515 23.5 28.0003 23.5C30.4855 23.5 32.5003 25.5147 32.5003 28C32.5003 30.4853 30.4855 32.5 28.0003 32.5ZM28.0003 30.5C29.381 30.5 30.5003 29.3807 30.5003 28C30.5003 26.6193 29.381 25.5 28.0003 25.5C26.6196 25.5 25.5003 26.6193 25.5003 28C25.5003 29.3807 26.6196 30.5 28.0003 30.5Z"
                      fill="#828282" />
                  </svg>
                </div>
              </div>

              <p class="selectMessage">
                {{ 'translations.documents.selectDocument' | translate }}
              </p>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<ng-template #pdfLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '100%',
      height: 'calc(100vh - 122px)',
      margin: '0px'
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #pdfLoaderMobile>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '96%',
        height: 'calc(100vh - 170px)',
        margin: '0 0 0 1rem',
      }"></ngx-skeleton-loader>
</ng-template>

<ng-template #folderLoader>
  <div class="folderLoader" *ngFor="let number of [0, 1, 2, 3]">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '7%',
        'margin-top': '10px',
        'margin-left': '4%',
        height: '25px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        'margin-top': '10px',
        'margin-left': '2%',
        width: '80%',
        height: '25px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>

<ng-template #backButtonLoader>
  <div class="backbutton-loader">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '85px',
        height: '38px',
        marginBottom: 0
      }"></ngx-skeleton-loader>
  </div>
</ng-template>