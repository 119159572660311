import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {}

export function translateLoaderFactory(httpClient: HttpClient) {
  const dateObject = new Date();
  const currentTimestamp = dateObject.getTime();
  return new TranslateHttpLoader(
    httpClient,
    '../../../assets/i18n/',
    `.json?v1=${currentTimestamp}`
  );
}
