<div class="dialog-container dialog-consent">
  <div class="dialog-head">
    <span>Bank Account Linking Consent</span>
    <img 
    src="../../../assets/images/cross_icon_grey.svg" 
    alt="close" 
    (click)="dialogRef.close()" 
    (keydown)="dialogRef.close()" 
    tabindex="0">
    </div>
  <div class="dialog-body">
    <b>We prioritize your privacy and security. Before proceeding, please review the following details:</b>
    <br><br>
    By adding your bank account to our system, you authorize us to securely access and read your bank transactions for the purpose of providing [describe the service, e.g., financial tracking, budgeting, or insights]. This access will allow our system to retrieve your transaction history, including deposits, withdrawals, and other relevant details.
    <br><br>
    <b>What we access:</b>
    <br><br>
    We will access your bank transaction data, including amounts, dates, merchant names, and any other relevant financial information associated with your account. This data will be used to provide you with [specific service, e.g., insights, reports, or recommendations].
    <br><br>
    <b>How we use the data:</b>
    <br><br>
    The data we collect will be used to analyze your transactions and provide you with personalized insights and reports to help you manage your finances. We may also use the data to improve your overall experience on our platform.
    <br><br>
    <b>What we don’t do:</b>
    <br><br>
    We will not store or share any of your bank login credentials. Additionally, we will not perform any financial transactions on your behalf or share your sensitive information with third parties without your explicit consent.
    <br><br>
    <b>Security:</b>
    <br><br>
    We take your privacy seriously. All data transmissions are encrypted to ensure that your information remains safe and secure. We comply with all applicable data protection laws to safeguard your privacy and ensure the security of your information.
    <br><br>
    <b>Your Rights:</b>
    <br><br>
    You have the right to revoke access to your bank account at any time by visiting your account settings. If you have any questions or concerns about the data we collect or how we use it, please feel free to contact us.
  </div>
  <div class="dialog-footer">
    <div class="checkbox">
      <mat-checkbox (change)="checkboxChange($event)"></mat-checkbox>
      <p>I agree that I have read, understood, and consent to the linking of your bank account and transaction data as outlined in this document.</p>
    </div>
    <div class="footer-btn">
      <button class="btn-white" (click)="dialogRef.close()">Cancel</button>
      <button class="btn-green" (click)="submitConsent()">Continue</button>
    </div>
  </div>
</div>
