<div class="report-modal-pg">
    <div class="report-header"
        [ngClass]="{ 'tablet' : (breakPointService.isTablet$ | async), 'mobile' : (breakPointService.isHandset$ | async)}">
        <div class="report-name">
            <span class="report-heading">Details for </span>
            <div class="heading-suffix">{{reportHeading}}</div>
        </div>
        <img class="img-cross-modal" src="../../../assets/images/cross_icon_grey.svg" height="24px" width="24px"
            alt="clear" (click)="handleClose()">
    </div>
    <div [ngClass]="{ 'tablet' : (breakPointService.isTablet$ | async), 'mobile' : (breakPointService.isHandset$ | async)}"
        class="report-wrapper">
        <div class="reports-container" [ngClass]="(!mobPdfActive ? 'show-menu':'hide-menu')">
            <div class="list-section">
                <mat-card class="list-card" *ngIf="!isLoadingList; else listLoader">
                    <div class="body">
                        <ng-container *ngIf="mailPreview?.length">
                            <h5>Email preview</h5>
                            <mat-selection-list [multiple]="false" hideSingleSelectionIndicator="true"
                                class="reports-list" [(ngModel)]="selectedFile"
                                (selectionChange)="onSelectionChange('email')">
                                <mat-list-option (click)="toggleMenu()" class="file-struct"
                                    *ngFor="let file of mailPreview; index as i" [value]="file"
                                    [ngClass]="!!selectedFile && selectedFile[0].sentAt === file.sentAt? 'active': ''">
                                    <div class="row">
                                        <div class="mail-icon"></div>
                                        <div class="file-metadata">
                                            <div>{{ commonService.formateDateTime(file.sentAt) }}</div>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </ng-container>
                        <ng-container *ngIf="renderedDocuments?.length">
                            <h5>Rendered documents</h5>
                            <mat-selection-list [multiple]="false" hideSingleSelectionIndicator="true"
                                class="reports-list" [(ngModel)]="selectedFile"
                                (selectionChange)="onSelectionChange('renderedPdf')">
                                <mat-list-option (click)="toggleMenu()" class="file-struct"
                                    *ngFor="let file of renderedDocuments; index as i" [value]="file"
                                    [ngClass]="!!selectedFile && selectedFile[0].id === file.id? 'active': ''">
                                    <div class="row">
                                        <div class="file-icon"></div>
                                        <div class="file-metadata">
                                            <div>{{ file.name }}</div>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </ng-container>
                        <ng-container *ngIf="staticDocuments?.length">
                            <h5>Static documents</h5>
                            <mat-selection-list [multiple]="false" hideSingleSelectionIndicator="true"
                                class="reports-list pb-0" [(ngModel)]="selectedFile"
                                (selectionChange)="onSelectionChange('staticPdf')">
                                <mat-list-option (click)="toggleMenu()" class="file-struct"
                                    *ngFor="let file of staticDocuments; index as i" [value]="file"
                                    [ngClass]="!!selectedFile && selectedFile[0].id === file.id? 'active': ''">
                                    <div class="row">
                                        <div class="file-icon"></div>
                                        <div class="file-metadata">
                                            <div>{{ file.name }}</div>
                                        </div>
                                    </div>
                                </mat-list-option>
                            </mat-selection-list>
                        </ng-container>
                    </div>
                </mat-card>
            </div>
            <button mat-stroked-button *ngIf="(breakPointService.isHandset$ | async) && mobPdfActive"
                class="back-button" (click)="toggleMenu()">
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="primary"
                        d="M3.06367 6.89433L8.42617 12.2568L6.89433 13.7887L0 6.89433L6.89433 0L8.42617 1.53183L3.06367 6.89433Z"
                        fill="#00B886" />
                </svg>
                <span class="text">
                    {{ 'translations.documents.back' | translate }}
                </span>
            </button>
            <div class="pdf-container" *ngIf="(selectedType === 'renderedPdf' || selectedType === 'staticPdf')">
                <ng-container *ngIf="!isLoading && !isLoadingList && filePath; else pdfLoader">
                    <div class="viewerTile" *ngIf="isPDF(selectedFile[0]?.name); else noPdfPreview">
                        <div class="viewer" *ngIf="filePath">
                            <app-pdf-viewer [filePath]="filePath" [fileName]="selectedFile[0]?.name"></app-pdf-viewer>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="email-container" *ngIf="selectedType === 'email'">
                <ng-container *ngIf="!isLoading && !isLoadingList; else pdfLoader">
                    <div class="email-header">
                        <div class="email-to">
                            <span class="label">To: </span>
                            <span class="value">{{ joinRecipients(mailPreview[0].recipients) }}</span>
                        </div>
                        <div class="email-cc">
                            <span class="label">Cc: </span>
                            <span class="value">{{ joinRecipients(mailPreview[0].cc) }}</span>
                        </div>
                        <div class="email-sub">
                            <span class="label">Subject: </span>
                            <span class="value">{{ mailPreview[0].subject }}</span>
                        </div>
                    </div>
                    <div class="email-body">
                        <iframe [srcdoc]="getHtmlContent(mailPreview[0].htmlContent)" *ngIf="mailPreview[0].htmlContent"
                            #iframeElement style="width: 100%; height: 100%; border: none"></iframe>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #noPdfPreview>
    <div class="no-preview-wrapper">
        <div class="no-prev-container">
            <div class="nofile-icon">
                <img src="../../../assets/images/file_icon_img.svg" height="24px" width="24px" alt="icon">
            </div>
            <div class="no-prev-desc">
                <div class="prev-header">No preview for this file</div>
                <div class="prev-subheader">We can't preview this file. Please download the file to view it.</div>
            </div>
            <button class="btn-black" (click)="downloadFile()">
                <img src="../../../assets/images/download_icon.svg" alt="icon" width="20px" height="20px">
                <span>Download</span>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #pdfLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '100%',
        height: '100%',
      }" style="height: 100%;"></ngx-skeleton-loader>
</ng-template>

<ng-template #listLoader>
    <div style="display: flex; flex-direction: column; gap: 24px; padding: 24px;">
        <div *ngFor="let number of [0, 1, 2]" style="display: flex; flex-direction: column; gap: 12px;">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                marginBottom: 0,
                width: '120px',
                height: '15px'
            }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                marginBottom: 0,
              width: '100%',
              height: '48px'
            }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>