import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Model } from 'survey-core';
import { themeJson } from './survey/theme';
import { CommonService } from 'src/app/shared/services/common.service';
import { BreakPointService } from 'src/app/shared/services/break-point.service';
import { PortfolioCompaniesService } from './portfolio-companies.service';

interface IModelArr {
  model: Model;
  tab: string;
}

interface IportfolioCompany {
  id: string;
  name: string;
  availableQuarterlyData: Iquarter[];
}

interface Iquarter {
  year: number;
  quarter: number;
}

@Component({
  selector: 'app-portfolio-companies',
  templateUrl: './portfolio-companies.component.html',
  styleUrls: ['./portfolio-companies.component.scss'],
})
export class PortfolioCompaniesComponent {
  isLoading: boolean = false;
  isQuarterLoading: boolean = false;
  isSavingInProgress: boolean = false;
  isEditInProgress: boolean = false;
  isSubmittingInProgress: boolean = false;
  companies: IportfolioCompany[];
  selectedCompany: IportfolioCompany | undefined;
  quarters: Iquarter[] = [];
  selectedQuarter: Iquarter;
  persistQuarter: Iquarter | undefined;
  persistReportData: any;
  vehicleId: string;
  model: Model;
  modelArr: IModelArr[] = [];
  isSubmitted = false;
  quarterData: any;
  isQuarterOpen: boolean = false;
  validQuarters: Iquarter[];
  hideAddBtn: boolean = false;
  formDefinition: any;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public breakPointService: BreakPointService,
    private commonService: CommonService,
    private portfolioCompaniesService: PortfolioCompaniesService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.validQuarters = this.generateQuarterlyPeriods();
    this.getFormDefinitions();
    this.getPortfolioCompanies();
  }

  getFormDefinitions() {
    this.portfolioCompaniesService
      .getFormDefinitions('portfolio-company-report')
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.formDefinition = parsedResponse.data.definition;
          this.createFormModel(parsedResponse.data.definition);
        },
        error: _error => {
          this.isQuarterLoading = true;
          this.commonService.errorNotification(
            'Error fetching form definition.'
          );
        },
      });
  }

  createFormModel(json: any) {
    this.model = new Model(json);
    this.model.applyTheme(themeJson as any);
    this.model.onValueChanged.add(this.changeFormStatus);
    // to add 0 to month with single digit - YYYY-MM format
    // this.model.onValueChanged.add(function (sender: any, options: any) {
    //   if (["pageGeneralData.establishmentDay", "pageCapital.investmentDate", "pageCapital.exitDate"].includes(options.name) && options.value) {
    //     let monthValue = options.value;
    //     let monthPart = monthValue.split("-")[1];

    //     if (monthPart && monthPart.length === 1) {
    //       monthPart = "0" + monthPart;
    //       options.value = monthValue.split("-")[0] + "-" + monthPart;
    //     }
    //   }
    // });
    this.resetFormStatus();
  }

  changeFormStatus() {
    const inputElement = document.getElementById(
      'valueChanged'
    ) as HTMLInputElement;
    if (inputElement) inputElement.value = 'true';
  }

  resetFormStatus() {
    const inputElement = document.getElementById(
      'valueChanged'
    ) as HTMLInputElement;
    if (inputElement) inputElement.value = 'false';
  }

  getFormStatus() {
    const inputElement = document.getElementById(
      'valueChanged'
    ) as HTMLInputElement;
    // console.log('valueChanged', inputElement.value);
    return inputElement.value === 'true';
  }

  getPortfolioCompanies() {
    this.portfolioCompaniesService
      .getPortfolioCompanies(this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.companies = parsedResponse.data;
          if (this.companies[0]) {
            this.selectPortfolioCompany(
              this.selectedCompany
                ? this.companies.filter(
                  x => x.id === this.selectedCompany!.id
                )[0]
                : this.companies[0]
            );
          } else {
            // setTimeout(() => {
            //   this.commonService.setSecondaryPath([]);
            // }, 0);
            this.resetFormStatus();
          }

          this.isLoading = false;
        },
        error: error => {
          if (error.error.message.includes('No data found')) {
            this.isLoading = false;
            this.companies = [];
          }
        },
      });
  }

  getCompanyList() {
    this.portfolioCompaniesService
      .getPortfolioCompanies(this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.companies = parsedResponse.data;
          // setTimeout(() => {
          //   this.commonService.setSecondaryPath([
          //     this.companies.filter(x => x.id === this.selectedCompany!.id)[0]
          //       .name,
          //   ]);
          // }, 0);
        },
        error: error => {
          if (error.error.message.includes('No data found')) {
            this.isLoading = false;
            this.companies = [];
          }
        },
      });
  }

  getFormData() {
    return this.model.data;
  }

  saveReportData() {
    this.isSavingInProgress = true;
    this.portfolioCompaniesService
      .updateQuarterlyReportData(
        this.selectedCompany!.id,
        this.selectedQuarter.year.toString(),
        this.selectedQuarter.quarter.toString(),
        {
          data: this.getFormData(),
          status: 'draft',
        }
      )
      .subscribe({
        next: _response => {
          this.selectPortfolioCompany(this.selectedCompany!);
          this.commonService.successNotification('Report saved successfully.');
        },
        error: _error => {
          this.commonService.errorNotification('Failed to save the report.');
        },
        complete: () => {
          this.isSavingInProgress = false;
        },
      });
  }

  silentSaveReportData() {
    this.portfolioCompaniesService.updateQuarterlyReportData(
      this.selectedCompany!.id,
      this.selectedQuarter.year.toString(),
      this.selectedQuarter.quarter.toString(),
      {
        data: this.getFormData(),
        status: 'draft',
      }
    );
  }

  submitReportData() {
    this.isSubmittingInProgress = true;
    this.portfolioCompaniesService
      .updateQuarterlyReportData(
        this.selectedCompany!.id,
        this.selectedQuarter.year.toString(),
        this.selectedQuarter.quarter.toString(),
        {
          data: this.getFormData(),
          status: 'final',
        }
      )
      .subscribe({
        next: _response => {
          this.selectPortfolioCompany(this.selectedCompany!);
          this.disableForm();
          this.commonService.successNotification(
            'Report submitted successfully.'
          );
        },
        error: _error => {
          this.commonService.errorNotification('Failed to submit the report.');
        },
        complete: () => {
          this.isSubmittingInProgress = false;
        },
      });
  }

  editReportData() {
    this.isEditInProgress = true;
    this.portfolioCompaniesService
      .updateQuarterlyReportData(
        this.selectedCompany!.id,
        this.selectedQuarter.year.toString(),
        this.selectedQuarter.quarter.toString(),
        {
          status: 'draft',
          data: this.quarterData.data,
        }
      )
      .subscribe({
        next: _response => {
          this.selectPortfolioCompany(this.selectedCompany!);
          this.enableForm();
        },
        error: _error => { },
        complete: () => {
          this.isEditInProgress = false;
        },
      });
  }

  disableForm() {
    this.model.mode = 'display';
  }

  enableForm() {
    this.model.mode = 'edit';
  }

  portfolioCompanyChangeHandler(company: IportfolioCompany) {
    if (this.getFormStatus()) {
      this.openSaveReportData('company', company);
    } else {
      this.selectPortfolioCompany(company);
    }
  }

  selectPortfolioCompany(company: IportfolioCompany) {
    if (this.selectedCompany && this.selectedCompany.id !== company.id) {
      this.persistQuarter = undefined;
      this.model.dispose()
      this.createFormModel(this.formDefinition);
    }
    this.selectedCompany = company;
    this.hideAddBtn = this.checkValidQuarters(company);
    this.selectedCompany.availableQuarterlyData.at(-1) &&
      this.selectQuarter(
        this.persistQuarter
          ? this.selectedCompany.availableQuarterlyData.filter(
            x =>
              x.year === this.persistQuarter?.year &&
              this.persistQuarter?.quarter === x.quarter
          )[0]
          : this.selectedCompany.availableQuarterlyData.at(0)!
      );
    // setTimeout(() => {
    //   this.selectedCompany &&
    //     this.commonService.setSecondaryPath([this.selectedCompany.name]);
    // }, 0);
  }

  checkValidQuarters(company: IportfolioCompany) {
    const filterSet = new Set(
      company.availableQuarterlyData.map(
        (item: Iquarter) => `${item.year}-${item.quarter}`
      )
    );
    const quarters = this.validQuarters.filter(
      (item: any) => !filterSet.has(`${item.year}-${item.quarter}`)
    );
    return quarters.length === 0;
  }

  selectQuarter(quarter: Iquarter) {
    this.selectedQuarter = quarter!;
    this.getQuarterData(quarter);
    this.persistQuarter = this.selectedQuarter;
  }

  getQuarterData(quarter: Iquarter) {
    this.isQuarterLoading =
      !this.isSavingInProgress &&
      !this.isEditInProgress &&
      !this.isSubmittingInProgress;
    this.portfolioCompaniesService
      .getQuarterReportData(
        this.selectedCompany!.id,
        quarter.year.toString(),
        quarter.quarter.toString()
      )
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.quarterData = parsedResponse.data;
          this.setFormData(parsedResponse.data.data);
          parsedResponse.data.status === 'final' && this.disableForm();
          parsedResponse.data.status === 'draft' && this.enableForm();
        },
        error: _error => { },
        complete: () => {
          this.isQuarterLoading = false;
        },
      });
  }

  setFormData(data: any) {
    this.persistReportData = data;
    // console.log(data)
    if (!!data) {
      Object.keys(data).forEach(key => {
        if (key.endsWith('-Comment')) {
          const baseKey = key.slice(0, -8);
          if (Array.isArray(data[baseKey])) {
            data[baseKey] = [data[key]];
          } else {
            data[baseKey] = data[key]
          }
          delete data[key];
        } else if (["pageGeneralData.establishmentDay", "pageCapital.investmentDate", "pageCapital.exitDate"].includes(key)) {
          let value = data[key];
          if (value) {
            let monthPart = value.split("-")[1];

            if (monthPart && monthPart.length === 1) {
              monthPart = "0" + monthPart;
              data[key] = value.split("-")[0] + "-" + monthPart;
            }
          }
        }
      });
      for (const key in data) {
        this.model.setValue(key, data[key]);
      }
    }
    this.resetFormStatus();
  }

  separateObject(obj: any, arrays: any) {
    const result = arrays.map(() => ({}));

    for (const key in obj) {
      for (let i = 0; i < arrays.length; i++) {
        const array = arrays[i];
        const match = array.find((item: any) => item.name === key);
        if (match) {
          result[i][key] = obj[key];
          break;
        }
      }
    }

    return result;
  }

  quarterChangeHandler(event: any) {
    // console.log(event.value, this.persistQuarter);
    if (!event.value) {
      return;
    }
    if (this.getFormStatus()) {
      this.openSaveReportData('quarter', event.value);
    } else {
      this.model.dispose()
      this.createFormModel(this.formDefinition);
      this.selectQuarter(event.value);
    }
  }

  addReportingPeriodHandler() {
    if (this.getFormStatus()) {
      this.openSaveReportData('addReport');
    } else {
      this.openAddReportingPeriod();
    }
  }

  addPortfolioCompanyHandler() {
    if (this.getFormStatus()) {
      this.openSaveReportData('addCompany');
    } else {
      this.openAddPortfolioCompany();
    }
  }

  renamePortfolioCompanyHandler(company: IportfolioCompany) {
    if (this.getFormStatus()) {
      this.openSaveReportData('renameCompany', company);
    } else {
      this.openRenamePortfolioCompany(company);
    }
  }

  deletePortfolioCompanyHandler(company: IportfolioCompany) {
    if (this.getFormStatus()) {
      this.openSaveReportData('deleteCompany', company);
    } else {
      this.openDeletePortfolioCompany(company);
    }
  }

  openAddPortfolioCompany() {
    const dialogRef = this.dialog.open(AddPortfolioCompanyDialog, {
      data: { vehicleId: this.vehicleId, type: 'add' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        this.selectedCompany = result.newCompany;
        this.getPortfolioCompanies();
        this.commonService.successNotification(
          'Portfolio company added successfully'
        );
      } else if (result && result.status === 'error') {
        this.commonService.errorNotification('Failed to add portfolio company');
      }
    });
  }

  openRenamePortfolioCompany(company: IportfolioCompany) {
    const dialogRef = this.dialog.open(AddPortfolioCompanyDialog, {
      data: {
        vehicleId: this.vehicleId,
        type: 'rename',
        company,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        this.getCompanyList();
        this.commonService.successNotification(
          'Portfolio company renamed successfully'
        );
      } else if (result && result.status === 'error') {
        this.commonService.errorNotification(
          'Failed to rename portfolio company'
        );
      }
    });
  }

  openDeletePortfolioCompany(company: IportfolioCompany) {
    const dialogRef = this.dialog.open(DeletePortfolioCompanyDialog, {
      data: {
        vehicleId: this.vehicleId,
        company,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        if (this.companies.length === 1) {
          this.companies = [];
        }
        if (company.id === this.selectedCompany!.id) {
          this.selectedCompany = undefined;
          this.persistQuarter = undefined;
          this.getPortfolioCompanies();
        } else {
          this.getCompanyList();
        }

        this.commonService.successNotification(
          'Portfolio company deleted successfully'
        );
      } else if (result === 'error') {
        this.commonService.errorNotification(
          'Failed to delete portfolio company'
        );
      }
    });
  }

  openAddReportingPeriod() {
    const dialogRef = this.dialog.open(AddQuarterDialog, {
      data: {
        vehicleId: this.vehicleId,
        company: this.selectedCompany,
        quarters: this.validQuarters,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'success') {
        this.persistQuarter = {
          year: +result.newQuarter.year,
          quarter: +result.newQuarter.quarter,
        };
        this.model.dispose()
        this.createFormModel(this.formDefinition);
        this.getPortfolioCompanies();
        this.commonService.successNotification(
          'Reporting period added successfully'
        );
      } else if (result && result.status === 'error') {
        this.commonService.errorNotification('Failed to add reporting period');
      }
      !this.selectedQuarter &&
        this.persistQuarter &&
        this.selectQuarter(this.persistQuarter!);
    });
  }

  openSaveReportData(type: string, data?: IportfolioCompany | Iquarter) {
    const dialogRef = this.dialog.open(SaveQuarterDialog, {
      data: {
        selectedCompany: this.selectedCompany,
        selectedQuarter: this.persistQuarter,
        formData: this.getFormData(),
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (
        result &&
        (result.status === 'success' || result.status === 'discard')
      ) {
        if (type === 'company') {
          this.model.dispose()
          this.createFormModel(this.formDefinition);
          this.selectPortfolioCompany(data as IportfolioCompany);
        } else if (type === 'quarter') {
          this.model.dispose()
          this.createFormModel(this.formDefinition);
          this.selectQuarter(data as Iquarter);
        } else if (type === 'addReport') {
          result.status === 'discard' &&
            this.setFormData(this.persistReportData);
          this.openAddReportingPeriod();
        } else if (type === 'addCompany') {
          result.status === 'discard' &&
            this.setFormData(this.persistReportData);
          this.openAddPortfolioCompany();
        } else if (type === 'renameCompany') {
          result.status === 'discard' &&
            this.setFormData(this.persistReportData);
          this.openRenamePortfolioCompany(data as IportfolioCompany);
        } else if (type === 'deleteCompany') {
          result.status === 'discard' &&
            this.setFormData(this.persistReportData);
          this.openDeletePortfolioCompany(data as IportfolioCompany);
        }
        this.resetFormStatus();
      } else {
        if (type === 'quarter') {
          this.selectedQuarter = this.persistQuarter as Iquarter
        }
      }
    });
  }

  generateQuarterlyPeriods(): Iquarter[] {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentQuarter = Math.floor(currentMonth / 3) + 1;

    const quarters = [];
    let year = currentYear;
    let quarter = currentQuarter;

    for (let i = 0; i < 5; i++) {
      quarters.push({ year, quarter });
      quarter--;
      if (quarter === 0) {
        quarter = 4;
        year--;
      }
    }

    return quarters;
  }
}

@Component({
  selector: 'add-portfolio-company-dialog',
  templateUrl: './add-portfolio-company-dialog.html',
  styleUrls: ['./portfolio-companies.component.scss'],
})
export class AddPortfolioCompanyDialog {
  companyName: string;
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<AddPortfolioCompanyDialog>,
    private portfolioCompaniesService: PortfolioCompaniesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data.type === 'rename') {
      this.companyName = this.data.company.name;
    }
  }

  createPortfolioCompany() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.portfolioCompaniesService
      .createPortfolioCompanie(this.data.vehicleId, {
        name: this.companyName,
      })
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.dialogRef.close({
            status: 'success',
            newCompany: parsedResponse.data,
          });
        },
        error: _error => {
          this.dialogRef.close({ status: 'error' });
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }

  renamePortfolioCompany() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.portfolioCompaniesService
      .updatePortfolioCompany(this.data.company.id, {
        name: this.companyName,
      })
      .subscribe({
        next: _response => {
          this.dialogRef.close({ status: 'success' });
        },
        error: _error => {
          this.dialogRef.close({ status: 'error' });
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }
}

@Component({
  selector: 'add-quarter-dialog',
  templateUrl: './add-quarter-dialog.html',
  styleUrls: ['./portfolio-companies.component.scss'],
})
export class AddQuarterDialog {
  quarters: Iquarter[] = [];
  quarter: Iquarter;
  isImport: boolean = false;
  loading: boolean = false;
  isQuarterOpen: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddQuarterDialog>,
    private portfolioCompaniesService: PortfolioCompaniesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() {
    const filterSet = new Set(
      this.data.company.availableQuarterlyData.map(
        (item: Iquarter) => `${item.year}-${item.quarter}`
      )
    );
    this.quarters = this.data.quarters.filter(
      (item: any) => !filterSet.has(`${item.year}-${item.quarter}`)
    );
    this.quarter = this.quarters[0];
  }
  createReportingPeriod() {
    const { year, quarter } = this.quarter;
    this.portfolioCompaniesService
      .createReportingPeriod(this.data.company.id, {
        importDataFromLastQuarter: this.isImport,
        year,
        quarter,
        data: {},
      })
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          this.dialogRef.close({
            status: 'success',
            newQuarter: parsedResponse.data,
          });
        },
        error: _error => {
          this.dialogRef.close({ status: 'error' });
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }

  toggleSelect() {
    this.isQuarterOpen = !this.isQuarterOpen;
  }
}

@Component({
  selector: 'delete-portfolio-company-dialog',
  templateUrl: './delete-portfolio-company-dialog.html',
  styleUrls: ['./portfolio-companies.component.scss'],
})
export class DeletePortfolioCompanyDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeletePortfolioCompanyDialog>,
    private portfolioCompaniesService: PortfolioCompaniesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  deletePortfolioCompany() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.portfolioCompaniesService
      .deletePortfolioCompanie(this.data.company.id)
      .subscribe({
        next: _response => {
          this.dialogRef.close('success');
        },
        error: _error => {
          this.dialogRef.close('error');
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }
}

@Component({
  selector: 'save-quarer-dialog',
  templateUrl: './save-quarter-dialog.html',
  styleUrls: ['./portfolio-companies.component.scss'],
})
export class SaveQuarterDialog {
  loading = false;
  constructor(
    public dialogRef: MatDialogRef<DeletePortfolioCompanyDialog>,
    private portfolioCompaniesService: PortfolioCompaniesService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  saveDraft() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.portfolioCompaniesService
      .updateQuarterlyReportData(
        this.data.selectedCompany.id,
        this.data.selectedQuarter.year.toString(),
        this.data.selectedQuarter.quarter.toString(),
        {
          data: this.data.formData,
          status: 'draft',
        }
      )
      .subscribe({
        next: _response => {
          this.commonService.successNotification('Report saved successfully.');
          this.dialogRef.close({
            status: 'success',
          });
        },
        error: _error => {
          this.commonService.errorNotification('Failed to save the report.');
        },
        complete: () => {
          this.dialogRef.disableClose = false;
          this.loading = false;
        },
      });
  }
  discard() {
    this.dialogRef.close({
      status: 'discard',
    });
  }
}
