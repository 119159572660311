<div class="management-page">
    <ng-container *ngIf="showMigration && !isLoading">
        <app-comming-soon [path]='"management"'></app-comming-soon>
    </ng-container>
    <ng-container *ngIf="!showMigration && !isLoading && showNoTopics">
        <div class="no-task-wrapper">
            <div class="no-topics-container">
                <div class="icon">
                    <img src="../../../assets/images/no-tasks-icon.svg" alt="">
                </div>
                <div class="text-area">
                    <div class="main">No ongoing topics</div>
                    <div class="sub-text">Looks like there are no ongoing topics for now. Please check again later</div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!showMigration && !showNoTopics">
        <div class="filter-tool-bar">
            <ng-container *ngIf="!isLoading; else searchLoader">
                <mat-form-field class="search-field">
                    <img src="../../assets/images/search.svg" alt="search" matPrefix />
                    <input matInput placeholder="Search" [(ngModel)]="search" (keyup)="searchChangeHandler()"
                        autocomplete="off">
                    <button class="clear-btn" *ngIf="search" matSuffix (click)="search=''; searchChangeHandler()">
                        <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px" alt="clear">
                    </button>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isLoading; else topicFilterLoader">
                <mat-form-field appearance="fill" class="topic-mat-select">
                    <mat-select placeholder="Topic" [(ngModel)]="topics" (selectionChange)="topicChangeHandler()"
                        [position]="'left'" multiple #select>
                        <mat-select-trigger>
                            <div class="topic">
                                Topic
                                <div *ngIf="topics" class="topic-count">{{topics.length}}</div>
                            </div>
                        </mat-select-trigger>
                        <mat-option class="topic-item" *ngFor="let topic of topicList" [value]="topic"
                            [matTooltip]="topic.name" matTooltipPosition="below"
                            [matTooltipDisabled]="topic.name.length<16">
                            <span class="topic-name">{{topic.name}}</span>
                            <span
                                style="margin-left: auto; color: var(--text-grey);">{{topic.uncompletedTaskCount}}</span></mat-option>
                    </mat-select>
                    <img class="arrow-down" [ngClass]="{ 'open': select.panelOpen}"
                        src="../../assets/images/arrow-down.svg" matSuffix />
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isLoading; else suggestTaskLoader">
                <button *ngIf="suggestTaskAvailable" (click)="openSuggestTask()" class="create-new-btn" mat-flat-button>
                    <mat-icon class="icon">add</mat-icon>
                    {{"Suggest task"}}
                </button>
            </ng-container>
        </div>
        <div class="tasks-section">
            <ng-container *ngIf="!isLoading; else laneLoader">
                <div class="lane" *ngFor="let taskData of taskDatas; index as i">
                    <div class="lane-header">
                        {{taskData.status}}
                        <span *ngIf="taskData.tasks.length > 0 && !isLoading"
                            class="count">{{taskData.tasks.length}}</span>
                    </div>
                    <div class="lane-body">
                        <ng-container *ngFor="let cardData of taskData.tasks">
                            <app-task-card [cardData]="cardData"
                                (cardClickEvent)="getTaskDetails($event, taskData.status)"></app-task-card>
                        </ng-container>
                        <div class="show-all" *ngIf="(taskData.status === 'Completed') && showGetArchived">
                            <ng-container *ngIf="!isGettingArchived; else spinner">
                                <div class="text">
                                    <div class="heading-text">Missing something?</div>
                                    <div class="sub-text">Completed tasks get archived.</div>
                                </div>
                                <button class="cta-btn" (click)="getArchivedTasks()">Show all</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
</ng-template>

<ng-template #topicFilterLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '140px',
            height: '38px',
            marginBottom: 0
          }"></ngx-skeleton-loader>
</ng-template>

<ng-template #suggestTaskLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '160px',
            height: '38px',
            marginBottom: 0,
            marginLeft: 'auto'
          }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #laneHeaderLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '150px',
            height: '25px',
            display: 'block',
            marginBottom: 0
          }"></ngx-skeleton-loader>
</ng-template>

<ng-template #taskCardLoader>
    <div class="lane-body">
        <div *ngFor="let number of [0, 1]">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '258px',
                height: '120px',
                marginBottom: 0
                        }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>

<ng-template #laneLoader>
    <div class="lane" *ngFor="let number of [0, 1, 2, 3]; index as i">
        <div class="lane-header">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '150px',
                height: '25px',
                display: 'block',
                marginBottom: 0
              }"></ngx-skeleton-loader>
        </div>
        <ng-container *ngIf="!isLoading; else taskCardLoader">
            <div class="lane-body">
                <div *ngFor="let number of [0, 1]">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                        width: '258px',
                        height: '120px',
                        marginBottom: 0
                                }"></ngx-skeleton-loader>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>