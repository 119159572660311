<div class="letterhead-container">
    <div class="letterhead-subheader">
        <div class="info-txt">
            <span class="bold">For report rendering,</span> you can use different PDF letterheads. <span class="action"
                (click)="openPdfOptions()">Learn more</span>
        </div>
        <div class="cta">
            <button [disabled]="isUploading" matTooltip="Select a PDF file (max 10MB)" (click)="openFileDialog(false)"
                class="upload-btn" *ngIf="!isLoading; else ctaBtnLoader">
                <ng-container *ngIf="!isUploading; else spinner">
                    <img src="../../../../assets/images/upload_icon_white.svg" height="20px" width="20px" alt="add" />
                    Upload file
                </ng-container>
            </button>
            <input type="file" id="newLetterheadInput" accept=".pdf" style="display: none;"
                (change)="onFileSelected($event)">
            <input type="file" id="reuploadInput" accept=".pdf" style="display: none;"
                (change)="reuploadFileSelected($event)">
        </div>
    </div>
    <div class="letterhead-section">
        <div class="letterhead-list" *ngIf="!isLoading; else letterheadLoader">
            <div class="letterhead-item">
                <div class="info">
                    <img src="../../../../assets/images/no_letterhead_icon.svg" alt="icon" height="48px" width="48px">
                    <div class="details">
                        <div class="title">No letterhead
                            <span *ngIf="!defalutLetterhead" class="default-badge">Default</span>
                        </div>
                    </div>
                </div>
                <div class="cta">
                    <div class="section" *ngIf="defalutLetterhead">
                        <img src="../../../../assets/images/more_icon.svg" alt="icon" height="20px" width="20px"
                            [matMenuTriggerFor]="noItemMenu" #menuRef="matMenuTrigger">
                    </div>
                    <mat-menu #noItemMenu="matMenu" xPosition="before" class="more-menu" action-position>
                        <div mat-menu-item class="item" (click)="removeDefault()">
                            <img src="../../../../assets/images/flag_team_icon.svg" alt="action" height="20px"
                                width="20px">Set
                            as Default
                        </div>
                    </mat-menu>
                </div>
            </div>
            <div class="letterhead-item" *ngFor="let item of letterHeads">
                <div class="info">
                    <img src="../../../../assets/images/letterhead_icon.svg" alt="icon" height="48px" width="48px">
                    <div class="details">
                        <div class="title">{{item.name}}
                            <span *ngIf="item.isDefault" class="default-badge">Default</span>
                        </div>
                        <div class="time-stamp">Uploaded on {{commonService.formateDate(item.uploadDate)}}</div>
                    </div>
                </div>
                <div class="cta">
                    <div class="section">
                        <img (click)="openPreview(item)" src="../../../../assets/images/eye_icon.svg" alt="icon"
                            height="20px" width="20px">
                    </div>
                    <div class="section">
                        <img src="../../../../assets/images/more_icon.svg" alt="icon" height="20px" width="20px"
                            [matMenuTriggerFor]="menu" #menuRef="matMenuTrigger">
                    </div>
                    <mat-menu #menu="matMenu" xPosition="before" class="more-menu" action-position>
                        <div *ngIf="!item.isDefault" mat-menu-item class="item" (click)="setAsDefault(item)">
                            <img src="../../../../assets/images/flag_team_icon.svg" alt="action" height="20px"
                                width="20px">Set
                            as Default
                        </div>
                        <div mat-menu-item class="item" (click)="renameLetterhead(item)">
                            <img src="../../../../assets/images/edit_icon.svg" alt="action" height="20px"
                                width="20px">Rename
                        </div>
                        <div mat-menu-item class="item" (click)="openFileDialog(true); selectedLetterhead = item;">
                            <img src="../../../../assets/images/upload_icon_grey.svg" alt="action" height="20px"
                                width="20px">Re-upload
                        </div>
                        <div mat-menu-item class="item" (click)="deleteLetterhead(item)">
                            <img src="../../../../assets/images/delete_icon.svg" alt="action" height="20px"
                                width="20px">Delete
                        </div>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #ctaBtnLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '134px',
                height: '44px',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>

<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>

<ng-template #letterheadLoader>
    <div *ngFor="let number of [0, 1, 2]" style="display: flex; gap: 12px; height: 82px; align-items: center;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                              margin: 0,
                              width: '48px',
                              height: '48px',
                              borderRadius: '50%'
                            }"></ngx-skeleton-loader>
        <div style="display: flex; flex-direction: column; gap: 4px;">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                              margin: 0,
                              width: '175px',
                              height: '20px'
                                  }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                              margin: 0,
                              width: '175px',
                              height: '20px'
                                  }"></ngx-skeleton-loader>
        </div>
        <div style="margin-left: auto;">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                              margin: '0 18px',
                              width: '24px',
                              height: '24px'
                                  }"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                              margin: '0 18px',
                              width: '24px',
                              height: '24px'
                                  }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>