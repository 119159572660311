import { Component } from '@angular/core';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent {
  serviceNotAvailableDesc = 'This vehicle is currently not configured to use this service. Please contact support at';
}
