<mat-sidenav-container *ngIf="!isInaccessible" class="sidenav-container">
  <mat-sidenav #drawer [disableClose]="(breakPointService.isHandset$| async) ? 'false' : 'true'"
    [class]="(breakPointService.isTablet$ | async) ? 'sidenav-tablet' : 'sidenav'"
    fixedInViewport="(isHandset$ | async) === false"
    [attr.role]="(breakPointService.isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(breakPointService.isHandset$ | async) ? 'over' : 'side'"
    [opened]="(breakPointService.isHandset$ | async) === false">

    <mat-toolbar>
      <img *ngIf="(breakPointService.isTablet$ | async) === false " src="../assets/images/suite7_gp_portal.svg" />
      <img *ngIf="(breakPointService.isTablet$ | async)" src="../assets/images/suite7_gp_portal_tablet.svg" />
    </mat-toolbar>

    <div *ngIf="breakPointService.isHandset$ | async" class="handsetDropDown">
      <mat-form-field appearance="fill" *ngIf="investors.length > 1 ; else singleInvestorMobile"
        [matTooltip]="selectedInvestor.name" matTooltipPosition="below"
        [matTooltipDisabled]="selectedInvestor.name.length<10">
        <img src="../../assets/images/gp_icon.svg" matPrefix />
        <mat-select [(value)]="selectedInvestor" (selectionChange)="onGPSChange($event.value)" #mobileInvestorSelect>
          <mat-option *ngFor="let investor of investors" [value]="investor" (click)="drawer.close()">
            {{ investor.name }}
          </mat-option>
        </mat-select>
        <img [ngClass]="{ 'open': mobileInvestorSelect.panelOpen}" class="arrow-down"
          src="../../assets/images/arrow-down.svg" matSuffix />
      </mat-form-field>
      <ng-template #singleInvestorMobile>
        <div class="single-investor-mobile" [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedInvestor?.name?.length<14">
          <img src="../../assets/images/gp_icon.svg" alt="gp-icon">
          <div>{{selectedInvestor.name}}</div>
        </div>
      </ng-template>
    </div>

    <!-- mobile screen menu item -->
    <mat-nav-list class="navList" *ngIf="breakPointService.isHandset$ | async">
      <ng-container *ngFor="let item of menuItems">
        <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
        <div mat-list-item class="menuItem" [routerLink]="!item.subMenus ? [
          '/gp/' +
          selectedInvestor.id +
          '/vehicle/' +
          selectedFund.id + '/' +
          item.path ] : null" routerLinkActive="active-link"
          (keyup.enter)="!item.subMenus && navigateToComponent(item)"
          (click)="!item.subMenus && navigateToComponent(item)" (click)="!item.subMenus && onSelectedMenu(item)"
          (click)="item.isToggled = !item.isToggled" (click)="!item.subMenus && drawer.close()">
          <img class="icon" [src]="item.activeimgURL" alt="icon" [ngClass]="
              selectedMenu.value === item.value && !item.subMenus
                ? 'showActiveImg'
                : 'hideActiveImg'
            " />
          <img class="icon" [src]="item.imgURL" [ngClass]="
              selectedMenu.value !== item.value || item.subMenus
                ? 'showInactiveImg'
                : 'hideInactiveImg'
            " alt="Menu Icon" />
          <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false"
            [ngClass]="item.value === 'Dashboard' ? 'dashboard-icon' : ''">
            {{ item.value }}</span>
          <div *ngIf="item.subMenus && (breakPointService.isTablet$ | async) === false" style="margin-left: auto;">
            <img *ngIf="!item.isToggled" src="../../../../assets/images/arrow_down_icon.svg" alt="Expand" />
            <img *ngIf="item.isToggled" src="../../../../assets/images/arrow_up_icon.svg" alt="Collapse" />
          </div>
        </div>

        <div *ngIf="item.subMenus && item.isToggled && (breakPointService.isTablet$ | async) === false">
          <ng-container *ngFor="let subItem of item.subMenus">
            <span mat-list-item class="menuItem subMenuItem" [routerLink]="
              '/gp/' +
              selectedInvestor.id +
              '/vehicle/' +
              selectedFund.id + '/' + item.path + '/' +
              subItem.path" routerLinkActive="active-link"
              (keyup.enter)="navigateToSubmenu(item.path+ '/'  + subItem.path)"
              (click)="navigateToSubmenu(item.path+ '/'  + subItem.path)" (click)="onSelectedMenu(item)"
              (click)="drawer.close()">
              <span>
                {{ subItem.value }}
              </span>
            </span>
          </ng-container>
        </div>
      </ng-container>
    </mat-nav-list>

    <!-- desktop screen menu item -->
    <mat-nav-list class="navList"
      *ngIf="(!(breakPointService.isHandset$ | async) && !(breakPointService.isTablet$ | async))">
      <ng-container *ngFor="let item of menuItems">
        <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
        <div mat-list-item class="menuItem" [routerLink]="!item.subMenus ? [
        '/gp/' +
        selectedInvestor.id +
        '/vehicle/' +
        selectedFund.id + '/' +
        item.path ] : null" routerLinkActive="active-link" (keyup.enter)="!item.subMenus && navigateToComponent(item)"
          (click)="!item.subMenus && navigateToComponent(item)" (click)="!item.subMenus && onSelectedMenu(item)"
          (click)="item.isToggled = !item.isToggled" tabindex="0">
          <img *ngIf="!item.subMenus" class="icon" [src]="item.activeimgURL" alt="icon" [ngClass]="
                selectedMenu.value === item.value && !item.subMenus
                  ? 'showActiveImg'
                  : 'hideActiveImg'
              " />
          <img *ngIf="!item.subMenus" class="icon" [ngClass]="
                selectedMenu.value !== item.value || item.subMenus
                  ? 'showActiveImg'
                  : 'hideActiveImg'
              " [src]="item.imgURL" alt="Menu Icon" />
          <img *ngIf="item.subMenus" mat-button class="icon" [src]="item.imgURL" alt="Menu Icon" />
          <span class="icon-title" [ngClass]="item.value === 'Dashboard' ? 'dashboard-icon' : ''">
            {{ item.value }}
          </span>
          <div *ngIf="item.subMenus" style="margin-left: auto;">
            <img *ngIf="!item.isToggled" src="../../../../assets/images/arrow_down_icon.svg" alt="Expand" />
            <img *ngIf="item.isToggled" src="../../../../assets/images/arrow_up_icon.svg" alt="Collapse" />
          </div>
        </div>

        <div *ngIf="item.subMenus && item.isToggled && (breakPointService.isTablet$ | async) === false">
          <ng-container *ngFor="let subItem of item.subMenus">
            <span *ngIf="hideBankAccount(subItem.key)" mat-list-item class="menuItem subMenuItem" [routerLink]="
              '/gp/' +
              selectedInvestor.id +
              '/vehicle/' +
              selectedFund.id + '/' + item.path + '/' +
              subItem.path" routerLinkActive="active-link"
              (keyup.enter)="navigateToSubmenu(item.path + '/' + subItem.path)"
              (click)="navigateToSubmenu(item.path +'/'+ subItem.path)" (click)="onSelectedMenu(item)">
              <span>
                {{ subItem.value }}
              </span>
            </span>
          </ng-container>
        </div>
      </ng-container>
    </mat-nav-list>

    <!-- tablet screen menu item -->
    <mat-nav-list class="navList" *ngIf="(breakPointService.isTablet$ | async)">
      <ng-container *ngFor="let item of menuItems">
        <mat-divider *ngIf="item.value == 'Settings'"></mat-divider>
        <div *ngIf="!item.subMenus " mat-list-item class="menuItem" [routerLink]="!item.subMenus ? [
        '/gp/' +
        selectedInvestor.id +
        '/vehicle/' +
        selectedFund.id + '/' +
        item.path ] : null" routerLinkActive="active-link" (keyup.enter)="navigateToComponent(item)"
          (click)="!item.subMenus && navigateToComponent(item)" (click)="onSelectedMenu(item)" tabindex="0">
          <img class="icon" [src]="item.activeimgURL" alt="icon" [ngClass]="
                selectedMenu.value === item.value
                  ? 'showActiveImg'
                  : 'hideActiveImg'
              " />
          <img class="icon" [ngClass]="
                selectedMenu.value !== item.value
                  ? 'showActiveImg'
                  : 'hideActiveImg'
              " [src]="item.imgURL" alt="Menu Icon" />
        </div>
        <div *ngIf="item.subMenus " mat-list-item class="menuItem" tabindex="0" [matMenuTriggerFor]="menu">
          <img mat-button [matMenuTriggerFor]="menu" class="icon" [src]="item.imgURL" alt="Menu Icon" />
        </div>

        <mat-menu #menu="matMenu" class="custom-menu" yPosition="below" xPosition="after">
          <ng-container *ngFor="let subItem of item.subMenus" class="custom-mat-container">
            <div mat-list-item class="custom-menu-item" [routerLink]="
                '/gp/' +
                selectedInvestor.id +
                '/vehicle/' +
                selectedFund.id + '/' + item.path + '/' +
                subItem.path" routerLinkActive="active-link"
              (keyup.enter)="navigateToSubmenu(item.path + '/' + subItem.path)"
              (click)="navigateToSubmenu(item.path + '/' + subItem.path)" (click)="onSelectedMenu(item)">
              {{ subItem.value }}
            </div>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-nav-list>

    <div class="sidenav-footer">
      <mat-nav-list class="navList">
        <div  mat-list-item class=" nav-item-base" (click)="openLpPortal()" *ngIf="lpPortalLoginEnabled" >
          <div class=" nav-content">

            <img src="../../../../assets/images/lp_icon.svg" alt="icon" />
            <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">
              Go to LP Portal
            </span>
          </div>

          <img src="../../../../assets/images/external_link_icon.svg" alt="icon" *ngIf="(breakPointService.isTablet$ | async) === false" />
        </div>
        <div *ngIf="baseData?.helpDeskUrl" mat-list-item (click)="openHelpDesk()" class="menuItem">
          <img src="../../../../assets/images/support_icon.svg" alt="icon" />

          <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">
            Help desk
          </span>
        </div>
        <div mat-list-item (click)="openTermsOfUse()" class="menuItem">
          <img src="../../../../assets/images/terms_icon.svg" alt="icon" />

          <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">
            Terms of use
          </span>
        </div>
        <div mat-list-item (click)="openDialog(false, '')" class="menuItem">
          <img src="../../../../assets/images/signout_icon.svg" alt="icon" />
          <span class="icon-title" *ngIf="(breakPointService.isTablet$ | async) === false">{{
            'translations.logOut' | translate
            }}</span>
        </div>
      </mat-nav-list>
      <div class="copy-rites">
        {{(breakPointService.isTablet$ | async)? "" : "© 2024 ACE Alternatives."}}
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="componentClass"
    [class]="(breakPointService.isTablet$ | async) ? 'tablet' : (breakPointService.isHandset$| async) ? 'mobile' : 'desktop'">
    <div class="header" [ngClass]="{ 'small-mobile' : (breakPointService.isSmallerHandset$ | async)}">
      <button type="button" aria-label="Toggle sidenav" (click)="drawer.toggle()" mat-icon-button
        *ngIf="breakPointService.isHandset$ | async" style="z-index: 1">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="dashBoardTitle">
        <mat-form-field appearance="fill" class="fundDropdown" *ngIf="funds.length > 1"
          [matTooltip]="selectedFund?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedFund?.name?.length<10">
          <img src="../../assets/images/fund_icon.svg" matPrefix />
          <mat-select [(value)]="selectedFund" (selectionChange)="onVehicleChange($event.value)" #fundSelect>
            <mat-option class="fund-item" *ngFor="let fund of funds" [value]="fund">
              {{ fund.name }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': fundSelect.panelOpen}" src="../../assets/images/arrow-down.svg"
            matSuffix />
        </mat-form-field>
        <ng-template #singleFund>
          <div class="single-fund">
            <img src="../../assets/images/fund_icon.svg">
            <div>{{selectedFund.name}}</div>
          </div>
        </ng-template>
        <div class="bread-crumb">
          <ng-container *ngIf="funds.length == 1">
            <!-- <img src="../../assets/images/fund_icon.svg"> -->
            <!-- <span class="fund-name">{{selectedFund.name}}</span> -->
            <div class="single-fund">
              <img src="../../assets/images/fund_icon.svg">
              <div>{{selectedFund.name}}</div>
            </div>
            <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
          </ng-container>
          <span class="primary-path" [ngClass]="{'hover': (commonService.secondaryPathObservable$ | async)?.length}"
            [ngStyle]="{'pointer-events': !(commonService.secondaryPathObservable$ | async)?.length ? 'none' : 'auto'}"
            (click)="breadCrumbNavigate([selectedMenu.viewValue], selectedMenu.viewValue, 'primary')">
            {{selectedMenu.viewValue}}</span>
          <ng-container *ngIf="!(breakPointService.isSmallerHandset$ | async)">
            <ng-container *ngIf="(commonService.secondaryPathObservable$ | async) as secondaryPaths">
              <ng-container *ngFor="let path of commonService.secondaryPathObservable$ | async; index as i">
                <img src="../../../../assets/images/arrow_right_icon.svg" alt="">
                <span class="path-name"
                  (click)="breadCrumbNavigate(secondaryPaths.slice(0, i+1), selectedMenu.viewValue, 'secondary')"
                  [ngClass]="{'hover': (i+1) !== (commonService.secondaryPathObservable$ | async)?.length}"
                  [ngStyle]="{'pointer-events': (i+1) !== (commonService.secondaryPathObservable$ | async)?.length ? 'auto' : 'none'}">{{path}}</span>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngIf="(breakPointService.isHandset$ | async) === false" class="investorDropdown">
        <mat-form-field appearance="fill" *ngIf="investors.length > 1 ; else singleInvestor"
          [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
          [matTooltipDisabled]="selectedInvestor?.name?.length<10">
          <img src="../../assets/images/gp_icon.svg" matPrefix />
          <mat-select [(value)]="selectedInvestor" (selectionChange)="onGPSChange($event.value)" #investorSelect>
            <mat-option class="investor-item" *ngFor="let investor of investors" [value]="investor">
              {{ investor.name }}
            </mat-option>
          </mat-select>
          <img class="arrow-down" [ngClass]="{ 'open': investorSelect.panelOpen}"
            src="../../assets/images/arrow-down.svg" matSuffix />
        </mat-form-field>
        <ng-template #singleInvestor>
          <div class="single-investor" [matTooltip]="selectedInvestor?.name" matTooltipPosition="below"
            [matTooltipDisabled]="selectedInvestor?.name?.length<14">
            <img src="../../assets/images/gp_icon.svg" alt="gp-icon">
            <div>{{selectedInvestor.name}}</div>
          </div>
        </ng-template>
      </div>
    </div>
    <div style="padding: 0 24px;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #menuItemLoader>
  <div *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8]">
    <div class="menuItemLoader">
      <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          width: '30px',
          height: '30px'
        }"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="(breakPointService.isTablet$ | async) === false" count="1" appearance="line" [theme]="{
          width: '130px'
        }"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<ng-template #logoLoader>
  <div class="logoLoader">
    <ngx-skeleton-loader *ngIf="!(breakPointService.isTablet$ | async)" count="1" appearance="line" [theme]="{
        width: '130px',
        height: '60px'
      }"></ngx-skeleton-loader>
    <ngx-skeleton-loader *ngIf="breakPointService.isTablet$ | async" count="1" appearance="line" [theme]="{
        width: '60px',
        height: '40px'
      }"></ngx-skeleton-loader>
  </div>
</ng-template>