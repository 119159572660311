<div class="pre-fill-dialog">
  <div class="content">
    <div class="heading">
      Select a quarter
      <button (click)="dialogRef.close()">
        <img src="../../../assets/images/cross_icon_grey.svg" alt="close" />
      </button>
    </div>
    <div class="form-field">
      <mat-form-field appearance="fill" class="quarter">
        <mat-select
          placeholder="select quarter"
          [(ngModel)]="preFillSelectedQuarter"
          (openedChange)="toggleSelect()">
          <mat-option
            *ngFor="let quarter of preFillQuarters"
            [value]="quarter"
            class="add-quarter-options">
            {{ quarter.year + ' Q' + quarter.quarter }}
          </mat-option>
        </mat-select>
        <img
          class="arrow-down"
          [ngClass]="{ 'open': isQuarterOpen}"
          src="../../assets/images/arrow-down.svg"
          matSuffix />
      </mat-form-field>
    </div>
    <mat-checkbox class="import-checkbox" [(ngModel)]="isImport"
      >Override existing data</mat-checkbox
    >
  </div>
  <div class="action">
    <button mat-flat-button class="cancel" (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      mat-flat-button
      class="save"
      *ngIf="data.preFillType === 'questionaireForm'"
      [disabled]="!preFillSelectedQuarter"
      (click)="createQuestionairePreFillForm()">
      <ng-container *ngIf="!preFillFormLoading; else whiteSpinner">
        Apply
      </ng-container>
    </button>
    <button
      mat-flat-button
      class="save"
      *ngIf="data.preFillType === 'questionaire'"
      [disabled]="!preFillSelectedQuarter"
      (click)="createQuestionairePreFill()">
      <ng-container *ngIf="!questionaireLoading; else whiteSpinner">
        Apply
      </ng-container>
    </button>
  </div>
</div>
<ng-template #whiteSpinner>
  <app-spiner-loader [color]="'white'"></app-spiner-loader>
</ng-template>
