import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(private apiEndpointsService: ApiEndpointsService) {}

  public getDocumentById(
    gpsId: string,
    vehicleId: string,
    documentId: string
  ): string {
    return this.apiEndpointsService.createUrl(
      `gps/${gpsId}/vehicles/${vehicleId}/documents/${documentId}`,
      false
    );
  }

  public getDocumentData(gpsId: string, vehicleId: string): string {
    return this.apiEndpointsService.createUrl(
      `gps/${gpsId}/vehicles/${vehicleId}/documents`,
      false
    );
  }
}
