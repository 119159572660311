import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { BankTransactionsService } from './bank-transactions.service';
import { MatSort } from '@angular/material/sort';
import { Location } from '@angular/common';

interface ITransactions {
  id: string,
  accountId: string,
  account: string,
  currency: string,
  amount: number,
  purpose: string,
  name: string,
  date: string
}

@Component({
  selector: 'app-bank-transactions',
  templateUrl: './bank-transactions.component.html',
  styleUrls: ['./bank-transactions.component.scss']
})
export class BankTransactionsComponent {
  // @Input() accountId: string;
  // @Input() accountName: string;
  // @Input() accountNumber: string;
  // @Output() backClick = new EventEmitter();
  isLoading: boolean = false;
  search: string;
  vehicleId: string;
  gpId: string;
  transactionId: string | undefined;
  selecetdTransaction: any;
  displayedColumns: string[] = [
    'date',
    'bankAccount',
    'purpose',
    'amount',
    'currency',
    'partnerName',
    'details'
    // 'accountId'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([] as any);
  private sort: MatSort;
  @ViewChild(MatSort, { static: false }) set matSort(sort: MatSort) {
    this.sort = sort;
    this.dataSource.sort = this.sort;
  }
  transactions: ITransactions[] = []
  noTransactions: boolean = false;
  bankAccount: any | null;
  accountList: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    public commonService: CommonService,
    private bankTransactionsService: BankTransactionsService
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.gpId = this.activatedRoute.snapshot.params['gpId'];
    this.transactionId = this.activatedRoute.snapshot.params['transactionId'];
    // this.dataSource = new MatTableDataSource([] as any);
    this.getTransactions();
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Transactions']);
    }, 0);
  }

  getTransactions() {
    this.isLoading = true;
    this.bankTransactionsService.getTransactions(this.vehicleId).subscribe({
      next: response => {
        let parsedResponse = JSON.parse(JSON.stringify(response));
        const uniqueAccount = [...new Set(parsedResponse.data.map((item: any) => item.account))]
        this.accountList = uniqueAccount.map((account: any) => {
          const accountInfo = account.split(' / ')
          return { bank: accountInfo[0], accountNo: accountInfo[1] }
        })
        this.dataSource = new MatTableDataSource(parsedResponse.data);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
          if (property === 'date') {
            return new Date(item[property] * 1000).getTime();
          }
          return item[property].toLocaleLowerCase()
        };
        this.noTransactions = !parsedResponse.data.length;
      },
      error: error => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  applyFilter() {
    const filterValue = (this.search || '').trim().toLowerCase();

    // Update filterPredicate to handle the specific columns
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const name = data.name ? data.name.toLowerCase() : '';
      return name.includes(filter);
    };

    this.dataSource.filter = filterValue;
  }

  syncTransactions() {
    console.log('sync transactions')
  }

  navigateBack() {
    // this.backClick.emit();
  }

  showDetails(transaction: any) {
    this.selecetdTransaction = transaction;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/transactions/${transaction.id}`
    );
    this.transactionId = transaction.id;
  }

  hideDetails() {
    this.transactionId = undefined;
    this._location.replaceState(
      `gp/${this.gpId}/vehicle/${this.vehicleId}/transactions`
    );
    this.commonService.setSecondaryPath([]);
    !this.transactions && this.getTransactions();
  }

  // applyFilter() {
  //   // const filterValue = (this.search || '').trim().toLowerCase();
  //   const filterData = {
  //     filterValue : (this.search || '').trim().toLowerCase(),
  //     closerFiltervalue: (this.closerValue ? this.closerValue.id : ''),
  //   }
  //   // Update filterPredicate to handle the specific columns
  //   this.dataSource.filterPredicate = (data: any, filter: string) => {
  //     const searchTerms = JSON.parse(filter);
  //     const id = data.investorId
  //       ? data.investorId.toString().toLowerCase()
  //       : '';
  //     const name = data.name ? data.name.toLowerCase() : '';
  //     const closingId = data.closingIds;
  //     return (id.includes(searchTerms.filterValue) || name.includes(searchTerms.filterValue)) && (!searchTerms.closerFiltervalue || closingId.includes(searchTerms.closerFiltervalue));
  //   };

  //   this.dataSource.filter = JSON.stringify(filterData);
  // }
}


@Component({
  selector: 'bank-transaction-detail',
  templateUrl: './bank-transaction-detail.component.html',
  styleUrls: ['./bank-transactions.component.scss']
})
export class BankTransactionDetailComponent {
  @Input() transaction: any;
  @Output() backClick = new EventEmitter();

  isLoading: boolean = false;
  transactionDetails: any;
  dateFields = ['bookingDate', 'date', 'valueDate']
  hideFields = ['id', 'bookingDate', 'valueDate', 'paymentPartner', 'tags']

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.transactionDetails = this.objectToArrayOfObjects(this.transaction)
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Transactions', 'details']);
    }, 0);
  }

  navigateBack() {
    this.backClick.emit();
  }

  objectToArrayOfObjects(obj: any) {
    return Object.entries(obj).map(([key, value]) => ({
      key: key,
      value: value
    }));
  }
}
