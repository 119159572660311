<div class="delete-container">
    <div class="content">
        <div class="heading">Delete “{{data.item}}” ?
            <button (click)="dialogRef.close()" [disabled]="loading">
                <img src="../../../../assets/images/cross_icon_grey.svg" alt="close">
            </button>
        </div>
        <div class="form-field">
            <div class="description">{{data.msg}}</div>
        </div>
    </div>
    <div class="action">
        <button mat-flat-button class="cancel" (click)="dialogRef.close()">Cancel</button>
        <button mat-flat-button class="delete" (click)="delete()" [disabled]="loading">Delete</button>
    </div>
</div>