import { Directive, AfterViewInit, Host, Input } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { MatSelect } from '@angular/material/select';

export const POS: {
  top: ConnectedPosition;
  right: ConnectedPosition;
  bottom: ConnectedPosition;
  left: ConnectedPosition;
} = {
  top: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -5,
    offsetX: 0,
    panelClass: '',
    weight: 120,
  },
  right: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetX: 0,
    offsetY: 10,
    panelClass: '',
    weight: 120,
  },
  bottom: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: 5,
    offsetX: 0,
    panelClass: '',
    weight: 120,
  },
  left: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetX: 0,
    offsetY: 10,
    panelClass: '',
    weight: 120,
  },
};
@Directive({
  selector: 'mat-select[position]',
})
export class MatSelectPositionDirective implements AfterViewInit {
  @Input('position') position: 'top' | 'bottom' | 'left' | 'right';
  constructor(@Host() private select: MatSelect) {}
  ngAfterViewInit() {
    (this.select as any)._positions.unshift(POS[this.position]);
  }
}
