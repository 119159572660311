import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/services/common.service';
import { LetterheadService } from './letterhead.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

export interface ILetterhead {
  id: string,
  name: string,
  uploadDate: string,
  isDefault: boolean
}
@Component({
  selector: 'app-letterheads',
  templateUrl: './letterheads.component.html',
  styleUrls: ['./letterheads.component.scss']
})
export class LetterheadsComponent {
  isLoading: boolean = false;
  isUploading: boolean = false;
  vehicleId: string;
  selectedFile: File | null = null;
  selectedLetterhead: ILetterhead;
  defalutLetterhead: ILetterhead;
  maxSizeMB: number = 10;
  letterHeads: ILetterhead[] = []

  constructor(
    public commonService: CommonService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private letterheadService: LetterheadService
  ) { }

  ngOnInit() {
    this.vehicleId = this.activatedRoute.snapshot.params['vehicleId'];
    this.getLetterheads();
    setTimeout(() => {
      this.commonService.setSecondaryPath(['Reporting letterheads']);
    }, 0);
  }

  getLetterheads() {
    this.isLoading = true;
    this.letterheadService
      .getAllLetterheads(this.vehicleId)
      .subscribe({
        next: response => {
          let parsedResponse = JSON.parse(JSON.stringify(response));
          console.log({ parsedResponse })
          this.letterHeads = parsedResponse.data;
          this.defalutLetterhead = this.letterHeads.filter(x => x.isDefault)[0]
        },
        error: _error => {
          this.isLoading = false;
          this.commonService.errorNotification('Failed to retrieve Letterheads')
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  openFileDialog(isReupload: boolean) {
    const targetId = !isReupload ? 'newLetterheadInput' : 'reuploadInput'
    const fileInput = document.getElementById(targetId) as HTMLInputElement;
    fileInput.click();
  }

  clearFileSelection(isReupload: boolean) {
    const targetId = !isReupload ? 'newLetterheadInput' : 'reuploadInput'
    const fileInput = document.getElementById(targetId) as HTMLInputElement;
    fileInput.value = '';
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    const maxSizeBytes = this.maxSizeMB * 1024 * 1024;
    if (this.selectedFile!.size > maxSizeBytes) {
      this.commonService.infoNotification(`Letterhead size exceeds ${this.maxSizeMB}MB. Please choose a smaller file.`)
      event.target.value = '';
    } else {
      this.uploadLetterhead();
    }
  }

  reuploadFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    const maxSizeBytes = this.maxSizeMB * 1024 * 1024;
    if (this.selectedFile!.size > maxSizeBytes) {
      this.commonService.infoNotification(`Letterhead size exceeds ${this.maxSizeMB}MB. Please choose a smaller file.`)
      event.target.value = '';
    } else {
      this.reUpload();
    }
  }

  uploadLetterhead() {
    this.isUploading = true;
    const formData = new FormData();
    this.selectedFile && formData.append('file', this.selectedFile);
    this.letterheadService.createLetterhead(this.vehicleId, formData).subscribe({
      next: _response => {
        this.getLetterheads();
        this.commonService.successNotification('Letterhead created successfully')
      },
      error: _error => {
        this.isUploading = false;
        this.clearFileSelection(false)
        this.commonService.errorNotification('Failed to create Letterhead')
      },
      complete: () => {
        this.clearFileSelection(false)
        this.isUploading = false;
      },
    });
  }

  reUpload() {
    const formData = new FormData();
    this.selectedFile && formData.append('file', this.selectedFile);
    this.letterheadService.reUploadLetterhead(this.selectedLetterhead.id, formData).subscribe({
      next: _response => {
        this.getLetterheads();
        this.commonService.successNotification('Letterhead updated successfully')
      },
      error: _error => {
        this.clearFileSelection(true)
        this.commonService.errorNotification('Failed to update Letterhead')
      },
      complete: () => {
        this.clearFileSelection(true)
      },
    });
  }

  renameLetterhead(letterhead: ILetterhead) {
    const dialogRef = this.dialog.open(RenameLetterheadDialog, {
      data: { ...letterhead, vehicleId: this.vehicleId },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getLetterheads()
        this.commonService.successNotification(
          'Letterhead renamed successfully'
        );
      } else if (result === 'error') {
        this.commonService.errorNotification(
          'Failed to rename letterhead'
        );
      }
    });
  }

  deleteLetterhead(letterhead: ILetterhead) {
    console.log(letterhead)
    const dialogRef = this.dialog.open(DeleteLetterheadDialog, {
      data: {
        ...letterhead
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getLetterheads();
        this.commonService.successNotification(
          'Letterhead deleted successfully'
        );
      } else if (result === 'error') {
        this.commonService.errorNotification(
          'Failed to delete letterhead'
        );
      }
    });
  }

  openPdfOptions() {
    this.dialog.open(PdfOptionsDialog)
  }

  uploadPdf() {
    console.log('upload')
  }

  openPreview(letterhead: ILetterhead) {
    const dialogRef = this.dialog.open(LetterHeadPreviewDialog, {
      data: {
        ...letterhead
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'error') {
        this.commonService.errorNotification(
          'Failed to retrive letterhead'
        );
      }
    });
  }

  setAsDefault(letterhead: ILetterhead) {
    this.isLoading = true;
    this.letterheadService.updateLetterhead(this.vehicleId, letterhead.id, { isDefault: true }).subscribe({
      next: _response => {
        this.getLetterheads();
        this.commonService.successNotification('Letterhead updated successfully')
      },
      error: _error => {
        this.commonService.errorNotification('Failed to update Letterhead')
      }
    });
  }

  removeDefault() {
    this.isLoading = true;
    this.letterheadService.removeDefault(this.vehicleId).subscribe({
      next: _response => {
        this.getLetterheads();
        this.commonService.successNotification('Letterheads updated successfully')
      },
      error: _error => {
        this.commonService.errorNotification('Failed to update Letterheads')
      }
    });
  }

}

@Component({
  selector: 'pdf-options-dialog',
  templateUrl: './pdf-options-dialog.html',
  styleUrls: ['./letterheads.component.scss']
})
export class PdfOptionsDialog {
  constructor(
    public dialogRef: MatDialogRef<PdfOptionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}

@Component({
  selector: 'rename-letterhead-dialog',
  templateUrl: './rename-letterhead-dialog.html',
  styleUrls: ['./letterheads.component.scss'],
})
export class RenameLetterheadDialog {
  isLoading = false;
  letterheadName: string;

  constructor(
    private letterheadService: LetterheadService,
    public dialogRef: MatDialogRef<RenameLetterheadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.letterheadName = this.data.name;
  }

  renameLetterhead() {
    this.dialogRef.disableClose = true;
    this.isLoading = true;
    this.letterheadService.updateLetterhead(this.data.vehicleId, this.data.id, { name: this.letterheadName }).subscribe({
      next: _response => {
        this.dialogRef.close('success');
      },
      error: _error => {
        this.isLoading = false;
        this.dialogRef.close('error');
      },
      complete: () => {
        this.isLoading = false;
        this.dialogRef.disableClose = false;
      },
    });
  }
}

@Component({
  selector: 'delete-letterhead-dialog',
  templateUrl: './delete-letterhead-dialog.html',
  styleUrls: ['./letterheads.component.scss'],
})
export class DeleteLetterheadDialog {
  loading = false;
  constructor(
    private letterheadService: LetterheadService,
    public dialogRef: MatDialogRef<DeleteLetterheadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  deleteLetterhead() {
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.letterheadService
      .deleteLetterhead(this.data.id)
      .subscribe({
        next: _response => {
          this.dialogRef.close('success');
        },
        error: _error => {
          this.dialogRef.close('error');
        },
        complete: () => {
          this.loading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }
}



@Component({
  selector: 'letterhead-preview-dialog',
  templateUrl: './letterhead-preview-dialog.html',
  styleUrls: ['./letterheads.component.scss']
})
export class LetterHeadPreviewDialog {
  isloading: boolean = false;
  filePath: any;

  constructor(
    private letterheadService: LetterheadService,
    public dialogRef: MatDialogRef<PdfOptionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getLetterhead()
  }

  getLetterhead() {
    this.isloading = true;
    this.dialogRef.disableClose = true;
    this.letterheadService
      .getLetterhead(this.data.id)
      .subscribe({
        next: response => {
          const blob = new Blob([response], {
            type: 'application/pdf; charset=utf-8',
          });
          this.filePath = blob;
          console.log(this.filePath)
        },
        error: _error => {
          this.isloading = false;
          this.dialogRef.close('error');
        },
        complete: () => {
          this.isloading = false;
          this.dialogRef.disableClose = false;
        },
      });
  }
}
