<div class="fino-container">
    <div class="fino-subheader">
        <ng-container *ngIf="!isLoading; else searchLoader">
            <mat-form-field class="fino-search-field">
                <img src="../../assets/images/search.svg" alt="search" matPrefix />
                <input matInput placeholder="Search" (input)="applyFilter()" autocomplete="off" [(ngModel)]="search"
                    autocomplete="off">
                <button class="clear-btn" matSuffix *ngIf="search" matSuffix (click)="search=''; applyFilter()">
                    <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px" alt="clear">
                </button>
            </mat-form-field>
        </ng-container>
        <div class="cta">
            <button [disabled]="isAddRedirect" class="add-account" *ngIf="!isLoading; else ctaBtnLoader"
                (click)="addBankAccount()">
                <ng-container *ngIf="!isAddRedirect; else spinner">
                    <img src="../../../../assets/images/plus_icon_white.svg" height="20px" width="20px" alt="add" />
                    Add account
                </ng-container>
            </button>
            <button [disabled]="isManageRedirect" class="manage-account" *ngIf="!isLoading; else ctaBtnLoader"
                (click)="manageBankAccount()">
                <ng-container *ngIf="!isManageRedirect; else spinner">
                    <img src="../../../../assets/images/edit_icon.svg" height="20px" width="20px" alt="edit" />
                    Manage accounts
                </ng-container>
            </button>
        </div>
    </div>
    <div class="fino-section">
        <div class="fino-table">
            <ng-container *ngIf="!isLoading">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
                    matSortDisableClear>
                    <ng-container matColumnDef="bank">
                        <th mat-header-cell *matHeaderCellDef> Bank </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="bank-column">
                                <img *ngIf="element.icon" [src]="element.icon" alt="logo" height="20px" width="20px">
                                {{element.bankName ? element.bankName : '-'}}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.name ? element.name : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="owner">
                        <th mat-header-cell *matHeaderCellDef> Owner </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.owner ? element.owner : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="iBan">
                        <th mat-header-cell *matHeaderCellDef> Iban </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.iban ? element.iban : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="bic">
                        <th mat-header-cell *matHeaderCellDef> Bic </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.bic ? element.bic : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="currency">
                        <th mat-header-cell *matHeaderCellDef> Currency </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.currency ? element.currency : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef> Type </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.type ? element.type : '-'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>
                            <ng-container *ngIf="!isLoading; else countLoader">
                                <div class="count-details">{{dataSource.filteredData.length}}</div>
                            </ng-container>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <img height="20px" width="20px" src="../../../../assets/images/delete_icon.svg" alt="remove"
                                (click)="deleteAccount(element)">
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                    </tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td *ngIf="!bankAccounts.length" class="mat-cell no-row" colspan="8" style="padding:1rem;">
                            <div class="no-account-found-container">
                                <img src="../../../../assets/images/invoice_empty_icon.svg" alt="no-invoice">
                                <div class="text-section">
                                    <div class="heading">No Bank accounts found</div>
                                    <div class="supporting-text">Looks like there are no bank accounts linked with this
                                        fund. Please start by adding.</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <table mat-table [dataSource]="[1,2]" class="loading-table">
                    <ng-container matColumnDef="bank">
                        <th mat-header-cell *matHeaderCellDef> Bank </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '90%',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '160px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="owner">
                        <th mat-header-cell *matHeaderCellDef> Owner </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '120px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="iBan">
                        <th mat-header-cell *matHeaderCellDef> Iban </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '230px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="bic">
                        <th mat-header-cell *matHeaderCellDef> Bic </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '90%',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="currency">
                        <th mat-header-cell *matHeaderCellDef> Currency </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '100px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef> Type </th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '100px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '70px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-container>
        </div>
    </div>
</div>

<!-- <app-bank-transactions *ngIf="showTransactions" [accountId]="selectedAccountId" [accountName]="selectedAccountName"
    [accountNumber]="selectedAccountNumber" (backClick)="hideTransactions()"></app-bank-transactions> -->

<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #countLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '180px',
            height: '24px',
            marginBottom: 0
      }"></ngx-skeleton-loader>
</ng-template>

<ng-template #ctaBtnLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '160px',
                height: '38px',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>