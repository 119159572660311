import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
export interface IAnnotationLogs {
  status: string;
  userId: string;
  filename: string;
  id?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) {}

  // GET /vehicles/{vehicleId}/reports
  getAllReports(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(`vehicles/${vehicleId}/reports`, false)
    );
  }

  // GET /vehicles/{vehicleId}/reports/{reportId}/limitedPartners
  getReportDetails(vehicleId: string, reportId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/limitedPartners`,
        false
      )
    );
  }

  // GET /vehicles/{vehicleId}/reports/{reportId}/limitedPartners/{limitedPartnerId}/details
  getLPReportDetails(
    vehicleId: string,
    reportId: string,
    limitedPartnerId: string
  ) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/limitedPartners/${limitedPartnerId}/details`,
        false
      )
    );
  }

  // GET /vehicles/{vehicleId}/reports/{reportId}/files/{fileId}
  getFilebyId(vehicleId: string, reportId: string, fileId: number) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/files/${fileId}`,
        false
      ),
      { responseType: 'arraybuffer' }
    );
  }
  savepdfAnnotation(vehicleId: string, reportId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/annotations`,
        false
      ),
      data
    );
  }
  getReportAnnotation(vehicleId: string, reportId: string, filename: any) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/annotation?filename=${filename}`,
        false
      )
    );
  }
  saveUploadReportFile(
    vehicleId: string,
    reportId: string,
    formData: FormData
  ) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/uploadfile`,
        false
      ),
      formData
    );
  }
  getDocumentFilebyFilePath(
    vehicleId: string,
    reportId: string,
    filePath: string
  ) {
    const filePathValue = encodeURIComponent(filePath);
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/filepath/${filePathValue}/getFile`,
        false
      )
    );
  }

  // get annotation logs
  getAnnotationLogStatus(vehicleId: string, reportId: string, fileName: any) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/annotationsstatus/${fileName}`,
        false
      )
    );
  }
  // create AnnotationLogs
  createAnnotationLogs(
    vehicleId: string,
    reportId: string,
    data: IAnnotationLogs
  ) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/anonnationlogs`,
        false
      ),
      data
    );
  }
  // update AnnotationLogs
  updateAnnotationLogs(
    vehicleId: string,
    reportId: string,
    data: IAnnotationLogs
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/reports/${reportId}/anonnationlogs`,
        false
      ),
      data
    );
  }
}
