<div *ngIf="(managementService.taskDetailsObservable$| async)">
    <div class="task-details-container" *ngIf="!isSuggest">
        <div class="task-header">
            <div class="toolbar">
                <div class="topic">
                    {{(managementService.taskDetailsObservable$| async)?.topic}}
                </div>
                <img src="../../../assets/images/cross_icon_grey.svg" alt="close" height="24px" width="24px"
                    (click)="managementService.closeDrawer()">
            </div>
            <div class="title-section">
                <div class="title">{{(managementService.taskDetailsObservable$| async)?.title}}</div>
                <div class="sub-text">
                    <div class="time-stamp" *ngIf="(managementService.taskDetailsObservable$| async)?.timeStamp">
                        <img *ngIf="!(managementService.taskDetailsObservable$| async)?.archived"
                            src="../../../assets/images/clock_icon_grey.svg" alt="icon" height="18px" width="18px">
                        <img *ngIf="(managementService.taskDetailsObservable$| async)?.archived"
                            src="../../../assets/images/archived_icon.svg" alt="icon" height="18px" width="18px">
                        {{(managementService.taskDetailsObservable$| async)?.archived ? 'Archived'
                        :(managementService.taskDetailsObservable$| async)?.timeStamp}}
                    </div>
                    <ng-container [ngSwitch]="(managementService.taskDetailsObservable$| async)?.status?.toLowerCase()">
                        <div class="status" *ngSwitchCase="'in progress'"
                            style="background: #F89E181A; color: #F89E18;">
                            <img src='../../../assets/images/clock_icon.svg' alt="icon" height="16x" width="16px">
                            In Progress
                        </div>
                        <div class="status" *ngSwitchCase="'completed'" style="background: #81BD2E1A; color: #81BD2E;">
                            <img src='../../../assets/images/done_circle.svg' alt="icon" height="16x" width="16px">
                            Completed
                        </div>
                        <div class="status" *ngSwitchCase="'to do'" style="background: #9A6BD71A; color: #9A6BD7;">
                            <img src='../../../assets/images/todo_icon.svg' alt="icon" height="16x" width="16px">
                            To Do
                        </div>
                        <div class="status" *ngSwitchCase="'to be staffed'"
                            style="background: #9A6BD71A; color: #9A6BD7;">
                            <img src='../../../assets/images/todo_icon.svg' alt="icon" height="16x" width="16px">
                            To be Staffed
                        </div>
                        <div class="status" *ngSwitchCase="'in planning'"
                            style="background: #9A6BD71A; color: #9A6BD7;">
                            <img src='../../../assets/images/todo_icon.svg' alt="icon" height="16x" width="16px">
                            In Planning
                        </div>
                        <div class="status" *ngSwitchCase="'proposed'" style="background: #9A6BD71A; color: #9A6BD7;">
                            <img src='../../../assets/images/todo_icon.svg' alt="icon" height="16x" width="16px">
                            Proposed
                        </div>
                        <div class="status" *ngSwitchCase="'on hold'" style="background: #AEAEAE1A;color: #AEAEAE;">
                            <img src='../../../assets/images/blocked_icon.svg' alt="icon" height="16x" width="16px">
                            On Hold
                        </div>
                        <div class="status" *ngSwitchCase="'blocked'" style="background: #AEAEAE1A;color: #AEAEAE;">
                            <img src='../../../assets/images/blocked_icon.svg' alt="icon" height="16x" width="16px">
                            Blocked
                        </div>
                        <div class="status" *ngSwitchCase="'cancelled'" style="background: #FA54301A;color: #FA5430;">
                            <img src='../../../assets/images/cross_circle.svg' alt="icon" height="16x" width="16px">
                            Cancelled
                        </div>

                        <div class="status" *ngSwitchDefault="" style="background: #9A6BD71A; color: #9A6BD7;">
                            <img src='../../../assets/images/todo_icon.svg' alt="icon" height="16x" width="16px">
                            {{(managementService.taskDetailsObservable$| async)?.status}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="subTasks.length > 0 || isLoading" class="subtask-container">
            <div class="subtask-heading">Subtasks</div>
            <ng-container *ngIf="!isLoading; else subtaskLoader">
                <div class="subtask-list">
                    <mat-accordion class="mat-elevation-z0" [multi]="true">
                        <mat-expansion-panel class="mat-elevation-z0" [disabled]="true" *ngFor="let subTask of subTasks"
                            hideToggle #row>
                            <mat-expansion-panel-header (click)="openSubTask(subTask, row)">
                                <mat-panel-title>
                                    <div class="subtask-item"
                                        [ngClass]="{'no-sub-subtask': subTask?.subSubTask?.length === 0}">
                                        <img [src]='"../../../assets/images/"+subTaskStatus[subTask.status.toLowerCase()]+".svg"'
                                            alt="icon" height="24px" width="24px"
                                            [matTooltip]="showTaskStatusTooltip(subTask.status)">
                                        {{subTask.name}}
                                        <ng-container
                                            *ngIf="subTask.subSubTask === null || subTask?.subSubTask?.length !== 0">
                                            <!-- <div *ngIf="subTask.showSpinner" class="spinner">
                                                <div class="spinner__circle">
                                                    <div class="spinner__circle-gradient"></div>
                                                    <div class="spinner__circle-inner"></div>
                                                </div>
                                            </div> -->
                                            <img *ngIf="subTask.showSpinner" class="arrow-icon"
                                                src="../../../assets/images/tube-spinner.svg" alt="spinner"
                                                height="24px" width="24px">
                                            <img *ngIf="!subTask.showSpinner" class="arrow-icon arrow-down"
                                                [ngClass]="{ 'open': row.expanded}"
                                                src="../../../assets/images/arrow_down_icon.svg" alt="icon"
                                                height="24px" width="24px">
                                        </ng-container>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="sub-subtask-item" *ngFor="let subSubTask of subTask.subSubTask">
                                <img [src]='"../../../assets/images/"+subTaskStatus[subSubTask.status.toLowerCase()]+".svg"'
                                    alt="icon" height="24px" width="24px"
                                    [matTooltip]="showTaskStatusTooltip(subSubTask.status)">
                                <!-- {{subSubTask.name}} -->
                                <div [innerHTML]="convertTextToLinks(subSubTask.name)"></div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                </div>
            </ng-container>
        </div>
    </div>
    <div class="suggest-task-container" *ngIf="isSuggest">
        <div class="suggest-task-header">
            <div class="heading">
                Suggest task
            </div>
            <img src="../../../assets/images/cross_icon_grey.svg" alt="close" height="24px" width="24px"
                (click)="managementService.closeDrawer();cancelTask()">
        </div>
        <form [formGroup]="suggestTaskForm">
            <div class="suggest-task-form">
                <div class="form-field">
                    <div class="lable">Title *</div>
                    <input type="text" class="title-field"
                        [ngClass]="{'has-error' : taskTitle?.touched && !taskTitle.valid}" placeholder="Enter a title"
                        autocomplete="off" formControlName="taskTitle">
                    <span *ngIf="taskTitle?.touched && !taskTitle.valid">
                        <img src="../../../assets/images/warning_icon.svg" height="20px" width="20px" alt="warning_icon"
                            class="input-error-icon">
                        <div class="error-message">Please enter title</div>
                    </span>
                </div>
                <div class="form-field">
                    <div class="lable">Topic *</div>
                    <mat-form-field appearance="fill" class="topic-select"
                        [ngClass]="{'error' : taskTopic?.touched && !taskTopic.valid}">
                        <mat-select placeholder="Select a topic" #select formControlName="taskTopic">
                            <mat-option class="topic-item suggest" *ngFor="let topic of topics"
                                [value]="topic.name">{{topic.name}}</mat-option>
                            <mat-option class="topic-item suggest" [value]="'Other'">Other</mat-option>
                        </mat-select>
                        <img class="arrow-down" *ngIf="!(taskTopic?.touched && !taskTopic?.valid)"
                            [ngClass]="{ 'open': select.panelOpen}" src="../../assets/images/arrow-down.svg"
                            matSuffix />
                        <img class="arrow-down" *ngIf="(taskTopic?.touched && !taskTopic.valid)"
                            [ngClass]="{ 'open': select.panelOpen}" src="../../assets/images/arrow-down-error.svg"
                            matSuffix />
                    </mat-form-field>
                    <div class="error-message" *ngIf="taskTopic?.touched && !taskTopic.valid">Please select Topic</div>
                </div>
                <div class="form-field">
                    <div class="lable">Description /Subtasks *</div>
                    <textarea type="" class="description-field"
                        [ngClass]="{'has-error' : subTaskDesc?.touched && !subTaskDesc.valid}" matInput
                        placeholder="Enter a description" autocomplete="off" formControlName="subTaskDesc"></textarea>
                    <div class="error-message" *ngIf="subTaskDesc?.touched && !subTaskDesc.valid">Please enter
                        description</div>
                </div>
                <div class="form-field">
                    <div class="lable">Attach a file</div>
                    <div class="file-upload" [ngClass]="{'has-file': files.length, 'has-error': isFileSizeExceed}"
                        (fileDropped)="onFileDropped($event)" appDnd>
                        <div class="file-upload-msg" *ngIf="!files.length">Drag and drop a file here or click the button
                            below to select a file to
                            upload.</div>
                        <label for="fileDropRef" *ngIf="!files.length">
                            <img src="../../../assets/images/upload_icon.svg" alt="icon" height="20px" width="20px">
                            Upload file</label>
                        <input *ngIf="!files.length" type="file" id="fileDropRef" #fileDropRef
                            (change)="fileBrowseHandler($event)" />
                        <div *ngIf="files.length" class="file">{{files[0].name}} <img (click)="files = []"
                                src="../../../assets/images/delete_icon.svg" alt="icon" height="20px" width="20px">
                        </div>
                    </div>
                    <div class="error-message" *ngIf="isFileSizeExceed">File size exceeds 10MB limit. Please upload a
                        smaller file.</div>
                </div>
                <div class="cta">
                    <button class="cancel" (click)="managementService.closeDrawer();cancelTask()">Cancel</button>
                    <button class="submit" type="button"
                        [disabled]="!suggestTaskForm.valid || !!isFileSizeExceed || submitted"
                        (click)="submitTask()">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #subtaskLoader>
    <div *ngFor="let number of [0, 1, 2, 3, 4]">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      width: '100%',
                      height: '45px'
                    }"></ngx-skeleton-loader>
    </div>
</ng-template>