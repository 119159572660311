import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

interface ICounterpartDetail {
  vehicleId: string;
  counterpartId?: string;
  isCreate: boolean;
  type?: string;
  tax_id?: string;
  reminders_enabled?: boolean;
  email?: string;
  legal_name?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CounterpartDetailService {
  public openDrawerFlag: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private counterpartDetails: BehaviorSubject<ICounterpartDetail | null> =
    new BehaviorSubject<ICounterpartDetail | null>(null);
  public counterpartListRefresh: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  counterpartDetailsObservable$ = this.counterpartDetails.asObservable();
  openDrawerObservable$ = this.openDrawerFlag.asObservable();
  counterpartListRefreshObservable$ =
    this.counterpartListRefresh.asObservable();

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) {}

  public openDrawer(): void {
    this.openDrawerFlag.next(true);
  }

  public closeDrawer(): void {
    this.openDrawerFlag.value && this.openDrawerFlag.next(false);
  }

  public setCounterpartDetails(data: ICounterpartDetail | null): void {
    !this.openDrawerFlag.value && this.counterpartDetails.next(data);
  }

  public setCounterpartListRefresh(data: boolean): void {
    this.counterpartListRefresh.next(data);
  }

  getCounterpartAddress(vehicleId: string, counterpartId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/addresses`,
        false
      )
    );
  }

  getCounterpartBankAccounts(vehicleId: string, counterpartId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/bank_accounts`,
        false
      )
    );
  }

  getCounterpartContacts(vehicleId: string, counterpartId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/contacts`,
        false
      )
    );
  }

  getCounterpartVatIds(vehicleId: string, counterpartId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/vat_ids`,
        false
      )
    );
  }

  createCounterpart(vehicleId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts`,
        false
      ),
      data
    );
  }

  createCounterpartContact(
    vehicleId: string,
    counterpartId: string,
    data: any
  ) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/contacts`,
        false
      ),
      data
    );
  }

  createVatId(vehicleId: string, counterpartId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/vat_ids`,
        false
      ),
      data
    );
  }

  createBankAccount(vehicleId: string, counterpartId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/bank_accounts`,
        false
      ),
      data
    );
  }

  makeDefaultBankaccount(
    vehicleId: string,
    counterpartId: string,
    bankAccountId: string
  ) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/bank_accounts/${bankAccountId}/make_default_new`,
        false
      ),
      {}
    );
  }

  makeDefaultContact(
    vehicleId: string,
    counterpartId: string,
    contactId: string
  ) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/contacts/${contactId}/make_default`,
        false
      ),
      {}
    );
  }

  updateCounterpart(vehicleId: string, counterpartId: string, data: any) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}`,
        false
      ),
      data
    );
  }

  updateAddress(
    vehicleId: string,
    counterpartId: string,
    addressId: string,
    data: any
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/addresses/${addressId}`,
        false
      ),
      data
    );
  }

  updateVatId(
    vehicleId: string,
    counterpartId: string,
    vatId: string,
    data: any
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/vat_ids/${vatId}`,
        false
      ),
      data
    );
  }

  updateContact(
    vehicleId: string,
    counterpartId: string,
    contactId: string,
    data: any
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/contacts/${contactId}`,
        false
      ),
      data
    );
  }

  updateBankAccount(
    vehicleId: string,
    counterpartId: string,
    bankAccountId: string,
    data: any
  ) {
    return this.apiHttpService.put(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/bank_accounts/${bankAccountId}`,
        false
      ),
      data
    );
  }

  deleteCounterpart(vehicleId: string, counterpartId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}`,
        false
      )
    );
  }

  deleteBankAccount(
    vehicleId: string,
    counterpartId: string,
    bankAccountId: string
  ) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/bank_accounts/${bankAccountId}`,
        false
      )
    );
  }

  deleteContact(vehicleId: string, counterpartId: string, contactId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/contacts/${contactId}`,
        false
      )
    );
  }

  deleteVatId(vehicleId: string, counterpartId: string, vatId: string) {
    return this.apiHttpService.delete(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/counterparts/${counterpartId}/vat_ids/${vatId}`,
        false
      )
    );
  }
}
