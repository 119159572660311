import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ITopicListItem } from './management.component';

export interface ITaskDetail {
  taskId?: number,
  title?: string,
  timeStamp?: string | null,
  topic?: string,
  archived?: boolean,
  status?: string,
  vehicleId?: string,
  isSuggest: boolean,
  topicList?: ITopicListItem[]
}

@Injectable({
  providedIn: 'root'
})
export class ManagementService {

  public openDrawerFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private taskDetails: BehaviorSubject<ITaskDetail | null> = new BehaviorSubject<ITaskDetail | null>(null);
  taskDetailsObservable$ = this.taskDetails.asObservable();

  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService
  ) { }

  public openDrawer(): void {
    this.openDrawerFlag.next(true);
  }

  public closeDrawer(): void {
    this.openDrawerFlag.value && this.openDrawerFlag.next(false);
  }

  public setTaskDetails(data: ITaskDetail | null): void {
    !this.openDrawerFlag.value && this.taskDetails.next(data);
  }


  getAllTasks(vehicleId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/tasks`,
        false
      )
    );
  }

  getTaskDetail(vehicleId: string, taskId: number) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/tasks/${taskId}`,
        false
      )
    );
  }

  // GET /gp-portal/v1/vehicles/{vehicleId}/subtasks/{subtaskId}/sub-subtasks
  getSubTaskDetail(vehicleId: string, subtaskId: string) {
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/subtasks/${subtaskId}/sub-subtasks`,
        false
      )
    );
  }

  getArchivedTasks(vehicleId: string, topicIds?: string[]) {
    const url = topicIds ? `vehicles/${vehicleId}/topic/tasks?onlyArchived=true&topicIds=${topicIds?.join(',')}` : `vehicles/${vehicleId}/topic/tasks?onlyArchived=true`
    return this.apiHttpService.get(
      this.apiEndpointsService.createUrl(
        url,
        false
      )
    );
  }

  // POST /gp-portal/v1/vehicles/{vehicleId}/tasks/suggest
  submitSuggestTask(vehicleId: string, data: any) {
    return this.apiHttpService.post(
      this.apiEndpointsService.createUrl(
        `vehicles/${vehicleId}/tasks/suggest`,
        false
      ),
      data
    );
  }
}
