<div class="investor-pg" *ngIf="!isShowDetails">
    <ng-container *ngIf="!noInvestor; else noDataTemplate">
        <div class="investor-tools">
            <!-- <ng-container *ngIf="!isLoading && investorData ; else countLoader">
                <div class="investor-count">{{dataSource.filteredData.length}} {{dataSource.filteredData.length !== 1 ?
                    'investors' :
                    'investor'}}</div>
            </ng-container> -->
            <ng-container *ngIf="!isLoading; else searchLoader">
                <mat-form-field class="investor-search-field">
                    <img src="../../assets/images/search.svg" alt="search" matPrefix />
                    <input matInput placeholder="Search" (input)="applyFilter()" [(ngModel)]="search"
                        autocomplete="off">
                    <button class="clear-btn" *ngIf="search" matSuffix (click)="search=''; applyFilter()">
                        <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px" alt="clear">
                    </button>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="!isLoading; else filterClosing">
                <mat-form-field appearance="fill" class="closing-mat-select">
                    <mat-select placeholder="Closing" [position]="'left'" [(value)]="closerValue" #select
                        (selectionChange)="applyFilter()">
                        <mat-select-trigger>
                            <div class="closing-placeholder">
                                {{closerValue ? closerValue.number : 'Closing'}}
                            </div>
                        </mat-select-trigger>
                        <ng-container *ngFor="let closer of closingsList">
                            <mat-option class="closing-item" [value]="closer"
                                [ngClass]="{'value-not-selected' : !closerValue}">
                                <div class="closing-mat-option-main">
                                    <div class="closing-mat-left-view">
                                        <div class="mat-number">{{closer.number}}</div>
                                        <div class="mat-date">{{closer.date ? commonService.formateDate(closer.date) :
                                            ''}}</div>
                                    </div>
                                    <ng-container *ngIf="closer.status">
                                        <div class="mat-right-view-badge in-progress"
                                            *ngIf="closer.status === 'ongoing'">
                                            <img src="../../../../assets/images/clock_icon.svg" alt="investor-icon"
                                                height="16px" width="16px" />
                                            In Progress
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-option>
                        </ng-container>
                        <div class="dropdown-clear-btn" *ngIf="closerValue">
                            <button type="button" class="btn-clear"
                                (click)="this.closerValue = null; select.close();applyFilter()">Clear</button>
                        </div>
                    </mat-select>
                    <img class="arrow-down" [ngClass]="{ 'open': select.panelOpen}"
                        src="../../assets/images/arrow-down.svg" matSuffix />
                </mat-form-field>
            </ng-container>
            <button class="investor-excel-export-btn" matTooltip="Exports all investor data in Excel format"
                mat-flat-button *ngIf="!isLoading; else downloadLoader" (click)="exportExcel()"
                [disabled]="isDownloading">
                <ng-container *ngIf="!isDownloading; else spinner">
                    <img src="../../../assets/images/export_excel_icon.svg" height="20px" width="20px" alt="export" />
                    {{(breakpointService.isSmallerHandset$ | async) ? 'Export' : 'Export as Excel'}}
                </ng-container>
            </button>
        </div>
        <div class="investor-section">
            <div class="investor-table-parent">
                <ng-container *ngIf="!isLoading">
                    <table mat-table [dataSource]="dataSource" matSort class="investor-table" matSortActive="investorId"
                        matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="investorId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Investor ID </th>
                            <td mat-cell *matCellDef="let element">
                                <!-- <p>
                                    {{element.investorId ? element.investorId : '-'}}
                                </p> -->
                                <div class="badge-circle">
                                    <span class="badge-text">{{element.investorId ? element.investorId : '-'}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let element">
                                <p>
                                    {{element.name ? element.name : '-'}}
                                </p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="element.investorType; else nullValue">
                                    <div class="investor-type-icon" *ngIf="element.investorType === 'person'">
                                        <img src="../../../../assets/images/user_icon.svg" alt="Investor Icon">
                                        {{ 'translations.investors.' + element.investorType | translate }}
                                    </div>
                                    <div class="investor-type-icon" *ngIf="element.investorType === 'entity'">
                                        <img src="../../../../assets/images/entity_icon.svg" alt="Investor Icon">
                                        {{ 'translations.investors.' + element.investorType | translate }}
                                    </div>
                                    <div class="investor-type-icon" *ngIf="element.investorType === 'multiplePersons'">
                                        <img src="../../../../assets/images/users_icon.svg" alt="Investor Icon">
                                        {{ 'translations.investors.' + element.investorType | translate }}
                                    </div>
                                    <div class="investor-type-icon" *ngIf="element.investorType === 'team'">
                                        <img src="../../../../assets/images/flag_team_icon.svg" alt="Investor Icon">
                                        {{ 'translations.investors.' + element.investorType | translate }}
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="currentClosingStatus">
                            <th mat-header-cell *matHeaderCellDef> Current closing </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="element.currentClosingStatus; else nullValue">
                                    <div class="current-closing-status-badge invited"
                                        *ngIf="element.currentClosingStatus === 'invited'">
                                        <img src="../../../../assets/images/invited_circle_icon.svg" alt="investor-icon"
                                            height="16px" width="16px" />
                                        Invited
                                    </div>
                                    <div class="current-closing-status-badge in-progress"
                                        *ngIf="element.currentClosingStatus === 'signingInProgress'">
                                        <img src="../../../../assets/images/clock_icon.svg" alt="investor-icon"
                                            height="16px" width="16px" />
                                        Signing
                                    </div>
                                    <div class="current-closing-status-badge in-progress"
                                        *ngIf="element.currentClosingStatus === 'inProgress'">
                                        <img src="../../../../assets/images/clock_icon.svg" alt="investor-icon"
                                            height="16px" width="16px" />
                                        In Progress
                                    </div>
                                    <div class="current-closing-status-badge in-progress"
                                        *ngIf="element.currentClosingStatus === 'reviewCompleted'">
                                        <img src="../../../../assets/images/checked_circle_orange.svg"
                                            alt="investor-icon" height="16px" width="16px" />
                                        Reviewed
                                    </div>
                                    <div class="current-closing-status-badge completed"
                                        *ngIf="element.currentClosingStatus === 'done'">
                                        <img src="../../../../assets/images/circle_tick_green_icon.svg"
                                            alt="investor-icon" height="16px" width="16px" />
                                        Completed
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="completedClosingsCount">
                            <th mat-header-cell *matHeaderCellDef> Completed closings </th>
                            <td mat-cell *matCellDef="let element">
                                <p>
                                    {{element.completedClosingsCount ? element.completedClosingsCount : '-'}}
                                </p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalAmount">
                            <th mat-header-cell *matHeaderCellDef> Total amount (in {{selectVehicle.currency}}) </th>
                            <td mat-cell *matCellDef="let element">
                                <p>
                                    {{element.totalAmount ? commonService.convertWithDecimal(element.totalAmount,
                                    selectVehicle) : '-'}}
                                </p>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef>
                                <ng-container *ngIf="!isLoading && investorData">
                                    <div class="count-details">{{dataSource.filteredData.length}}</div>
                                </ng-container>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <img src="../../../../assets/images/arrow_right_icon.svg" alt="Investor Details">
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showDetails(row)"></tr>
                    </table>
                </ng-container>
                <ng-container *ngIf="isLoading">
                    <table mat-table [dataSource]="[1,2,3,4,5,6]" class="investor-table">

                        <ng-container matColumnDef="investorId">
                            <th mat-header-cell *matHeaderCellDef> Investor ID </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '80px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '165px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '80px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="currentClosingStatus">
                            <th mat-header-cell *matHeaderCellDef> Current closing </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '80px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="completedClosingsCount">
                            <th mat-header-cell *matHeaderCellDef> Completed closings </th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '80px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="totalAmount">
                            <th mat-header-cell *matHeaderCellDef> Total amount (in EUR)</th>
                            <td mat-cell *matCellDef="let element">
                                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                                    width: '80px',
                                    height: '24px',
                                    marginBottom: 0
                                    }">
                                </ngx-skeleton-loader>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="details">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element"></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-template #noDataTemplate>
        <div class="no-investor-wrapper">
            <div class="no-investor-container">
                <div class="icon">
                    <img src="../../../assets/images/crm_icon.svg" alt="icon" height="24px" width="24px">
                </div>
                <div class="text-area">
                    <div class="main">No investors yet</div>
                    <div class="sub-text">Looks like there are no investors for now. Please check again later</div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
<app-investor-detail *ngIf="isShowDetails" [lpId]="limitedPartnerId" [vehicleId]="vehicleId" [gpId]="gpId"
    (backClick)="hideDetails()"></app-investor-detail>

<ng-template #spinner>
    <div style="height: 128px;display: flex;justify-content: center;align-items: center;">
        <span class="loader"></span>
    </div>
</ng-template>

<ng-template #searchLoader>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '400px',
            height: '38px',
            marginBottom: 0
        }"></ngx-skeleton-loader>
</ng-template>

<ng-template #downloadLoader>
    <div style="margin-left: auto;">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                width: '160px',
                height: '38px',
                marginBottom: 0
        }"></ngx-skeleton-loader>
    </div>
</ng-template>
<ng-template #filterClosing>
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            width: '140px',
            height: '38px',
            marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #nullValue>-</ng-template>