<div class="portfolio-container">
  <ng-container *ngIf="!noPortfolioFound; else noDataTemplate">
    <div class="portfolio-header">
      <ng-container *ngIf="!isLoading; else searchLoader">
        <mat-form-field class="investor-search-field">
          <img src="../../assets/images/search.svg" alt="search" matPrefix />
          <input matInput placeholder="Search" [(ngModel)]="search" (input)="applyFilter()" autocomplete="off" />
          <button class="clear-btn" *ngIf="search" matSuffix (click)="search = ''; applyFilter()">
            <img src="../../../assets/images/cross_icon_grey.svg" height="20px" width="20px" alt="clear" />
          </button>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="!isLoading; else downloadLoader">
        <button class="add-company-btn" (click)="addCompany()">
          <img src="assets/images/plus_icon_white.svg" /> Add company
        </button>
      </ng-container>
    </div>
    <div class="gp-portfolio-table">
      <ng-container *ngIf="!isLoading">
        <ng-container *ngFor="let data of portfolioData">
          <div class="gp-portfolio-table-cell">
            <div class="left-container" (click)="editPortfolio(data)">
              <div class="portfolio-image">
                <img *ngIf="!data.logo" src="../../assets/images/pc_icon.svg" alt="icon" height="24px" width="24px" />
                <img class="logo" *ngIf="data.logo" [src]="data.logo" alt="icon" height="48px" width="48px" />
              </div>
              <div class="portfolio-data">
                <div class="title">{{ data.name }}</div>
                <ng-container *ngIf="data.inPortfolioSince">
                  <div class="description">
                    In portfolio since
                    {{
                    data.inPortfolioSince
                    ? commonService.formateDate(data.inPortfolioSince)
                    : ''
                    }}
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="right-container">
              <div class="mat-menu-icon" [matMenuTriggerFor]="menu" mat-icon-button>
                <img src="../../../assets/images/three_dot_menu_icon.svg" alt="menu_icon" height="20px" width="20px" />
              </div>
              <mat-menu class="portfolio-mat-menu" #menu="matMenu" xPosition="before" portfolio-action-position>
                <div mat-menu-item (click)="editPortfolio(data)">
                  <img src="../../../assets/images/edit_icon.svg" alt="delete_icon" height="20px" width="20px" />
                  Edit
                </div>
                <div mat-menu-item (click)="deletePortfolio(data)">
                  <img src="../../../assets/images/delete_icon.svg" alt="delete_icon" height="20px" width="20px" />
                  Delete
                </div>
              </mat-menu>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <ng-container *ngFor="let item of [1, 2, 3, 4, 5]">
          <div class="gp-portfolio-table-cell">
            <div class="left-container">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                  width: '165px',
                  height: '30px',
                  marginBottom: 0
                }">
              </ngx-skeleton-loader>
            </div>
            <div class="right-container">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                  width: '90px',
                  height: '30px',
                  marginBottom: 0
                }">
              </ngx-skeleton-loader>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noDataTemplate>
    <div class="wrapper">
      <div class="no-portfolio-companies">
        <div class="icon">
          <img src="../../../assets/images/pc_icon.svg" alt="" />
        </div>
        <div class="text">
          <div class="heading">No companies added...</div>
          <div class="support-text">Quickly add a portfolio company</div>
        </div>
        <div class="action">
          <button mat-flat-button (click)="addCompany()">
            <mat-icon class="icon-btn">add</mat-icon>Add a company
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #searchLoader>
  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      width: '400px',
      height: '38px',
      marginBottom: 0
    }"></ngx-skeleton-loader>
</ng-template>

<ng-template #downloadLoader>
  <div style="margin-left: auto">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        width: '160px',
        height: '38px',
        marginBottom: 0
      }"></ngx-skeleton-loader>
  </div>
</ng-template>